import React from "react";

// Customizable Area Start
import {
  Box,
  Avatar,
  MenuItem,
  Menu,
  Tabs,
  Tab,
  Typography
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { imgLogo } from "../src/assets";
import ToastMessageDisplay from "./ToastMessageDisplay";


// Customizable Area End


export default class Header extends React.Component<any, any> {

  constructor(props: any) {
    super(props);

    // Customizable Area Start
    this.state = {
      openDropdown: false,
    };

    // Customizable Area End
  }

  // Customizable Area Start



  
  handleUserPermissionAccessHeaderModules = (moduleKey: string) => {   
    const permissions = localStorage.getItem('user_permissions')
   
    if(permissions == "all permission allowed") {
      return true;
    } 
    const permissionsArr = JSON.parse(permissions || "[]");
    const containsPurchaseRequisition = permissionsArr.includes(moduleKey);
    return !!containsPurchaseRequisition;   
  }

  
  


  handleTabValueChange = (event: any, value: string) => {
    if (value === "dashboard") {
      this.props.navigation.navigate("Dashboard");
    } else if (value === "createOrFind") {
      this.props.navigation.navigate("CreateOrFind");
    } else if (value === "reports") {
      this.props.navigation.navigate("MyReports");
    } else if (value === "settings") {
      this.props.navigation.navigate("Settings");
    }
  };

  handleOpenDropdown = (): void => {
    this.setState({
      openDropdown: true
    });
  };

  handleCloseDropdown = (): void => {
    this.setState({
      openDropdown: false
    });
  };


  handleLogout = (): void => {
    localStorage.removeItem("authToken")
    localStorage.removeItem("userProfile_email")
    localStorage.removeItem("userProfile_user_Name")
    this.setState({userLoginDetailsCatch : {}})
    this.props.navigation.navigate("EmailAccountLoginBlock");
    window.location.reload();
  };


  handleTabsActiveStyleChange = (activeValue: string) => {
    if (this.props.active === activeValue) {
        return newStyles.activeTabMenu
    }
    else {
        return newStyles.tabMenu
    }
  }

  handleNoMyReportsModulesPermitMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      noMyReportsModulesPermitMessage: '',
    })
  }

  handleSettingsModulesPermitMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      noSettingsModulePermitMessage: '',
    })
  }

  hasAccessPermission = () => {
    const grants = localStorage.getItem('user_permissions')
    if(grants == "all permission allowed") return true

    const permissions = ["supplier and items", "purchase orders","request for proposal", "budgets", "purchase requisitions"]
    const permissionsArr = JSON.parse(grants || "[]");

    for(const element of permissions){
      if(permissionsArr.includes(element)){
        return true
      }
    }
    
    return false
  }





  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={newStyles.mainContainer}>
          <Box>
            <Box style={newStyles.headerContainer}>
              <img src={imgLogo} style={newStyles.logoImg} />
              <Box style={newStyles.avatarContainer}>
                <Avatar
                data-testid={"header_avatar"}
                  onClick={this.handleOpenDropdown}
                  style={newStyles.avatar}
                >
                  {this.props.profile}
                </Avatar>
                {!this.state.openDropdown && (
                  <ExpandMoreIcon data-testid="header_dropdownOpen" onClick={this.handleOpenDropdown} />
                )}
                {this.state.openDropdown && (
                  <ExpandLessIcon data-testid="header_dropdownClose" onClick={this.handleCloseDropdown} />
                )}
              </Box>
            </Box>

            <Box sx={{ position: "absolute", top: "100px" }}>
              <Menu
                open={this.state.openDropdown}
                onClose={this.handleCloseDropdown}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                style={{position:"absolute",top:"4rem"}}
              >
                <MenuItem data-test-id="btnSettings">
                  <Box>
                    <Typography style={{fontWeight: "bold"}}>{this.props.loggedUserName}</Typography>
                    <Typography style={{fontSize: "12px"}}>{this.props.loggedUserEmail}</Typography>
                  </Box>
                  </MenuItem>
                  <Typography style={{border: '0.1px solid gray', maxWidth: '90%', margin: 'auto'}}></Typography>
                <MenuItem style={{fontWeight: "bold"}}  data-test-id="btnLogout" onClick={this.handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>

          <Box style={newStyles.tabsContainer}>
          <ToastMessageDisplay data-testid="noMyReportModule" message={this.state.noMyReportsModulesPermitMessage} handleClose={this.handleNoMyReportsModulesPermitMessageSnackbarClose} />
          <ToastMessageDisplay data-testid="noSettingsModule" message={this.state.noSettingsModulePermitMessage} handleClose={this.handleSettingsModulesPermitMessageSnackbarClose} />
            <Tabs
              style={{display: 'flex', alignItems: 'center' as  'center', paddingLeft:'25px'}}
              TabIndicatorProps={{
                style: {
                    display: "none", 
                }
              }}
              id={"tab-values"}
              value={this.props.active}
              onChange={this.handleTabValueChange}
            >
              {this.hasAccessPermission() && <Tab style={this.handleTabsActiveStyleChange("dashboard")}  label="Dashboard" value="dashboard" />}
              {this.hasAccessPermission() && <Tab style={this.handleTabsActiveStyleChange("createOrFind")} label="Create or Find" value="createOrFind"/>}
              {this.handleUserPermissionAccessHeaderModules('reports') && <Tab style={this.handleTabsActiveStyleChange("reports")} label="My Reports"  value="reports"/>}
              {this.handleUserPermissionAccessHeaderModules('settings') && <Tab style={this.handleTabsActiveStyleChange("settings")} label="Settings" value="settings"/>}
            </Tabs>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    },
    button: {
      textTransform: "none"
    }
  }
});


const newStyles = {
  mainContainer: {
    backgroundColor: "rgba(242, 242, 236, 1)",
    width: "100%",
    flex: "1",
    alignItems: "flex-start",
    boxSizing: "border-box" as "border-box"
  },
  headerContainer: {
    display: "flex",
    displayDirection: "column",
    flex: "1",
    position: "relative" as "relative",
    backgroundColor: "#FFFFFF",
    margin: "auto",
    height: "120px",
    width: "100vw",
    boxSizing: "border-box" as "border-box"
  },
  logoImg: {
    width: "129.63px",
    height: "40px",
    paddingLeft: "20px",
    paddingTop: "40px"
  },
  avatarContainer: {
    display: "flex",
    displayDirection: "column",
    height: "40px",
    marginLeft: "auto",
    paddingRight: "20px",
    paddingTop: "40px",
    justifyContent: "baseline"
  },
  avatar: {
    color: "white",
    backgroundColor: "blue",
    flex: "1"
  },
  avatarDropdownMenu: {
    margin: 0,
    paddingLeft: "50px",
    flex: "1"
  },
  tabsContainer: {
    position: "relative" as "relative",
    backgroundImage: "linear-gradient(#EFEFEB, #F8F8F5)",
    margin: "auto",
    height: "96px",
    width: "100vw",
    boxShadow: "#F0F0ED 0px 60px 60px -80px inset",
    boxSizing: "border-box" as "border-box"
  },
  tabMenu: {
    fontFamily: "Plus Jakarta Display",
    fontSize: "24",
    fontWeight: 400,
    padding: "15px",
    margin: "15px",
    lineSpacing: "29",
    letterSpacing: "-0.5",
    boxSizing: "border-box" as "border-box",
  },
  activeTabMenu : {
    fontFamily: "Plus Jakarta Display",
    fontSize: "24",
    fontWeight: 400,
    padding: "15px",
    margin: "15px",
    lineSpacing: "29",
    letterSpacing: "-0.5",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: '50px',
    boxSizing: "border-box" as "border-box",
  },
  menuListContainer: {
    position: "relative" as "relative",
    backgroundColor: "#FFFFFF",
    height: "86px",
    width: "96vw",
    borderRadius: "15px",
    margin: "auto",
    marginTop: "35px",
    display: "flex",
    boxSizing: "border-box" as "border-box"
  },
  menuListItems: {
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 400,
    paddingTop: "30px",
    lineSpacing: "19",
    letterSpacing: "-0.33",
    marginRight: "30px",
    justifyContent: "space-between"
  },
  dropDown: {
    minWidth: "328px",
    maxHeight: "56px",
    marginLeft: "20px",
    marginTop: "15px",
    backgroundColor: "#F2F2EC",
    fontSize: "16",
    margin: "dense",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400
  },
  dropDownItems: {
    lineSpacing: "19",
    letterSpacing: "-0.33",
    fontSize: "16",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400
  },
  divider: {
    width: "1px",
    height: "30px",
    marginLeft: "70px",
    marginTop: "28px"
  },
  tableContainer: {
    position: "relative" as "relative",
    backgroundColor: "#FFFFFF",
    width: "96vw",
    borderRadius: "15px",
    margin: "auto",
    marginBottom: "50px",
    marginTop: "25px",
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    boxSizing: "border-box" as "border-box"
  },
  tableHeaderRows: {
    lineSpacing: "17",
    letterSpacing: "-0.29",
    fontSize: "16",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400
  },
  tableBodyRows: {
    lineSpacing: "19",
    letterSpacing: "0",
    fontSize: "16",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400
  },
  icons: {
    fontSize: "medium",
    paddingRight: "10px"
  },
  iconColumn: {
    whiteSpace: "nowrap" as "nowrap"
  },
  paperRoot: {
    position: "absolute !important" as "absolute !important",
    top: "100px !important" as "100px !important "
  }
};


// Customizable Area End
