import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { setStorageData } from "../../../framework/src/Utilities";
let baseURL = require('../../../framework/src/config').baseURL

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  userProfile: string;
  userType: string;
  accountId: any,
  active: number;
  token: string;
  errorMsg: string;
  loading: boolean;
  openDropdown: boolean;
  tabValue: number;
  subTabValue: number;
  subTabValueCof: string;
  subTabValueDashboard: string,
  isRowSelected: boolean;
  subTabValueCreateOrFind: number,
  checkedCreateOrFind: boolean,
  openAddRequisitionModal: boolean,
  openEditRequisitionModal: boolean,
  openCreateNewModal: boolean,
  openNextPurchaseModal:boolean,
  openNextCustomFromRequisitionModal:boolean,
  openPdfModal:boolean,
  openShowRequisitionModal: boolean,
  prAcceptRejectActionStatusMessage: string,
  uploadedFileRequestForProposals: string,
  durationDate: any,
  deliveryDate: any,
  location: string,
  department: string,
  status: any,
  products: any[],
  prProductAll: any[],
  prProduct: any[],
  productsSearch: any[],
  productPurchaseRequisition: any[],
  editedDurationDate: string,
  editedPRGen:string,
  editedDeliveryDate: string,
  editedRequester: string,
  editedLocation: string,
  editedCatalougeItemPr: any[],
  editedCatalougeItemProductRequisition: any[],
  editedNonCatalougeItemPr: any[],
  editedDepartment: string,
  editedBillingLocation: string,
  editedShippingLocation: string,
  editedBudget: string,
  editedSupplier: string,
  editedPayment: string,
  editedTaxation: string,
  editedStatus: string,
  editedProducts: any[],
  prAcceptRejectActionStatus: string,
  isPrAcceptCheck: boolean,
  isPrRejectCheck: boolean,
  poAcceptRejectActionStatus: string,
  purchaseRequisitionList: any[],
  editedPurchaseRequisition: any,
  editedPrNumberDefault: string,
  showPurchaseRequisition: any,
  isShowPurchaseRequisition: boolean,
  showSettingsLocation: any[],
  showSettingsCategory: any[],
  showSettingsUnitMeasurement: any[],
  productsList: any[],
  statusList : any[],
  createPurchaseRequisitionLoading: boolean,
  draftPurchaseRequisitionLoading: boolean,
  editPurchaseRequisitionLoading: boolean,
  selectedProductIds: string[],
  rfpList: any[],
  rfpStatus: string,
  prStatusList: any[],
  prNumberUnique: any,
  rfpNumberUnique:any,
  poNumberUnique:any,
  prPoNumberUnique: any,
  prQuantity: string,
  prQuantityEdit: string,
  prNonCatalougeItems: string,
  prEditNonCatalougeItems: string,
  addRfpProducts:any[],
  addRfpProductsNonCatItemText: string,
  prProductId:any,
  prProductObj: any, 
  prNonCatalougeItemsKeydown: string,
  rfpCurrency: string,
  rfpCreationDate: any,
  rfpDueDate: string,
  rfpAddress: string,
  rfpDesiredDeliveryDate: string,
  rfpEmailOfSupplier: string,
  rfpEmailOfSuppliers: any[],
  rfpExistingProduct: string,
  rfpAttachment: string,
  rfpSelectExistingSuppliers: any[],
  addItemSelectExistingSuppliers: any[],
  addItemSupplier: any,
  addItemSupplierMultiple: string[],
  editedItemSupplierMultiple: string[],
  editedSupplierMultipleRequiredField: string,
  addItemSupplierNoUnique: any,
  prCatalougeItemSelectedRow: any,
  rfpFileAttachment: any,
  selectedRow: any,
  rfpSelectedRow: any,
  dashPoSelectedRow:string,
  openAddRFPModal: boolean,
  openNextRFPMainCreateFieldsModal: boolean,
  catchRfpIdForPdfCreate: any,
  openShowRFPModal: boolean,
  openEditRFPModal: boolean,
  openRfpPdfSupplierScreenModal:boolean,
  openRfpPdfSuccessModal:boolean,
  openRfpSupplierMailSeccessModal:boolean,
  openPoPdfSupplierScreenModal:boolean,
  openPoPdfSuccessModal:boolean,
  openPoSupplierMailSeccessModal:boolean,
  showRfp: any,
  dashboardOptions: string[],
  editRfp: any,
  editedRfpId: string,
  editedRfpStatus: string,
  editedRfpCurrency: string,
  editedRfpCreationDate: string,
  editedRfpDueDate: string,
  editedRfpAddress: string,
  editedRfpDesiredDeliveryDate: string,
  editedRfpEmailOfSupplier: string,
  editedRfpEmailOfSuppliers: any[],
  editedrfpMultiEmailOfSuppliersStoreArr: any,
  editedRfpExistingProduct: string,
  editedRfpAttachment: string,
  editedRfpFileAttachment: any,
  isFileEdited: boolean,
  showIsEditRFPValidation: boolean,
  editedRfpStatusRequiredError: string,
  editedRfpCurrencyRequiredError: string,
  editedRfpCreationDateRequiredError: string,
  editedRfpDueDateRequiredError: string,
  editedRfpAddressRequiredError: string,
  editedRfpDesiredDeliveryDateRequiredError: string,
  editedRfpExistingProductRequiredError: string,
  editedRfpAttachmentTypeRequiredError: string,
  editedRfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: string,
  editedRfpFileAttachmentRequiredError: string,
  editedRfpSupplierEmailMatchError: string,
  openEditRfpShowErrorModal: boolean,
  totalPagesPurchaseRequisition: number,
  currentPagePurchaseRequisition: number,
  totalPagesPurchaseRequisitionPO: number,
  currentPagePurchaseRequisitionPO: number,
  itemsPerPage: number,
  itemsPerPageProduct: number,
  suppliersList: any[],
  totalPagesRfp: number,
  currentPageRfp: number,
  showIsCreateRFPValidation: boolean,
  showIsAddRfpProductsValidation: boolean,
  addRfpProductsRequiredError:string,
  rfpStatusRequiredError: string,
  rfpCurrencyRequiredError: string,
  rfpCreationDateRequiredError: string,
  rfpDueDateRequiredError: string,
  rfpAddressRequiredError: string,
  rfpDesiredDeliveryDateRequiredError: string,
  rfpExistingProductRequiredError: string,
  rfpAttachmentTypeRequiredError: string,
  rfpAttachmentTypeMatchError: string,
  rfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: string,
  rfpFileAttachmentRequiredError: string,
  rfpSupplierEmailMatchError: string,
  openCreateRfpShowErrorModal: boolean,
  openCreatePoShowErrorModal: boolean,
  openCreatePrPoShowErrorModal: boolean,
  showIsCreatePurchaseRequisitionValidation: boolean,
  durationDateRequiredError: string,
  deliveryDateRequiredError: string,
  locationRequiredError: string,
  locationAddressRequiredError: string,
  locationIdentityCodeRequiredError: string,
  locationGstRequiredError: string,
  locationValidGstLengthError : string,
  departmentRequiredError: string,
  prStatusListRequiredError: string,
  productsRequiredError: string,
  prQuantityRequiredError: string,
  prNonCatalougeItemsRequiredError: string,
  showIsEditPurchaseRequisitionValidation: boolean,
  editedCreationDateCheckMessagePr: string,
  editedDeliveryDateCheckMessagePr: string,
  editedDurationDateRequiredError: string,
  editedDeliveryDateRequiredError: string,
  editedLocationRequiredError: string,
  editedDepartmentRequiredError: string,
  editedCatalougeItemPrRequiredError: string,
  editedNonCatalougeItemPrRequiredError: string,
  editedStatusRequiredError: string,
  editedProductsRequiredError: string,
  rfpSuppliers: any,
  rfpEditedSuppliers: any,
  selectedSupplierIds: string[],
  durationDateType: string,
  deliveryDateType: string,
  editedDurationDateType: string,
  editedDeliveryDateType: string,
  rfpCreationDateType: string,
  rfpDueDateType: string,
  rfpDesiredDeliveryDateType: string,
  editedRfpCreationDateType: string,
  editedRfpDueDateType: string,
  editedRfpDesiredDeliveryDateType: string,
  showDefaultMenuValue: boolean,
  purchaseOrdersList: any[],
  totalPagesPurchaseOrder: number,
  currentPagePurchaseOrder: number,
  purchaseOrderSelectedRow: any,
  openAddPurchaseOrderModal: boolean,
  openShowPurchaseOrderModal: boolean,
  selectPoOptions: string,
  poId: string,
  poLocation: string,
  poSupplier: string,
  poOrderDate: string,
  poFobPoint: string,
  poShippingAddress: any,
  prPoShippingAddressOptional: string,
  poShippingAddressOptional: string,
  poBillingAddress: string,
  poGstNumber: string,
  poModeOfTransit: string,
  poProduct: any[],
  poCommentForPO: string,
  poAmountCalculation: string,
  poDepartment: string,
  poBudget: string,
  poComment: string,
  editedPoId: string,
  editedPoNumber: any,
  editedPoLocation: string,
  editedPoSupplier: string,
  editedPoPaymentTermsName: string,
  poEditScreenActiveTabs: number,
  poViewScreenActiveTabs:number,
  editedPoOrderDate: any,
  editedPoFobPoint: string,
  editedPoShippingAddress: string,
  editedPoShippingAddressOptional: string,
  editedPoBillingAddress: string,
  editedPoGstNumber: string,
  editedPoModeOfTransit: string,
  editedPoProduct: any[],
  editedPoCommentForPO: string,
  editedPoAmountCalculation: string,
  editedPoDepartment: string,
  editedPoBudget: string,
  editedPoComment: string,
  poOrderDateType: string,
  openEditPurchaseOrderModal: boolean,
  editedPoOrderDateType: string,
  editedPoDeliveryDate: string,
  showPurchaseOrder: any,
  openPurchaseRequisitionListModal: boolean,
  customFromRequisitionSelectedRow: boolean,
  customFromRequisitionPoId: string,
  customFromRequisitionPoSupplier: string,
  customFromRequisitionPoOrderDate: string,
  customFromRequisitionPoFobPoint: string,
  customFromRequisitionPoShippingAddress: string,
  customFromRequisitionPoBillingAddress: string,
  customFromRequisitionPoTaxId: string,
  customFromRequisitionPoModeOfTransit: string,
  customFromRequisitionPoCommentForPO: string,
  customFromRequisitionPoAmountCalculation: string,
  customFromRequisitionPoBudget: string,
  customFromRequisitionPoComment: string,
  customFromRequisitionPoOrderDateType: string,
  openCustomFromRequisitionPoModal: boolean,
  openCustomFromRequisitionCreatePoModal: boolean,
  customFromRequisitionSelectedId: string,
  receiptsList: any[],
  totalPagesReceipt: number;
  currentPageReceipt: number;
  receiptSelectedRow: any;
  openAddReceiptModal: boolean;
  openEditReceiptModal: boolean;
  receiptSelectedPo: string;
  receiptQuantityReceived: string;
  receiptQuantityRequested: string;
  receiptReceiptDate: string;
  receiptComments: string;
  receiptAttachmentFile: any;
  editedReceiptSelectedPo: string,
  editedReceiptQuantityReceived: string;
  editedReceiptQuantityRequested: string;
  editedReceiptReceiptDate: string;
  editedReceiptComments: string;
  editedReceiptAttachmentFile: any;
  receiptDateType: string;
  editedReceiptDateType: string;
  receipt: any;
  isReceiptFileEdited: boolean;
  editedReceiptId: string;
  openShowReceiptModal: boolean;
  showReceipt: any;
  currencyList: string[];
  purchaseOrderLocationRequiredField: string,
  purchaseOrderSupplierRequiredField: string,
  purchaseOrderPaymentTermsRequiredField: string,
  purchaseOrderTaxationNameRequiredField: string,
  purchaseOrderOrderDateRequiredField: string,
  purchaseOrderFobPointRequiredField: string,
  purchaseOrderShippingAddressRequiredField: string,
  purchaseOrderBillingAddressRequiredField: string,
  purchaseOrderGstNumberRequiredField: string,
  purchaseOrderDeliveryDateRequiredField: string,
  purchaseOrderDeliveryDateErrorFormat: string,
  purchaseOrderModeOfTransitRequiredField: string,
  purchaseOrderProductsRequiredField: string,
  purchaseOrderCommentsForPoRequiredField: string,
  purchaseOrderAmountCalculationRequiredField: string,
  purchaseOrderDepartmentRequiredField: string,
  purchaseOrderBudgetRequiredField: string,
  purchaseOrderCommentRequiredField: string,
  editedPurchaseOrderLocationRequiredField: string,
  editedPurchaseOrderSupplierRequiredField: string,
  editedPurchaseOrderOrderDateRequiredField: string,
  editedPurchaseOrderDeliveryDateRequiredField: string,
  editedPurchaseOrderPaymentTermsRequiredField: string,
  editedPurchaseOrderFobPointRequiredField: string,
  editedPurchaseOrderShippingAddressRequiredField: string,
  editedPurchaseOrderBillingAddressRequiredField: string,
  editedPurchaseOrderGstNumberRequiredField: string,
  editedPurchaseOrderModeOfTransitRequiredField: string,
  editedPurchaseOrderProductsRequiredField: string,
  editedPurchaseOrderCommentsForPoRequiredField: string,
  editedPurchaseOrderAmountCalculationRequiredField: string,
  editedPurchaseOrderDeliveryDateErrorFormat: string,
  editedPurchaseOrderDepartmentRequiredField: string,
  editedPurchaseOrderBudgetRequiredField: string,
  editedPurchaseOrderCommentRequiredField: string,
  customFromRequisitionPoFobPointRequiredField: string,
  customFromRequisitionPoShippingAddressRequiredField: string,
  customFromRequisitionPoBillingAddressRequiredField: string,
  customFromRequisitionPoTaxIdRequiredField: string,
  customFromRequisitionPoModeOfTransitRequiredField: string,
  customFromRequisitionPoSupplierRequiredField: string,
  customFromRequisitionPoOrderDateRequiredField: string,
  customFromRequisitionPoCommentForPORequiredField: string,
  customFromRequisitionPoAmountCalculationRequiredField: string,
  customFromRequisitionPoBudgetRequiredField: string,
  customFromRequisitionPoCommentRequiredField: string,
  showIsCreatePurchaseOrderValidation: boolean,
  showIsEditPurchaseOrderValidation: boolean,
  showIsCreateCustomFromRequisitionValidation: boolean,
  receiptQuantityReceivedRequiredField: string;
  receiptQuantityRequestedRequiredField: string;
  receiptReceiptDateRequiredField: string;
  receiptCommentsRequiredField: string;
  receiptAttachmentFileRequiredField: any;
  editedReceiptQuantityReceivedRequiredField: string;
  editedReceiptQuantityRequestedRequiredField: string;
  editedReceiptReceiptDateRequiredField: string;
  editedReceiptCommentsRequiredField: string;
  editedReceiptAttachmentFileRequiredField: any;
  showIsCreateReceiptValidation: boolean;
  showIsEditReceiptValidation: boolean,
  openAddRFPDownloadFiles: boolean,
  dashboardOptionSelected: string,
  totalPagesSupplier: number,
  currentPageSupplier: number,
  openAddSupplierModal: boolean,
  supplierSupplierName: string,
  supplierUniqueCode: string,
  supplierTermsOfPayment: string,
  paymentTermsNameSuppliers: any[],
  quantityErrDashPageMessage: string,
  quantityErrorPRCreateMessage: string,
  quantityErrorPREditMessage: string,
  supplierEmailAddress: string,
  supplierContactNumber: string,
  supplierPointOfContact: string,
  supplierAddressOfSupplierCompany: string,
  supplierGSTNumber: string,
  supplierSupplierLocation: string,
  editedSupplierSupplierName: string,
  editedSupplierUniqueCode: string,
  editedSupplierTermsOfPayment: string,
  editedSupplierEmailAddress: string,
  editedSupplierContactNumber: string,
  editedSupplierPointOfContact: string,
  editedSupplierAddressOfSupplierCompany: string,
  editedSupplierGSTNumber: string,
  editedSupplierSupplierLocation: string,
  editedSupplierStateProvince: string,
  editedSupplierZipCodePinCode: any,
  editedSupplierPaymentList: any[],
  supplierSelectedRow: any,
  openEditSupplierModal: boolean,
  editedSupplierId: string,
  showSupplier: any,
  openShowSupplierModal: boolean,
  currentPageProduct: number,
  totalPagesProduct: number,
  productSelectedRow: any,
  productName: string,
  productPartId: string,
  productDescription: string,
  productUnitMeasurement: string,
  productUnitPrice: string,
  productCategory: string,
  productType: string,
  editedProductName: string,
  editedAddNotes: string,
  editedProductPartId: string,
  editedProductDescription: string,
  editedProductUnitMeasurement: string,
  editedProductUnitPrice: string,
  editedProductCategory: string,
  editedProductType: string,
  openAddProductModal: boolean,
  openEditProductModal: boolean,
  openShowProductModal: boolean,
  editedProductId: string,
  showProduct: any,
  budgetName: string,
  budgetFor: string,
  budgetPeriod: string,
  budgetAmount: any,
  budgetComment: string,
  budgetProductCategories: string,
  editedBudgetId: string,
  editedBudgetName: string,
  editedBudgetStartingDate: string,
  editedBudgetFor: string,
  editedBudgetPeriod: string,
  editedBudgetAmount: string,
  editedBudgetComment: string,
  editedBudgetProductCategories: string,
  budgetListing: any[],
  totalPagesBudget: number,
  currentPageBudget: number,
  budgetSelectedRow: any,
  openAddBudgetModal: boolean,
  openEditBudgetModal: boolean,
  openShowBudgetModal: boolean,
  openShowSettingLocationModal: boolean,
  openEditSettingLocationModal: boolean,
  openEditSettingCategoryModal: boolean,
  openEditSettingUnitMeasurementModal: boolean,
  showBudget: any,
  showSettingsLocationSingleData: any,
  showSettingsLocationAPICallId: any,
  productNameRequiredField: string,
  productPartIdRequiredField: string,
  productDescriptionRequiredField: string,
  productUnitMeasurementRequiredField: string,
  productUnitPriceRequiredField: string,
  productCategoryRequiredField: string,
  productTypeRequiredField: string,
  editedProductNameRequiredField: string,
  editedProductPartIdRequiredField: string,
  editedProductDescriptionRequiredField: string,
  editedProductUnitMeasurementRequiredField: string,
  editedProductUnitPriceRequiredField: string,
  editedProductCategoryRequiredField: string,
  editedProductTypeRequiredField: string,
  supplierNameRequiredField: string,
  supplierUniqueCodeRequiredField: string,
  supplierTermsOfPaymentRequiredField: string,
  supplierEmailAddressRequiredField: string,
  supplierEmailAddressEmailMatchError: string,
  supplierContactNumberRequiredField: string,
  supplierContactNumberMatchError: string,
  supplierPointOfContactRequiredField: string,
  supplierAddressOfSupplierCompanyRequiredField: string,
  supplierGstNumberRequiredField: string,
  supplierStateProvinceRequiredField: string,
  supplierZipCodePinCodeRequiredField: string,
  supplierStateProvince: string,
  editSupplierStateProvince: string,
  supplierZipCodePinCode: string,
  supplierLocationRequiredField: string,
  editedSupplierNameRequiredField: string,
  editedSupplierUniqueCodeRequiredField: string,
  editedSupplierTermsOfPaymentRequiredField: string,
  editedSupplierValidGstLengthError: string,
  editedSupplierEmailAddressRequiredField: string,
  editedSupplierEmailAddressEmailMatchError: string,
  editedSupplierContactNumberRequiredField: string,
  editedSupplierContactNumberMatchError: string,
  editedSupplierPointOfContactRequiredField: string,
  editedSupplierAddressOfSupplierCompanyRequiredField: string,
  editedSupplierGstNumberRequiredField: string,
  editedSupplierZipCodePinCodeRequiredField: string,
  editedSupplierStateProvinceRequiredField: string,
  editedSupplierLocationRequiredField: string,
  budgetNameRequiredField: string,
  budgetForRequiredField: string,
  budgetPeriodRequiredField: string,
  budgetAmountRequiredField: string,
  budgetCommentRequiredField: string,
  budgetProductCategoriesRequiredField: string,
  editedBudgetNameRequiredField: string,
  editedBudgetForRequiredField: string,
  editedBudgetPeriodRequiredField: string,
  editedBudgetAmountRequiredField: string,
  editedBudgetCommentRequiredField: string,
  editedBudgetProductCategoriesRequiredField: string,
  showIsCreateSupplierValidation: boolean,
  showIsEditSupplierValidation: boolean,
  showIsCreateProductValidation: boolean,
  showIsEditProductValidation: boolean,
  showIsCreateBudgetValidation: boolean,
  showIsEditBudgetValidation: boolean,
  customFromRequisitionSelectMessage: string,
  showSettings: boolean,
  showInformation: boolean,
  showCurrencySettings: boolean,
  showLocationSettings: boolean,
  showUsers: boolean,
  informationSettingsCompanyName: string,
  settingsLocation: string,
  settingsAddress: string,
  settingsCategory: string,
  settingsCategoryRequiredField: string,
  editSettingsCategoryRequiredField: string,
  categorySettingCreatePost: boolean,
  categorySettingUpdation:boolean,
  settingsUnitMeasurement: string,
  unitMeasurementSettingPost: boolean,
  settingsUnitMeasurementRequiredField: string,
  settingsIdentificationCode: string,
  settingsGst: string,
  editSettingsGst: string,
  editSettingsId: string,
  createSettingsCategoryMessage:string,
  editSettingsCategoryId: string,
  editSettingsLocation: string,
  editSettingsCategorySL: string,
  editSettingsUnitMeasurementId: string,
  editSettingsUnitMeasurementSL: string,
  editSettingsCategoryName: string,
  editSettingsUnitMeasurement: string,
  editSettingsAddress: string,
  editSettingsIdentificationCode: string,
  informationSettingsPhoneNumber: string,
  informationSettingsAddress: string,
  informationSettingsEmail: string,
  informationSettingsPinCode: string,
  informationSettingsWebsite: string,
  informationSettingsGstNumber: string,
  currencySettingsCurrency: string,
  enableEditInformationSettings: boolean,
  enableEditCurrencySettings: boolean,
  informationSettings: any,
  currencySettings: any, 
  locationSettingsNameOfLocation: string,
  locationSettingsAddress: string,
  locationSettingsLocationIdentificationCode: string,
  locations: any[],
  selectedCurrencyCurrencySettings: string,
  openAddLocationsModal: boolean,
  locationsList: any[],
  showUserSettings: boolean,
  showCategorySettings: boolean,
  showUnitMeasureSettings: boolean,
  openAddUserModal: boolean,
  usersList: any[],
  userSettingsName: string,
  userSettingsRoles: string,
  userSettingsDesignation: string,
  userSettingsEmailId: string,
  userSettingsDepartment: string,
  productCategoriesList: any[],
  departmentList: any[],
  budgetList: any[],
  supplierList: any[],
  getPaymentTermListSupplier: any[],
  paymentTermsList: any[],
  taxations: any[],
  showPaymentTermsSettings: boolean,
  showTaxationSettings: boolean,
  paymentTermsName: string,
  PaymentTermDeleteMessage:string,
  unitMeasurementDeleteMessage:string,
  unitMeasurementCreateMessage: string,
  unitMeasurementUpdateMessage: string,
  creditDays: string,
  taxName: string,
  taxPercentage: string,
  editPaymentTermsName: string,
  editCreditDays: string,
  paymentTermId: any,
  editTaxName: string,
  editTaxPercentage: string,
  taxationId: any,
  showEditPaymentTermsSettingField: boolean,
  showEditPaymentTermsSettingBtn: boolean,
  showEditTaxationSettingBtn: boolean,
  showEditTaxationEditField: boolean,
  showViewTaxationSetting : boolean,
  showEditTaxationEditBtn: boolean,
  rfpAttachmentDropdownDisplay: boolean,
  locationDropdownList: any[],
  purchaseRequisitionSearchLocation: string,
  receiptSelectedPOPopulatedData: any,
  rfpEmailOfSupplierEmailPatternError: boolean,
  editedRfpEmailOfSupplierEmailPatternError: boolean,
  users: any[],
  receiptAttachment: string,
  receiptAttachmentDropdownDisplay: boolean,
  editedReceiptAttachment: string,
  editedReceiptAttachmentDropdownDisplay: boolean,
  receiptSelectedPoRequiredField: string,
  rfpEmailOfSupplierEmailDuplicateError: boolean,
  editedRfpEmailOfSupplierEmailDuplicateError: boolean,
  editedReceiptAttachmentTypeMatchError: string,
  openUserPermissionsModal: boolean,
  userPermissionsList: {id: number, title: string, status: boolean}[],
  userPermissionsList1: any,
  dateValidationError: string,
  alreadyCreatedUsers: any[],
  userIdForUserAccessPermission: string,
  // dashboard state variables start
  dashboardMenuSelection: string,
  dropDownItemValue: string,
  dashboardShowHeader: boolean,
  currentPage: number,
  currentPagePaymentTerms: number,
  currentPageTaxations: number,
  currentPageUnitMeasure: number,
  currentPageCategory: number,
  currentPageSettingsLocation: number,
  noOfPages: number,
  noOfPagesPaymentTerms: number,
  noOfPagesTaxations: number,
  noOfPagesUnitMeasure: number,
  noOfPagesCategory: number,
  noOfPagesSettingsLocation: number,
  dashboardRecordResults: any[],
  rfpData: any,
  selectedDepartmentPRIds: number[],
  departmentsList: any[],
  editedPoSupplierId: number,
  editedPoTaxId: string,
  editedPurchaseOrderTaxIdRequiredField: string,
  selectedDepartmentPOIds: number[],
  selectedDashboardProductIds: string[],
  selectedDashboardStatus: string[],
  editedDashboardPoSupplier: string,
  editedBudgetProductCategoriesId: number,
  editedBudgetForId: number,
  saveLocationsValidation: boolean,
  saveUsersValidation: boolean,
  showIsCreateInformationValidation: boolean,
  informationSettingsCompanyNameRequiredField: string,
  informationSettingsPhoneNumberRequiredField: string,
  informationSettingsPhoneNumberMatchError: string,
  informationSettingsAddressRequiredField: string,
  informationSettingsEmailRequiredField: string,
  informationSettingsEmailMatchError: string,
  informationSettingsPinCodeRequiredField: string,
  informationSettingsPinCodeMatchError: string,
  informationSettingsWebsiteMatchError: string,
  informationSettingsWebsiteRequiredField: string,
  informationSettingsGstNumberRequiredField: string,
  showIsCreateCurrencyValidation: boolean,
  showIsCreatePaymentTermsValidation: boolean,
  paymentTermNameRequiredField: string,
  paymentTermNameRequiredFieldEdit: string,
  paymentTermCreditDaysRequiredFieldEdit: string,
  paymentTermCreditDaysRequiredField: string,
  showIsEditPaymentTermsValidation: boolean,
  showIsUpdateTaxationValidation: boolean,
  showIsCreateTaxationValidation: boolean,
  taxNameRequiredField: string,
  taxPercentageRequiredField: string,
  editTaxNameRequiredField: string,
  editTaxPercentageRequiredField: string,
  paymentTermFoundOrNotMessage: string,

  currencySettingsCurrencyRequiredField: string,
  // dashboard state variables end
  purchaseRequisitionCreateMessage: string,
  purchaseRequisitionDraftMessage: string,
  purchaseOrderDraftMessage: string,
  editedPurchaseOrderDraftMessage: string,
  purchaseRequisitionEditMessage: string,
  rfpCreateMessage: string,
  rfpCreateMessagedDueDate: string,
  rfpEditMessage: string,
  poCreateMessage: string,
  poEditMessage: string,
  receiptCreateMessage: string,
  receiptQuantityError: string,
  receiptSelectFileTypeMessage: string,
  receiptEditMessage: string,
  supplierCreateMessage: string,
  supplierEditMessage: string,
  productCreateMessage: string,
  productEditMessage: string,
  budgetCreateMessage: string,
  budgetEditMessage: string,
  informationSettingsEditMessage: string,
  currencySettingsEditMessage: string,
  userCreateMessage: string,
  userDeleteMessage: string,
  categoryDeleteMessage:string,
  categoryAddMessage: string,
  locationCreateMessage: string,
  locationCreateMessagePr: string,
  deliveryOrDurationDateCheckMessagePr: string,
  creationDateCheckMessagePr: string,
  deliveryDateCheckMessagePr: string,
  locationDeleteMessage: string,
  locationUpdateMessage: string,
  paymentTermsCreateMessage: string,
  paymentTermsEditMessage: string,
  paymentTermsDeleteMessage: string,
  paymentTermsFetchMessage: string,
  taxationsCreateMessage: string,
  taxationEditMessage: string,
  taxationsDeleteMessage: string,
  productTypesList: any[],
  itemTypesList: any[],
  budgetForList: any[],
  productTypeSelectedId: string,
  budgetForSelectedId: string,
  editedProductTypeSelectedId: string,
  editedBudgetForSelectedId: string,
  productUnitPriceMatchError: string,
  productSupplierRequiredField: string,
  editedProductUnitPriceMatchError: string,
  budgetAmountMatchError: string,
  editedBudgetAmountMatchError: string,
  poGSTMatchError: string,
  supplierGSTMatchError: string,
  editedPoGSTMatchError: string,
  editedSupplierGSTMatchError: string,
  rolesList: any[],
  productList: any[],
  supplierFoundOrNotMessage: string,
  productsFoundOrNotMessage: string,
  startingFromDate: any,
  startingFromDateRequiredField: string,

  // new RFP states

  rfpUniqNumberRequiredError: string,
  rfpCreateScreenActiveTabs: number,
  isRfpCreationAndApiSuccessDone: boolean,
  rfpLocation: string,
  rfpLocationRequiredError: string,
  rfpDepartments: string,
  rfpDepartmentRequiredError: string,
  rfpSelectExistingSupplier: string[],
  rfpSelectExistingSupplierLabel: string[],
  rfpNonCatalougeCollectedItems: string,
  filteredRfpProducts: any[],
  rfpEmailToSupplierSentOrNotErrorMessage: string,
  isRfpMailSendConfirmed: boolean,
  isRfpMailSending : boolean,
  isRfpCreating: boolean,
  rfpFinalPdfDownloadOrNotMessage: string, 
  catchRfpEmailSendToSupplier: any,

  // new PO states
  poCreateScreenActiveTabs: number,
  poEmailToSupplierSentOrNotErrorMessage: string,
  poMailSendConfirmed: boolean,
  poMailSending : boolean,
  poCreating: boolean,
  poFinalPdfDownloadOrNotMessage: string, 
  poEmailSendToSupplier: any,
  poNonCatalougeCollectedItem: string,
  editedPoNonCatalougeCollectedItem: string,
  poEditing: boolean,
  poAttachment: string,
  filteredPoProducts: any[],
  catchPoIdForPdfCreate: any,
  catchPoMailOfSupplier: any,
  isPoCreationAndApiSuccessDone: boolean,
  isPoMailSendConfirmed: boolean,
  isPoMailSending : boolean,
  isPdfDownloaded: boolean,
  idReceiptPdfDownloaded: boolean,
  editedPoSaveDraft: boolean,
  poSaveDraft: boolean,
  prPoCreateMessage: string,
  editedPoProductCatalouge: any[],
  editedPoProductNonCatalouge: any[],
  isEditedPdfDownloaded:boolean,
  editedPoFinalPdfDownloadOrNotMessage:string,
  isEditedPoMailSendConfirmed:boolean,
  openEditPoShowErrorModal: boolean,
  editedFinalPdfDownloadOrNotMessage:string,
  editedPoEmailToSupplierSentOrNotErrorMessage:string,
  isEditedPoMailSending: boolean,
  catchEditedPoMailOfSupplier: string,
  editedPoPaymentTermList: any,
  openEditedPoPdfSupplierScreenModal:boolean,
  openEditedPoSupplierMailSeccessModal:boolean,
  isReceiptPdfDownloaded: boolean,
  receiptPdfSuccessMessage: string,
  editedPoNonCatlogItems:any,
  isEditedPoCreationAndApiSuccessDone: boolean,
  editedPoSuccessOrNotMessage: string,
  isDeleteRecordPO: number,
  selectBudgetIsBlankMessage: string,
  selectTaxationIsBlankMessage: string,

  // new pr po states
  prPoCreateScreenActiveTabs:number,
  prPoSelectedRequisitionBudgetError:string,
  prPoSelectedRequisitionSupplierError:string,
  prPoSelectedRequisitionPaymentError:string,
  prPoSelectedRequisitionTaxationError:string,
  prPoSelectedRequisitionShippingError:string,
  prPoSelectedRequisitionBillingError:string,
  prPoSelectedRequisitionDepartment:any,
  prPoSelectedRequisitionNumber:string,
  customFromRequisitionCataItemData: any[],
  customFromRequisitionNonCataItemData: any[],
  prPoSelectedRequisitionCreationDate:string,
  prPoSelectedRequisitionDeliveryDate:string,
  prPoItemsNotFoundMessage: string,
  showPurchaseOrderProductsData:string,
  showIsCreatePrPurchaseOrderValidation: boolean,
  openPrPoPdfSupplierScreenModal: boolean,
  openPrPoSupplierMailSeccessModal: boolean,
  prPoCreating: boolean,
  isPrPoMailSendConfirmed: boolean,
  catchPrPoMailOfSupplier: any,
  isPrPoMailSending: boolean,
  prPoEmailToSupplierSentOrNotErrorMessage: string,
  isPrPoCreationAndApiSuccessDone: boolean,
  prPoFinalPdfDownloadOrNotMessage: string,
  catchPrPoIdForPdfCreate: any,
  prPoSaveDraft: boolean,

  // new reciept states

  receiptNumberUnique : any,
  receiptSupplierName : string,
  receiptSupplierNameRequiredField: string,
  receiptInvoiceNumber : string,
  receiptInvoiceNumberRequiredField : string,
  receiptAttachmentRequiredField: string,
  receiptSelectedPOtSearchTerm: string,
  locationExistOrNotErrorMessage: string,
  locationExistOrNotCheckingRequiredError:string,
  recieptsPOSelectionDataArr : any[],
  recieptsPORemainingQuantityDataArr: any[],
  receiptCatelogueItemsStore: any[],
  receiptBasedOnStatus: any,
  receiptNonCatelogueItemsStore: any[],
  receiptNumberUniqueRequiredField: string,
  openReceiptCreateShowErrorModal: boolean,
  isShowReceiptPdfDownloadBtn: boolean,
  isShowReceiptOpenPdfModalSuccess: boolean,
  isReceiptCreating: boolean,
  isReadOnlyReceiptField: boolean,
  receiptCatProductsIdCatch : any,
  receiptNonCatProductsIdCatch : any,
  

  //dash rfp new states

  dashboardItemsPerPage: number,
  dashboardNoOfPages:number,
  editedExistingRfpNumber : string,
  editedRfpLocation: string,
  editedRfpDepartment: string,
  editedRfpSelectExistingSupplier: any[],
  editedRfpSelectExistingSupplierLabels: string[],
  editedRfpCatelogItems: any[],
  editedRfpNonCatelogItems:any[],
  editedRfpScreenActiveTabs : number,
  rfpUpdateMessage: string,
  editedFilteredRfpProducts: any[],
  openEditedRfpPdfSupplierScreenModal:boolean,
  openEditedRfpShowErrorModal: boolean,
  isEditedRfpUpdationApiSuccessDone: boolean,
  isEditedRfpMailSendConfirmed:boolean,
  isEditedRfpMailSending:boolean,
  isRfpUpdating:boolean,
  isEditedRFPFileDownloaded:boolean,
  editedRfpLocationRequiredError:string,
  editedRfpDepartmentRequiredError:string,
  editedRfpNonCatalougeCollectedItems:string,
  editedRfpEmailToSupplierSentOrNotErrorMessage : string,
  catchEditedRfpIdForPdfUpdation: any,
  openEditedRfpSupplierMailSeccessModal:boolean,
  catchEditedRfpEmailSendToSupplier:any,
  rfpCatelogDeletedItemsArr: any[],
  rfpNonCatelogDeletedItemsArr: any[],
  dashboardNoRecordMessage: string,
  editedRfpFinalPdfDownloadOrNotMessage: string,

  // new user setting states
  userSettingAddUserName: string,
  userSettingAddUserDesignation: string,
  userSettingAddUserEmailId: string,
  userSettingAddUserRole: string,

  userSettingNameRequiredError: string,
  userSettingDesignationRequiredError: string,
  userSettingEmailRequiredError: string,
  userSettingEmailInvalidError: string,
  userSettingRoleRequiredError: string,

  catchUserSettingsCreatedUserId:any,
  selectedUserPermissions: any[],
  selectAllPermissions : boolean,
  isUserCreating : boolean,
  editedSelectedUserPermissions: any[],
  editedSelectAllPermissions : boolean,
  openEditedUserPermissionsModal : boolean,
  userSettingSelectedRow: any,
  editedCatchIdForUserPermissionsUpdation: any,
  userSettingPermissionUpdatedMessage: string,
  editedCatchCoreUserCreatedAccountId: any,
  isUserSettingPermissionsUpdating: boolean,
  isUserPermissionsCreating : boolean,
  userPermissionsCreatedMessage : string,
  userPermissionMissingIdErrorMessage: string,
  createUserPermissionsValidation: boolean,

  userLoginDetailsCatch : any,
  noDashModulePermitMessage: any,
  isDashBtnDisabled : boolean,
  isCreateOrFindTabsDisabled : boolean,
  noCreateOrFindTabModulesPermitMessage: any,
  isMyReportsDisabled : boolean,
  isSettingsDisabled:boolean,
  noMyReportsModulesPermitMessage : string,
  noSettingsModulePermitMessage:string,
  supplierContactNumberErrorMessage: boolean,
  supplierContactNumberValidationError : string,
  supplierValidGstLengthError: string,
  supplierEmailInvalidPatternError: boolean,


  settingsCategoryFeildError:string,
  supplierPointContactNumberValidationError : string,
  supplierPointOfContactNumberErrorMessage: boolean,
  categoryUpdateMessage: string,

  informationSettingEmailInvalidPatternError : boolean,
  infoSettingContactNumberErrorMessage: boolean,
  informationSettingsPhoneNumberDigitLimitError: string,
  informationSettingsComapanyNameRequiredField: string,
  informationSettingsGSTNumberRequiredField: string,
  unitMeasurementSettingsDuplicasyMessage: string,
  categoryDuplicasyErrorMessage: string,
  filterMultiItemsState : string,
  itemsSearchBySpecificValue : string,
  dashboardSearchBySpecificValue: string,
  anchorEl: any,
  isFilterSelectOpen : boolean,
  mainSearchKeysData : {
    lable: string;
    key: string;
    assosiated_model?: string,
    selected : boolean;
  }[],
  isOpenDropdownSelectSearch : boolean,
  isMenuForSearchOpen : boolean,
  mainSearchCOFItemsKeysData : {
    lable: string;
    key: string;
    assosiated_model?: string;
    selected: boolean;
  }[],
  cofItemsFilterMultiState : string,
  isCOFItemsMenuForSearchOpen : boolean,
  anchorElForCOFItems : any,
  itemsCofSearchBySpecificValue : string,
  mainSearchSettingItemsKeysData : {
    lable: string;
    key: string;
    selected : boolean;
  }[],
  itemsSettingSearchBySpecificValue : string,
  settingItemsFilterMultiState : string,
  anchorElForSettingsScreens : any,
  isSettingsScreensMenuForSearchOpen : boolean,
  associetedItemNameForMainSearch : string,
  associetedItemNameForItemProductsSearch : string,
  receiptAttachmentTypeMatchError : string,
  isReceiptPoNumberFoundError: string,
  receiptAttachmentRequiredError : string,
  createOrFindNoRecordsFoundPopup : string,
  searchFilterEmptyPopup : string,
  itemsSearchValueEmptyPopup : string,
  cofItemsSearchFilterEmptyPopup : string,
  cofItemsSearchQuerryEmptyPopup : string,
  createOrFindItemsNoRecordsFoundPopup : string,
  settingsSearchFilterEmptyPopup : string,
  settingsItemsSearchValueEmptyPopup : string,
  settingsNoRecordsFoundPopup : string,
  locationCharError : string,
  editedLocationCharError : string,
  editedLocationAddressRequiredError : string,
  editedLocationIdentityCodeRequiredError : string,
  editedLocationGstRequiredError : string,
  editedLocationValidGstLengthError: string,
  editSaveLocationsValidation : boolean,
  suppliersPaymentTermCatchedLables : string[],
  isEditedSupplierPaymentListOpenOrClosed : boolean,
  isSupplierPaymentListOpenOrClosedForUpdate : boolean,
  editedPaymentTermsNameSuppliers : any[],
  editedSuppliersPaymentTermCatchedLables : any[],
  isSupplierPaisEditedSupplierPaymentListOpenOrClosedForUpdate : boolean,
  paymentTermCreditDaysInvalidMatchRequiredField : string,
  addItemSupplierMultipleOpenOrClosed : boolean,
  addItemSupplierMultipleLables : string[],
  updatedItemSupplierMultipleLables : string[],
  isUpdatedItemSupplierMultipleOpenOrClosed : boolean,
  supplierZipCodePinCodeLengthInvalidError : string,
  taxationDeleteMessage : string,
  isCategorySettingGotResponseOrNot : boolean,
  isUnitMeasureSettingGotResponseOrNot : boolean,
  isPaymentTermSettingGotResponseOrNot: boolean,
  isLocationSettingGotResponseOrNot: boolean,
  isUsersSettingGotResponseOrNot : boolean,
  paymentTermShowViewApiRespObj : any,
  openShowPaymentTermsModal : boolean,
  taxationShowViewApiRespObj : any,
  isPRGetApicalled: boolean,
  isRFPGetApicalled: boolean,
  isPOGetApicalled : boolean,
  isRecieptcalled : boolean,
  isSupplierscalled : boolean,
  isItemscalled : boolean,
  isBudgetcalled: boolean,
  loggedUserNameCatch : string,
  loggedUserEmailCatch : string,
  informationSettingNoOfPeopleWhoWillAccess : string,
  informationSettingsSelectInfoSize : string,
  informationSettingsSelectInfoSizeRequiredField : string,
  informationSettingsNoPeopleRequiredField : string,
  informationSettingsNoPeopleLengthValidationField : string,
  infoSettingGstLengthError : string,
  taxSerialNumber : number,
  locationSerialNumber : number,
  unitMeasureSerialNumber : number,
  categorySerialNumber:number,
  payTermsSerialNumber : number,
  
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  dashboardAllRecordGetApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllDashboardOptionsAPICallId: string = "";
  getAllPRStatusesAPICallId: string = "";
  createPurchaseRequisitionAPICallId: string = "";
  editPurchaseRequisitionAPICallId = "";
  dashboardAcceptRejectActionsPurchaseRequisitionAPICallId = ""
  showPurchaseRequisitionAPICallId: string = "";
  showSettingsLocationAPICall: string = "";
  showSettingsCategoryAPICall: string = "";
  showSettingsUnitMeasurementAPICall: string = "";
  deleteSettingUnitMeasurementAPICallId:string = "";
  getUserProfileAPICallId: string = "";
  getAllPurchaseRequisitionListAPICallId: string = "";
  getAllProductsListAPICallId: string = "";
  getSearchedProductAPICallId: string = "";
  callDownloadPdfApi: string = "";
  callMailSendApi: string = "";
  getAllRFPListAPICallId: string = "";
  getRfpSupplierMailSendApiCallId :string = "";
  createRfpAPICallId: string = "";
  showRfpAPICallId: string = "";
  editRfpAPICallId: string = "";
  getAllSuppliersListAPICallId: string = "";
  getAllPurchaseOrderListAPICallId: string = "";
  createPurchaseOrderAPICallId: string = "";
  editPurchaseOrderAPICallId: string = "";
  showPurchaseOrderAPICallId: string = "";
  getPoSupplierMailSendApiCallId : string = "";
  getPrPoSupplierMailSendApiCallId: string = "";
  createPurchaseRequisitionOrderAPICallId: string = "";
  getPoEditSupplierMailSendApiCallId: string = "";
  createCustomFromRequisitionPoAPICallId: string = "";
  getAllReceiptsAPICallID: string = "";
  createReceiptAPICallID: string = "";
  editReceiptAPICallID: string = "";
  showReceiptAPICallId: string = "";
  getCurrencyListAPICallId: string = "";
  getRfpFileAPICallId: string = "";
  createSupplierAPICallId: string = "";
  editSupplierAPICallID: string = "";
  showSupplierAPICallId: string = "";
  createProductAPICallId: string = "";
  editProductAPICallId: string = "";
  showProductAPICallId: string = "";
  getBudgetListAPICallId: string = "";
  createBudgetAPICallId: string = "";
  editBudgetAPICallId: string = "";
  editSettingsLocationAPICallId: string = "";
  editSettingsCategoryAPICallId: string = "";
  editSettingsUnitMeasurementAPICallId: string = "";
  showBudgetAPICallId: string = "";
  showSettingsLocationAPICallId: string = "";
  createInformationSettingsAPICallId: string = "";
  createLocationSettingsAPICall: string = "";
  createCategorySettingsAPICall: string = "";
  createUnitMeasurementSettingsAPICall: string = "";
  createCurrencySettingsAPICallId: string = "";
  getInformationSettingsAPICallId: string = "";
  getCurrencySettingsAPICallId: string = "";
  saveLocationsAPICallId: string = "";
  getLocationsAPICallId: string = "";
  deleteLocationAPICallId: string = "";
  getUsersAPICallId: string = "";
  deleteUserAPICallId: string = "";
  deleteCategoryAPICallId:string = ""
  saveUsersAPICallId: string = "";
  purchaseRequisitionPostApiId: string = "";
  getDepartmentListAPICallId: string = "";
  getSupplierListAPICallId: string = "";
  getPaymentListAPICallId: string = "";
  paymentTermsPostApiId: string = "";deletePaymentTermListAPICallId
  :string = ""
  showViewPaymentTermListAPICallId : string="";
  showViewTaxationApiCallId: string="";
  editPaymentTermsApiCallId: string = "";
  getTaxationListAPICallId: string = "";
  createTaxationListAPICallId: string = "";
  editTaxationListAPICallId: string = "";
  getProductCategoriesAPICallId: string = "";
  getLocationDropdownListAPICallId: string = "";
  getStatusDropdownListAPICallId:string= "";
  getreceiptSelectedPOPopulatedDataAPICallId: string = "";
  getUserAccessPermissionAPICallId: string = "";
  saveUserAccessPermissionAPICallId: string = "";
  saveAsDraftPurchaseRequisitionAPICallId = "";
  saveAsDraftRfpAPICallId = "";
  saveAsDraftPurchaseOrderAPICallId = "";
  editedPoSaveAsDraftPurchaseOrderAPICallId: string = "";
  saveAsDraftReceiptAPICallID = "";
  saveAsDraftProductAPICallId = "";
  saveAsDraftBudgetAPICallId = "";
  getProductTypesListAPICallId = "";
  getBudgetForListAPICallId = "";
  getRolesListAPICallId = "";
  getProductListAPICallId = "";
  // dashboard message ids start
  editPurchaseRequisitionSaveAsDraftAPICallId = "";
  draftPurchaseRequisitionAPICallId = "";
  editRFPListSaveAsDraftCallId = "";
  editPurchaseOrderSaveAsDraftAPICallId = "";
  editBudgetSaveAsDraftAPICallId = "";
  editReceiptSaveAsDraftAPICallID = "";
  editSupplierSaveAsDraftAPICallID = "";
  getAllDepartmentsListAPICallId = "";
  dashboardEditPurchaseRequisitionAPICallId = "";
  editDashboardRfpAPICallId = "";
  dashboardEditPurchaseOrderAPICallId = "";
  dashboardAcceptRejectActionsPurchaseOrderAPICallId="";
  dashboardEditReceiptAPICallID = "";
  dashboardEditSupplierAPICallID = "";
  dashboardEditBudgetAPICallId = "";
  saveAsDraftSupplierAPICallId = "";
  receiptPdfDownloadAPICallId = "";
  // dashboard message ids end


  getEditedRfpSupplierMailSendApiCallId: string = "";
  getAllReceiptsPurchaseOrdersForSelectionAPICallId: string = "";
  getAllReceiptsPoForRemainingQuantityAPICallId: string = "";

  getUserModulePermissionsAPICallId: string = "";
  deleteTaxationAPICallId : string = "";
  getUserPermissionsAPICallId: string = "";

  


  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage)

    ];

    this.state = {
      openCreateNewModal: false,
      openNextPurchaseModal:false,
      openNextCustomFromRequisitionModal:false,
      openPdfModal:false,
      // dashboard state variables start
      dashboardMenuSelection: 'My Requisitions',
      userProfile: '',
      userType: '',
      accountId: '',
      openDropdown: false,
      active: 0,
      dropDownItemValue: 'Purchase Requisition',
      dashboardShowHeader: false,
      currentPage: 1,
      currentPagePaymentTerms: 1,
      currentPageTaxations:1,
      currentPageUnitMeasure: 1,
      currentPageCategory: 1,
      currentPageSettingsLocation: 1,
      noOfPages: 1,
      noOfPagesPaymentTerms: 1,
      noOfPagesTaxations: 1,
      noOfPagesUnitMeasure: 1,
      noOfPagesCategory: 1,
      noOfPagesSettingsLocation: 1,
      dashboardRecordResults: [],
      rfpData: {},
      selectedDepartmentPRIds: [],
      departmentsList: [],
      editedPoSupplierId: 0,
      editedPoTaxId: '',
      editedPurchaseOrderTaxIdRequiredField: '',
      selectedDepartmentPOIds: [],
      selectedDashboardProductIds: [],
      selectedDashboardStatus: [],
      editedDashboardPoSupplier: '',
      editedBudgetProductCategoriesId: 0,
      editedBudgetForId: 0,
      // dashboard state variables end
      dashboardData: [],
      creationDateCheckMessagePr:'',
      deliveryDateCheckMessagePr:'',
      editedCreationDateCheckMessagePr:'',
      editedDeliveryDateCheckMessagePr:'',
      isPrAcceptCheck:false,
      isPrRejectCheck:false,
      openEditPoShowErrorModal: false,
      errorMsg: "",
      token: "",
      loading: false,
      tabValue: 0,
      subTabValue: 0,
      subTabValueCof: 'purchase requisitions',
      subTabValueDashboard: 'purchase requisitions',
      isRowSelected: true,
      subTabValueCreateOrFind: 0,
      checkedCreateOrFind: false,
      openAddRequisitionModal: false,
      openEditRequisitionModal: false,
      openShowRequisitionModal: false,
      prAcceptRejectActionStatusMessage: '',
      prAcceptRejectActionStatus: '',
      poAcceptRejectActionStatus: '',
      uploadedFileRequestForProposals: '',
      durationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      deliveryDate: '',
      location: '',
      department: '',
      status: '',
      products: [],
      prProductAll: [],
      prProduct:[],
      productsSearch: [],
      productPurchaseRequisition: [],
      purchaseRequisitionList: [],
      editedPurchaseRequisition: {},
      editedPrNumberDefault: '',
      editedDurationDate: '',
      editedPRGen:"",
      editedDeliveryDate: '',
      editedRequester: '',
      editedLocation: '',
      editedCatalougeItemPr: [],
      editedCatalougeItemProductRequisition: [],
      editedNonCatalougeItemPr: [],
      editedDepartment: '',
      editSettingsGst: '',
      editedBillingLocation: "",
      editedShippingLocation: "",
      editedBudget: "",
      editedSupplier: "",
      editedPayment: "",
      editedTaxation: "",
      editedStatus: '',
      editedProducts: [],
      showPurchaseRequisition: {},
      isShowPurchaseRequisition: false,
      showSettingsLocation: [],
      showSettingsCategory: [],
      showSettingsUnitMeasurement: [],
      productsList: [],
      statusList: [],
      createPurchaseRequisitionLoading: false,
      draftPurchaseRequisitionLoading: false,
      editPurchaseRequisitionLoading: false,
      selectedProductIds: [],
      rfpList: [],
      rfpStatus: '',
      prStatusList:[],
      prNumberUnique: '',
      rfpNumberUnique:'',
      poNumberUnique: '',
      prPoNumberUnique: '',
      prProductId:'',
      prProductObj: {},
      prQuantity: '',
      prQuantityEdit: '',
      prNonCatalougeItems: '',
      prEditNonCatalougeItems: '',
      prNonCatalougeItemsKeydown: '',
      rfpCurrency: '',
      addRfpProducts: [],
      addRfpProductsNonCatItemText: '',
      rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      rfpDueDate: '',
      rfpAddress: '',
      rfpDesiredDeliveryDate: '',
      rfpEmailOfSupplier: '',
      rfpEmailOfSuppliers: [],
      rfpExistingProduct: '',
      rfpAttachment: '',
      rfpSelectExistingSuppliers: [],
      addItemSelectExistingSuppliers: [],
      addItemSupplier: '',
      addItemSupplierMultiple: [],
      editedItemSupplierMultiple: [],
      editedSupplierMultipleRequiredField: '',
      addItemSupplierNoUnique: '',
      prCatalougeItemSelectedRow: '',
      rfpFileAttachment: '',
      selectedRow: '',
      rfpSelectedRow: '',
      dashPoSelectedRow:'',
      openAddRFPModal: false,
      openNextRFPMainCreateFieldsModal: false,
      catchRfpIdForPdfCreate: '',
      openShowRFPModal: false,
      openEditRFPModal: false,
      openRfpPdfSupplierScreenModal:false,
      openRfpPdfSuccessModal:false,
      openRfpSupplierMailSeccessModal:false,
      openPoPdfSupplierScreenModal:false,
      openPoPdfSuccessModal:false,
      openPoSupplierMailSeccessModal:false,
      showRfp: {},
      dashboardOptions: [],
      editRfp: {},
      editedRfpId: '',
      editedRfpStatus: '',
      editedRfpCurrency: '',
      editedRfpCreationDate: '',
      editedRfpDueDate: '',
      editedRfpAddress: '',
      editedRfpDesiredDeliveryDate: '',
      editedRfpEmailOfSupplier: '',
      editedRfpEmailOfSuppliers: [],
      editedrfpMultiEmailOfSuppliersStoreArr: [],
      editedRfpExistingProduct: '',
      editedRfpAttachment: '',
      editedRfpFileAttachment: '',
      editedRfpSupplierEmailMatchError: '',
      isFileEdited: false,
      showIsEditRFPValidation: false,
      editedRfpStatusRequiredError: '',
      editedRfpCurrencyRequiredError: '',
      editedRfpCreationDateRequiredError: '',
      editedRfpDueDateRequiredError: '',
      unitMeasurementDeleteMessage:"",
      unitMeasurementCreateMessage: '',
      unitMeasurementUpdateMessage: '',
      editedRfpAddressRequiredError: '',
      editedRfpDesiredDeliveryDateRequiredError: '',
      editedRfpExistingProductRequiredError: '',
      editedRfpAttachmentTypeRequiredError: '',
      editedRfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: '',
      editedRfpFileAttachmentRequiredError: '',
      openEditRfpShowErrorModal: false,
      totalPagesPurchaseRequisition: 0,
      currentPagePurchaseRequisition: 1,
      totalPagesPurchaseRequisitionPO: 0,
      currentPagePurchaseRequisitionPO: 1,
      itemsPerPage: 6,
      itemsPerPageProduct: 2,
      suppliersList: [],
      totalPagesRfp: 0,
      currentPageRfp: 1,
      showIsCreateRFPValidation: false,
      showIsAddRfpProductsValidation:false,
      addRfpProductsRequiredError : '',
      rfpStatusRequiredError: '',
      rfpCurrencyRequiredError: '',
      rfpCreationDateRequiredError: '',
      rfpDueDateRequiredError: '',
      rfpAddressRequiredError: '',
      rfpDesiredDeliveryDateRequiredError: '',
      rfpExistingProductRequiredError: '',
      rfpAttachmentTypeRequiredError: '',
      rfpAttachmentTypeMatchError: '',
      rfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: '',
      rfpFileAttachmentRequiredError: '',
      rfpSupplierEmailMatchError: '',
      openCreateRfpShowErrorModal: false,
      openCreatePoShowErrorModal: false,
      openCreatePrPoShowErrorModal: false,
      showIsCreatePurchaseRequisitionValidation: false,
      durationDateRequiredError: '',
      deliveryDateRequiredError: '',
      locationRequiredError: '',
      locationAddressRequiredError: '',
      locationIdentityCodeRequiredError: '',
      locationGstRequiredError: '',
      locationValidGstLengthError: '',
      departmentRequiredError: '',
      prStatusListRequiredError: '',
      productsRequiredError: '',
      prQuantityRequiredError: '',
      prNonCatalougeItemsRequiredError: '',
      showIsEditPurchaseRequisitionValidation: false,
      editedDurationDateRequiredError: '',
      editedDeliveryDateRequiredError: '',
      editedLocationRequiredError: '',
      editedDepartmentRequiredError: '',
      editedCatalougeItemPrRequiredError: '',
      editedNonCatalougeItemPrRequiredError: '',
      editedStatusRequiredError: '',
      editedProductsRequiredError: '',
      rfpSuppliers: [],
      rfpEditedSuppliers: [],
      selectedSupplierIds: [],
      durationDateType: 'date',
      deliveryDateType: 'date',
      editedDurationDateType: 'text',
      editedDeliveryDateType: 'text',
      rfpCreationDateType: 'text',
      rfpDueDateType: 'text',
      rfpDesiredDeliveryDateType: 'text',
      editedRfpCreationDateType: 'text',
      editedRfpDueDateType: 'text',
      editedRfpDesiredDeliveryDateType: 'text',
      showDefaultMenuValue: false,
      purchaseOrdersList: [],
      totalPagesPurchaseOrder: 0,
      currentPagePurchaseOrder: 1,
      purchaseOrderSelectedRow: '',
      openAddPurchaseOrderModal: false,
      openShowPurchaseOrderModal: false,
      selectPoOptions: '',
      poId: '',
      poLocation: '',
      poSupplier: '',
      poOrderDate: '',
      poFobPoint: '',
      poShippingAddress: '',
      prPoShippingAddressOptional: '',
      poShippingAddressOptional: '',
      poBillingAddress: '',
      poGstNumber: '',
      poModeOfTransit: '',
      poProduct: [],
      poCommentForPO: '',
      poAmountCalculation: '',
      poDepartment: '',
      poBudget: '',
      poComment: '',
      editedPoId: '',
      editedPoNumber: '',
      editedPoLocation: '',
      editedPoSupplier: '',
      editedPoPaymentTermsName: '',
      poEditScreenActiveTabs: 0,
      poViewScreenActiveTabs:0,
      editedPoOrderDate: '',
      editedPoDeliveryDate: '',
      editedPoFobPoint: '',
      editedPoShippingAddress: '',
      editedPoShippingAddressOptional: '',
      editedPoBillingAddress: '',
      editedPoGstNumber: '',
      editedPoModeOfTransit: '',
      editedPoProduct: [],
      editedPoCommentForPO: '',
      editedPoAmountCalculation: '',
      editedPoDepartment: '',
      editedPoBudget: '',
      editedPoComment: '',
      poOrderDateType: 'text',
      openEditPurchaseOrderModal: false,
      editedPoOrderDateType: 'text',
      showPurchaseOrder: {},
      openPurchaseRequisitionListModal: false,
      customFromRequisitionSelectedRow: false,
      customFromRequisitionPoId: '',
      customFromRequisitionPoSupplier: '',
      customFromRequisitionPoOrderDate: '',
      customFromRequisitionPoFobPoint: '',
      customFromRequisitionPoShippingAddress: '',
      customFromRequisitionPoBillingAddress: '',
      customFromRequisitionPoTaxId: '',
      customFromRequisitionPoModeOfTransit: '',
      customFromRequisitionPoCommentForPO: '',
      customFromRequisitionPoAmountCalculation: '',
      customFromRequisitionPoBudget: '',
      customFromRequisitionPoComment: '',
      customFromRequisitionPoOrderDateType: "text",
      openCustomFromRequisitionPoModal: false,
      openCustomFromRequisitionCreatePoModal: false,
      customFromRequisitionSelectedId: '',
      receiptsList: [],
      totalPagesReceipt: 0,
      currentPageReceipt: 1,
      receiptSelectedRow: '',
      openAddReceiptModal: false,
      openEditReceiptModal: false,
      receiptSelectedPo: '',
      receiptQuantityReceived: '',
      receiptQuantityRequested: '',
      receiptReceiptDate: '',
      receiptComments: '',
      receiptAttachmentFile: '',
      editedReceiptSelectedPo: '',
      editedReceiptQuantityReceived: '',
      editedReceiptQuantityRequested: '',
      editedReceiptReceiptDate: '',
      editedReceiptComments: '',
      editedReceiptAttachmentFile: '',
      receiptDateType: 'text',
      editedReceiptDateType: 'text',
      receipt: {},
      isReceiptFileEdited: false,
      editedReceiptId: '',
      openShowReceiptModal: false,
      showReceipt: {},
      currencyList: [],
      purchaseOrderLocationRequiredField: '',
      purchaseOrderSupplierRequiredField: '',
      purchaseOrderPaymentTermsRequiredField: '',
      purchaseOrderTaxationNameRequiredField: '',
      purchaseOrderOrderDateRequiredField: '',
      purchaseOrderFobPointRequiredField: '',
      purchaseOrderShippingAddressRequiredField: '',
      purchaseOrderBillingAddressRequiredField: '',
      purchaseOrderGstNumberRequiredField: '',
      purchaseOrderDeliveryDateRequiredField: '',
      purchaseOrderDeliveryDateErrorFormat: '',
      purchaseOrderModeOfTransitRequiredField: '',
      purchaseOrderProductsRequiredField: '',
      purchaseOrderCommentsForPoRequiredField: '',
      purchaseOrderAmountCalculationRequiredField: '',
      purchaseOrderDepartmentRequiredField: '',
      purchaseOrderBudgetRequiredField: '',
      purchaseOrderCommentRequiredField: '',
      editedPurchaseOrderLocationRequiredField: '',
      editedPurchaseOrderSupplierRequiredField: '',
      editedPurchaseOrderOrderDateRequiredField: '',
      editedPurchaseOrderDeliveryDateRequiredField: '',
      editedPurchaseOrderPaymentTermsRequiredField: '',
      editedPurchaseOrderFobPointRequiredField: '',
      editedPurchaseOrderShippingAddressRequiredField: '',
      editedPurchaseOrderBillingAddressRequiredField: '',
      editedPurchaseOrderGstNumberRequiredField: '',
      editedPurchaseOrderModeOfTransitRequiredField: '',
      editedPurchaseOrderProductsRequiredField: '',
      editedPurchaseOrderCommentsForPoRequiredField: '',
      editedPurchaseOrderAmountCalculationRequiredField: '',
      editedPurchaseOrderDeliveryDateErrorFormat: '',
      editedPurchaseOrderDepartmentRequiredField: '',
      editedPurchaseOrderBudgetRequiredField: '',
      editedPurchaseOrderCommentRequiredField: '',
      customFromRequisitionPoFobPointRequiredField: '',
      customFromRequisitionPoShippingAddressRequiredField: '',
      customFromRequisitionPoBillingAddressRequiredField: '',
      customFromRequisitionPoTaxIdRequiredField: '',
      customFromRequisitionPoModeOfTransitRequiredField: '',
      customFromRequisitionPoSupplierRequiredField: '',
      customFromRequisitionPoOrderDateRequiredField: '',
      customFromRequisitionPoCommentForPORequiredField: '',
      customFromRequisitionPoAmountCalculationRequiredField: '',
      customFromRequisitionPoBudgetRequiredField: '',
      customFromRequisitionPoCommentRequiredField: '',
      showIsCreatePurchaseOrderValidation: false,
      showIsEditPurchaseOrderValidation: false,
      showIsCreateCustomFromRequisitionValidation: false,
      receiptQuantityReceivedRequiredField: '',
      receiptQuantityRequestedRequiredField: '',
      receiptReceiptDateRequiredField: '',
      receiptCommentsRequiredField: '',
      receiptAttachmentFileRequiredField: '',
      editedReceiptQuantityReceivedRequiredField: '',
      editedReceiptQuantityRequestedRequiredField: '',
      editedReceiptReceiptDateRequiredField: '',
      editedReceiptCommentsRequiredField: '',
      editedReceiptAttachmentFileRequiredField: '',
      showIsCreateReceiptValidation: false,
      showIsEditReceiptValidation: false,
      openAddRFPDownloadFiles: false,
      dashboardOptionSelected: 'Purchase Requisition',
      totalPagesSupplier: 0,
      currentPageSupplier: 1,
      openAddSupplierModal: false,
      supplierSupplierName: '',
      supplierUniqueCode: '',
      supplierTermsOfPayment: '',
      paymentTermsNameSuppliers: [],
      quantityErrDashPageMessage: '',
      quantityErrorPRCreateMessage:'',
      quantityErrorPREditMessage: '',
      supplierEmailAddress: '',
      supplierContactNumber: '',
      supplierPointOfContact: '',
      supplierAddressOfSupplierCompany: '',
      supplierGSTNumber: '',
      supplierStateProvince: '',
      supplierSupplierLocation: '',
      editedSupplierSupplierName: '',
      editedSupplierUniqueCode: '',
      editedSupplierTermsOfPayment: '',
      editedSupplierEmailAddress: '',
      editedSupplierContactNumber: '',
      editedSupplierPointOfContact: '',
      editedSupplierAddressOfSupplierCompany: '',
      editedSupplierGSTNumber: '',
      editSupplierStateProvince: '',
      supplierZipCodePinCode: '',
      editedSupplierSupplierLocation: '',
      editedSupplierStateProvince: '',
      editedSupplierZipCodePinCode: '',
      editedSupplierPaymentList: [],
      supplierSelectedRow: '',
      openEditSupplierModal: false,
      editedSupplierId: '',
      showSupplier: {},
      openShowSupplierModal: false,
      currentPageProduct: 1,
      totalPagesProduct: 0,
      productSelectedRow: '',
      productName: '',
      productPartId: '',
      productDescription: '',
      productUnitMeasurement: '',
      productUnitPrice: '',
      productCategory: '',
      productType: '',
      editedProductName: '',
      editedAddNotes: '',
      editedProductPartId: '',
      editedProductDescription: '',
      editedProductUnitMeasurement: '',
      editedProductUnitPrice: '',
      editedProductCategory: '',
      editedProductType: '',
      openAddProductModal: false,
      openEditProductModal: false,
      openShowProductModal: false,
      editedProductId: '',
      showProduct: {},
      budgetName: '',
      budgetFor: '',
      budgetPeriod: '',
      budgetAmount: '',
      budgetComment: '',
      budgetProductCategories: '',
      editedBudgetId: '',
      editedBudgetName: '',
      editedBudgetStartingDate: '',
      editedBudgetFor: '',
      editedBudgetPeriod: '',
      editedBudgetAmount: '',
      editedBudgetComment: '',
      editedBudgetProductCategories: '',
      budgetList: [],
      totalPagesBudget: 0,
      currentPageBudget: 1,
      budgetSelectedRow: '',
      openAddBudgetModal: false,
      openEditBudgetModal: false,
      openShowBudgetModal: false,
      openShowSettingLocationModal: false,
      openEditSettingLocationModal: false,
      openEditSettingCategoryModal: false,
      openEditSettingUnitMeasurementModal: false,
      showBudget: {},
      showSettingsLocationSingleData: {},
      showSettingsLocationAPICallId: {},
      productNameRequiredField: '',
      productPartIdRequiredField: '',
      productDescriptionRequiredField: '',
      productUnitMeasurementRequiredField: '',
      productUnitPriceRequiredField: '',
      productCategoryRequiredField: '',
      productTypeRequiredField: '',
      editedProductNameRequiredField: '',
      editedProductPartIdRequiredField: '',
      editedProductDescriptionRequiredField: '',
      editedProductUnitMeasurementRequiredField: '',
      editedProductUnitPriceRequiredField: '',
      editedProductCategoryRequiredField: '',
      editedProductTypeRequiredField: '',
      supplierNameRequiredField: '',
      supplierUniqueCodeRequiredField: '',
      supplierTermsOfPaymentRequiredField: '',
      supplierEmailAddressRequiredField: '',
      supplierEmailAddressEmailMatchError: '',
      supplierContactNumberRequiredField: '',
      supplierContactNumberMatchError: '',
      supplierPointOfContactRequiredField: '',
      supplierAddressOfSupplierCompanyRequiredField: '',
      supplierGstNumberRequiredField: '',
      supplierStateProvinceRequiredField: '',
      supplierZipCodePinCodeRequiredField: '',
      supplierLocationRequiredField: '',
      editedSupplierNameRequiredField: '',
      editedSupplierUniqueCodeRequiredField: '',
      editedSupplierTermsOfPaymentRequiredField: '',
      editedSupplierValidGstLengthError:'',
      editedSupplierEmailAddressRequiredField: '',
      editedSupplierEmailAddressEmailMatchError: '',
      editedSupplierContactNumberRequiredField: '',
      editedSupplierContactNumberMatchError: '',
      editedSupplierPointOfContactRequiredField: '',
      editedSupplierAddressOfSupplierCompanyRequiredField: '',
      editedSupplierGstNumberRequiredField: '',
      editedSupplierZipCodePinCodeRequiredField: '',
      editedSupplierStateProvinceRequiredField: '',
      editedSupplierLocationRequiredField: '',
      budgetNameRequiredField: '',
      budgetForRequiredField: '',
      budgetPeriodRequiredField: '',
      budgetAmountRequiredField: '',
      budgetCommentRequiredField: '',
      budgetProductCategoriesRequiredField: '',
      editedBudgetNameRequiredField: '',
      editedBudgetForRequiredField: '',
      editedBudgetPeriodRequiredField: '',
      editedBudgetAmountRequiredField: '',
      editedBudgetCommentRequiredField: '',
      editedBudgetProductCategoriesRequiredField: '',
      showIsCreateSupplierValidation: false,
      showIsEditSupplierValidation: false,
      showIsCreateProductValidation: false,
      showIsEditProductValidation: false,
      showIsCreateBudgetValidation: false,
      showIsEditBudgetValidation: false,
      customFromRequisitionSelectMessage: '',
      showSettings: true,
      showInformation: false,
      showCurrencySettings: false,
      showLocationSettings: false,
      showUsers: false,
      informationSettingsCompanyName: '',
      settingsLocation: '',
      settingsAddress: '',
      settingsCategory: '',
      settingsCategoryRequiredField: '',
      editSettingsCategoryRequiredField: '',
      categorySettingCreatePost: false,
      categorySettingUpdation:false,
      settingsUnitMeasurement: '',
      settingsUnitMeasurementRequiredField: '',
      unitMeasurementSettingPost: false,
      settingsIdentificationCode: '',
      settingsGst: '',
      editSettingsId: '',
      createSettingsCategoryMessage:'',
      editSettingsCategoryId: '',
      editSettingsLocation: '',
      editSettingsCategorySL: '',
      editSettingsUnitMeasurementId: '',
      editSettingsUnitMeasurementSL: '',
      editSettingsCategoryName: '',
      editSettingsUnitMeasurement: '',
      editSettingsAddress: '',
      editSettingsIdentificationCode: '',
      informationSettingsPhoneNumber: '',
      informationSettingsAddress: '',
      informationSettingsEmail: '',
      informationSettingsPinCode: '',
      informationSettingsWebsite: '',
      informationSettingsGstNumber: '',
      currencySettingsCurrency: '',
      enableEditInformationSettings: false,
      enableEditCurrencySettings: false,
      informationSettings: {},
      currencySettings: {}, 
      locationSettingsNameOfLocation: '',
      locationSettingsAddress: '',
      locationSettingsLocationIdentificationCode: '',
      locations: [{
        name_of_location: '',
        adderss: '',
        location_identification_code: '',
        name_of_location_validation: '',
        address_validation: '',
        location_identification_code_validation: '',
      }],
      selectedCurrencyCurrencySettings: '',
      openAddLocationsModal: false,
      locationsList: [],
      showUserSettings: false,
      showCategorySettings: false,
      showUnitMeasureSettings: false,
      openAddUserModal: false,
      usersList: [],
      userSettingsName: '',
      userSettingsRoles: '',
      userSettingsDesignation: '',
      userSettingsEmailId: '',
      userSettingsDepartment: '',
      productCategoriesList: [],
      departmentList: [],
      budgetListing: [],
      supplierList:[],
      getPaymentTermListSupplier: [],
      paymentTermsList: [],
      taxations : [],
      showPaymentTermsSettings: false,
      showTaxationSettings: false,
      paymentTermsName: '',
      PaymentTermDeleteMessage: '',
      creditDays: '',
      taxName: '',
      taxPercentage: '',
      editPaymentTermsName: '',
      editCreditDays: '',
      paymentTermId: '',
      editTaxName: '',
      editTaxPercentage: '',
      taxationId:'',
      showEditPaymentTermsSettingField: false,
      showEditPaymentTermsSettingBtn: false,
      showEditTaxationEditField: false,
      showViewTaxationSetting : false,
      showEditTaxationEditBtn: false,
      showEditTaxationSettingBtn: true,
      rfpAttachmentDropdownDisplay: false,
      locationDropdownList: [],
      purchaseRequisitionSearchLocation: '',
      receiptSelectedPOPopulatedData: {},
      rfpEmailOfSupplierEmailPatternError: false,
      editedRfpEmailOfSupplierEmailPatternError: false,
      users: [{
        name: "",
        designation: "",
        role: "",
        email: "",
        department: "",
        name_validation: "",
        designation_validation: "",
        role_validation: "",
        email_validation: "",
        email_matching_error: "",
        department_validation: ""
      }],
      receiptAttachment: "",
      receiptAttachmentDropdownDisplay: false,
      editedReceiptAttachment: "",
      editedReceiptAttachmentDropdownDisplay: false,
      receiptSelectedPoRequiredField: '',
      rfpEmailOfSupplierEmailDuplicateError: false,
      editedRfpEmailOfSupplierEmailDuplicateError: false,
      editedReceiptAttachmentTypeMatchError: "",
      openUserPermissionsModal: false,
      userPermissionsList: [
        {
          id: 1,
          title: "Purchase Requisition",
          status: true,
        },
        {
          id: 3,
          title: "Request for Proposals",
          status: true,
        },
        {
          id: 4,
          title: "Request for Proposals Approval",
          status: true,
        },
        {
          id: 5,
          title: "Purchase Orders",
          status: true,
        },
        {
          id: 7,
          title: "Receipts",
          status: true,
        },
        {
          id: 8,
          title: "Receipts Approval",
          status: true,
        },
        {
          id: 9,
          title: "Reports",
          status: true,
        },
        {
          id: 10,
          title: "Budgets",
          status: true,
        },
        {
          id: 11,
          title: "Warehouse Manager",
          status: true,
        },
        {
          id: 12,
          title: "Supplier & Item",
          status: true,
        },
      
      ],
      userPermissionsList1: {
        purchase_requisitions: true,
        purchase_requisitions_approval: true,
        request_for_proposals: true,
        request_for_proposals_approval: true,
        purchase_orders: true,
        purchase_orders_approval: true,
        receipts: true,
        receipts_approval: true,
        reports: true,
        budgets: true,
        warehouse_manager: true,
        supplier_and_item: true
      },
      dateValidationError: "",
      alreadyCreatedUsers: [],
      userIdForUserAccessPermission: "",
      saveLocationsValidation: false,
      saveUsersValidation: false,
      showIsCreateInformationValidation: false,
      informationSettingsCompanyNameRequiredField: '',
      informationSettingsPhoneNumberRequiredField: '',
      informationSettingsPhoneNumberMatchError: '',
      informationSettingsAddressRequiredField: '',
      informationSettingsEmailRequiredField: '',
      informationSettingsEmailMatchError: '',
      informationSettingsPinCodeRequiredField: '',
      informationSettingsPinCodeMatchError: '',
      informationSettingsWebsiteMatchError: '',
      informationSettingsWebsiteRequiredField: '',
      informationSettingsGstNumberRequiredField: '',
      showIsCreateCurrencyValidation: false,
      showIsCreatePaymentTermsValidation: false,
      paymentTermNameRequiredField: '',
      paymentTermCreditDaysRequiredField: '',
      paymentTermNameRequiredFieldEdit: '',
      paymentTermCreditDaysRequiredFieldEdit: '',
      showIsEditPaymentTermsValidation: false,
      showIsUpdateTaxationValidation: false,
      showIsCreateTaxationValidation: false,
      taxNameRequiredField: '',
      taxPercentageRequiredField: '',
      editTaxNameRequiredField:'',
      editTaxPercentageRequiredField:'',
      paymentTermFoundOrNotMessage: '',
      currencySettingsCurrencyRequiredField: '',
      purchaseRequisitionCreateMessage: '',
      purchaseRequisitionDraftMessage: '',
      purchaseOrderDraftMessage: '',
      editedPurchaseOrderDraftMessage: '',
      purchaseRequisitionEditMessage: '',
      rfpCreateMessage: '',
      rfpCreateMessagedDueDate: '',
      rfpEditMessage: '',
      poCreateMessage: '',
      poEditMessage: '',
      receiptCreateMessage: '',
      receiptQuantityError: '',
      receiptSelectFileTypeMessage: '',
      receiptEditMessage: '',
      supplierCreateMessage: '',
      supplierEditMessage: '',
      productCreateMessage: '',
      productEditMessage: '',
      budgetCreateMessage: '',
      budgetEditMessage: '',
      informationSettingsEditMessage: '',
      currencySettingsEditMessage: '',
      userCreateMessage: '',
      userDeleteMessage: '',
      categoryDeleteMessage: '',
      categoryAddMessage: '',
      locationCreateMessage: '',
      locationCreateMessagePr: '',
      deliveryOrDurationDateCheckMessagePr: '',
      locationDeleteMessage: '',
      locationUpdateMessage: '',
      paymentTermsCreateMessage: '',
      paymentTermsEditMessage: '',
      paymentTermsDeleteMessage: '',
      paymentTermsFetchMessage: '',
      taxationsCreateMessage: '',
      taxationEditMessage: '',
      taxationsDeleteMessage: '',
      productTypesList: [{id:1, name:"Product"}, {id:2, name: "Service"}],
      itemTypesList: [{id:1, name:"Product"}, {id:2, name: "Service"}],
      budgetForList: [],
      productTypeSelectedId: '',
      budgetForSelectedId: '',
      editedProductTypeSelectedId: '',
      editedBudgetForSelectedId: '',
      productUnitPriceMatchError: '',
      productSupplierRequiredField: '',
      editedProductUnitPriceMatchError: '',
      budgetAmountMatchError: '',
      editedBudgetAmountMatchError: '',
      poGSTMatchError: '',
      supplierGSTMatchError: '',
      editedPoGSTMatchError: '',
      editedSupplierGSTMatchError: '',
      rolesList: [],
      productList: [],
      supplierFoundOrNotMessage: '',
      productsFoundOrNotMessage: '',
      startingFromDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      startingFromDateRequiredField: '',

      // new RFP states default

      rfpUniqNumberRequiredError: '',
      rfpCreateScreenActiveTabs: 0,
      isRfpCreationAndApiSuccessDone: false,
      rfpLocation:'',
      rfpLocationRequiredError:  '',
      rfpDepartments: '',
      rfpDepartmentRequiredError: '',
      rfpSelectExistingSupplier: [],
      rfpSelectExistingSupplierLabel: [],
      rfpNonCatalougeCollectedItems:'',
      filteredRfpProducts: [],
      rfpEmailToSupplierSentOrNotErrorMessage: '',
      isRfpMailSendConfirmed: false,
      isRfpMailSending : false,
      isRfpCreating: false,
      rfpFinalPdfDownloadOrNotMessage: '',
      catchRfpEmailSendToSupplier: '',

      // new PO states default
      poCreateScreenActiveTabs: 0,
      poEmailToSupplierSentOrNotErrorMessage: '',
      poMailSendConfirmed: false,
      poMailSending : false,
      poCreating: false,
      poFinalPdfDownloadOrNotMessage: '', 
      poEmailSendToSupplier: '',
      poNonCatalougeCollectedItem: '',
      editedPoNonCatalougeCollectedItem: '',
      poEditing: false,
      poAttachment: '',
      filteredPoProducts: [],
      catchPoIdForPdfCreate: '',
      catchPoMailOfSupplier: '',
      isPoCreationAndApiSuccessDone: false,
      isPoMailSendConfirmed: false,
      isPoMailSending : false,
      isPdfDownloaded: false,
      idReceiptPdfDownloaded: false,
      poSaveDraft: false,
      editedPoSaveDraft: false,
      prPoCreateMessage: '',
      editedPoProductCatalouge: [],
      editedPoProductNonCatalouge: [],
      editedPoNonCatlogItems: "",
      isEditedPdfDownloaded:false,
      editedPoFinalPdfDownloadOrNotMessage:'',
      isEditedPoMailSendConfirmed:false,
      editedFinalPdfDownloadOrNotMessage:'',
      editedPoEmailToSupplierSentOrNotErrorMessage:'',
      openEditedPoSupplierMailSeccessModal:false,
      isEditedPoCreationAndApiSuccessDone:false,
      isEditedPoMailSending: false,
      catchEditedPoMailOfSupplier:'',
      editedPoPaymentTermList: '',
      openEditedPoPdfSupplierScreenModal: false,
      isShowReceiptOpenPdfModalSuccess: false,
      isShowReceiptPdfDownloadBtn: false,
      isReceiptPdfDownloaded: false,
      receiptPdfSuccessMessage: '',
      editedPoSuccessOrNotMessage: '',
      isDeleteRecordPO: 0,
      selectBudgetIsBlankMessage: '',
      selectTaxationIsBlankMessage: '',

      // new pr po states
      prPoCreateScreenActiveTabs:0,
      prPoSelectedRequisitionBudgetError:'',
      prPoSelectedRequisitionSupplierError:'',
      prPoSelectedRequisitionPaymentError:'',
      prPoSelectedRequisitionTaxationError:'',
      prPoSelectedRequisitionShippingError:'',
      prPoSelectedRequisitionBillingError:'',
      prPoSelectedRequisitionDepartment:'',
      prPoSelectedRequisitionNumber:'',
      customFromRequisitionCataItemData: [],
      customFromRequisitionNonCataItemData: [],
      prPoSelectedRequisitionCreationDate:'',
      prPoSelectedRequisitionDeliveryDate:'',
      prPoItemsNotFoundMessage: '',
      showPurchaseOrderProductsData:'',
      showIsCreatePrPurchaseOrderValidation: false,
      openPrPoPdfSupplierScreenModal: false,
      openPrPoSupplierMailSeccessModal: false,
      prPoCreating: false,
      isPrPoMailSendConfirmed: false,
      catchPrPoMailOfSupplier: '',
      isPrPoMailSending: false,
      prPoEmailToSupplierSentOrNotErrorMessage: '',
      isPrPoCreationAndApiSuccessDone: false,
      prPoFinalPdfDownloadOrNotMessage: '',
      catchPrPoIdForPdfCreate: '',
      prPoSaveDraft: false,

      // new receipt states default

      receiptNumberUnique: '',
      receiptSupplierName: '',
      receiptSupplierNameRequiredField : '',
      receiptInvoiceNumber: '',
      receiptInvoiceNumberRequiredField: '',
      receiptAttachmentRequiredField: '',
      receiptSelectedPOtSearchTerm: '',
      locationExistOrNotErrorMessage: '',
      locationExistOrNotCheckingRequiredError: '',
      recieptsPOSelectionDataArr: [],
      recieptsPORemainingQuantityDataArr: [],
      receiptCatelogueItemsStore: [],
      receiptBasedOnStatus: '',
      receiptNonCatelogueItemsStore: [],
      receiptNumberUniqueRequiredField: '',
      openReceiptCreateShowErrorModal: false,
      isReceiptCreating: false,
      isReadOnlyReceiptField: false,
      receiptCatProductsIdCatch: '',
      receiptNonCatProductsIdCatch: '',

      // new dash rfp states default

      dashboardItemsPerPage: 6,
      dashboardNoOfPages:1,
      editedExistingRfpNumber:'',
      editedRfpLocation: '',
      editedRfpDepartment: '',
      editedRfpSelectExistingSupplier: [],
      editedRfpSelectExistingSupplierLabels: [],
      editedRfpCatelogItems: [],
      editedRfpNonCatelogItems:[],
      editedRfpScreenActiveTabs : 0,
      rfpUpdateMessage: '',
      editedFilteredRfpProducts: [],
      openEditedRfpPdfSupplierScreenModal: false,
      openEditedRfpShowErrorModal: false,
      isEditedRfpUpdationApiSuccessDone: false,
      isEditedRfpMailSendConfirmed:false,
      isEditedRfpMailSending:false,
      isRfpUpdating:false,
      isEditedRFPFileDownloaded: false,
      editedRfpLocationRequiredError: '',
      editedRfpDepartmentRequiredError: '',
      editedRfpNonCatalougeCollectedItems: '',
      editedRfpEmailToSupplierSentOrNotErrorMessage: '',
      catchEditedRfpIdForPdfUpdation:'',
      openEditedRfpSupplierMailSeccessModal: false,
      catchEditedRfpEmailSendToSupplier: '',
      rfpCatelogDeletedItemsArr: [],
      rfpNonCatelogDeletedItemsArr: [],
      dashboardNoRecordMessage: '',
      editedRfpFinalPdfDownloadOrNotMessage: '',
      // new user setting state defaults
      userSettingAddUserName: '',
      userSettingAddUserDesignation: '',
      userSettingAddUserEmailId: '',
      userSettingAddUserRole: '',
      userSettingNameRequiredError: '',
      userSettingDesignationRequiredError: '',
      userSettingEmailRequiredError: '',
      userSettingEmailInvalidError: '',
      userSettingRoleRequiredError: '',
      catchUserSettingsCreatedUserId : '',
      selectedUserPermissions: [],
      selectAllPermissions: false,
      isUserCreating: false,
      editedSelectedUserPermissions: [],
      editedSelectAllPermissions : false,
      openEditedUserPermissionsModal : false,
      userSettingSelectedRow: '',
      editedCatchIdForUserPermissionsUpdation : '',
      userSettingPermissionUpdatedMessage:'',
      editedCatchCoreUserCreatedAccountId: '',
      isUserSettingPermissionsUpdating: false,
      isUserPermissionsCreating: false,
      userPermissionsCreatedMessage: '',
      userPermissionMissingIdErrorMessage: '',
      createUserPermissionsValidation: false,

      userLoginDetailsCatch : {},
      noDashModulePermitMessage: '',
      isDashBtnDisabled : false,
      isCreateOrFindTabsDisabled : false,
      noCreateOrFindTabModulesPermitMessage: false,
      isMyReportsDisabled : false,
      isSettingsDisabled : false,
      noMyReportsModulesPermitMessage: '',
      noSettingsModulePermitMessage: '',
      supplierContactNumberErrorMessage:  false,
      supplierContactNumberValidationError : '',
      supplierValidGstLengthError: '',
      supplierEmailInvalidPatternError : false,
      settingsCategoryFeildError:'',
  supplierPointContactNumberValidationError : '',
  supplierPointOfContactNumberErrorMessage : false,
  categoryUpdateMessage : "",
  informationSettingEmailInvalidPatternError : false,
  infoSettingContactNumberErrorMessage : false,
  informationSettingsPhoneNumberDigitLimitError: '',
  informationSettingsComapanyNameRequiredField: '',
  informationSettingsGSTNumberRequiredField: '',
  unitMeasurementSettingsDuplicasyMessage: '',
  categoryDuplicasyErrorMessage : "",
  filterMultiItemsState : "",
  itemsSearchBySpecificValue : "",
  dashboardSearchBySpecificValue: "",
  anchorEl : null,
  isFilterSelectOpen : false,
  mainSearchKeysData: [
    {
      lable : "PR Number",
      key : "purchase_requisition_number",
      assosiated_model: "",
      selected : false
    },
    {
      lable : "Creation Date",
      key : "pr_creation_date",
      assosiated_model: "",
      selected : false
    },
    {
      lable : "Delivery Date",
      key : "delivery_date",
      assosiated_model: "",
      selected : false
    },
    {
      lable : "Location",
      key : "name_of_location",
      assosiated_model: "location",
      selected : false
    },
    {
      lable : "Department",
      key : "department_id?.name",
      assosiated_model: "department",
      selected : false
    },
    {
      lable : "Status",
      key : "status",
      selected : false
    },
  ],
  isOpenDropdownSelectSearch : false,
  isMenuForSearchOpen  : false,
  mainSearchCOFItemsKeysData :  [
    {
      lable : "Item ID",
      key : "item_id",
      assosiated_model : "",
      selected : false
    },
    {
      lable : "Unit Measurement",
      key : "unit_measurement",
      assosiated_model : "",
      selected : false
    },
    {
      lable : "Unit Price",
      key : "unit_price",
      assosiated_model : "",
      selected : false
    },
    {
      lable : "Product Category",
      key : "product_category?.name,",
      assosiated_model : "product_category",
      selected : false
    }
  ],
  cofItemsFilterMultiState : '',
  isCOFItemsMenuForSearchOpen : false,
  anchorElForCOFItems : null,
  itemsCofSearchBySpecificValue : "",
  mainSearchSettingItemsKeysData : [
    {
      lable: "",
      key: "",
      selected : false
    }
  ],
  itemsSettingSearchBySpecificValue : "",
  settingItemsFilterMultiState : "",
  anchorElForSettingsScreens : "",
  isSettingsScreensMenuForSearchOpen : false,
  associetedItemNameForMainSearch : "",
  associetedItemNameForItemProductsSearch : "",
  receiptAttachmentTypeMatchError : "",
  isReceiptPoNumberFoundError: '',
  receiptAttachmentRequiredError : "",
  createOrFindNoRecordsFoundPopup : "",
  searchFilterEmptyPopup : "",
  itemsSearchValueEmptyPopup : "",
  cofItemsSearchFilterEmptyPopup : "",
  cofItemsSearchQuerryEmptyPopup : "",
  createOrFindItemsNoRecordsFoundPopup : "",
  settingsSearchFilterEmptyPopup : "",
  settingsItemsSearchValueEmptyPopup : "",
  settingsNoRecordsFoundPopup :  "",
  locationCharError : "",
  editedLocationCharError : "",
  editedLocationAddressRequiredError : "",
  editedLocationIdentityCodeRequiredError : "",
  editedLocationGstRequiredError : "",
  editedLocationValidGstLengthError:"",
  editSaveLocationsValidation : false,
  suppliersPaymentTermCatchedLables : [],
  isEditedSupplierPaymentListOpenOrClosed : false,
  isSupplierPaymentListOpenOrClosedForUpdate : false,
  editedPaymentTermsNameSuppliers : [],
  editedSuppliersPaymentTermCatchedLables : [],
  isSupplierPaisEditedSupplierPaymentListOpenOrClosedForUpdate : false,
  paymentTermCreditDaysInvalidMatchRequiredField : '',
  addItemSupplierMultipleOpenOrClosed : false,
  addItemSupplierMultipleLables : [],
  updatedItemSupplierMultipleLables : [],
  isUpdatedItemSupplierMultipleOpenOrClosed : false,
  supplierZipCodePinCodeLengthInvalidError : '',
  taxationDeleteMessage : '',
  isCategorySettingGotResponseOrNot :false,
  isUnitMeasureSettingGotResponseOrNot : false,
  isPaymentTermSettingGotResponseOrNot:false,
  isLocationSettingGotResponseOrNot: false,
  isUsersSettingGotResponseOrNot : false,
  paymentTermShowViewApiRespObj : {},
  openShowPaymentTermsModal : false,
  taxationShowViewApiRespObj : {},
  isPRGetApicalled: false,
  isRFPGetApicalled: false,
  isPOGetApicalled : false,
  isRecieptcalled : false,
  isSupplierscalled : false,
  isItemscalled : false,
  isBudgetcalled: false,
  loggedUserNameCatch : "",
  loggedUserEmailCatch : "",
  informationSettingNoOfPeopleWhoWillAccess : "",
  informationSettingsSelectInfoSize : "",
  informationSettingsSelectInfoSizeRequiredField : "",
  informationSettingsNoPeopleRequiredField : "",
  informationSettingsNoPeopleLengthValidationField : "",
  infoSettingGstLengthError : "",
  taxSerialNumber : 0,
  locationSerialNumber : 0,
  unitMeasureSerialNumber : 0,
  categorySerialNumber:0,
  payTermsSerialNumber : 0
  }
  
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    if(!this.handleHeaderAccessPermission()){
      if(this.hasPermission("reports")) this.props.navigation.navigate("MyReports");
      else if(this.hasPermission("settings")) this.props.navigation.navigate("Settings");
      else {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        window.location.reload();
      }
    }
    if(window.location.pathname==="/Settings" && !this.hasPermission("settings")){
      this.props.navigation.navigate("Dashboard");
    }
    this.getUserProfileDataFromLocalStorage()
    this.handleGetUserModulePermissions()
    this.handleGetUserPermissions()
    this.handleGetAllDashboardOptions();
    this.getAllPurchaseRequisitionsList();
    this.getAllProductsList();
    this.getAllSuppliersList();
    this.handleGetCurrencyList();
    this.getDepartmentList();
    this.getBudgetList();
    this.getSupplierList();
    this.getPaymentTermList();
    this.getTaxationList();
    this.getProductCategories();
    this.getLocationDropdownList();
    this.getRolesList();
    this.handleGetAllPRStatuses();
    // dashboard methods start
    this.handleDashboardAllRecordsRequests();
    this.handleGetSettingsLocation();
    this.handleGetUserProfile();
    this.setState({prNumberUnique: Math.floor(Math.random() * 900000000) + 100000000})

    // dashboard methods end
    this.checkTabOnInit()
    this.checkDashboardTabOnInit()
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    this.handleGetAllDashboardOptionsAPICallIdResponse(message)

    this.handleCreatePurchaseRequisitionApiCallIdResponse(message)

    // Modification start

    this.handleSaveAsDraftPurchaseOrderApiCallIdResponse(message)

    this.handleSaveAsDraftPurchaseOrderEditedApiCallIdResponse(message)

    this.showSettingsCategoryAPICallResponse(message)

    this.showSettingsLocationAPICallIdResponse(message)

    this.showSettingsLocationAPICallResponse(message)

    this.createLocationSettingsAPICallResponse(message)

    this.editSettingsLocationAPICallIdResponse(message)

    
    // Modification end

    

                 
    this.handleGetAllPurchaseRequisitionListAPICallIdResponse(message)
    
    this.handleEditPurchaseRequisitionAPICallIdResponse(message)

    this.handleShowPurchaseRequisitionAPICallIdResponse(message)

    this.handleGetUserProfileAPICallIdResponse(message)

    this.handleGetAllProductsListAPICallIdResponse(message)

    this.handleGetAllSuppliersListAPICallIdResponse(message)

    this.handleGetAllRFPListAPICallIdResponse(message)

    this.handleCreateRfpAPICallIdResponse(message)

    this.handleShowRfpAPICallIdResponse(message)

    this.handleEditRfpAPICallIdResponse(message)

    this.editSettingsCategoryAPICallIdResponse(message)

    this.editSettingsUnitMeasurementAPICallIdResponse(message)

    this.createCategorySettingsAPICallResponse(message)

    this.handleDeleteSettingUnitMeasurementAPICallIdResponse(message)

    this.handleDeleteCategoryAPICallIdResponse(message)

    this.createUnitMeasurementSettingsAPICallResponse(message)

    this.handleGetAllPurchaseOrderListAPICallIdResponse(message)      
    
    this.handleCreatePurchaseOrderAPICallIdResponse(message)

    this.handleCreatePurchaseRequisitionOrderAPICallIdResponse(message)

    this.handleEditPurchaseOrderAPICallIdResponse(message)

    this.handleShowPurchaseOrderAPICallIdResponse(message)

    this.handleCreateCustomFromRequisitionPoAPICallIdResponse(message)

    this.handleGetAllReceiptsAPICallIdResponse(message)

    this.handleCreateReceiptAPICallIdResponse(message)

    this.handleShowReceiptAPICallIdResponse(message)

    this.handleGetCurrencyListAPICallIdResponse(message)

    this.handleCreateSupplierAPICallIdResponse(message)

    this.handleEditSupplierAPICallIdResponse(message)

    this.handleShowSupplierAPICallIdResponse(message)

    this.handleCreateProductAPICallIdResponse(message)

    this.handleEditProductAPICallIdResponse(message)

    this.handleShowProductAPICallIdResponse(message)

    this.handleGetBudgetListAPICallIdResponse(message)

    this.handleCreateBudgetAPICallIdResponse(message)

    this.handleGetSupplierListAPICallIdResponse(message)

    this.handleGetPaymentTermsListAPICallIdResponse(message)

    this.handleCreatePaymentTermsListAPICallIdResponse(message)

    this.handleDeletePaymentTermListAPICallIdResponse(message)

    this.handleDeleteTaxationAPICallIdResponse(message)

    this.handleShowViewPaymentTermListAPICallIdResponse(message)

    this.handleShowViewTaxationListAPICallIdResponse(message)

    this.handleEditPaymentTermsListAPICallIdResponse(message)

    this.handleGetTaxationsListAPICallIdResponse(message)

    this.handleCreateTaxationAPICallIdResponse(message)

    this.handleEditTaxationAPICallIdResponse(message)

    this.handleEditBudgetAPICallIdResponse(message)

    this.handleShowBudgetAPICallIdResponse(message)

    this.handleCreateInformationSettingsAPICallIdResponse(message)

    this.handleCreateCurrencySettingsAPICallIdResponse(message)

    this.handleGetInformationSettingsAPICallIdResponse(message)

    this.handleGetCurrencySettingsAPICallIdResponse(message)

    this.handleGetLocationsAPICallIdResponse(message)

    this.handleGetLocationsAPICallIdResponse(message)

    this.handleDeleteLocationAPICallIdResponse(message)

    this.handleGetUsersAPICallIdResponse(message)

    this.handleDeleteUserAPICallIdResponse(message)

    this.handleSaveUsersAPICallIdResponse(message)

    this.handleGetDepartmentListAPICallIdResponse(message)

    this.handleGetProductCategoriesAPICallIdResponse(message)

    this.handleGetLocationDropdownListAPICallIdResponse(message)


    this.handleGetUserAccessPermissionAPICallIdResponse (message)

    this.handleSaveUserAccessPermissionAPICallIdResponse(message)

    this.handleGetBudgetForListAPICallIdResponse(message)

    this.handleGetAllSearchProductsListAPICallIdResponse(message)

    this.handleGetAllReceiptsPurchaseOrdersForSelectionAPICallIdResponse(message)

    this.handleGetAllReceiptsPoForRemainingQuantityAPICallIdResponse(message)

    //
    // dashboard receive methods start
    //
    
    this.handleDashboardApiCallIdResponse(message)

    this.handleDashboardEditPurchaseRequisitionAPICallIdResponse(message)

    this.handleDashboardAcceptRejectActionsPurchaseRequisitionAPICallIdResponse(message)

    this.handleEditDashboardRfpAPICallIdResponse(message)

    this.handleDashboardEditPurchaseOrderAPICallIdResponse(message)


    this.handleDraftPurchaseRequisitionApiCallIdResponse(message)

    this.handleDashboardAcceptRejectActionsPurchaseOrderAPICallIdResponse(message)

    this.handleGetPRAllStatus(message);

    this.showSettingsUnitMeasurementAPICallResponse(message);


    // rfp reciev

    this.handleGetRfpSupplierMailSendApiCallIdResponse(message)

    this.handleGetEditedRfpSupplierMailSendApiCallIdResponse(message)

    // po recieve

    this.handleGetPoSupplierMailSendApiCallIdResponse(message)

    this.handleGetPrPoSupplierMailSendApiCallIdResponse(message)

    this.handleGetEditedPoSupplierMailSendApiCallIdResponse(message)

    this.handlegetUserModulePermissionsAPICallIdResponse(message)

    this.handleGetUserPermissionsAPICallIdResponse(message)


    //
    // dashboard receive methods end
    //
    // Customizable Area End
  }

  // Customizable Area Start

  handleRecieveSessionResponce = (message: Message)=> {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }
  }

  handleRestApiJsonDataResponseSubNestedFunc = (responseJson:any) => {
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false
      });
    } else {
      this.setState({
        dashboardData: responseJson.data,
        errorMsg: "",
        loading: false
      });
    }
  }

  handleGetAllDashboardOptionsSuccessAndErrorsNestedFunc=(apiResponse:any)=>{
    if(apiResponse.errors){
      console.log('error for Dashboard Options API', apiResponse.errors)
    } else {
      this.setState({
        dashboardOptions: apiResponse?.dashboard_options
      })
    }
  }

  handleGetAllDashboardOptionsAPICallIdResponse = (message: Message) => {
    if (this.getAllDashboardOptionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetAllDashboardOptionsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreatePurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse : any) =>{
    if(apiResponse?.errors){
      this.setState({
        purchaseRequisitionCreateMessage: 'Purchase Requisition Not Created',
      })
    } else {
       this.getAllPurchaseRequisitionsList()
      this.handleSelectedRow(apiResponse?.data?.id)
      this.setState({
        purchaseRequisitionCreateMessage: 'Purchase Requisition Created',
      },()=>{
        this.handleDashboardAllRecordsRequests()
        this.getAllPurchaseRequisitionsList()
      })
      setTimeout(() => {
        this.handleAddRequisitionCloseModal()
      }, 1500);
    }
  }

  handleCreatePurchaseRequisitionApiCallIdResponse = (message: Message) => {
    if (this.createPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
      this.setState({
        location: '',
        deliveryDate: '',
        products: [],
        durationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
        showIsCreatePurchaseRequisitionValidation: false,
        department: '',
        status: '',
        createPurchaseRequisitionLoading: false,
        currentPage:1,
        deliveryDateCheckMessagePr: ''
      })
    }
  }

  handleSaveAsDraftPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        purchaseOrderDraftMessage: 'Purchase Order Cannot SaveAsDraft'
      })
    } else {
      this.setState({
        purchaseOrderDraftMessage: 'Purchase Order SaveAsDraft'
      })
      this.handleGetAllPurchaseOrders()
      this.handleDashboardAllRecordsRequests();
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
      setTimeout(() => {
        this.handleAddPurchaseOrderCloseModal();
        this.handleEditPurchaseOrderCloseModal();
        this.handleClosePurchaseFromRequisitionCreatePO();
      }, 1500);
    }  
  }

  handleSaveAsDraftPurchaseOrderApiCallIdResponse = (message: Message) => {
    if (this.saveAsDraftPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      this.handleSaveAsDraftPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleSaveAsDraftPurchaseOrderEditSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        editedPurchaseOrderDraftMessage: 'Purchase Order Cannot SaveAsDraft'
      })
    } else {
      this.setState({
        editedPurchaseOrderDraftMessage: 'Purchase Order SaveAsDraft'
      })
      this.handleDashboardAllRecordsRequests();
      setTimeout(() => {
        this.handleEditPurchaseOrderCloseModal();
      }, 1500);
    }  
  }

  handleSaveAsDraftPurchaseOrderEditedApiCallIdResponse = (message: Message) => {
    if (this.editedPoSaveAsDraftPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      this.handleSaveAsDraftPurchaseOrderEditSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleGetAllPurchaseRequisitionDataItemsPerPageSetNestedFunc = () => {
    if(this.state.purchaseRequisitionList.length>0 && this.state.purchaseRequisitionList.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesPurchaseRequisition: this.state.purchaseRequisitionList.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesPurchaseRequisition: Math.floor(this.state.purchaseRequisitionList.length/this.state.itemsPerPage)+1
      })
    }
  }

  renderCustomPRTableData = (val:any)=>val?.attributes?.status ===  'approved'

  handleGetAllPurchaseRequisitionPOCustomDataItemsPerPageSetNestedFunc = () =>{
    if(this.state.purchaseRequisitionList?.filter((val:any)=>(val?.attributes?.status ===  'approved')).length>0 && this.state.purchaseRequisitionList?.filter((val:any)=>(val?.attributes?.status ===  'approved')).length%this.state.itemsPerPage===0){
      this.setState({
        totalPagesPurchaseRequisitionPO: this.state.purchaseRequisitionList?.filter(this.renderCustomPRTableData)?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesPurchaseRequisitionPO: Math.floor(this.state.purchaseRequisitionList?.filter((val:any)=>(val?.attributes?.status ===  'approved'))?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleRequisitionsNoRecordPopup = () => {
    if(this.state.purchaseRequisitionList.length === 0 && this.state.itemsSearchBySpecificValue){
      this.setState({
        createOrFindNoRecordsFoundPopup : "No Expected Record Found!"
      })
    }
  }


  handleGetAllPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for Get All Purchase Requisition API', apiResponse?.errors)
    } else {
      this.setState({
        purchaseRequisitionList: apiResponse?.data|| [],
        isPRGetApicalled: true,
      })
      this.handleRequisitionsNoRecordPopup()
      this.handleGetAllPurchaseRequisitionDataItemsPerPageSetNestedFunc()
      this.handleGetAllPurchaseRequisitionPOCustomDataItemsPerPageSetNestedFunc()
    }
  }

  handleGetAllPurchaseRequisitionListAPICallIdResponse = (message: Message) => {
    if (this.getAllPurchaseRequisitionListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        purchaseRequisitionEditMessage: "Purchase Requisition Not Updated"
      })
    } else {
      this.setState({
        openEditRequisitionModal: false,
        purchaseRequisitionEditMessage: "Purchase Requisition Updated"
      });
      this.getAllPurchaseRequisitionsList();
    } 
  }

  handleEditPurchaseRequisitionAPICallIdResponse = (message: Message) => {
    if (this.editPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse) 
      this.setState({
        purchaseRequisitionEditMessage: '',
      })
    }
  }

  handleShowPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      this.setState({
        showPurchaseRequisition: apiResponse?.data
      })
    } 
  }

  handleShowPurchaseRequisitionAPICallIdResponse = (message: Message) => {
    if (this.showPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetUserProfileSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for userProfile API', apiResponse?.errors)
    } else {
      this.setState({
        // userProfile: apiResponse?.data?.attributes?.user_name?.split(/\s/)
        // .reduce((response: string, word: any) => (response += word.slice(0, 1)), ""),
        userType: apiResponse?.data?.attributes?.user_type,
        accountId: apiResponse?.data?.id,
      })      
    }
  }

  handleGetUserProfileAPICallIdResponse = (message: Message) => {
    if (this.getUserProfileAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetUserProfileSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handlegetUserModulePermissionsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for userProfile API', apiResponse?.errors)
    } else {
      this.setState({
        userLoginDetailsCatch : apiResponse
      })

    }
  }

  handlegetUserModulePermissionsAPICallIdResponse = (message: Message) => {
    if (this.getUserModulePermissionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handlegetUserModulePermissionsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetUserPermissionsResponseSuccessAndErrorsNestedFunc = async (apiResponse:any) => {
    if(!apiResponse?.errors){
      await setStorageData('user_permissions',apiResponse.data.attributes.user_permission);
    }
  }
  
  handleGetUserPermissionsAPICallIdResponse = (message: Message) => {
    if (this.getUserPermissionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetUserPermissionsResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }



  handleGetAllProductsListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.productsList.length>0 && this.state.productsList.length%this.state.itemsPerPageProduct==0){
      this.setState({
        totalPagesProduct: this.state.productsList.length/this.state.itemsPerPageProduct
      })
    }
    else{
      this.setState({
        totalPagesProduct: Math.floor(this.state.productsList.length/this.state.itemsPerPageProduct)+1
      })
    }
  }

  handleAllProductsNoRecordPopup = () => { 
    if(this.state.productsList.length === 0 && this.state.itemsCofSearchBySpecificValue){
      this.setState({
        createOrFindItemsNoRecordsFoundPopup : "No Expected Record Found!"
      })
    }
  }

  handleGetAllProductsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllProductsList API', apiResponse?.errors)
    } else {
      this.setState({
        productsList: apiResponse?.data || [],
        isItemscalled : true,
      })
      this.handleAllProductsNoRecordPopup()
      this.handleGetAllProductsListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetAllProductsListAPICallIdResponse = (message:Message) => {
    if (this.getAllProductsListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllProductsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetAllSuppliersListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.suppliersList.length>0 && this.state.suppliersList.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesSupplier: this.state.suppliersList.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesSupplier: Math.floor(this.state.suppliersList.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleSuppliersNoRecordPopup = () => {
    if(this.state.suppliersList.length === 0 && this.state.itemsSearchBySpecificValue){
      this.setState({
        createOrFindNoRecordsFoundPopup : "No Expected Record Found!"
      })
    }
  }

  handleGetAllSuppliersListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllSuppliersList API', apiResponse?.errors)
    } else {
      this.setState({
        suppliersList: apiResponse?.data||[],
        isSupplierscalled: true,
      })
      this.handleSuppliersNoRecordPopup()
      this.handleGetAllSuppliersListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetAllSuppliersListAPICallIdResponse = (message:Message) => {
    if (this.getAllSuppliersListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllSuppliersListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetAllRFPListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.rfpList?.length>0 && this.state.rfpList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesRfp: this.state.rfpList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesRfp: Math.floor(this.state.rfpList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleRPPNoRecordPopup = () => {
    if(this.state.rfpList?.length === 0 && this.state.itemsSearchBySpecificValue){
      this.setState({
        createOrFindNoRecordsFoundPopup : "No Expected Record Found!"
      })
    }
  }

  handleGetAllRFPListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllRFPList API', apiResponse?.errors)
    } else {
      this.setState({
        rfpList: apiResponse?.data||[],
        isRFPGetApicalled: true,
      })
      this. handleRPPNoRecordPopup()
      this.handleGetAllRFPListItemsPerPageSetSubNestedFunc()
    }  
  }

  handleGetAllRFPListAPICallIdResponse = (message:Message) => {
    if (this.getAllRFPListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetAllRFPListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCreateRfpSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      this.setState({
        rfpCreateMessage: 'Request For Proposal Is Not Created',
      })
    } else {
      this.setState({isRfpCreating : false})
      this.handleRfpSelectedRow(apiResponse?.data?.id);
      this.handleRfpIdForPdfCreation(apiResponse?.data?.id);
      this.handleRfpCatchMailForSuppliers(apiResponse?.data?.id);
      this.setState({
        rfpCreateMessage: 'Request For Proposal Is Created',
        openRfpPdfSupplierScreenModal: true,
        isRfpCreationAndApiSuccessDone: true,
      })
      this.getAllRFP()
    }
  }


  handleCreateRfpAPICallIdResponse = (message: Message) => {
    this.setState({isRfpCreating : false})

    if (this.createRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleCreateRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetRfpSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      this.setState({
        rfpEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      this.setState({
        isRfpMailSendConfirmed:false,
        openRfpPdfSupplierScreenModal: false,
        openRfpSupplierMailSeccessModal: true,
        rfpEmailToSupplierSentOrNotErrorMessage:'',
        rfpSelectExistingSuppliers: [],
      })
    } else {
      this.setState({
        rfpEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }



  handleGetRfpSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isRfpMailSending : false})

    if (this.getRfpSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetRfpSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetEditedRfpSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    this.setState({
      isEditedRfpMailSendConfirmed: false,
    })
    if (apiResponse?.errors) {
      this.setState({
        editedRfpEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      this.setState({
        openEditedRfpPdfSupplierScreenModal: false,
        isEditedRfpMailSendConfirmed:false,
        openEditedRfpSupplierMailSeccessModal:true,
        catchEditedRfpEmailSendToSupplier: '',
        editedRfpEmailToSupplierSentOrNotErrorMessage:apiResponse?.message,
      })
    } else {
      this.setState({
        editedRfpEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }

  handleGetEditedRfpSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isEditedRfpMailSending : false})

    if (this.getEditedRfpSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetEditedRfpSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }






  handleShowRfpSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showRFP API', apiResponse?.errors)
    } else {
      this.setState({
        showRfp: apiResponse?.data
      })
    }
  }

  handleShowRfpAPICallIdResponse = (message:Message) => {
    if (this.showRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleEditRfpSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        rfpEditMessage: 'Request For Proposal Is Not Updated',
      })
    } else {
      this.setState({
        openEditRFPModal: false,
        showIsEditRFPValidation: false,
        openAddRFPDownloadFiles: true,
        rfpEditMessage: 'Request For Proposal Is Updated'
      })
      this.getAllRFP()
    } 
  }

  handleEditRfpAPICallIdResponse = (message:Message) => {
    if (this.editRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetAllPurchaseOrderListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.purchaseOrdersList?.length>0 && this.state.purchaseOrdersList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesPurchaseOrder: this.state.purchaseOrdersList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesPurchaseOrder: Math.floor(this.state.purchaseOrdersList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handlePONoRecordPopup = () => {
    if(this.state.purchaseOrdersList?.length === 0 && this.state.itemsSearchBySpecificValue){
      this.setState({
        createOrFindNoRecordsFoundPopup : "No Expected Record Found!"
      })
    }
  }


  handleGetAllPurchaseOrderListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllPO API', apiResponse?.errors)
    } else {
      this.setState({
        purchaseOrdersList: apiResponse?.data||[],
        isPOGetApicalled : true,
      })
      this.handlePONoRecordPopup()
      this.handleGetAllPurchaseOrderListItemsPerPageSetSubNestedFunc()
    } 
  }

  handleGetAllPurchaseOrderListAPICallIdResponse = (message:Message) => {
    if (this.getAllPurchaseOrderListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllPurchaseOrderListSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleGetAllReceiptsPurchaseOrdersForSelectionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllReceiptsPurchaseOrdersForSelection API', apiResponse?.errors)
    } else {
      this.setState({
        recieptsPOSelectionDataArr: apiResponse?.data
      })
    }
  }

  handleGetAllReceiptsPurchaseOrdersForSelectionAPICallIdResponse = (message:Message) => {
    if (this.getAllReceiptsPurchaseOrdersForSelectionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllReceiptsPurchaseOrdersForSelectionSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleGetAllReceiptsPoForRemainingQuantitySuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllRecieptsPoForRemainingQuantity API', apiResponse?.errors)
    } else {
      this.setState({
        recieptsPORemainingQuantityDataArr: apiResponse?.data
      })
    }
  }

  handleGetAllReceiptsPoForRemainingQuantityAPICallIdResponse = (message:Message) => {
    if (this.getAllReceiptsPoForRemainingQuantityAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllReceiptsPoForRemainingQuantitySuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleCreatePurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        poCreateMessage: 'Purchase Order Is Not Created',
      })
    } else {
      this.setState({poCreating: true, prPoCreating: true})
      
      this.handleGetAllPurchaseOrders()
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
      this.handlePoIdForPdfCreation(apiResponse?.data?.id);
      this.handlePoCatchMailForSuppliers(apiResponse?.data?.id);
      this.handleDashboardAllRecordsRequests()
      this.setState({
        poCreateMessage: 'Purchase Order Is Created',
        isPoMailSendConfirmed: false,
        openPoPdfSupplierScreenModal: true,
        openPrPoPdfSupplierScreenModal: true,
        isPoCreationAndApiSuccessDone: true,
        openPurchaseRequisitionListModal: false,
        openCustomFromRequisitionCreatePoModal: false,
        prPoCreateScreenActiveTabs: 0,
        currentPagePurchaseRequisitionPO: 1,
        prPoSelectedRequisitionBudgetError: '',
        prPoSelectedRequisitionSupplierError: '',
        prPoSelectedRequisitionPaymentError: '',
        prPoSelectedRequisitionTaxationError: '',
        prPoSelectedRequisitionShippingError: '',
        prPoSelectedRequisitionBillingError: '',
        purchaseOrderCommentRequiredField: '',
        customFromRequisitionSelectedRow: false,
        poCreateScreenActiveTabs: 1,
        selectPoOptions: '',
        poCreating: true,
        paymentTermsNameSuppliers: [],
        getPaymentTermListSupplier:[],
        deliveryDate: ''
      })
    } 
  }

  handleCreatePurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.createPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePurchaseOrderSuccessAndErrorsNestedFunc(apiResponse)  
      this.setState({
        poId: '',
        poLocation: '',
        poOrderDate: '',
        poFobPoint: '',
        poDepartment: '',
        poProduct: [],
        poComment: '',
        poCommentForPO: '',
        poAmountCalculation: '',
        poGstNumber: '',
        poBillingAddress: '',
        poSupplier: '',
        poBudget: '',
        poModeOfTransit: '',
        poShippingAddress: '',
        poShippingAddressOptional: '',
        taxName: '',
        openCustomFromRequisitionCreatePoModal: false,
        showIsCreatePurchaseOrderValidation: false,
        paymentTermsNameSuppliers: [],
        poCreating: false,
        currentPage: 1,
        getPaymentTermListSupplier: [],
        deliveryDate: '',
      })
    }
  }

  handleGetPoSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      this.setState({
        isPoMailSendConfirmed:false,
        openPoPdfSupplierScreenModal: false,
        openPoSupplierMailSeccessModal: true,
        poEmailToSupplierSentOrNotErrorMessage:'Email Send Successfully',
      })
    } else {
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }

  handleGetPoSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isPoMailSending : false})

    if (this.getPoSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetPoSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetEditedPoSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      this.setState({
        editedPoEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      this.setState({
        isEditedPoMailSendConfirmed: false,
        openEditedPoPdfSupplierScreenModal: false,
        openEditedPoSupplierMailSeccessModal: true,
        catchEditedPoMailOfSupplier: '',
      })
    }
  }

  handleGetEditedPoSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isEditedPoMailSending : false})

    if (this.getPoEditSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetEditedPoSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
      this.handleDashboardAllRecordsRequests()
    }
  }

  handleCreatePurchaseRequisitionOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        prPoCreateMessage: 'Purchase Order Is Not Created',
      })
    } else {
      this.setState({poCreating: true, prPoCreating: true})
      
      this.handleGetAllPurchaseOrders()
      this.handleDashboardAllRecordsRequests()
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
      this.handlePrPoIdForPdfCreation(apiResponse?.data?.id);
      this.handlePoCatchMailForSuppliers(apiResponse?.data?.id);
      this.setState({
        prPoCreateMessage: 'Purchase Order Is Created',
        isPoMailSendConfirmed: false,
        openPoPdfSupplierScreenModal: true,
        openPrPoPdfSupplierScreenModal: true,
        isPoCreationAndApiSuccessDone: true,
        openPurchaseRequisitionListModal: false,
        currentPagePurchaseRequisitionPO: 1,
        prPoSelectedRequisitionBudgetError: '',
        prPoSelectedRequisitionSupplierError: '',
        prPoSelectedRequisitionPaymentError: '',
        prPoSelectedRequisitionTaxationError: '',
        prPoSelectedRequisitionShippingError: '',
        prPoSelectedRequisitionBillingError: '',
        purchaseOrderCommentRequiredField: '',
        customFromRequisitionSelectedRow: false,
        selectPoOptions: '',
      })
    } 
  }

  handleCreatePurchaseRequisitionOrderAPICallIdResponse = (message:Message) => {
    if (this.createPurchaseRequisitionOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePurchaseRequisitionOrderSuccessAndErrorsNestedFunc(apiResponse)  
      this.setState({
        poId: '',
        poLocation: '',
        poOrderDate: '',
        poFobPoint: '',
        poDepartment: '',
        poProduct: [],
        poComment: '',
        poCommentForPO: '',
        poAmountCalculation: '',
        poGstNumber: '',
        poBillingAddress: '',
        poSupplier: '',
        poBudget: '',
        poModeOfTransit: '',
        poShippingAddress: '',
        prPoCreating: false,
        paymentTermsNameSuppliers: [],
      })
    }
  }

  handleGetPrPoSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      this.setState({
        isPrPoMailSendConfirmed:false,
        openPrPoPdfSupplierScreenModal: false,
        openPrPoSupplierMailSeccessModal: true,
        catchPrPoMailOfSupplier: '',
        prPoEmailToSupplierSentOrNotErrorMessage:'Email Send Successfully',
      })
    } else {
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }

  handleGetPrPoSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isPrPoMailSending : false})

    if (this.getPrPoSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetPrPoSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        poEditMessage: 'Purchase Order Is Not Updated'
      })
    } else {
      this.setState({
        openEditPurchaseOrderModal: false,
        poShippingAddress: '',
        poBillingAddress: '',
        poGstNumber: '',
        poEditMessage: 'Purchase Order Is Updated',
      })
      this.handleGetAllPurchaseOrders()
    }  
  }

  handleEditPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.editPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleShowPurchaseOrderSuccessAndErrorsNesteFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPO API', apiResponse?.errors)
    } else {
      this.setState({
        showPurchaseOrder: apiResponse?.data,
      })
      
    }  
  }

  handleShowPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.showPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowPurchaseOrderSuccessAndErrorsNesteFunc(apiResponse)
    }
  }

  handleCreateCustomFromRequisitionPoSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createCustomFromRequisitionPO', apiResponse?.errors)
    } else {
      this.handleGetAllPurchaseOrders()
      this.handleCustomFromRequisitionPOCloseModal()
      this.handleAddCustomFromRequisitionPOCloseModal()
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
    }
  }


  handleCreateCustomFromRequisitionPoAPICallIdResponse = (message:Message) => {
    if (this.createCustomFromRequisitionPoAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateCustomFromRequisitionPoSuccessAndErrorsNestedFunc(apiResponse)
      this.setState({
        poShippingAddress: '',
        poBillingAddress: '',
        poGstNumber: '',
      })
    }
  }

  handleGetAllReceiptsItemsPerPageSubNestedFunc = () => {
    if(this.state.receiptsList?.length>0 && this.state.receiptsList?.length%this.state.itemsPerPage == 0){
      this.setState({
        totalPagesReceipt: this.state.receiptsList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesReceipt: Math.floor(this.state.receiptsList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleReceiptNoRecordPopup = () => {
    if(this.state.receiptsList.length === 0 && this.state.itemsSearchBySpecificValue){
      this.setState({
        createOrFindNoRecordsFoundPopup : "No Expected Record Found!"
      })
    }
  }


  handleGetAllReceiptsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllReceipts API', apiResponse?.errors)
    } else {
      this.setState({
        receiptsList: apiResponse?.data||[],
        isRecieptcalled : true,
      })
      this.handleReceiptNoRecordPopup()
      this.handleGetAllReceiptsItemsPerPageSubNestedFunc()
    } 
  }

  handleGetAllReceiptsAPICallIdResponse = (message:Message) => {
    if (this.getAllReceiptsAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllReceiptsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreateReceiptSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    this.setState({isReceiptCreating : false})
    if(apiResponse?.errors){
      this.setState({
        receiptCreateMessage: 'Receipt Is Not Created',
      })
    } else {      
      this.setState({
        isReceiptCreating : false,
        receipt: apiResponse?.data || [],
        isShowReceiptPdfDownloadBtn: true,
        isReadOnlyReceiptField: true,
        showIsCreateReceiptValidation: false,  
        openReceiptCreateShowErrorModal: false,
        receiptCreateMessage: 'Receipt is created',
        receiptQuantityError: '',
        receiptBasedOnStatus: '',
      })
      this.handleReceiptSelectedRow(apiResponse?.data?.id)
      this.handleGetAllReceipts()
    }
  }

  handleCreateReceiptAPICallIdResponse = (message:Message) => {
    if (this.createReceiptAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateReceiptSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleShowReceiptSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editReceipt API', apiResponse?.errors)
    } else {
      this.setState({
        showReceipt: apiResponse?.data,
      })
    }
  }

  handleShowReceiptAPICallIdResponse = (message:Message) => {
    if (this.showReceiptAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowReceiptSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetCurrencyListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for currencyList API', apiResponse?.errors)
    } else {
      this.setState({
        currencyList: apiResponse?.countries,
      })
    }
  }

  handleGetCurrencyListAPICallIdResponse = (message:Message) => {
    if (this.getCurrencyListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetCurrencyListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreateSupplierSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        supplierCreateMessage: 'Supplier Is Not Created',
      })
    } else {
      this.setState({
        openAddSupplierModal: false,
        supplierStateProvince : '',
        supplierZipCodePinCode : '',
        supplierAddressOfSupplierCompany: '',
        supplierContactNumber: '',
        supplierEmailAddress: '',
        supplierGSTNumber: '',
        supplierPointOfContact: '',
        supplierSupplierLocation: '',
        supplierSupplierName: '',
        supplierTermsOfPayment: '',
        supplierUniqueCode: '',
        paymentTermsNameSuppliers: [],
        suppliersPaymentTermCatchedLables : [],
        supplierCreateMessage: 'Supplier Is Created',
      })
      this.handleSupplierSelectedRow(apiResponse?.data?.id)
      this.getAllSuppliersList()
    }
  }

  handleCreateSupplierAPICallIdResponse = (message:Message) => {
    if (this.createSupplierAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateSupplierSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleEditSupplierSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        supplierEditMessage: 'Supplier Is Not Updated',
      })
    } else {
      this.setState({
        openEditSupplierModal: false,
        supplierEditMessage: 'Supplier Is Updated',
      })
      this.getAllSuppliersList()
    } 
  }

  handleEditSupplierAPICallIdResponse = (message:Message) => {
    if (this.editSupplierAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditSupplierSuccessAndErrorsNestedFunc(apiResponse)  
    }
  }


  handleShowSupplierSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    
    if(apiResponse?.errors){
      console.log('error for showSupplier API', apiResponse?.errors)
    } else {
      this.setState({
        showSupplier: apiResponse?.data,
        poGstNumber: apiResponse?.data?.attributes?.gst_number,
        editedPoGstNumber: apiResponse?.data?.attributes?.gst_number,
        getPaymentTermListSupplier:apiResponse?.data?.attributes?.payment_terms?.data
      })
    }
  }

  handleShowSupplierAPICallIdResponse = (message:Message) => {
    if (this.showSupplierAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowSupplierSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCreateProductSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    
    if(apiResponse?.errors){
      this.setState({
        productCreateMessage: 'Product Is Not Created',
      })
    } else {
      this.setState({
        openAddProductModal: false,
        productCategory: '',
        productDescription: '',
        productName: '',
        productPartId: '',
        productUnitMeasurement: '',
        productUnitPrice: '',
        productTypeSelectedId: '',
        addItemSupplier : '',
        addItemSupplierMultiple: [],
        addItemSupplierMultipleLables : [],
        productCreateMessage: 'Product Is Created',
      })
      this.handleProductSelectedRow(apiResponse?.data?.id)
      this.getAllProductsList()
    } 
  }

  handleCreateProductAPICallIdResponse = (message:Message) => {
    if (this.createProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateProductSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditProductSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        productEditMessage: 'Product Is Not Updated',
      })
    } else {
      this.setState({
        openEditProductModal: false,
        productEditMessage: 'Product Is Updated',
      })
      this.getAllProductsList()
    } 
  }

  handleEditProductAPICallIdResponse = (message:Message) => {
    if (this.editProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditProductSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleShowProductSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showProduct API', apiResponse?.errors)
    } else {
      this.setState({
        showProduct: apiResponse?.data,
      })
    }
  }

  handleShowProductAPICallIdResponse = (message:Message) => {
    if (this.showProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowProductSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetBudgetListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.budgetList?.length>0 && this.state.budgetList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesBudget: this.state.budgetList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesBudget: Math.floor(this.state.budgetList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleBudgetListNoRecordPopup = (message : string) => {
    if(this.state.budgetList?.length===0 && this.state.itemsSearchBySpecificValue){
      this.setState({
        createOrFindNoRecordsFoundPopup : message
      })
    }
  }

  handleGetBudgetListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetList API', apiResponse?.errors)
    } else {
      this.setState({
        budgetList: apiResponse?.data,
        isBudgetcalled : true,
      })
      if(!this.state.budgetList?.length){
        this.setState({selectBudgetIsBlankMessage:'No Budget Created. You Can Create Your Budget From Create Or Find'})
      }
      this.handleBudgetListNoRecordPopup("No Expected Record Found!")
      this.handleGetBudgetListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetBudgetListAPICallIdResponse = (message:Message) => {
    if (this.getBudgetListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetBudgetListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCreateBudgetSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        budgetCreateMessage: 'Budget Is Not Created',
      })
    } else {
      this.setState({
        openAddBudgetModal: false,
        startingFromDate : new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
        budgetAmount: '', 
        budgetComment: '',
        budgetFor: '',
        budgetName: '',
        budgetPeriod: '',
        budgetProductCategories: '',
        budgetCreateMessage: 'Budget Is Created',
      })
      this.handleBudgetSelectedRow(apiResponse?.data?.id)
      this.getBudgetList()
    }  
  }

  handleCreateBudgetAPICallIdResponse = (message:Message) => {
    if (this.createBudgetAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateBudgetSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditBudgetSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        budgetEditMessage: 'Budget Is Not Updated',
      })
    } else {
      this.setState({
        openEditBudgetModal: false,
        budgetEditMessage: 'Budget Is Updated',
      })
      this.handleGetAllBudgets()
    } 
  }

  handleEditBudgetAPICallIdResponse = (message:Message) => {
    if (this.editBudgetAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditBudgetSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleShowBudgetSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showBudget API', apiResponse?.errors)
    } else {
      this.setState({
        showBudget: apiResponse?.data,
      })
    }
  }

  handleShowBudgetAPICallIdResponse = (message:Message) => {
    if (this.showBudgetAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowBudgetSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleInfoCloseModalAndInvokeUserSettingsScreen = () => {
    this.setState({
      showInformation: false,
      showSettings: true
    })
  }

  handleCreateInformationSettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        informationSettingsEditMessage: 'Information Settings Is Not Updated',
      })
    } else {
      this.setState({
        showIsCreateInformationValidation: false,
        informationSettingsEditMessage: 'Information Settings Is Updated',
      })

      setTimeout(() => {
        this.handleInfoCloseModalAndInvokeUserSettingsScreen()
      }, 1500);
    }  
  }

  handleCreateInformationSettingsAPICallIdResponse = (message:Message) => {
    if (this.createInformationSettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateInformationSettingsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreateCurrencySettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        currencySettingsEditMessage: 'Currency Is Not Updated'
      })
    } else {
      this.setState({
        currencySettingsEditMessage: 'Currency Is Updated'
      })
    }  
  }

  handleCreateCurrencySettingsAPICallIdResponse = (message:Message) => {
    if (this.createCurrencySettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateCurrencySettingsSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleGetInformationSettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getInformationSettings API', apiResponse?.errors)
    } else {
      this.setState({
        informationSettings: apiResponse?.data,
        informationSettingsAddress: apiResponse?.data?.attributes?.address,
        informationSettingsCompanyName: apiResponse?.data?.attributes?.company_name,
        informationSettingsEmail: apiResponse?.data?.attributes?.email,
        informationSettingsGstNumber: apiResponse?.data?.attributes?.gst_number,
        informationSettingsPhoneNumber: apiResponse?.data?.attributes?.phone_number,
        informationSettingsPinCode: apiResponse?.data?.attributes?.pin_code,
        informationSettingsWebsite: apiResponse?.data?.attributes?.website,
        poShippingAddress: apiResponse?.data?.attributes?.address,
        informationSettingsSelectInfoSize : apiResponse?.data?.attributes?.organization_size || "",
        informationSettingNoOfPeopleWhoWillAccess : apiResponse?.data?.attributes?.no_of_people || "",
      })
    } 
  }

  handleGetInformationSettingsAPICallIdResponse = (message:Message) => {
    if (this.getInformationSettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetInformationSettingsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetCurrencySettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getCurrencySettings API', apiResponse?.errors)
    } else {
      this.setState({
        currencySettingsCurrency: apiResponse?.data?.attributes?.currency,
      })
    }
  }

  handleGetCurrencySettingsAPICallIdResponse = (message:Message) => {
    if (this.getCurrencySettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetCurrencySettingsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetLocationsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getLocations API', apiResponse?.errors)
    } else {
      this.setState({
        locationsList: apiResponse?.data,
        currentPage: 1,
        noOfPages: Math.ceil(apiResponse?.data?.length / 6)
      })
    } 
  }

  handleGetLocationsAPICallIdResponse = (message:Message) => {
    if (this.getLocationsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetLocationsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleDeleteLocationSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        locationDeleteMessage: 'Location Is Not Deleted',
      })
    } else {
      this.setState({
        locationDeleteMessage: 'Location Is Deleted',
      })
      this.handleGetSettingsLocation()
    } 
  }

  handleDeleteLocationAPICallIdResponse = (message:Message) => {
    if (this.deleteLocationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteLocationSuccessAndErrorsNestedFunc(apiResponse)  
    }
  }

  handleSettingsUsersListNoRecordPopup = (message:string) => { 
    if(this.state.usersList?.length === 0){
      this.setState({
        settingsNoRecordsFoundPopup : message
      })
    }
  }

  handleGetUsersSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getUsers API', apiResponse?.errors)
    } else {
      this.setState({
        usersList: apiResponse?.data,
        currentPage: 1,
        isUsersSettingGotResponseOrNot : true,
        noOfPages: Math.ceil(apiResponse?.data?.length / 6)
      })
      this.handleSettingsUsersListNoRecordPopup("No Expected Record Found!")
    }
  }

  handleGetUsersAPICallIdResponse = (message:Message) => {
    if (this.getUsersAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetUsersSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleDeleteUserSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        userDeleteMessage: 'User Is Not Deleted',
      })
    } else {
      this.setState({
        userDeleteMessage: 'User Is Deleted',
      })
      this.handleGetUsers()
    }
  }

  handleDeleteUserAPICallIdResponse = (message:Message) => {
    if (this.deleteUserAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteUserSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleSaveUsersAlreadyCreatedUsersSubNestedFunc = (apiResponse:any) => {
    if(apiResponse?.meta?.already_created_account.length>0){
      this.setState({
        alreadyCreatedUsers: apiResponse?.meta?.already_created_account[0],
        saveUsersValidation: false,
        userSettingAddUserName: '',
        userSettingAddUserDesignation: '',
        userSettingAddUserEmailId: '',
        userSettingAddUserRole: '',
        userSettingNameRequiredError: '',
        userSettingDesignationRequiredError: '',
        userSettingEmailRequiredError: '',
        userSettingEmailInvalidError: '',
        userSettingRoleRequiredError: '',
      })
    }
    else{
      this.handleGetUsers()
      this.setState({
        saveUsersValidation: false,
        userSettingNameRequiredError: '',
        userSettingDesignationRequiredError: '',
        userSettingEmailRequiredError: '',
        userSettingEmailInvalidError: '',
        userSettingRoleRequiredError: '',
        userCreateMessage: 'User Is Created',
        catchUserSettingsCreatedUserId: apiResponse?.data?.find((item: any) => item?.id)?.id,
        openUserPermissionsModal : true,
        userSettingSelectedRow:apiResponse?.data?.find((item: any) => item?.id)?.id
      })
    }
  }

  handleSaveUsersSuccessAndErrorsNestedFunc =  (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        userCreateMessage: 'User Is Not Created',
      })
    } else {
      this.handleSaveUsersAlreadyCreatedUsersSubNestedFunc(apiResponse)
      this.setState({
        userSettingAddUserName: '',
        userSettingAddUserDesignation: '',
        userSettingAddUserEmailId: '',
        userSettingAddUserRole: '',
      })
    }
  }

  handleSaveUsersAPICallIdResponse = (message:Message) => {
  this.setState({isUserCreating : false})

    if (this.saveUsersAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleSaveUsersSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetDepartmentListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getDepartment API', apiResponse?.errors)
    } else {
      this.setState({
        departmentList: apiResponse
      })
    } 
  }

  handleGetDepartmentListAPICallIdResponse = (message:Message) => {
    if (this.getDepartmentListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetDepartmentListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetProductCategoriesSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getProductCategories API', apiResponse?.errors)
    } else {
      this.setState({
        productCategoriesList: apiResponse?.data
      })
    } 
  }

  handleGetProductCategoriesAPICallIdResponse = (message:Message) => {
    if (this.getProductCategoriesAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetProductCategoriesSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetLocationDropdownListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getLocationDropdown API', apiResponse?.errors)
    } else {
      this.setState({
        locationDropdownList: apiResponse?.data
      })
      if(!this.state.locationDropdownList?.length){
        this.setState({locationCreateMessagePr: 'No Location Found !!. You Can Add Location From Settings'})
      }
    } 
  }

  handleGetLocationDropdownListAPICallIdResponse = (message:Message) => {
    if (this.getLocationDropdownListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetLocationDropdownListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetUserAccessPermissionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        userSettingPermissionUpdatedMessage: 'Error! User Permissions Not Updated!'
      })
    } else {
      this.setState({
        userPermissionsList1: apiResponse?.data?.attributes?.permission,
        editedCatchIdForUserPermissionsUpdation: '',
        openEditedUserPermissionsModal : false,
        createUserPermissionsValidation : false,
        isUserSettingPermissionsUpdating : false,
        editedCatchCoreUserCreatedAccountId : ''
      })
      this.handleGetUsers()
      this.setState({
        userSettingPermissionUpdatedMessage : 'User Permissions Updated!'
      })
    }
  }

  handleGetUserAccessPermissionAPICallIdResponse = (message:Message) => {
    this.setState({
      isUserSettingPermissionsUpdating : false,
    })

    if (this.getUserAccessPermissionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetUserAccessPermissionSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleSaveUserAccessPermissionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        userPermissionsCreatedMessage:'Error! User Permissions Not Added'
      })
    } else {
      this.handleGetUsers()
      this.setState({
        openUserPermissionsModal: false,
        selectedUserPermissions : [],
        selectAllPermissions : false,
        catchUserSettingsCreatedUserId: '',
        userPermissionsCreatedMessage:'User Permissions Added!'
      })
    }
  }

  handleSaveUserAccessPermissionAPICallIdResponse = (message:Message) => {
    this.setState({isUserPermissionsCreating: false})

    if (this.saveUserAccessPermissionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleSaveUserAccessPermissionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetBudgetForListSuccessAndErrorsNestedFunc= (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetForList API', apiResponse?.errors)
    } else {
      this.setState({
        budgetForList: apiResponse
      })
    }
  }

  handleGetBudgetForListAPICallIdResponse = (message:Message) => {
    if (this.getBudgetForListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetBudgetForListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  


  //
  // dashboard receive methods definition start
  //

  handleDashboardSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.error('Error for Dashboard Records API', apiResponse?.errors);
    } else {
      const data = apiResponse?.dashboard_records?.data?.filter((d: any) => d.attributes.status !== null);
      const len = Math.floor(data?.length / this.state.dashboardItemsPerPage);
      const dataCount = data?.length % this.state.dashboardItemsPerPage === 0 ? len : len + 1;
      this.setState({
        dashboardRecordResults: data,
        dashboardNoOfPages: dataCount,
        dashboardShowHeader: true
      })
      if(this.state.dashboardRecordResults?.length>0 && this.state.dashboardRecordResults?.length%this.state.dashboardItemsPerPage==0){
        this.setState({
          dashboardNoOfPages: this.state.dashboardRecordResults.length/this.state.dashboardItemsPerPage
        })
      }
      else{
        this.setState({
          dashboardNoOfPages: Math.floor(this.state.dashboardRecordResults?.length/this.state.dashboardItemsPerPage)+1
        })
      }


      if(!this.state.dashboardRecordResults){
        this.setState({
          dashboardNoRecordMessage: 'No record found !'
        })
      }
    }
  }


  handleDashboardApiCallIdResponse = (message: Message) => {
    if (this.dashboardApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handleDashboardSuccessAndErrorsNestedFunc(apiResponse)
    }
  }



  handleDashboardEditPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        purchaseRequisitionEditMessage: "Purchase Requisition Not Updated"
      })
    } else {
      this.setState({
        purchaseRequisitionEditMessage: "Purchase Requisition Updated"
      },()=> {
        this.handleDashboardAllRecordsRequests()
        this.setState({editPurchaseRequisitionLoading: false})
        setTimeout(() => {
          this.handleEditRequisitionCloseModal()
        }, 1500);
      })
    }
  }

  handleDashboardEditPurchaseRequisitionAPICallIdResponse = (message:Message) => {
    if (this.dashboardEditPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDashboardEditPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleDashboardAcceptRejectActionsPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      if(this.state.prAcceptRejectActionStatus === 'approved'){
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR Is Not Approved'
        })
      }else{
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR Is Not Rejected'
        })
      }
    } else {
      if(this.state.prAcceptRejectActionStatus === 'approved'){
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR Is Approved'
        },()=>{
          this.handleDashboardAllRecordsRequests();
        })
      }else{
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR Is Rejected'
        },()=>{
          this.handleDashboardAllRecordsRequests();
        })
      }
    }
  }

  handleDashboardAcceptRejectActionsPurchaseRequisitionAPICallIdResponse = (message:Message) => {
    if (this.dashboardAcceptRejectActionsPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDashboardAcceptRejectActionsPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleEditDashboardRfpSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      this.setState({
        rfpUpdateMessage: 'Request For Proposal Is Not Updated',
      })
    } else {
      this.setState({isRfpUpdating : false})

      this.handleRfpSelectedRow(apiResponse?.data?.id);
      this.handleEditedRfpIdForPdfUpdation(apiResponse?.data?.id);
      this.handleEditedRfpCatchMailForSuppliers(apiResponse?.data?.id);

      this.setState({
        showIsEditRFPValidation: false,
        openEditedRfpShowErrorModal:false,
        rfpUpdateMessage: 'Request For Proposal Is Updated',
        openEditedRfpPdfSupplierScreenModal: true,
        isEditedRfpUpdationApiSuccessDone: true,
      });
      this.handleDashboardAllRecordsRequests()
    }
  }


  handleEditDashboardRfpAPICallIdResponse = (message: Message) => {
    this.setState({isRfpUpdating : false})

    if (this.editDashboardRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditDashboardRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleDashboardEditPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({editedPoSuccessOrNotMessage: "Purchase Order Not Updated"})
    } else {
      this.setState({
        openEditedPoPdfSupplierScreenModal:true,
        editedPoShippingAddress: '',
        editedPoBillingAddress: '',
        editedPoShippingAddressOptional: '',
        poEditing: false,
        editedPoSuccessOrNotMessage: 'Purchase Order Updated Successfully',
        poEditScreenActiveTabs: 1,
      },()=> this.handleDashboardAllRecordsRequests())
    }  
  }

  handleDashboardEditPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.dashboardEditPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDashboardEditPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleDraftPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse : any) =>{
    if(apiResponse?.errors){
      this.setState({
        purchaseRequisitionDraftMessage: 'Purchase Requisition Not Save As Draft',
      })
    } else {
      this.getAllPurchaseRequisitionsList()
      this.handleSelectedRow(apiResponse?.data?.id)
      this.setState({
        purchaseRequisitionDraftMessage: 'Purchase Requisition Save As Draft',
      },()=>{
        this.handleDashboardAllRecordsRequests()
        this.getAllPurchaseRequisitionsList()
      })
      setTimeout(() => {
        this.handleAddRequisitionCloseModal()
        this.handleEditRequisitionCloseModal()
      }, 1200);
    }
  }

  handleDraftPurchaseRequisitionApiCallIdResponse = (message: Message) => {
    if (this.saveAsDraftPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDraftPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
      
      this.setState({
        showIsCreatePurchaseRequisitionValidation: false,
        showIsEditPurchaseRequisitionValidation: false,
        location: '',
        deliveryDate: '',
        department: '',
        draftPurchaseRequisitionLoading: false,
        products: [],
        deliveryDateCheckMessagePr: '',
        durationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
        editedDurationDate: '',
        editedDeliveryDate: '',
        editedLocation: '',
        editedDepartment: '',
        currentPage:1,
      })
    }
  }

  
  handleDashboardAcceptRejectActionsPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for Dashboard PO accept-reject actions API : ', apiResponse?.errors)
    } else {
      this.setState({
        poAcceptRejectActionStatus: ''
      }, () => this.handleDashboardAllRecordsRequests());
    }
  }

  handleDashboardAcceptRejectActionsPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.dashboardAcceptRejectActionsPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDashboardAcceptRejectActionsPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleSettingsCategoryPagination = () => {
    if(this.state.showSettingsCategory?.length>0 && this.state.showSettingsCategory?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesCategory: this.state.showSettingsCategory?.length/this.state.itemsPerPage,
        currentPageCategory:1,
        categorySerialNumber:0,
      })
    }
    else{
      this.setState({
        noOfPagesCategory: Math.floor(this.state.showSettingsCategory?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleSettingsCategoryNoRecordPopup = (message : string) => { 
    if(this.state.showSettingsCategory?.length === 0){
      this.setState({
        settingsNoRecordsFoundPopup : message
      })
    }
  }

  showSettingsCategorySuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      this.setState({
        showSettingsCategory: apiResponse?.data,
        isCategorySettingGotResponseOrNot : true,
      })
      this.handleSettingsCategoryNoRecordPopup('No Expected Record Found!')
      this.handleSettingsCategoryPagination()
    } 
  }

  showSettingsCategoryAPICallResponse = (message:Message) => {
    if (this.showSettingsCategoryAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.showSettingsCategorySuccessErrorResponse(apiResponse)
     
    }
  }

  handleUnitMeasurePagination = () => {
    if(this.state.showSettingsUnitMeasurement?.length>0 && this.state.showSettingsUnitMeasurement?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesUnitMeasure: this.state.showSettingsUnitMeasurement?.length/this.state.itemsPerPage,
        currentPageUnitMeasure:1,
        unitMeasureSerialNumber : 0
      })
    }
    else{
      this.setState({
        noOfPagesUnitMeasure: Math.floor(this.state.showSettingsUnitMeasurement?.length/this.state.itemsPerPage)+1
      })
    }
  }

  
  handleSettingsUnitMeasurementNoRecordPopup = (message : string) => { 
    if(this.state.showSettingsUnitMeasurement?.length===0){
      this.setState({
        settingsNoRecordsFoundPopup : message
      })
    }
  }

  showSettingsUnitMeasurementSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      this.setState({
        showSettingsUnitMeasurement: apiResponse?.data,
        isUnitMeasureSettingGotResponseOrNot : true,
      })
      this.handleSettingsUnitMeasurementNoRecordPopup("No Expected Record Found!")
      this.handleUnitMeasurePagination()
    }

  }

  showSettingsUnitMeasurementAPICallResponse = (message:Message) => {
    if (this.showSettingsUnitMeasurementAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.showSettingsUnitMeasurementSuccessErrorResponse(apiResponse)
    }
  }

  showSettingsLocationSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for SettingsLocation API', apiResponse?.errors)
    } else {
      this.setState({
        showSettingsLocationSingleData : apiResponse.data,
      })
    }  
  }

  showSettingsLocationAPICallIdResponse = (message:Message) => {
    if (this.showSettingsLocationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.showSettingsLocationSuccessErrorResponse(apiResponse)
    }
  }

  SettingsLocationPagination = () => {
    if(this.state.showSettingsLocation?.length>0 && this.state.showSettingsLocation?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesSettingsLocation: this.state.showSettingsLocation?.length/this.state.itemsPerPage,
        currentPageSettingsLocation: 1,
        locationSerialNumber : 0,
      })
    }
    else{
      this.setState({
        noOfPagesSettingsLocation: Math.floor(this.state.showSettingsLocation?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleSettingsLocationNoRecordPopup = (message : string) => { 
    if(this.state.showSettingsLocation?.length === 0){
      this.setState({
        settingsNoRecordsFoundPopup : message
      })
    }
  }

  showSettingsLocationIdSuccessErrorREsponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      this.setState({
        showSettingsLocation: apiResponse?.data,
        isLocationSettingGotResponseOrNot : true,
      })
      this.handleSettingsLocationNoRecordPopup("No Expected Record Found!")
      this.SettingsLocationPagination()
    } 
  }

  showSettingsLocationAPICallResponse = (message:Message) => {
    
    if (this.showSettingsLocationAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.showSettingsLocationIdSuccessErrorREsponse(apiResponse)
    }
  }

  createLocationSettingsSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        locationCreateMessage: 'Location Settings Is Not Created',
      })
    } else {
      this.setState({
        location:'',
        settingsAddress: '',
        settingsIdentificationCode: '',
        settingsGst: '',
        saveLocationsValidation: false,
        locationCreateMessage: 'Location Settings Is Created'
      },()=>{this.handleGetSettingsLocation()})
    }  
  }

  createLocationSettingsAPICallResponse = (message:Message) => {
    if (this.createLocationSettingsAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.createLocationSettingsSuccessErrorResponse(apiResponse)
    }
  }

  editSettingsLocationSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        locationUpdateMessage: 'Location Is Not Updated',
      })
    } else {
      this.setState({
        openEditSettingLocationModal: false,
        locationUpdateMessage: 'Location Is Updated',
      })
      this.handleGetSettingsLocation()
    } 
  }

  editSettingsLocationAPICallIdResponse = (message:Message) => {
   
    if (this.editSettingsLocationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.editSettingsLocationSuccessErrorResponse(apiResponse)
 
    }
  }

  editSettingsCategoryAPICallIdSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        budgetEditMessage: 'Category Is Not Updated',
        categoryUpdateMessage : 'Category Is Not Updated'
      })
    } else {
      this.setState({
        openEditSettingCategoryModal: false,
        budgetEditMessage: 'Category Is Updated',
        categoryUpdateMessage : 'Category Is Updated',
      })
      this.handleGetDataCategorySettings()
    }
  }

  editSettingsCategoryAPICallIdResponse = (message:Message) => { 
    if (this.editSettingsCategoryAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.editSettingsCategoryAPICallIdSuccessErrorResponse(apiResponse)
      
    }
  }

  editSettingsUnitMeasurementAPICallIdSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        unitMeasurementUpdateMessage: 'Unit Is Not Updated',
      })
    } else {
      this.setState({
        openEditSettingUnitMeasurementModal: false,
        unitMeasurementUpdateMessage: 'Unit Is Updated',
      },()=>{
        this.handleGetDataUnitMeasurementSettings()
      })
    }  
  }

  editSettingsUnitMeasurementAPICallIdResponse = (message:Message) => { 
    if (this.editSettingsUnitMeasurementAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.editSettingsUnitMeasurementAPICallIdSuccessErrorResponse(apiResponse)
    }
  }

  handleCreateCategorySettingsDuplicasyError= (apiResponse : any) => {
    if(apiResponse?.errors?.name){
      this.setState({
        categoryDuplicasyErrorMessage : 'Category Already Been Taken *'
      })
    } else {
      this.setState({
        categoryAddMessage: 'Category Is Not Added',
        informationSettingsEditMessage: 'Information Settings Is Not Updated',
        createSettingsCategoryMessage:"Category Not Created"
      })
    }
  }

  createCategorySettingsSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.handleCreateCategorySettingsDuplicasyError(apiResponse)
      
    } else {
      this.setState({
        categorySettingCreatePost: false,
        categoryAddMessage: 'Category is Added',
        settingsCategoryRequiredField: '',
        settingsCategory: '',
        showIsCreateInformationValidation: false,
        informationSettingsEditMessage: 'Information Settings Is Updated',
        createSettingsCategoryMessage:"Category Created"
      })
    }  
    this.handleGetDataCategorySettings()
  }

  createCategorySettingsAPICallResponse = (message:Message) => { 
    if (this.createCategorySettingsAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.createCategorySettingsSuccessErrorResponse(apiResponse)
      
    }

  }



  handleCreateUnitMeasurementSettingsDuplicasyError = (apiResponse:any) => {
    if(apiResponse?.errors?.unit_of_measurment){
      this.setState({
        unitMeasurementSettingsDuplicasyMessage : 'Measurement Already Been Taken *'
      })
    }
    this.setState({
      informationSettingsEditMessage: 'Information Settings Is Not Updated',
      unitMeasurementCreateMessage:'Unit Measurement Not Created'
    })
  }

  createUnitMeasurementSettingsSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.handleCreateUnitMeasurementSettingsDuplicasyError(apiResponse)
    } else {
      this.setState({
        unitMeasurementSettingPost: false,
        unitMeasurementCreateMessage: 'Unit Of Measurement Is Created',
        settingsUnitMeasurementRequiredField:"",
        settingsUnitMeasurement: '',
      })
    }  
    this.handleGetDataUnitMeasurementSettings()
  }

  createUnitMeasurementSettingsAPICallResponse = (message:Message) => { 
    if (this.createUnitMeasurementSettingsAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.createUnitMeasurementSettingsSuccessErrorResponse(apiResponse)
    }
  }

  //
  // dashboard receive methods definition end
  //

  //
  // Dashboard methods definitions start
  //

  handleDashboardAllRecordsRequests = (): void => {
    const token_localStorage = localStorage.getItem('authToken');

    const headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage
    };

    const dashboardAllRecordGetApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.dashboardApiCallId = dashboardAllRecordGetApiMsg.messageId;

    dashboardAllRecordGetApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/dashboards/fetch_all_records?dashboard_option=${this.state.dropDownItemValue}&dashboard_status=${this.state.dashboardMenuSelection}&query=${this.state.dashboardSearchBySpecificValue}`);
      
    dashboardAllRecordGetApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    dashboardAllRecordGetApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');

    runEngine.sendMessage(dashboardAllRecordGetApiMsg.id, dashboardAllRecordGetApiMsg);
  };

  handleEditPRDepartment = (event: any) => {

    this.setState({
      selectedDepartmentPRIds: event.target.value
    });
    
  };

  isEditPurchaseRequisitionSaveAsDraftValidCheck = () => {
    if (this.state.selectedProductIds.length == 0) {
      this.setState({
        editedProductsRequiredError: "Select product(s) *",
      })
      return false;
    }
    return true;
  };
  
  handleEditPurchaseRequisitionSaveAsDraft = () => {
    if (!this.isEditPurchaseRequisitionSaveAsDraftValidCheck()) {
      this.setState({
        showIsEditPurchaseRequisitionValidation: true,
      })
    }
  };

  handleAcceptRequisitionMyApprovalApiCall = (selectedData:any) => {
    const acceptedRequisition = 'approved';
    this.handleDashboardAcceptRejectPurchaseRequisition(selectedData, acceptedRequisition)
  }

  handleRejectRequisitionMyApprovalApiCall = (selectedData:any) => {
    const rejectedRequisition = 'rejected';
    
    this.handleDashboardAcceptRejectPurchaseRequisition(selectedData, rejectedRequisition)
  }

  handleAcceptPurchaseOrderMyApprovalApiCall = (selectedData: any) => {
    this.setState({
      poAcceptRejectActionStatus : 'true'
    })
    this.handleDashboardAcceptRejectActionPurchaseOrder(selectedData,'approved', 'true')
    this.handlePurchaseOrderSelectedRow(selectedData?.id)
  }

  handleRejectPurchaseOrderMyApprovalApiCall = (selectedData: any) => {
    this.setState({
      poAcceptRejectActionStatus : 'false'
    })
    this.handleDashboardAcceptRejectActionPurchaseOrder(selectedData, 'rejected', 'false')
    this.handlePurchaseOrderSelectedRow(selectedData?.id)
  }

  handleAcceptRequestForProposalsMyApprovalApiCall = (selectedData: any) => {
    this.handleDashboardEditRFPActionBtn(selectedData,'awarded')
  }

  handleRejectRequestForProposalsMyApprovalApiCall = (selectedData: any) => {
    this.handleDashboardEditRFPActionBtn(selectedData,'cancelled')
  }

  handleEditPODepartment = (event: any) => {
    this.setState({
      selectedDepartmentPOIds: event.target.value
    });
  };


  handleEditPoTaxIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editedPoTaxId: event.target.value
    })
  };

  handleDashboardEditRequisitionOpenModal = (editedRequisition: any) => {
    let temp = editedRequisition?.attributes?.products?.data?.map((i: any, index: number) => {
      return i.id
    }) || [];
    this.setState({
      selectedDashboardProductIds: temp
    });
    this.setState({
        openEditRequisitionModal: true,
        editedPurchaseRequisition: editedRequisition,
        editedPrNumberDefault: editedRequisition?.attributes?.purchase_requisition_number,
        editedDurationDate: editedRequisition?.attributes?.pr_creation_date,
        editedDeliveryDate: editedRequisition?.attributes?.delivery_date,
        editedDepartment: editedRequisition?.attributes?.department_id?.id,
        editedLocation: editedRequisition?.attributes?.location_setting_id?.id,
        editedCatalougeItemPr: editedRequisition?.attributes?.requested_items_purchase_requisition,
        editedCatalougeItemProductRequisition: editedRequisition?.attributes?.product_purchase_requisitions,
        editedNonCatalougeItemPr: editedRequisition?.attributes?.non_catalogue_items,
        showIsEditPurchaseRequisitionValidation: false,
    })
    this.handleSelectedRow(editedRequisition?.id)
  };
  destroyFuncNonCataPr = (value:any) =>{
    if(value?._destroy === true){
      return true
    }else{
      return false
    }
  }
  deleteNonCatalougeItem = ()=>{
    
    return this.state.editedNonCatalougeItemPr.filter((value)=> {
      return value?.attributes?.isNew || value?.attributes?.is_non_catalague_item || value?.id
    }).map((value:any)=>{
      const {quantity} = value;
      if(value?._destroy === false || value?._destroy || value?.is_non_catalague_item === true || value?.id){ 
        return {
          id: value?.id,
          name: value?.name,
          quantity,
          is_non_catalague_item: true,
          _destroy: this.destroyFuncNonCataPr(value)
        }
      }else if(value?.attributes?.isNew === true){
        return {
          name: value?.attributes?.name,
          quantity,
          is_non_catalague_item: true,
        }
      }
    })
  }

  destroyFuncCataPr = (value:any) =>{
    if(value?._destroy === true){
      return true
    }else{
      return false
    }
  }

  deleteCatalougeItemPr = () =>{
   return this.state.editedCatalougeItemPr.filter((value: any) => value?.id || value?._destroy).map((value: any)=>{
      const { quantity } = value;
      if(value?._destroy === false ||value?._destroy || value?.is_non_catalague_item === false|| value?.record_id){ 
        return {
          id: Number(value?.record_id),
          quantity,
          is_non_catalague_item: false,
          _destroy: this.destroyFuncCataPr(value),
        }; 
      }else if(value?.id || value?._destroy === undefined){
        return {
          product_id: Number(value?.id),
          quantity,
        }; 
      }
    })
  }

  handleDashboardEditPurchaseRequisition = () => {
    if(!this.isDashboardEditPurchaseRequisitionValidCheck()){
      this.setState({
        showIsEditPurchaseRequisitionValidation: true,
      })
      return  
    }
    this.setState({editPurchaseRequisitionLoading: true})
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      purchase_requisition: { 
        status: "pending",
        pr_creation_date: this.state.editedDurationDate,
        delivery_date: this.state.editedDeliveryDate,
        location: this.state.editedLocation,
        department_id: this.state.editedDepartment,
        product_purchase_requisitions_attributes: this.deleteCatalougeItemPr(),
        non_catalogue_items_attributes: this.deleteNonCatalougeItem()
      }
    };
  
    const editPurchaseRequisitionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardEditPurchaseRequisitionAPICallId = editPurchaseRequisitionApiMsg.messageId;
  
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisitions/${this.state.editedPurchaseRequisition.id}`
    );
  
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(editPurchaseRequisitionApiMsg.id, editPurchaseRequisitionApiMsg); 
  };

  handleDashboardAcceptRejectPurchaseRequisition = (selectedData:any,options: string) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    this.setState({
      prAcceptRejectActionStatus: options
    })
    
    const httpBody = {
      purchase_requisition: {
        type: "purchase_creation",
        pr_creation_date: this.state.showPurchaseRequisition?.attributes?.pr_creation_date,
        delivery_date: this.state.showPurchaseRequisition?.attributes?.delivery_date,
        location_setting_id: this.state.showPurchaseRequisition?.attributes?.location_setting_id?.id,
        department_id: this.state.showPurchaseRequisition?.attributes?.department_id?.id,
        account_id: this.state.showPurchaseRequisition?.attributes?.account?.data?.id,
        status: options,
        product_purchase_requisitions_attributes: this.state.showPurchaseRequisition?.attributes?.requested_items_purchase_requisition?.filter((value: any) => value?.id).map((value: any)=>{
          const { quantity, id, record_id } = value;
          return {
            quantity,
            product_id: id,
            id: record_id
          };
        }),
        non_catalogue_items_attributes: this.state.showPurchaseRequisition?.attributes?.non_catalogue_items?.filter((value: any) => value?.attributes?.isNew || value?.attributes?.is_non_catalague_item).map((value: any)=>{
          const id = value.id
          const name = value?.attributes?.name || value?.name
          const quantity = value?.quantity
          const is_non_catalague_item=  value?.attributes?.isNew || value?.is_non_catalague_item
          return {
            id,
            name,
            quantity,
            is_non_catalague_item,
          }
        }),
        dashboard_status: this.state.dashboardMenuSelection
      }
    };
  
    const actionsPurchaseRequisitionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardAcceptRejectActionsPurchaseRequisitionAPICallId = actionsPurchaseRequisitionApiMsg.messageId;
  
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisitions/${selectedData?.id}`
    );
  
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(actionsPurchaseRequisitionApiMsg.id, actionsPurchaseRequisitionApiMsg); 
  };

  // rfp dashboard edit functions

  rfpAddExistingCatalogItemToFormData=(product:any, i:number, formData:any) =>{
    formData.append(`request_for_proposal_items_attributes[${i}][id]`, product?.id);
    formData.append(`request_for_proposal_items_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpAddNewCatalogItemToFormData=(product:any, i:number, formData:any)=>{
    formData.append(`request_for_proposal_items_attributes[${i}][product_id]`, product?.id);
    formData.append(`request_for_proposal_items_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpDeleteCatalogItemFromFormData=(product:any, i:number, formData:any)=>{
    formData.append(`request_for_proposal_items_attributes[${i}][id]`, product?.id);
    formData.append(`request_for_proposal_items_attributes[${i}][_destroy]`, '1');
  }
  
  rfpAddExistingNonCatalogItemToFormData=(product:any, i:number, formData:any)=>{
    formData.append(`rfp_non_catelogues_attributes[${i}][id]`, product?.id);
    formData.append(`rfp_non_catelogues_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpAddNewNonCatalogItemToFormData=(product:any, i:number, formData:any)=>{
    formData.append(`rfp_non_catelogues_attributes[${i}][name]`, product?.attributes?.name);
    formData.append(`rfp_non_catelogues_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpDeleteNonCatalogItemFromFormData=(product:any, i:number, formData:any) =>{
    formData.append(`rfp_non_catelogues_attributes[${i}][id]`, product?.id);
    formData.append(`rfp_non_catelogues_attributes[${i}][_destroy]`, '1');
  }
  
  rfpAddProductToFormData=(product:any, i:number, formData:any)=>{
    switch (product?.type) {
      case "existing_catelog_items":
        this.rfpAddExistingCatalogItemToFormData(product, i, formData);
        break;
      case "newlyAdded_catelog_items":
        this.rfpAddNewCatalogItemToFormData(product, i, formData);
        break;
      case "deleted_catelog_items":
        this.rfpDeleteCatalogItemFromFormData(product, i, formData);
        break;
      case "existing_non_catelog_items":
        this.rfpAddExistingNonCatalogItemToFormData(product, i, formData);
        break;
      case "newlyAdded_non_catelog_items":
        this.rfpAddNewNonCatalogItemToFormData(product, i, formData);
        break;
      case "deleted_non_catelog_items":
        this.rfpDeleteNonCatalogItemFromFormData(product, i, formData);
        break;
      default:
        break;
    }
  }
  
  rfpAddProductsToFormDataMainDriverFunc=(products:any, formData:any) =>{
    for (let i = 0; i < products.length; i++) {
      this.rfpAddProductToFormData(products[i], i, formData);
    }
  }


  handleDashboardEditRFP = () => {
    if (!this.isEditRFPValidCheck()) {
      this.setState({
        showIsEditRFPValidation: true,
        openEditedRfpShowErrorModal:true
      })
      return
    }

    this.setState({isRfpUpdating : true})

    const token_localStorage = localStorage.getItem('authToken')
    let headers = {
      'token': token_localStorage,
    }

    const filteredFinalExistingcatelog  : any[] = this.state.editedRfpCatelogItems.filter((value: any) => value?.id && value?.request_for_proposal_id).map(items => ({ 
      ...items, 
      type: "existing_catelog_items" 
    }))

    const filteredFinalNewcatelog : any[] = this.state.editedRfpCatelogItems.filter((value: any) => value?.isNewlyAdded).map(items => ({ 
      ...items, 
      type: "newlyAdded_catelog_items" 
    }))

    const filteredFinalDeletedcatelog : any[] = this.state.rfpCatelogDeletedItemsArr.filter((value: any) => value?._destroy).map(items => ({
      ...items, 
      type: "deleted_catelog_items"
    }))

    const filteredFinalExistingNonCatelog  : any[] = this.state.editedRfpNonCatelogItems.filter((value: any) => value?.id && value?.request_for_proposal_id).map(items => ({
      ...items, 
      type: "existing_non_catelog_items"
    }))

    const filteredFinalNewNonCatelog : any[] = this.state.editedRfpNonCatelogItems.filter((value: any) => value?.attributes?.theInputIsNonCatalog).map(items => ({
      ...items, 
      type: "newlyAdded_non_catelog_items"
    }))

    const filteredFinalDeletedNoncatelog : any[] = this.state.rfpNonCatelogDeletedItemsArr.filter((value: any) => value?._destroy).map(items => ({
      ...items, 
      type: "deleted_non_catelog_items"
    }))


    const mainAllProductsArray : any[] = [
      ...filteredFinalExistingcatelog,
      ...filteredFinalNewcatelog,
      ...filteredFinalDeletedcatelog,
      ...filteredFinalExistingNonCatelog,
      ...filteredFinalNewNonCatelog,
      ...filteredFinalDeletedNoncatelog
    ]

    let formdata = new FormData();
    formdata.append("currency", this.state.editedRfpCurrency);
    formdata.append("creation_date", this.state.editedRfpCreationDate);
    formdata.append("due_date", this.state.editedRfpDueDate);
    formdata.append("desired_delivery_date", this.state.editedRfpDesiredDeliveryDate);
    if (this.state.editedRfpFileAttachment?.name) {
      formdata.append("file", this.state.editedRfpFileAttachment);
    }

    formdata.append("loation_setting_id", this.state.editedRfpLocation);
    formdata.append("department_id", this.state.editedRfpDepartment);
    for (let supplier of this.state.editedRfpSelectExistingSupplier){
      formdata.append("supplier_ids[]",supplier)
    }
    formdata.append("email_of_supplier", this.state.editedRfpEmailOfSupplier);

    this.rfpAddProductsToFormDataMainDriverFunc(mainAllProductsArray, formdata)

    for (let pair of formdata.entries()) {
    }

    const editRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editDashboardRfpAPICallId = editRFPListApiMsg.messageId;

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_request_proposal/${this.state.editRfp?.id}`
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(editRFPListApiMsg.id, editRFPListApiMsg);
  };

  handleDashboardEditRFPActionBtn = (selectedValue:any, statusValue:any ) => {
    const token_localStorage = localStorage.getItem('authToken')

    let editedCatelogList:any = selectedValue?.attributes?.request_for_proposal_products?.map((catelog: any, index: number) => {
      return catelog
    })
  
    let editedNonCatelogList:any = selectedValue?.attributes?.rfp_non_catelogues?.map((nonCatelog: any, index: number) => {
      return nonCatelog
    })
    let headers = {
      'token': token_localStorage,
    }

    const filteredFinalExistingcatelog  : any[] = editedCatelogList.filter((value: any) => value?.id && value?.request_for_proposal_id).map((items:any) => ({ 
      ...items, 
      type: "existing_catelog_items" 
    }))

    const filteredFinalExistingNonCatelog  : any[] = editedNonCatelogList.filter((value: any) => value?.id && value?.request_for_proposal_id).map((items:any) => ({
      ...items, 
      type: "existing_non_catelog_items"
    }))

    const mainAllProductsArray : any[] = [
      ...filteredFinalExistingcatelog,
      ...filteredFinalExistingNonCatelog,
    ]

    let formdata = new FormData();
    formdata.append("currency", selectedValue?.attributes?.currency);
    formdata.append("creation_date", selectedValue?.attributes?.creation_date);
    formdata.append("due_date", selectedValue?.attributes?.due_date);
    formdata.append("status", statusValue);
    formdata.append("desired_delivery_date", selectedValue?.attributes?.desired_delivery_date);
    if (selectedValue?.attributes?.file?.name) {
      formdata.append("file", selectedValue?.attributes?.file);
    }

    formdata.append("loation_setting_id", selectedValue?.attributes?.location_setting?.id);
    formdata.append("department_id", selectedValue?.attributes?.department?.id);
    for (let supplier of selectedValue?.attributes?.suppliers?.data?.map((item:any)=>(String(item?.attributes?.id)))){
      formdata.append("supplier_ids[]",supplier)
    }

    this.rfpAddProductsToFormDataMainDriverFunc(mainAllProductsArray, formdata)

    const editRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editDashboardRfpAPICallId = editRFPListApiMsg.messageId;

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_request_proposal/${selectedValue?.id}`
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(editRFPListApiMsg.id, editRFPListApiMsg);
  };


  handleDashboardAcceptRejectActionPurchaseOrder = (selectedData: any, selectedStatus: string, statusAcceptReject: string) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    let selectedPoProductsId: any[] = selectedData?.attributes?.products?.data?.map((product: any) => {
      return product?.id
    })  
    
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",
            location: selectedData?.attributes?.location,
            order_date: selectedData?.attributes?.order_date,
            status: selectedStatus,
            fob_point: selectedData?.attributes?.fob_point,
            shipping_address: selectedData?.attributes?.shipping_address,
            billing_address: selectedData?.attributes?.billing_address,
            gst_number: selectedData?.attributes?.tax_id,
            mode_of_transit: selectedData?.attributes?.mode_of_transit,
            comments_for_po: selectedData?.attributes?.comments_for_po,
            amount_calculation: selectedData?.attributes?.amount_calculation,
            payment_term_id: selectedData?.payment_term?.id,
            budget_id: selectedData?.attributes?.budget?.id,
            comment: selectedData?.attributes?.comment,
            product_ids: selectedPoProductsId,
            approved : statusAcceptReject
        }
      }
    };

    const actionsPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardAcceptRejectActionsPurchaseOrderAPICallId = actionsPurchaseOrderApiMsg.messageId;
  
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${selectedData?.id}`
    );
  
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(actionsPurchaseOrderApiMsg.id, actionsPurchaseOrderApiMsg); 
  };


  handleDashboardEditPurchaseOrderOpenModal = (editedPO: any): void => {
    
    this.setState({
      openEditPurchaseOrderModal: true,
      editedPoId: editedPO?.id,
      editedPoNumber: editedPO?.attributes?.purchase_order_number,
      editedPoBudget: editedPO?.attributes?.budget?.id,
      editedPoComment: editedPO?.attributes?.comment,
      editedPoCommentForPO: editedPO?.attributes?.comments_for_po,
      editedPoDepartment: editedPO?.attributes?.department?.id,
      editedPoLocation: editedPO?.attributes?.location,
      editedPoOrderDate: editedPO?.attributes?.order_date,
      editedPoDeliveryDate: editedPO?.attributes?.delivery_date,
      editedPoProductNonCatalouge:editedPO?.attributes?.non_catalogue_items,
      editedPoProductCatalouge:editedPO?.attributes?.product_purchase_order,
      editedPoTaxId:editedPO?.attributes?.taxation?.id,
      editedPoSupplier: editedPO?.attributes?.supplier?.data?.id,
      catchEditedPoMailOfSupplier:editedPO?.id,
      editedPoPaymentTermsName: editedPO?.attributes?.payment_term?.id,
      editedPoShippingAddress: editedPO?.attributes?.shipping_address,
      editedPoBillingAddress: editedPO?.attributes?.billing_address,
    })
    this.handlePurchaseOrderSelectedRow(editedPO?.id)
  };

  isDashboardEditPurchaseOrderValidCheck = () => {
    let flag = true;
    if(this.state.editedPoSupplier === ""){
      this.setState({
        editedPurchaseOrderSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.editedPoOrderDate === ""){
      this.setState({
        editedPurchaseOrderOrderDateRequiredField: "Select the Order Date *",
      })
      flag=false;
    }
    if(this.state.editedPoDeliveryDate === ""){
      this.setState({
        editedPurchaseOrderDeliveryDateRequiredField: "Select the Delivery Date *",
      })
      flag=false;
    }
    if(this.state.editedPoPaymentTermsName === ''){
      this.setState({
        editedPurchaseOrderPaymentTermsRequiredField: "Select the Payment Terms *",
      })
      flag=false;
    }
    if(this.state.editedPoTaxId === ""){
      this.setState({
        editedPurchaseOrderTaxIdRequiredField: "Select the Taxations *",
      })
      flag=false;
    }
    if(this.state.editedPoBillingAddress === ""){
      this.setState({
        editedPurchaseOrderBillingAddressRequiredField: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.editedPoShippingAddress === ""){
      this.setState({
        editedPurchaseOrderShippingAddressRequiredField: "Enter the Shipping Address *",
      })
      flag=false;
    }
    if(this.state.editedPoBudget === ""){
      this.setState({
        editedPurchaseOrderBudgetRequiredField: "Enter the Budget *",
      })
      flag=false;
    }
    if(this.state.editedPoDepartment === ""){
      this.setState({
        editedPurchaseOrderDepartmentRequiredField: "Select the Department *",
      })
      flag=false;
    }
    if(this.state.editedPoProductCatalouge.length === 0){
      this.setState({
        editedPurchaseOrderProductsRequiredField: "Select the Product *",
      })
      flag=false;
    }
    
    if(new Date(this.state.editedPoDeliveryDate)
    .getDate() < new Date(this.state.editedPoOrderDate)
    .getDate()){
      this.setState({
        editedPurchaseOrderDeliveryDateErrorFormat: 'Delivery Date must be bigger'
      })
      return flag
    }
    return flag;
  }  


  isDashboardEditPurchaseRequisitionValidCheck = () => {
    let flag=true;

    if(this.state.editedLocation === ""){
      this.setState({
        editedLocationRequiredError: "Enter location *",
      })
      flag=false;
    }
    if(this.state.editedDurationDate === ""){
      this.setState({
        editedDurationDateRequiredError: "Enter Creation date *",
      })
      flag=false;
    }
    if(this.state.editedDepartment === ""){
      this.setState({
        editedDepartmentRequiredError: "Enter department *",
      })
      flag=false;
    }
    if(this.state.editedDeliveryDate === ""){
      this.setState({
        editedDeliveryDateRequiredError: "Enter Delivery date *",
      })
      flag=false;
    }
    if(this.state.editedCatalougeItemPr?.length === 0){
      this.setState({
        editedCatalougeItemPrRequiredError: "Enter Catalouge item *",
      })
      flag=false;
    }
    let currentDate:any = new Date()
    .getFullYear()
    .toString()
    .padStart(4, "0") +
    "-" +
    (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0") +
    "-" +
    new Date()
      .getDate()
      .toString()
      .padStart(2, "0")

    if(this.state.editedDurationDate<currentDate){
      this.setState({editedCreationDateCheckMessagePr: 'Invalid Creation Date *'})
      flag=false
    }
    if(this.state.editedDeliveryDate !== '' && this.state.editedDurationDate>this.state.editedDeliveryDate){
      this.setState({editedDeliveryDateCheckMessagePr: 'Invalid Delivery Date *'})
      flag=false
    }
    return flag;
  }
  
  //
  // Dashboard methods defintions end
  //

  
  handleOpenDropdown = (): void => {
    this.setState({
      openDropdown: true
    })
  }
  
  handleCloseDropdown = (): void => {
    this.setState({
      openDropdown: false
    })
  }

  handleInputChangeForSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      itemsSearchBySpecificValue : event.target.value
    })
  }

  handleDashboardInputChangeForSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
    dashboardSearchBySpecificValue : event.target.value
    })
  }
  handleCOFItemsInputChangeForSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      itemsCofSearchBySpecificValue: event.target.value
    })
  }

  handleCOFItemsClearSearchFunc = () => {
    const updatedItems = this.state.mainSearchCOFItemsKeysData?.map((item) => ({
      ...item,
      selected: false,
    }));

    this.setState({
      mainSearchCOFItemsKeysData: updatedItems,
      isCOFItemsMenuForSearchOpen : false,
      anchorElForCOFItems : null,
      itemsCofSearchBySpecificValue : '',
      cofItemsFilterMultiState : '',
      associetedItemNameForItemProductsSearch : ""
    }, ()=> this.getAllProductsList())
   
  }

  handleDashboardClearSearchFunc = () => {
    this.setState({
     dashboardSearchBySpecificValue: ''
    }, () => {
      this.handleDashboardAllRecordsRequests()
  })
    
  }


  handleMainSearchValidation = () => {
    if(this.state.itemsSearchBySpecificValue === ""){
      this.setState({
        itemsSearchValueEmptyPopup: "Please enter querry to search!",
      })
    }
   else {
      this.handleMainSearchClickFuncCall()
    }
  }

  handleMainSearchClickFuncCall = () => {
    if(this.state.subTabValueCof === 'request for proposal'){
      this.getAllRFP()
    } else if(this.state.subTabValueCof === 'purchase orders'){
      this.handleGetAllPurchaseOrders()
    } else if(this.state.subTabValueCof === 'receipts'){
      this.handleGetAllReceipts()
    } else if(this.state.subTabValueCof === 'supplier and items'){
      this.getAllSuppliersList()
    } else if(this.state.subTabValueCof === 'budgets'){
      this.getBudgetList()
    } else {
      this.getAllPurchaseRequisitionsList()
    }
  }


  handleMainSearchClearAllClickFuncCall = () => {
    const updatedItemsOrigin = this.state.mainSearchKeysData?.map((elem) => ({
      ...elem,
      selected: false,
    }));

    this.setState({
      mainSearchKeysData : updatedItemsOrigin,
      filterMultiItemsState : "",
      itemsSearchBySpecificValue : "",
      anchorEl: null,
      isMenuForSearchOpen : false,
      associetedItemNameForMainSearch : ""
    }, ()=> this.handleMainSearchClickFuncCall())
  }



  handleMenuOpen = (event : any) => {
    this.setState({ isMenuForSearchOpen: true, anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ isMenuForSearchOpen: false });
  };

  handleAssociatedModelForSearchNamesForBE = (associated_model_check? : string) => {
    if(associated_model_check) {
      this.setState({
        associetedItemNameForMainSearch : associated_model_check 
      })
    } else {
      this.setState({
        associetedItemNameForMainSearch : ""
      })
    }
  }

  handleMenuItemSelect = (value : string, associated_model_check? : string) => {
    const updatedItem = this.state.mainSearchKeysData?.map((elem) => ({
      ...elem,
      selected: elem.key === value,
    }));
    this.setState({
      mainSearchKeysData: updatedItem,
      filterMultiItemsState: value,
      isMenuForSearchOpen: false,
    });

    this.handleAssociatedModelForSearchNamesForBE(associated_model_check)
  };


  handleCOFItemsMenuOpen = (event : any) => {
    this.setState({ isCOFItemsMenuForSearchOpen: true, anchorElForCOFItems: event.currentTarget });
  };

  handleCOFItemsMenuItemSelectSearch = (value : string) => {
    this.setState({ cofItemsFilterMultiState: value, isCOFItemsMenuForSearchOpen: false });
  };

  handleCOFItemsMenuClose = () => {
    this.setState({ isCOFItemsMenuForSearchOpen: false });
  };

  handleAssociatedModelForCOFItemsSearchForBE = (associated_model_item? : string) => {
    if(associated_model_item) {
      this.setState({
        associetedItemNameForItemProductsSearch : associated_model_item
      })
    } else {
      this.setState({
        associetedItemNameForItemProductsSearch : ""
      })
    }
  }

  handleCOFItemsMenuItemSelect = (selectedKey : string, associated_model_item? : string) => {
    const updatedItems = this.state.mainSearchCOFItemsKeysData?.map((item) => ({
      ...item,
      selected: item.key === selectedKey,
    }));
    this.setState({
      mainSearchCOFItemsKeysData: updatedItems,
      cofItemsFilterMultiState: selectedKey,
      isCOFItemsMenuForSearchOpen: false,
    });

    this.handleAssociatedModelForCOFItemsSearchForBE(associated_model_item)
  };

  generateSearchItemCreateORFindSection = (lable : string, key : string, assosiated_model : string, selected : boolean) => ({
    lable,
    key,
    assosiated_model,
    selected,
  });
  
  generateTabSearchKeysData = (tabValue : string) => {
    switch (tabValue) {
      case "request for proposal":
        return [
          this.generateSearchItemCreateORFindSection("RFP Number", "rfp_number", "", false),
          this.generateSearchItemCreateORFindSection("Creation Date", "creation_date", "", false),
          this.generateSearchItemCreateORFindSection("Due Date", "due_date", "", false),
          this.generateSearchItemCreateORFindSection("Status", "status", "", false),
        ];
      case "purchase orders":
        return [
          this.generateSearchItemCreateORFindSection("PO Number", "purchase_order_number", "", false),
          this.generateSearchItemCreateORFindSection("Supplier", "supplier_name", "supplier", false),
          this.generateSearchItemCreateORFindSection("Requester", "user_name", "account",false),
          this.generateSearchItemCreateORFindSection("Due Date", "order_date", "", false),
          this.generateSearchItemCreateORFindSection("Department", "name","department", false),
          this.generateSearchItemCreateORFindSection("Status", "status", "", false),
        ]
      case "receipts":
        return [
          this.generateSearchItemCreateORFindSection("Receipt Number", "receipt_number", "", false),
          this.generateSearchItemCreateORFindSection("PO Number", "purchase_order_number", "purchase_order", false),
          this.generateSearchItemCreateORFindSection("Supplier Invoice Number", "supplier_invoice_number","supplier_invoice_number", false),
          this.generateSearchItemCreateORFindSection("Creation Date", "receipt_date", "", false),
        ]
      case "supplier and items":
        return [
          this.generateSearchItemCreateORFindSection("Supplier Name", "supplier_name", "", false),
          this.generateSearchItemCreateORFindSection("Unique Code", "unique_code", "", false),
          this.generateSearchItemCreateORFindSection("Address of Supplier Company", "address_of_supplier_company", "", false),
        ];
      case "budgets":
        return [
          this.generateSearchItemCreateORFindSection("Name of Budget", "name", "", false),
          this.generateSearchItemCreateORFindSection("Budget Amount", "amount", "", false),
        ];
      default:
        return [
          this.generateSearchItemCreateORFindSection("PR Number", "purchase_requisition_number", "", false),
          this.generateSearchItemCreateORFindSection("Creation Date", "pr_creation_date", "", false),
          this.generateSearchItemCreateORFindSection("Delivery Date", "delivery_date", "", false),
          this.generateSearchItemCreateORFindSection("PR Location", "name_of_location", "location", false),
          this.generateSearchItemCreateORFindSection("Department", "name", "department", false),
          this.generateSearchItemCreateORFindSection("Status", "status", "", false),
        ]
    }
  };
  

  generateItemProductKeysData = (tabValue: string) => {
    switch (tabValue) {
      case "supplier and items":
        return [
          this.generateSearchItemCreateORFindSection("Item ID", "item_id", "", false),
          this.generateSearchItemCreateORFindSection("Unit Measurement", "unit_measurement", "", false),
          this.generateSearchItemCreateORFindSection("Unit Price", "unit_price", "", false),
          this.generateSearchItemCreateORFindSection("Product Category", "name", "product_category", false),
        ];
       default:
        return [] 
    }
  }
  


  handleDynaStatesDataChangeAfterSubTabChange = (tabValue: string) => {
    const commonState = {
      itemsSearchBySpecificValue: '',
      filterMultiItemsState: '',
      associetedItemNameForMainSearch: ''
    };
  
    const mainSearchKeysDataCatch = this.generateTabSearchKeysData(tabValue);
    const cofItemsProductsKeyData = this.generateItemProductKeysData(tabValue);
  
    this.setState({
      ...commonState,
      itemsCofSearchBySpecificValue : '',
      cofItemsFilterMultiState : '',
      isCOFItemsMenuForSearchOpen : false,
      isMenuForSearchOpen : false, 
      associetedItemNameForItemProductsSearch : "",
      mainSearchKeysData : mainSearchKeysDataCatch,
      mainSearchCOFItemsKeysData : cofItemsProductsKeyData
    });


  };


  handleSettingsSearchMenuOpen = (event : any) => {
    this.setState({ isSettingsScreensMenuForSearchOpen: true, anchorElForSettingsScreens: event.currentTarget });
  };

  handleSettingsSearchMenuClose = () => {
    this.setState({ isSettingsScreensMenuForSearchOpen: false });
  };

  handleSettingsSearchMenuItemSelect = (value : string) => {
    const updatedItem = this.state.mainSearchSettingItemsKeysData?.map((elem) => ({
      ...elem,
      selected: elem.key === value,
    }));
    this.setState({
      mainSearchSettingItemsKeysData: updatedItem,
      settingItemsFilterMultiState: value,
      isSettingsScreensMenuForSearchOpen: false
    });
  };

  handleSettingsScreensInputChangeForSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      itemsSettingSearchBySpecificValue : event.target.value
    })
  }

  handleSettingsAllCoreSearchValidation = () => {
    if(this.state.itemsSettingSearchBySpecificValue === ""){
      this.setState({
        settingsItemsSearchValueEmptyPopup: "Please Enter Querry To Search!",
      })
    } 
   else {
      this.handleSettingsScreensMainSearchClickFuncCall()
    }
  }

  handleSettingsScreensMainSearchClickFuncCall = () => {
    if(this.state.showLocationSettings){
      this.handleGetSettingsLocation()
    } else if(this.state.showUnitMeasureSettings){
      this.handleGetDataUnitMeasurementSettings()
    } else if(this.state.showCategorySettings){
      this.handleGetDataCategorySettings()
    } else if(this.state.showPaymentTermsSettings){
      this.getPaymentTermList()
    } else if(this.state.showTaxationSettings){
      this.getTaxationList()
    } else if(this.state.showUserSettings){
      this.handleGetUsers()
    }
  }


  handleSettingScreensMainSearchClearAllClickFuncCall = () => {
    const updatedItemsOrigin = this.state.mainSearchSettingItemsKeysData?.map((elem) => ({
      ...elem,
      selected: false,
    }));

    this.setState({
      mainSearchSettingItemsKeysData : updatedItemsOrigin,
      settingItemsFilterMultiState: "",
      itemsSettingSearchBySpecificValue : "",
      anchorElForSettingsScreens: null,
      isSettingsScreensMenuForSearchOpen : false,
    }, ()=> this.handleSettingsScreensMainSearchClickFuncCall())
  }

  handleSubTabValueChange = (event: React.ChangeEvent<{}>, value: any): void => {
    
    this.setState({
      subTabValueCof: value,
      subTabValueDashboard: value,
      subTabValue: value,
    }, ()=> this.handleDynaStatesDataChangeAfterSubTabChange(value))
  }

  handleGetAllDashboardOptions = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllDashboardOptionsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDashboardOptionsAPICallId = getAllDashboardOptionsApiMsg.messageId;

    getAllDashboardOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/dashboards/dashboard_options"
    );

    getAllDashboardOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllDashboardOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllDashboardOptionsApiMsg.id, getAllDashboardOptionsApiMsg); 
  }

  handlePRStatusSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if (apiResponse?.errors) {
      console.error('Error for Dashboard Records API', apiResponse?.errors);
    } else {
      this.setState({
        prStatusList: apiResponse?.dashboard_options,
      })
    }
  }

  handleGetPRAllStatus = (message:Message) =>{
    if (this.getAllPRStatusesAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handlePRStatusSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetAllPRStatuses = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllPRStatusesApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPRStatusesAPICallId = getAllPRStatusesApiMsg.messageId;

    getAllPRStatusesApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/status_options"
    );

    getAllPRStatusesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllPRStatusesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllPRStatusesApiMsg.id, getAllPRStatusesApiMsg); 
  }


handleAddRequisitionOpenModal = () => {
  this.setState({
      openAddRequisitionModal: true,
      prNumberUnique: Math.floor(Math.random() * 900000000) + 100000000,

  })
}

handleAddReceiptOpenModal = () => {
  this.handleGetReciptsPurchaseOrdersForSelection()
  this.handleReceiptNewAutoGeneratedNumber()
  this.handleGetReciptsPoForRemainingQuantity()

  this.setState({
      openAddReceiptModal: true,
      receiptQuantityError: '',
  })
}

handleAddSupplierOpenModal = () => {
  this.setState({
      openAddSupplierModal: true,
  })
}

handleAddProductOpenModal = () => {
  this.handleItemsAndSuppliersNewAutoGeneratedNumber();
  this.handleGetDataUnitMeasurementSettings()

  this.setState({
      openAddProductModal: true,
  })
  this.getBudgetList()
}

handleAddBudgetOpenModal = () => {
  this.getBudgetForList()
  this.setState({
      openAddBudgetModal: true,
  })
}

handleReceiptCreateShowErrorModalClose = () => {
  this.setState({
    openReceiptCreateShowErrorModal: false,
  })
}


handleAddReceiptCloseModal = () => {
  this.setState({
    openAddReceiptModal: false,
    showIsCreateReceiptValidation: false,
    receiptAttachmentFile: '',
    receiptSelectedPo: '',
    receiptReceiptDate: '',
    receiptInvoiceNumber:'',
    receiptAttachment:'',
    receiptSelectedPOPopulatedData: {},
    receiptCatelogueItemsStore:[],
    receiptNonCatelogueItemsStore: [],
    isReceiptCreating : false,
    receiptBasedOnStatus: '',
    isShowReceiptPdfDownloadBtn: false,
    isShowReceiptOpenPdfModalSuccess: false,
    receiptPdfSuccessMessage: '',
  })
}

handleAddRequisitionCloseModal = () => {
  this.setState({
      openAddRequisitionModal: false,
      showIsCreatePurchaseRequisitionValidation: false,
      deliveryDate: '',
      durationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      department: '',
      location: '',
      status: '',
      products: [],
      prProduct: [],
      prNonCatalougeItems:'',
      prNumberUnique: '',
      purchaseRequisitionCreateMessage: '',
      quantityErrorPRCreateMessage: '',
      deliveryDateCheckMessagePr: ''
  })
}

handleAddSupplierCloseModal = () => {
  this.setState({
    openAddSupplierModal: false,
    showIsCreateSupplierValidation: false,
    supplierAddressOfSupplierCompany: '',
    supplierContactNumber: '',
    supplierEmailAddress: '',
    supplierGSTNumber: '',
    supplierPointOfContact: '',
    supplierSupplierLocation: '',
    supplierSupplierName: '',
    supplierTermsOfPayment: '',
    supplierUniqueCode: '',
    supplierStateProvince : '',
    supplierZipCodePinCode : '',
    paymentTermsNameSuppliers: [],
    suppliersPaymentTermCatchedLables : [],
    supplierContactNumberErrorMessage : false,
  })
}

handleAddProductCloseModal = () => {
  this.setState({
    openAddProductModal: false,
    showIsCreateProductValidation: false,
    productCategory: '',
    productDescription: '',
    productName: '',
    productPartId: '',
    productTypeSelectedId: '',
    productUnitMeasurement: '',
    productUnitPrice: '',
    addItemSupplier : '',
    addItemSupplierMultiple: [],
    addItemSupplierMultipleLables : [],
  })
}

handleEditPurchaseOrderCloseModal = () => {
  this.setState({
      openEditPurchaseOrderModal: false,
      openEditedPoPdfSupplierScreenModal:false,
      poEditScreenActiveTabs: 0,
      poCreateScreenActiveTabs: 0,
      poEditing: false,
      editedPoId: '',
      editedPoNumber: '',
      editedPoLocation: '',
      editedPoOrderDate: '',
      editedPoDeliveryDate: '',
      editedPoFobPoint: '',
      editedPoDepartment: '',
      editedPoProduct: [],
      editedPoComment: '',
      editedPoCommentForPO: '',
      editedPoAmountCalculation: '',
      editedPoGstNumber: '',
      editedPoBillingAddress: '',
      editedPoSupplier: '',
      editedPoPaymentTermsName: '',
      editedPoBudget: '',
      editedPoModeOfTransit: '',
      editedPoShippingAddress: '',
      editedPoShippingAddressOptional: '',
      poComment:'',
      editedPoSuccessOrNotMessage: '',
      getPaymentTermListSupplier: [],
      editedPurchaseOrderDraftMessage: '',
      editedPoSaveDraft: false,
  })
}

handleAddPurchaseOrderCloseModal = () => {
  this.setState({
      openAddPurchaseOrderModal: false,
      poSaveDraft: false,
      showIsCreatePurchaseOrderValidation: false,
      openCreatePoShowErrorModal: false,
      filteredPoProducts: [],
      poCreateScreenActiveTabs: 0,
      poId: '',
      poLocation: '',
      poOrderDate: '',
      poFobPoint: '',
      poDepartment: '',
      poComment: '',
      poCommentForPO: '',
      poAmountCalculation: '',
      poGstNumber: '',
      poBillingAddress: '',
      poSupplier: '',
      poBudget: '',
      poModeOfTransit: '',
      poShippingAddress: '',
      selectPoOptions: '',
      paymentTermsName: '',
      deliveryDate: '',
      taxName: '',
      poNumberUnique: '',
      poViewScreenActiveTabs:0,
      paymentTermsNameSuppliers: [],
      openPoPdfSupplierScreenModal: false,
      openPoSupplierMailSeccessModal: false,
      purchaseOrderDraftMessage: '',
      poShippingAddressOptional: '',
      getPaymentTermListSupplier: [],
      selectBudgetIsBlankMessage: '',
      poCreating: false
  })
}

handleEditedPoSupplierMailSeccessCloseModal = () => {
  this.setState({
    isEditedPoMailSendConfirmed:false,
    editedPoOrderDate: '',
    deliveryDate: '',
    rfpFileAttachment: '',
    rfpSelectExistingSupplier: [],
    rfpSelectExistingSupplierLabel: [],
    editedPoLocation: '',
    editedPoDepartment: '',
    poEditScreenActiveTabs: 0,
    editedPoNumber: '',
    editedPoComment:'',
    openEditPurchaseOrderModal: false,
    poEditing:false,
    showIsEditPurchaseOrderValidation: false,
    isEditedPoCreationAndApiSuccessDone: false,
    openEditedPoSupplierMailSeccessModal: false,
    editedPoFinalPdfDownloadOrNotMessage:'',
    isEditedPoMailSending:false,
    editedPoEmailToSupplierSentOrNotErrorMessage:'',
  })
}

handlePoSupplierMailSeccessCloseModal = () => {
  this.setState({
    poOrderDate: '',
    deliveryDate: '',
    poLocation: '',
    poDepartment: '',
    poCreateScreenActiveTabs: 0,
    poNumberUnique: '',
    filteredPoProducts:[],
    openAddPurchaseOrderModal: false,
    showIsCreatePurchaseOrderValidation: false,
    openCreatePoShowErrorModal: false,
    isPoCreationAndApiSuccessDone: false,
    isPoMailSendConfirmed:false,
    openPoSupplierMailSeccessModal: false,
    poFinalPdfDownloadOrNotMessage:'',
    isPoMailSending:false,
  })
}

handleAddPurchaseRequisitionOrderCloseModal = () => {
  this.setState({
      openCustomFromRequisitionCreatePoModal: false,
      showIsCreatePrPurchaseOrderValidation: false,
      openCreatePoShowErrorModal: false,
      filteredPoProducts: [],
      poCreateScreenActiveTabs: 0,
      poId: '',
      poLocation: '',
      poOrderDate: '',
      poFobPoint: '',
      poDepartment: '',
      poComment: '',
      poCommentForPO: '',
      poAmountCalculation: '',
      poGstNumber: '',
      poBillingAddress: '',
      poSupplier: '',
      poBudget: '',
      poModeOfTransit: '',
      poShippingAddress: '',
      selectPoOptions: '',
      prPoNumberUnique: '',
      isPrPoCreationAndApiSuccessDone: false,
      poViewScreenActiveTabs:0,
      openPrPoPdfSupplierScreenModal: false,
      openPrPoSupplierMailSeccessModal: false,
      prPoShippingAddressOptional: ''
  })
}

handlePrPoSupplierMailSeccessCloseModal = () => {
  this.setState({
    poOrderDate: '',
    deliveryDate: '',
    poLocation: '',
    poDepartment: '',
    prPoCreateScreenActiveTabs: 0,
    poNumberUnique: '',
    openCustomFromRequisitionCreatePoModal: false,
    showIsCreatePrPurchaseOrderValidation: false,
    openCreatePrPoShowErrorModal: false,
    isPrPoCreationAndApiSuccessDone: false,
    isPrPoMailSendConfirmed:false,
    openPrPoSupplierMailSeccessModal: false,
    prPoFinalPdfDownloadOrNotMessage:'',
    isPrPoMailSending:false,
    openPrPoPdfSupplierScreenModal: false,
    prPoShippingAddressOptional: '',
    paymentTermsName:'',
    taxName: '',
    prPoEmailToSupplierSentOrNotErrorMessage: '',
  })
}

handleAddCustomFromRequisitionPOCloseModal = () => {
  this.setState({
      openCustomFromRequisitionCreatePoModal: false,
      showIsCreateCustomFromRequisitionValidation: false,
      customFromRequisitionPoId: '',
      customFromRequisitionPoOrderDate: '',
      customFromRequisitionPoFobPoint: '',
      customFromRequisitionPoComment: '',
      customFromRequisitionPoCommentForPO: '',
      customFromRequisitionPoAmountCalculation: '',
      customFromRequisitionPoTaxId: '',
      customFromRequisitionPoBillingAddress: '',
      customFromRequisitionPoSupplier: '',
      customFromRequisitionPoBudget: '',
      customFromRequisitionPoModeOfTransit: '',
      customFromRequisitionPoShippingAddress: '',
      poBillingAddress: '',
      poShippingAddress: '',
      poGstNumber: '',
  })
}

handleAddBudgetCloseModal = () => {
  this.setState({
    openAddBudgetModal: false,
    showIsCreateBudgetValidation: false,
    budgetAmount: '',
    budgetComment: '',
    budgetFor: '',
    budgetName: '',
    budgetPeriod: '',
    budgetProductCategories: ''
  })
}

handleEditRequisitionOpenModal = (editedRequisition: any) => {
  let temp = editedRequisition?.attributes?.products?.data?.map((i: any, index: number) => {
    return i.id
  })
  this.setState({
    selectedProductIds: temp
  })
  this.setState({
      openEditRequisitionModal: true,
      editedPurchaseRequisition: editedRequisition,
      editedDurationDate: editedRequisition?.attributes?.pr_creation_date,
      editedDeliveryDate: editedRequisition?.attributes?.delivery_date,
      editedDepartment: editedRequisition?.attributes?.department_id?.id,
      editedLocation: editedRequisition?.attributes?.location_setting_id?.id,
      showIsEditPurchaseRequisitionValidation: false,
  })
  this.handleSelectedRow(editedRequisition?.id)
}

handleEditRequisitionCloseModal = () => {
  this.setState({
      openEditRequisitionModal: false,
      purchaseRequisitionEditMessage:'',
      quantityErrorPREditMessage:''
  })
}

handleOpenNextPurchaseModal = () => {
  this.setState({
    openNextPurchaseModal : true,
    poCreateScreenActiveTabs: 1,
    poEditScreenActiveTabs: 1,
    poViewScreenActiveTabs:1,
  })
}

handlePoTabsScreenClose = () => {
  this.setState({
    poOrderDate: '',
    poDepartment: '',
    poComment: '',
    poCommentForPO: '',
    poAmountCalculation: '',
    poGstNumber: '',
    poBillingAddress: '',
    poSupplier: '',
    poBudget: '',
    poModeOfTransit: '',
    poShippingAddress: '',
    selectPoOptions: '',
    openAddPurchaseOrderModal: false,
    showIsCreatePurchaseOrderValidation: false,
    openCreatePoShowErrorModal: false,
    filteredPoProducts: [],
    poCreateScreenActiveTabs: 0,
    poId: '',
    poLocation: '',
    poFobPoint: '',
    poNumberUnique: '',
    openPoPdfSupplierScreenModal: false,
    openPoSupplierMailSeccessModal: false,
    poCreating: false,
    poViewScreenActiveTabs:0,
    paymentTermsNameSuppliers: [],
    openPrPoPdfSupplierScreenModal: false,
    openPrPoSupplierMailSeccessModal: false,
  })
}

handleCustomFromRequisitionCreatePONextModal = () => {
  this.setState({
    prPoCreateScreenActiveTabs : 1
  })
} 

handleEditSupplierCloseModal = () => {
  this.setState({
    openEditSupplierModal: false,
  })
}

handleEditProductCloseModal = () => {
  this.setState({
    openEditProductModal: false,
  })
}

handleEditBudgetCloseModal = () => {
  this.setState({
    openEditBudgetModal: false,
  })
}

handleRfpNewAutoGeneratedNumber = () => {
  const min = 100000000; 
  const max = 999999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const uniqueNumber = String(randomNumber).padStart(9, '0');
  this.setState({rfpNumberUnique:  uniqueNumber });
}

checkFetchPaymentTermsOrNot = () =>{ 
  if(this.state.getPaymentTermListSupplier?.length === 0 ){
    this.setState({paymentTermsFetchMessage: 'Select Supplier First to Select PaymentTerms'})
  }else{
    this.setState({paymentTermsFetchMessage: ''})
  }
}

checkBudgetFetchOrNot = () =>{ 
  if(this.state.budgetList?.length === 0 ){
    this.setState({selectBudgetIsBlankMessage: 'No Budget Created. You Can Create Your Budget From Create Or Find'})
  }
}

checkTaxationFetchOrNot = () =>{
  if(this.state.taxations?.length === 0 ){
    this.setState({selectTaxationIsBlankMessage: 'No Taxation Found. You Can Add Your Taxation From Settings'})
  }
}

handleReceiptNewAutoGeneratedNumber = () => {
  const min = 100000000; 
  const max = 999999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const uniqueNumber = String(randomNumber).padStart(9, '0');
  this.setState({receiptNumberUnique:  uniqueNumber });
}

handleItemsAndSuppliersNewAutoGeneratedNumber = () => {
  const min = 100000000;
  const max = 999999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const uniqueNumber = String(randomNumber).padStart(9, '0');
  this.setState({addItemSupplierNoUnique:  uniqueNumber });
}

handleAddRequestForProposalsOpenModal = () => {
  this.handleRfpNewAutoGeneratedNumber()
  
  this.setState({
      openAddRFPModal: true,
  })

  if(!this.state.locationDropdownList){
    this.setState({
      locationExistOrNotErrorMessage: 'No Existing Location Found!.. Please First Create From Location Setting'
    })
  }
}

handleNextRFPMainCreateFieldsOpenModal = () => {
  this.setState({
    rfpCreateScreenActiveTabs: 1
  })
}


handleAddPurchaseOrderOpenModal = () => {
  this.handleGetInformationSettings()
  this.getSupplierList()
  this.setState({
    openAddPurchaseOrderModal: true,
    poNumberUnique: Math.floor(Math.random() * 900000000) + 100000000,
    poCreateScreenActiveTabs:0
  })
}

handlePoLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poLocation: event.target.value
  })
}

handlePoSupplierChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poSupplier: event.target.value
  })
  this.handleShowSupplier(event.target.value)
}

handlePoDropdownProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if (value) {
    this.setState({
      filteredPoProducts: [...this.state.filteredPoProducts, { ...value, quantity: '1' }],
    })
  }
  this.setState({
    poNonCatalougeCollectedItem: ''
  })
}


handleEditedPoProductsCatalougeQuantityChange = (product: any, index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {  
  let editedPoProductCatalougeStore = this.state.editedPoProductCatalouge.map((prod, idx) => {
    return product === prod ? {
    ...prod, quantity: event.target.value,
    } : prod
  })

  this.setState({editedPoProductCatalouge: editedPoProductCatalougeStore })
}

handleEditedPoProductsNonCatalougeQuantityChange = (product: any, index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  let editedPoProductNonCatalougeStore = this.state.editedPoProductNonCatalouge.map((prod, idx) => {
    return product === prod ? {
    ...prod, quantity: event.target.value,
    } : prod
  })
  this.setState({ editedPoProductNonCatalouge: editedPoProductNonCatalougeStore })
}

handleProductsEditPoChangeCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.id){
    
    this.setState({editedPoProductCatalouge: [...this.state.editedPoProductCatalouge, {...value, quantity:'1'}]})
  }
  this.setState({editedPoNonCatlogItems:'',  isDeleteRecordPO: 0})
}

handleProductsEditPoChangeNonCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.attributes?.theInputIsNonCatalog === true){
    this.setState({editedPoProductNonCatalouge: [...this.state.editedPoProductNonCatalouge, {...value, quantity:'1'}],})
  }
  this.setState({editedPoNonCatlogItems:'', isDeleteRecordPO: 0})
}

handleEditPoDropdownProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if (value) {
    this.setState({
      filteredPoProducts: [...this.state.filteredPoProducts, { ...value, quantity: '1' }],
    })
  }
  this.setState({
    editedPoNonCatalougeCollectedItem: ''
  })
}

getEditedSearchedPoNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoNonCatlogItems: event.target.value,
    editedPoNonCatalougeCollectedItem: event.target.value
  })
}

handlePoProductsQuantityChange = (product: any, index: number,event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const poProductsStore = [...this.state.filteredPoProducts]
  poProductsStore[index] = { ...this.state.filteredPoProducts[index], ...product, product_id: product?.id, quantity: event.target.value }
  this.setState({ filteredPoProducts: poProductsStore })
}

handleEditedPoProductsDelete = (event:React.MouseEvent<HTMLButtonElement>, product: any, index: number) => {
  this.editedPoProductDeleteHandler(product,index)
}

editedPoProductDeleteHandler = (product:any,index:number)=>{
  const filterEditedProductsAfterDelete = this.state.editedPoProductCatalouge?.map((pro: any, filteredIndex: number) => {
    if(Number(pro?.id) === Number(product?.id)){
      return {...pro,_destroy: 1};
    }else if(pro?._destroy === undefined || Number(pro?.id) === Number(product?.id)){
      return {...pro,_destroy: 0};
    }else{
      return {...pro};
    }
  });
  this.setState({
    editedPoProductCatalouge:filterEditedProductsAfterDelete
  })
}

handleEditedPoNonCataProductsDelete = (product: any, index: number) => {
  const filterEditedProductsAfterDelete = this.state.editedPoProductNonCatalouge?.map((pro: any, filteredIndex: number) => {
    
    if(Number(pro?.id) === Number(product?.id)){
      return {...pro,_destroy: 1};
    }else if(pro?._destroy === undefined){
      return {...pro,_destroy: 0};
    }else{
      return {...pro};
    }
  });

  this.setState({
    editedPoProductNonCatalouge: filterEditedProductsAfterDelete,
  })
}

handlePrPoProductsCataQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const prPoProductsStore = [...this.state.customFromRequisitionCataItemData]
  prPoProductsStore[index] = { ...this.state.customFromRequisitionCataItemData[index], ...product, product_id: product?.id, quantity: event.target.value }
  this.setState({ customFromRequisitionCataItemData: prPoProductsStore})
}

handlePrPoProductsNonCataQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const prPoProductsStore = [...this.state.customFromRequisitionNonCataItemData]
  prPoProductsStore[index] = { ...this.state.customFromRequisitionNonCataItemData[index], ...product, product_id: product?.id, quantity: event.target.value }
  this.setState({ customFromRequisitionNonCataItemData: prPoProductsStore })
}

getSearchedPoNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poNonCatalougeCollectedItem: event.target.value,
    editedPoNonCatalougeCollectedItem: event.target.value
  })
}

handlePoProductsDelete = (event:React.MouseEvent<HTMLButtonElement>, product: any, index: number) => {
  this.CommonPoProductDelete(index)
}

CommonPoProductDelete =(index:number)=>{
  const filteredProductsAfterDelete = this.state.filteredPoProducts?.filter((pro: any, filteredIndex: number) => (filteredIndex !== index))
  this.setState({
    filteredPoProducts: filteredProductsAfterDelete,
  })
}

handlePoOrderDateChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poOrderDate: event.target.value,
    deliveryDate: ''
  })
}

handlePoFobPointChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poFobPoint: event.target.value
  })
}

handlePoShippingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poShippingAddress: event.target.value
  })
}

handlePoShippingAddressOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poShippingAddressOptional: event.target.value
  })
}

handlePrPoShippingAddressOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    prPoShippingAddressOptional: event.target.value
  })
}

handleEditPoShippingAddressOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoShippingAddressOptional: event.target.value
  })
}

handlePoBillingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poBillingAddress: event.target.value
  })
}

handlePoGstNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poGstNumber: event.target.value
  })
}

handlePoModeOfTransitChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poModeOfTransit: event.target.value
  })
}

handlePoProductChange = (event: any) => {
  this.setState({
    poProduct: event.target.value
  })
}

handlePoCommentForPoChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poCommentForPO: event.target.value
  })
}

handlePoAmountCalculationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poAmountCalculation: event.target.value
  })
}

handlePoDepartmentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poDepartment: event.target.value
  })
}

handlePoBudgetChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poBudget: event.target.value
  })
}

handlePoCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poComment: event.target.value
  })
}


handleEditPoLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoLocation: event.target.value
  })
}

handleEditPoSupplierChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoSupplier: event.target.value
  })
  this.handleShowSupplier(event.target.value)
}

handleEditPoOrderDateChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoOrderDate: event.target.value,
    editedPoDeliveryDate: ''
  })
}

handleEditPoDeliveryDateChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoDeliveryDate: event.target.value,
    editedPoOrderDate: (this.state.editedPoOrderDate && this.state.editedPoOrderDate > event.target.value) ? '' : this.state.editedPoOrderDate
  })
}

handleEditPoPaymentTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoPaymentTermsName: event.target.value
  })
}

handleEditPoFobPointChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoFobPoint: event.target.value
  })
}

handleEditPoShippingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoShippingAddress: event.target.value
  })
}

handleEditPoBillingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoBillingAddress: event.target.value
  })
}

handleEditPoGstNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoGstNumber: event.target.value
  })
}

handleEditPoModeOfTransitChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoModeOfTransit: event.target.value
  })
}

handleEditPoProductChange = (event: any) => {
  this.setState({
    editedPoProduct: event.target.value
  })
}

handleEditPoCommentForPoChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoCommentForPO: event.target.value
  })
}

handleEditPoAmountCalculationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoAmountCalculation: event.target.value
  })
}

handleEditPoDepartmentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoDepartment: event.target.value
  })
}

handleEditPoBudgetChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoBudget: event.target.value
  })
}

handleEditPoCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoComment: event.target.value
  })
}

handlePurchaseRequisitionSearchLocationChnage = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    purchaseRequisitionSearchLocation: event.target.value
  })
}

handleAddRFPProductsScreenCloseModal = () => {
  this.setState({
    rfpDesiredDeliveryDate: '',
    rfpSelectExistingSupplier: [],
    rfpSelectExistingSupplierLabel: [],
    rfpCurrency: '',
    rfpCreateScreenActiveTabs: 0,
    rfpDueDate: '',
    rfpLocation: '',
    rfpDepartments: '',
    rfpFileAttachment: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    filteredRfpProducts: [],
    productsSearch: [],
    openRfpPdfSupplierScreenModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    isRfpCreationAndApiSuccessDone: false,
    openRfpSupplierMailSeccessModal: false,
    isRfpMailSending:false,
    isRfpMailSendConfirmed:false,
    rfpFinalPdfDownloadOrNotMessage:'',
    openAddRFPModal: false,
    rfpEmailOfSupplier: '',
  })
}


handleAddRequestForProposalsCloseModal = () => {
  this.setState({
    rfpCurrency: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    rfpDueDate: '',
    rfpDesiredDeliveryDate: '',
    rfpLocation: '',
    rfpDepartments: '',
    rfpSelectExistingSupplier: [],
    rfpSelectExistingSupplierLabel: [],
    rfpFileAttachment: '',
    rfpCreateScreenActiveTabs: 0,
    filteredRfpProducts: [],
    productsSearch: [],
    openRfpPdfSupplierScreenModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    isRfpCreationAndApiSuccessDone: false,
    isRfpMailSending:false,
    openRfpSupplierMailSeccessModal: false,
    isRfpMailSendConfirmed:false,
    rfpFinalPdfDownloadOrNotMessage:'',
    openAddRFPModal: false,
    rfpEmailOfSuppliers: [],
  })
}


handleRfpSupplierMailSeccessCloseModal = () => {
  this.setState({
    rfpCurrency: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    rfpDueDate: '',
    rfpDesiredDeliveryDate: '',
    rfpFileAttachment: '',
    rfpSelectExistingSupplier: [],
    rfpSelectExistingSupplierLabel: [],
    rfpLocation: '',
    rfpDepartments: '',
    rfpCreateScreenActiveTabs: 0,
    openAddRFPModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    rfpAttachmentDropdownDisplay: false,
    isRfpCreationAndApiSuccessDone: false,
    isRfpMailSendConfirmed:false,
    openRfpSupplierMailSeccessModal: false,
    rfpFinalPdfDownloadOrNotMessage:'',
    isRfpMailSending:false,
    filteredRfpProducts: [],
  })
}


handleEditRFPOpenModalPdfAttachNestedFunc = (editedRFP:any) => {
  if(editedRFP?.attributes?.pdf_attach === null){
    this.setState({
      editedRfpAttachment: ''
    })
  }
  else{
    this.setState({
      editedRfpAttachment: editedRFP?.attributes?.pdf_attach
    })
  }
}

handleEditRFPOpenModal = (editedRFP: any): void => {

  let editedCatelogList = editedRFP?.attributes?.request_for_proposal_products?.map((catelog: any, index: number) => {
    return catelog
  })

  let editedNonCatelogList = editedRFP?.attributes?.rfp_non_catelogues?.map((nonCatelog: any, index: number) => {
    return nonCatelog
  })

  this.setState({
    isFileEdited: false,
    openEditRFPModal: true,
    editRfp: editedRFP,
    editedRfpId: editedRFP?.id,
    editedRfpStatus: editedRFP?.attributes?.status,
    editedExistingRfpNumber: editedRFP?.attributes?.rfp_number,
    editedRfpCurrency: editedRFP?.attributes?.currency,
    editedRfpCreationDate: editedRFP?.attributes?.creation_date,
    editedRfpDueDate: editedRFP?.attributes?.due_date,
    editedRfpDesiredDeliveryDate: editedRFP?.attributes?.desired_delivery_date,
    editedRfpLocation: editedRFP?.attributes?.location_setting?.id,
    editedRfpDepartment : editedRFP?.attributes?.department?.id,
    editedRfpSelectExistingSupplier: editedRFP?.attributes?.suppliers?.data?.map((item:any)=>(String(item?.attributes?.id))),
    editedRfpEmailOfSuppliers: editedRFP?.attributes?.existing_supplier_email_address,
    editedRfpFileAttachment: editedRFP?.attributes?.file,
    editedRfpCatelogItems: editedCatelogList,
    editedRfpNonCatelogItems : editedNonCatelogList,
  })

  this.handleRfpSelectedRow(editedRFP?.id)
}


handleCloseAlreadyCreatedUsersSnackbar = () => {
  this.setState({
    alreadyCreatedUsers: []
  })
}

handleEditReceiptOpenModalPdfAttachNestedFunc = (editedReceipt:any) => {
  if(editedReceipt?.attributes?.pdf_attach === null){
    this.setState({
      editedReceiptAttachment: ''
    })
  }
  else{
    this.setState({
      editedReceiptAttachment: editedReceipt?.attributes?.pdf_attach
    })
  }
}

handleEditSupplierOpenModal = (editedSupplier: any): void => {

  this.setState({
    openEditSupplierModal: true,
    editedSupplierId: editedSupplier?.id,
    editedSupplierAddressOfSupplierCompany: editedSupplier?.attributes?.address_of_supplier_company,
    editedSupplierContactNumber: editedSupplier?.attributes?.contact_number,
    editedSupplierEmailAddress: editedSupplier?.attributes?.email_address,
    editedSupplierGSTNumber: editedSupplier?.attributes?.gst_number,
    editedSupplierPointOfContact: editedSupplier?.attributes?.point_of_contact,
    editedSupplierSupplierName: editedSupplier?.attributes?.supplier_name,
    editedSupplierTermsOfPayment: editedSupplier?.attributes?.terms_of_payment,
    editedSupplierUniqueCode: editedSupplier?.attributes?.unique_code,
    editedSupplierStateProvince: editedSupplier?.attributes?.state,
    editedSupplierZipCodePinCode: editedSupplier?.attributes?.zip_code,
    editedSuppliersPaymentTermCatchedLables: editedSupplier?.attributes?.payment_terms?.data?.map((item: any)=> (item?.attributes?.payment_term_name)),
    editedPaymentTermsNameSuppliers : editedSupplier?.attributes?.payment_terms?.data?.map((item: any)=> (item?.id.toString())),

  })
  this.handleSupplierSelectedRow(editedSupplier?.id)
}

handleEditProductOpenModal = (editedProduct: any): void => {
  this.handleGetDataUnitMeasurementSettings()

  this.setState({
    openEditProductModal: true,
    editedProductId: editedProduct?.id,
    editedProductCategory: editedProduct?.attributes?.category_setting?.data?.attributes?.id,
    editedProductDescription: editedProduct?.attributes?.description,
    editedProductName: editedProduct?.attributes?.name,
    editedAddNotes: editedProduct?.attributes?.name,
    editedProductPartId: editedProduct?.attributes?.part_id,
    editedProductTypeSelectedId: editedProduct?.attributes?.item_type,
    editedProductUnitMeasurement: editedProduct?.attributes?.unit_of_measure_setting?.data?.attributes?.id,
    editedProductUnitPrice: editedProduct?.attributes?.unit_price,
    editedItemSupplierMultiple: editedProduct?.attributes?.supplier?.data?.map((item: any)=> (item?.attributes?.id.toString())),
    updatedItemSupplierMultipleLables: editedProduct?.attributes?.supplier?.data?.map((item: any)=> (item?.attributes?.supplier_name))

  })
  this.handleProductSelectedRow(editedProduct?.id)
  this.getProductCategories()
}

handleEditSettingLocationOpenModal = (data: any): void => {
  this.setState({
    openEditSettingLocationModal: true,
    editSettingsLocation:data.attributes?.name_of_location,
    editSettingsAddress:data.attributes?.adderss,
    editSettingsIdentificationCode:data.attributes?.location_identification_code,
    editSettingsGst:data.attributes?.gst_number,
    editSettingsId : data.id
  })
  this.handleShowSettingsLocation(data?.id)
}

handleEditSettingCategoryOpenModal = (data: any): void => {
  this.setState({
    openEditSettingCategoryModal: true,
    editSettingsCategoryName:data.attributes?.name,
    editSettingsCategoryId : data.id
  })
}

handleEditSettingUnitMeasurementOpenModal = (data: any): void => {
  this.setState({
    openEditSettingUnitMeasurementModal: true,
    editSettingsUnitMeasurement:data.attributes?.unit_of_measurment,
    editSettingsUnitMeasurementId : data.id
  })
  this.handleShowSettingsLocation(data?.id)
}

handleEditRFPCloseModal = () => {
  this.setState({
    openEditRFPModal: false,
    showIsEditRFPValidation: false,
  })
}

handleEditReceiptCloseModal = () => {
  this.setState({
    openEditReceiptModal: false,
  })
}

handleDurationDateChange = (event: any) => {
  this.setState({
    durationDate: event.target.value,
    deliveryDate: '',
    deliveryDateCheckMessagePr: ''
  })
}

handleDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    deliveryDate: event.target.value,
    poOrderDate: (this.state.poOrderDate && this.state.poOrderDate > event.target.value) ? '' : this.state.poOrderDate,
  })
}

handlePrDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    deliveryDate: event.target.value,
  })
}

handleDeliveryDateTypeFocus = () => {
  this.setState({
    deliveryDateType: 'date'
  })
}

handleDeliveryDateTypeBlur = () => {
  this.setState({
    deliveryDateType: 'text'
  })
}

handleDurationDateTypeFocus = () => {
  this.setState({
    durationDateType: 'date'
  })
}

handleDurationDateTypeBlur = () => {
  this.setState({
    durationDateType: 'text'
  })
}

handlePoOrderDateTypeFocus = () => {
  this.setState({
    poOrderDateType: 'date'
  })
}

handlePoOrderDateTypeBlur = () => {
  this.setState({
    poOrderDateType: 'text'
  })
}

handleReceiptReceiptDateTypeFocus = () => {
  this.setState({
    receiptDateType: 'date'
  })
}

handleSelectPoOptions = (event: React.ChangeEvent<HTMLSelectElement>) => {
  if(typeof event !== 'undefined'){
    this.setState({selectPoOptions: event.target.value})
  }
}

handleReceiptReceiptDateTypeBlur = () => {
  this.setState({
    receiptDateType: 'text'
  })
}

handleEditedReceiptReceiptDateTypeFocus = () => {
  this.setState({
    editedReceiptDateType: 'date'
  })
}

handleEditedReceiptReceiptDateTypeBlur = () => {
  this.setState({
    editedReceiptDateType: 'text'
  })
}

handleEditPoOrderDateTypeFocus = () => {
  this.setState({
    editedPoOrderDateType: 'date'
  })
}

handleEditPoOrderDateTypeBlur = () => {
  this.setState({
    editedPoOrderDateType: 'text'
  })
}

handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    location: event.target.value
  });
}

handlePrLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 this.setState({
    location: event.target.value
  })
}

handleCheckLocationPresent = () =>{
  if(this.state.locationDropdownList.length === 0){
    this.setState({
      locationCreateMessagePr: 'No Existing Location Found!.. Please First Create From Location Setting'
    })
  }
}

handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    department: event.target.value
  })
}

handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    status: event.target.value
  })
}

handlePrNonCatalougeItemsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    prNonCatalougeItems: event.target.value
  })
}

quantityCheckCreatePr=(quantity?: string) =>{
  if(Number(quantity?.length)<=8 && quantity !== undefined){
    this.setState({prQuantity: quantity})
    return quantity
  }else{
    this.setState({quantityErrorPRCreateMessage: 'Quantity Must Be Less Than 8 Digit' });
    return "1"
  }
}

handlePrQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const temp = [...this.state.prProduct]
  temp[index] = {...this.state.prProduct[index],...product,product_id: product?.id, quantity: this.quantityCheckCreatePr(event.target.value)}
  this.setState({ prProduct: temp })
}

quantityCheckEditPr = (quantity?: string) =>{
  if(Number(quantity?.length)<=8 && quantity !== undefined){
    this.setState({prQuantityEdit: quantity})
    return quantity
  }else{
    this.setState({quantityErrorPREditMessage: 'Quantity Must Be Less Than 8 Digit' });
    return "1"
  }
}
handleEditPrQuantityNonCatalouge = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  // NON CATALOUGE ITEM QUANTITY
  const temp2 = this.state.editedNonCatalougeItemPr.map((prod, idx) => {
    return product === prod ? {
      ...prod, quantity: this.quantityCheckEditPr(event.target.value),
    } : prod
  })
  this.setState({ editedNonCatalougeItemPr: temp2 })
}

handleEditPrQuantityCatalouge = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  // CATALOUGE ITEM QUANTITY
  const temp1 = this.state.editedCatalougeItemPr.map((prod, idx) => {
    return product === prod ? {
      ...prod, quantity: this.quantityCheckEditPr(event.target.value),
    } : prod
  })
  this.setState({ editedCatalougeItemPr: temp1 })
}

handleProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if(value){
    this.setState({
      prProduct: [...this.state.prProduct,{...value, quantity:'1'}],
    })
  }
  this.setState({prNonCatalougeItems: ''})
}

handleProductsChangeCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.id){
    this.setState({editedCatalougeItemPr: [...this.state.editedCatalougeItemPr, {...value, quantity:'1'}]})
  }
  this.setState({prEditNonCatalougeItems:''})
  if(this.state.productsList.length === 0){
    this.setState({productsFoundOrNotMessage: 'No Product Found Create It'})
  }else{
    this.setState({productsFoundOrNotMessage: ''})
  }
}

handleProductsChangeNonCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.attributes?.isNew === true){
    this.setState({editedNonCatalougeItemPr: [...this.state.editedNonCatalougeItemPr, {...value, quantity:'1'}],})
  }
  this.setState({prEditNonCatalougeItems:''})
}

removeTableCellPR = (event:React.MouseEvent<HTMLButtonElement>,product: any, index: number) => {
  this.prTableCellRemoveHandler(index)
}

prTableCellRemoveHandler = (index:number)=>{
  const filteredProducts = this.state.prProduct?.filter((pro:any, proIndex: number)=>(index !== proIndex))
  this.setState({
    prProduct:filteredProducts,
  })
}

handleRemoveTableCellPrNonCatalouge = (index:number)=>{
  const filteredNonCatelogAfterDelet = this.state.editedNonCatalougeItemPr?.filter((pro: any, filteredIndex: number) => (filteredIndex !== index))
  const getDeletedNonCataItemsPr = this.state.editedNonCatalougeItemPr?.find((pro: any, filteredIndex: number) => (filteredIndex === index))

  if(getDeletedNonCataItemsPr){
    this.setState({
      editedNonCatalougeItemPr: [...filteredNonCatelogAfterDelet, { ...getDeletedNonCataItemsPr, _destroy: true}],
    })
  }
}

handleRemoveTableCellPrCatalouge = (index:number)=>{
  const filteredCatelogAfterDelet = this.state.editedCatalougeItemPr?.filter((pro: any, filteredIndex: number) => (filteredIndex !== index))
  const getDeletedItemsPr = this.state.editedCatalougeItemPr?.find((pro: any, filteredIndex: number) => (filteredIndex === index))

  if(getDeletedItemsPr){
    this.setState({
      editedCatalougeItemPr: [...filteredCatelogAfterDelet, { ...getDeletedItemsPr, _destroy: true}],
    })
  }
}

handleProductSelected = (e: React.ChangeEvent<HTMLInputElement>, product?: any) =>{  
  if(product?.id === e.target.value){
    this.setState({
      prCatalougeItemSelectedRow: e.target.checked 
    })
  }
}

handleEditDurationDate = (event: any) => {
  this.setState({
    editedDurationDate: event.target.value,
    editedDeliveryDate: '',
    editedDeliveryDateCheckMessagePr: ''
  })
}

handleStartingFromDate = (event: any) => {
  this.setState({
    startingFromDate: event.target.value
  })
}

handleEditBudgetStartingDate = (event: any) => {
  this.setState({
    editedBudgetStartingDate: event.target.value
  })
}

handleEditBudgetName = (event: any) => {
  this.setState({
    editedBudgetName: event.target.value
  })
}

handleEditBudgetProductCategoriesId = (event: any) => {
  this.setState({
    editedBudgetProductCategoriesId: event.target.value
  })
}

handleEditBudgetPeriod = (event: any) => {
  this.setState({
    editedBudgetPeriod: event.target.value
  })
}

handleEditBudgetAmount = (event: any) => {
  this.setState({
    editedBudgetAmount: event.target.value
  })
}

handleEditBudgetComment = (event: any) => {
  this.setState({
    editedBudgetComment: event.target.value
  })
}

handleEditDeliveryDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedDeliveryDate: event.target.value,
  })
}

handleEditRequester = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRequester: event.target.value
  })
}

handleEditDurationDateTypeFocus = () => {
  this.setState({
    editedDurationDateType: 'date'
  })
}

handleEditDeliveryDateTypeFocus = () => {
  this.setState({
    editedDeliveryDateType: 'date'
  })
}

handleEditDurationDateTypeBlur = () => {
  this.setState({
    editedDurationDateType: 'text'
  })
}

handleEditDeliveryDateTypeBlur = () => {
  this.setState({
    editedDeliveryDateType: 'text'
  })
}

handleEditLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedLocation: event.target.value
  })
}

handleEditStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedStatus: event.target.value
  })
}

handleEditDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedDepartment: event.target.value
  })
}

handleEditBudgetapi = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudget: event.target.value
  })
}

handleEditSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplier: event.target.value
  })
}

handleEditPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPayment: event.target.value
  })
}

handleEditTaxation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedTaxation: event.target.value
  })
}

handleEditBillingLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBillingLocation: event.target.value
  })
}

handleEditProducts = (event: any) => {
  this.setState({
    selectedProductIds: event.target.value
  })
}

handleShowRequisitionOpenModal = (purchaseRequisitionId: string) => {
  this.setState({
    openShowRequisitionModal: true
  })
  this.handleShowPurchaseRequisition(purchaseRequisitionId)
  this.handleSelectedRow(purchaseRequisitionId)
}

handleShowReceiptOpenModal = (receiptId: number, productId: string, type: string) => {
  this.setState({receiptCatProductsIdCatch : '', receiptNonCatProductsIdCatch: ''})

  this.setState({
    openShowReceiptModal: true,
  })
  if(type === "catelog_show"){
    this.setState({
      receiptCatProductsIdCatch: productId,
    })
  } else if(type === "non_catelog_show"){
    this.setState({
      receiptNonCatProductsIdCatch: productId,
    })
  }
  this.handleShowReceipt(receiptId)
  this.handleReceiptSelectedRow(receiptId)
}

handleShowSupplierOpenModal = (supplierId: string) => {
  this.setState({
    openShowSupplierModal: true,
  })
  this.handleShowSupplier(supplierId)
  this.handleSupplierSelectedRow(supplierId)
}

handleShowProductOpenModal = (productId: number) => {
  this.setState({
    openShowProductModal: true,
  })
  this.handleShowProduct(productId)
  this.handleProductSelectedRow(productId)
}

handleShowBudgetOpenModal = (budgetId: number) => {
  this.setState({
    openShowBudgetModal: true,
  })
  this.handleShowBudget(budgetId)
  this.handleBudgetSelectedRow(budgetId)
}

handleShowSettingLocationOpenModal = (data: number) => {
  this.setState({
    openShowSettingLocationModal: true,
  })
  this.handleShowSettingsLocation(data)
}


// rfp change events
handleRfpScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    rfpCreateScreenActiveTabs: value,
  });
}

handleRfpLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpLocation: event.target.value
  })
}

handleRfpDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpDepartments: event.target.value
  })
}

handleRfpDropdownProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if (value) {
    this.setState({
      filteredRfpProducts: [...this.state.filteredRfpProducts, { ...value, quantity: '1' }],
    })
  }
  this.setState({
    rfpNonCatalougeCollectedItems: ''
  })
}


getSearchedNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpNonCatalougeCollectedItems: event.target.value
  })
}

handlerfpProductsQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  let qtyValue: any = numericValue;
  if (qtyValue === '0') {
    event.preventDefault();
    qtyValue = '1';
    event.target.value = qtyValue;
  }

  const rfpProductsStore = [...this.state.filteredRfpProducts];
  rfpProductsStore[index] = { ...this.state.filteredRfpProducts[index], ...product, product_id: product?.id, quantity: qtyValue };
  this.setState({ filteredRfpProducts: rfpProductsStore });
}


handleRfpCatchMailForSuppliers = (email: any) => {
  this.setState({
    catchRfpEmailSendToSupplier: email
  })
}

handleEditedRfpScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    editedRfpScreenActiveTabs: value,
  });
}

handleEditedRfpLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpLocation: event.target.value
  })
}

handleEditedRfpDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpDepartment: event.target.value
  })
}

handleEditedRFPScreenFieldsOpenModal = () => {
  this.setState({
    editedRfpScreenActiveTabs: 1
  })
}

handleEditedRfpCatelogProductChange = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.id){
    this.setState({editedRfpCatelogItems: [...this.state.editedRfpCatelogItems, {...value, quantity:'1', isNewlyAdded: true}]})
  }

  this.setState({
    editedRfpNonCatalougeCollectedItems: ''
  })
  if(this.state.productsList.length === 0){
    this.setState({productsFoundOrNotMessage: 'No Product Found Create It'})
  }else{
    this.setState({productsFoundOrNotMessage: ''})
  }
}


handleEditedRfpNonCatelogProductChange = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.attributes?.theInputIsNonCatalog){
    this.setState({editedRfpNonCatelogItems: [...this.state.editedRfpNonCatelogItems, {...value, quantity:'1', isNewlyAdded: true}]})
  }

  this.setState({
    editedRfpNonCatalougeCollectedItems: ''
  })
}

getEditedAndSearchedNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpNonCatalougeCollectedItems : event.target.value
  })
}

handleEditedRfpCatelogQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {

  const numericValue = event.target.value.replace(/\D/g, "");
  let qtyValue : any = numericValue;
  if (qtyValue === '0') {
    event.preventDefault();
    qtyValue = '1';
    event.target.value = qtyValue
  }

  const editedRfpCatStore = [...this.state.editedRfpCatelogItems]
  editedRfpCatStore[index] = { ...this.state.editedRfpCatelogItems[index], ...product, quantity: event.target.value }
  this.setState({ editedRfpCatelogItems: editedRfpCatStore })
 
}

handleEditedRfpNonCatelogItemsQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  let nonCatQtyValue : any = event.target.value;
  if (nonCatQtyValue === '0') {
    event.preventDefault(); 
    nonCatQtyValue = '1';
    event.target.value = nonCatQtyValue
  }
  const editedRfpNonCatStore = [...this.state.editedRfpNonCatelogItems]
  editedRfpNonCatStore[index] = { ...this.state.editedRfpNonCatelogItems[index], ...product, quantity: nonCatQtyValue }
  this.setState({ editedRfpNonCatelogItems: editedRfpNonCatStore })
}


handleRfpMailSendConfirmation = () => {
  this.setState({
    isRfpMailSendConfirmed:true
  })
}


handleEditedRfpMailSendConfirmation = () => {
  this.setState({
    isEditedRfpMailSendConfirmed:true
  })
}

handleRfpProductsDelet = (event:React.MouseEvent<HTMLButtonElement>, product: any, index: number) => {
  const filteredProductsAfterDelet = this.state.filteredRfpProducts?.filter((pro: any, filteredIndex: number) => (filteredIndex !== index))
  this.setState({
    filteredRfpProducts: filteredProductsAfterDelet,
  })
}

handleEditedRfpCatelogRecordDelet = (event:React.MouseEvent<HTMLButtonElement>, product: any, coreIndex: number) => {
  this.editedRfpCatelogRecDeleteHandler(coreIndex)
}
editedRfpCatelogRecDeleteHandler = (coreIndex:number)=>{
  const filteredCatelogAfterDelet = this.state.editedRfpCatelogItems?.filter((pro: any, filteredIndex: number) => (filteredIndex !== coreIndex))
  const getDeletedItems = this.state.editedRfpCatelogItems?.find((pro: any, filteredIndex: number) => (filteredIndex === coreIndex))
  
  this.setState({
    editedRfpCatelogItems: filteredCatelogAfterDelet,
  })

  if(getDeletedItems){
    this.setState({
      rfpCatelogDeletedItemsArr: [...this.state.rfpCatelogDeletedItemsArr, { ...getDeletedItems, _destroy: '1'}],
    })
  }
}

handleEditedRfpNonCatelogItemsRecordDelet = (event:React.MouseEvent<HTMLButtonElement>, product: any, coreIndex: number) => {
  this.editedRfpNonCatelogItemsRecDelete(coreIndex)
}

editedRfpNonCatelogItemsRecDelete = (coreIndex:number)=>{
  const filteredNonCatelogAfterDelet = this.state.editedRfpNonCatelogItems?.filter((pro: any, filteredIndex: number) => (filteredIndex !== coreIndex))
  const getNonCatDeletedItems = this.state.editedRfpNonCatelogItems?.find((pro: any, filteredIndex: number) => (filteredIndex === coreIndex))

  this.setState({
    editedRfpNonCatelogItems: filteredNonCatelogAfterDelet,
  })

  if (getNonCatDeletedItems) {
    const updatedItem = { ...getNonCatDeletedItems, _destroy: '1' }
    this.setState({
      rfpNonCatelogDeletedItemsArr: [...this.state.rfpNonCatelogDeletedItemsArr, updatedItem],
    })
  }
}

handleEditedRfpIdForPdfUpdation = (id: number) => {
  this.setState({
    catchEditedRfpIdForPdfUpdation: id
  })
}

handleEditedRfpCatchMailForSuppliers = (email: any) => {
  this.setState({
    catchEditedRfpEmailSendToSupplier: email
  })
}









handleRfpTabsScreenClose = () => {
  const rfpScreenStatesCleared = {
    rfpDueDate: '',
    rfpDesiredDeliveryDate: '',
    rfpSelectExistingSupplier: [],
    rfpSelectExistingSupplierLabel: [],
    rfpFileAttachment: '',
    rfpCreateScreenActiveTabs: 0,
    rfpEmailOfSupplier:'',
    productsSearch: [],
    openRfpPdfSupplierScreenModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    isRfpCreationAndApiSuccessDone: false,
    isRfpMailSendConfirmed:false,
    openRfpSupplierMailSeccessModal: false,
    filteredRfpProducts: [],
    isRfpMailSending:false,
    rfpFinalPdfDownloadOrNotMessage:'',
    rfpCurrency: '',
    rfpEmailOfSupplierEmailPatternError:false,
    rfpEmailOfSupplierEmailDuplicateError:false,
    rfpSupplierEmailMatchError:'',
    locationExistOrNotCheckingRequiredError: '',
    rfpLocation: '',
    rfpDepartments: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    openAddRFPModal: false,
    rfpEmailOfSuppliers: [],
  }

  this.setState(rfpScreenStatesCleared)
}

handleRfpMailSendConfirmationCloseBar = () => {
  this.setState({
    isRfpMailSendConfirmed:false
  })
}


handleEditedRfpTabsScreenClose = () => {
  const initialState = {
    editedRfpEmailOfSupplier: '',
    editedRfpCurrency: '',
    editedRfpDueDate: '',
    editedRfpDesiredDeliveryDate: '',
    editedRfpLocation: '',
    editedRfpDepartment: '',
    editedRfpSelectExistingSupplier: [],
    editedRfpSelectExistingSupplierLabels: [],
    editedRfpFileAttachment: '',
    editedRfpScreenActiveTabs: 0,
    rfpUpdateMessage:'',
    productsSearch: [],
    editedExistingRfpNumber: '',
    editedRfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    editedFilteredRfpProducts: [],
    rfpNonCatelogDeletedItemsArr: [],
    rfpCatelogDeletedItemsArr: [],
    openEditedRfpPdfSupplierScreenModal: false,
    showIsEditRFPValidation: false,
    openEditedRfpShowErrorModal: false,
    isEditedRfpUpdationApiSuccessDone: false,
    openRfpSupplierMailSeccessModal: false,
    isRfpMailSendConfirmed: false,
    isEditedRfpMailSendConfirmed: false,
    isEditedRfpMailSending: false,
    isRfpUpdating: false,
    openEditRfpShowErrorModal: false,
    isEditedRFPFileDownloaded: false,
    editedRfpFinalPdfDownloadOrNotMessage: '',
    openEditRFPModal: false,
  };

  this.setState(initialState);
}


getInitialRFPState = () => ({
  rfpUpdateMessage:'',
  editedRfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
  editedRfpDueDate: '',
  editedRfpDesiredDeliveryDate: '',
  editedRfpScreenActiveTabs: 0,
  editedRfpLocation: '',
  editedRfpDepartment: '',
  editedRfpSelectExistingSupplier: [],
  editedRfpSelectExistingSupplierLabels: [],
  editedRfpFileAttachment: '',
  editedFilteredRfpProducts: [],
  editedRfpEmailOfSupplier:'',
  rfpCatelogDeletedItemsArr: [],
  openEditRfpShowErrorModal: false,
  editedExistingRfpNumber: '',
  editedRfpCurrency: '',
  openEditedRfpShowErrorModal: false,
  isEditedRfpUpdationApiSuccessDone: false,
  openRfpSupplierMailSeccessModal: false,
  isEditedRfpMailSending: false,
  isRfpMailSendConfirmed: false,
  isRfpUpdating: false,
  isEditedRfpMailSendConfirmed: false,
  isEditedRFPFileDownloaded: false,
  productsSearch: [],
  rfpNonCatelogDeletedItemsArr: [],
  editedRfpFinalPdfDownloadOrNotMessage: '',
  openEditRFPModal: false,
  openEditedRfpPdfSupplierScreenModal: false,
  showIsEditRFPValidation: false,
});

handleEditedRFPProductsScreenCloseModal = () => {
  const editedRFPProducts = {
    ...this.getInitialRFPState(),
    openEditedRfpPdfSupplierScreenModal: false,
    openEditRfpShowErrorModal: false
  };

  this.setState(editedRFPProducts);
}

handleEditedRequestForProposalsCloseModal = () => {
  const editedRFPClosedStates = {
    ...this.getInitialRFPState()
  };

  this.setState(editedRFPClosedStates);
}


handleEditedRfpSupplierEditedMailSeccessCloseModal = () => {
  this.setState({
    editedExistingRfpNumber: '',
    editedRfpCurrency: '',
    editedRfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    editedRfpDueDate: '',
    editedRfpDesiredDeliveryDate: '',
    editedRfpLocation: '',
    editedRfpDepartment: '',
    editedRfpSelectExistingSupplier: [],
    editedRfpSelectExistingSupplierLabels: [],
    editedRfpFileAttachment: '',
    editedRfpScreenActiveTabs: 0,
    rfpUpdateMessage:'',
    editedFilteredRfpProducts: [],
    editedRfpEmailOfSupplier:'',
    productsSearch: [],
    rfpNonCatelogDeletedItemsArr: [],
    rfpCatelogDeletedItemsArr: [],
    openEditedRfpPdfSupplierScreenModal: false,
    isRfpUpdating:false,
    showIsEditRFPValidation: false,
    openEditedRfpShowErrorModal: false,
    isRfpMailSendConfirmed:false,
    isEditedRfpMailSendConfirmed:false,
    isEditedRfpUpdationApiSuccessDone: false,
    openEditedRfpSupplierMailSeccessModal: false,
    isEditedRfpMailSending:false,
    isEditedRFPFileDownloaded:false,
    editedRfpFinalPdfDownloadOrNotMessage:'',
    openEditRFPModal: false
  })
}

handleEditedRfpMailSendConfirmationCloseBar = () => {
  this.setState({
    isEditedRfpMailSendConfirmed:false
  })
}

handleCloseEditedRfpErrorModal = () => {
  this.setState({
    openEditedRfpShowErrorModal: false,
  })
}






handleEditedRfpFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({
    editedRfpFinalPdfDownloadOrNotMessage: '',
  })
}



handleShowSettingLocationCloseModal = () => {
  this.setState({
    openShowSettingLocationModal: false,
  })
}

handleEditSettingsLocationCloseModal = () => {
  this.setState({
    openEditSettingLocationModal: false,
  })
}

handleEditSettingsCategoryCloseModal = () => {
  this.setState({
    openEditSettingCategoryModal: false,
  })
}

handleEditSettingsUnitMeasurementCloseModal = () => {
  this.setState({
    openEditSettingUnitMeasurementModal: false,
  })
}

handleShowRequisitionCloseModal = () => {
  this.setState({
    openShowRequisitionModal: false,
    prAcceptRejectActionStatusMessage: ''
  })
}

handleShowPurchaseOrderCloseModal = () => {
  this.setState({
    openShowPurchaseOrderModal: false,
    poBillingAddress: '',
    poGstNumber: '',
    poShippingAddress: '',
    poViewScreenActiveTabs:0
  })
}

handlePurchaseRequisitionListOpenModal = () => {
  this.setState({
    openPurchaseRequisitionListModal: true
  },()=>{
    this.getAllPurchaseRequisitionsList()
  })
}

handleRfpStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpStatus: event.target.value
  })
}


handleRFPProductsChange = (event: any) => {
  this.setState({
    addRfpProducts: event.target.value
  })
}

handleRFPProductsScreenNonCatItemTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    addRfpProductsNonCatItemText: event.target.value
  })
}

handleRfpCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpCurrency: event.target.value
  })
}


handleRfpCreationDateChange = (event : React.ChangeEvent<HTMLInputElement>) => {
  const newCreationDate = event.target.value;
  const newDueDate = newCreationDate > this.state.rfpDueDate ? '' : this.state.rfpDueDate;
  const newDeliveryDate = newCreationDate > this.state.rfpDesiredDeliveryDate ? '' : this.state.rfpDesiredDeliveryDate;
  
  this.setState({
    rfpCreationDate: newCreationDate,
    rfpDueDate: newDueDate,
    rfpDesiredDeliveryDate : newDeliveryDate
  });
};



handleRfpDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpDueDate: event.target.value
  })
}

handleRfpAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpAddress: event.target.value
  })
}

handleRfpDesiredDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpDesiredDeliveryDate: event.target.value
  })
}



handleRfpCreationDateTypeFocus = () => {
  this.setState({
    rfpCreationDateType: 'date'
  })
}

handleRfpCreationDateTypeBlur = () => {
  this.setState({
    rfpCreationDateType: 'text'
  })
}

handleRfpDueDateTypeFocus = () => {
  this.setState({
    rfpDueDateType: 'date'
  })
}

handleRfpDueDateTypeBlur = () => {
  const creationDate = this.state.rfpCreationDate;
  const dueDate = this.state.rfpDueDate;
  
  if (creationDate && dueDate < creationDate) {
    this.setState({ rfpDueDate: '' });
  }

  this.setState({
    rfpDueDateType: 'text'
  })
}

handleRfpDesiredDeliveryDateTypeFocus = () => {
  this.setState({
    rfpDesiredDeliveryDateType: 'date'
  })
}

handleRfpDesiredDeliveryDateTypeBlur = () => {
  const creationDate = this.state.rfpCreationDate;
  const delDate = this.state.rfpDesiredDeliveryDate;
  
  if (creationDate && delDate < creationDate) {
    this.setState({ rfpDesiredDeliveryDate: '' });
  }
  this.setState({
    rfpDesiredDeliveryDateType: 'text'
  });
}

handleEditRfpCreationDateTypeFocus = () => {
  this.setState({
    editedRfpCreationDateType: 'date'
  })
}

handleEditRfpCreationDateTypeBlur = () => {
  this.setState({
    editedRfpCreationDateType: 'text'
  })
}

handleEditRfpDueDateTypeFocus = () => {
  this.setState({
    editedRfpDueDateType: 'date'
  })
}

handleEditRfpDueDateTypeBlur = () => {
  const creationDate = this.state.editedRfpCreationDate;
  const dueDate = this.state.editedRfpDueDate;
  
  if (creationDate && dueDate < creationDate) {
    this.setState({ editedRfpDueDate: '' });
  }

  this.setState({
    editedRfpDueDateType: 'text'
  })
}

handleEditRfpDesiredDeliveryDateTypeFocus = () => {
  this.setState({
    editedRfpDesiredDeliveryDateType: 'date'
  })
}

handleEditRfpDesiredDeliveryDateTypeBlur = () => {
  const creationDate = this.state.editedRfpCreationDate;
  const delDate = this.state.editedRfpDesiredDeliveryDate;
  
  if (creationDate && delDate < creationDate) {
    this.setState({editedRfpDesiredDeliveryDate: '' });
  }

  this.setState({
    editedRfpDesiredDeliveryDateType: 'text'
  })
}

handleRfpEmailOfSupplierChangeEmailMatchNestedFunc = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(!event.target.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
    this.setState({
      rfpEmailOfSupplierEmailPatternError: true
    })
  }
  else{
    this.setState({
      rfpEmailOfSupplierEmailPatternError: false
    }) 
  }
}

handleRfpEmailOfSupplierChangeEmailDuplicateNestedFunc = (emailDuplicate:any) => {
  if(emailDuplicate){
    this.setState({
      rfpEmailOfSupplierEmailDuplicateError: true
    })
  }
  else{
    this.setState({
      rfpEmailOfSupplierEmailDuplicateError: false
    })
  }
}

handleRfpEmailOfSupplierKeydown = (evt:any) => {
  if (["Enter", "Tab", ","].includes(evt.key)) {
    evt.preventDefault();

    let test = this.state.rfpEmailOfSupplier.trim(); 
    if(this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.state.rfpEmailOfSuppliers.push(test)
      this.setState({rfpEmailOfSupplier: ''})
    }
  }
};

handleEditedRfpEmailOfSupplierKeydown = (evt:React.KeyboardEvent<HTMLInputElement>) => {
  if (["Enter", "Tab", ","].includes(evt?.key)) {
    evt.preventDefault();

    let supplier = this.state.editedRfpEmailOfSupplier.trim(); 
    if(this.state.editedRfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.state.editedRfpEmailOfSuppliers.push(supplier)
      this.setState({editedRfpEmailOfSupplier: ''})
    }
  }
};

handleEditedRfpDeleteEmailOfSuppliers = (item:any) => {
  const result = this.state.editedRfpEmailOfSuppliers.filter((val: any) => val !== item)
  this.setState({editedRfpEmailOfSuppliers: result})
};

handleDeleteEmailOfSuppliers = (item:any) => {
  const result = this.state.rfpEmailOfSuppliers.filter((val: any) => val !== item)
  this.setState({rfpEmailOfSuppliers: result})
};

handleEmailItemEdit = (item:any) =>{
  const result = this.state.rfpEmailOfSuppliers.filter(i => i !== item)
  this.setState({rfpEmailOfSuppliers: result})
  this.setState({rfpEmailOfSupplier: item})
  
};

handleRfpEmailOfSupplierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpEmailOfSupplier: event.target.value
  })
  this.handleRfpEmailOfSupplierChangeEmailMatchNestedFunc(event)
  const emailDuplicate = this.state.rfpEmailOfSuppliers.find((email: string) => email === event.target.value)
  this.handleRfpEmailOfSupplierChangeEmailDuplicateNestedFunc(emailDuplicate)
}

handleEditedRfpEmailOfSupplierChangeEmailMatchNestedFunc = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(!event.target.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
    this.setState({
      editedRfpEmailOfSupplierEmailPatternError: true
    })
  }
  else{
    this.setState({
      editedRfpEmailOfSupplierEmailPatternError: false
    }) 
  }
}

handleEditedRfpEmailOfSupplierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpEmailOfSupplier: event.target.value
  })
  this.handleEditedRfpEmailOfSupplierChangeEmailMatchNestedFunc(event)
}

handleRfpEmailOfSupplierKeyDownEventNestedFunc = (event: any) => {
  if(event.target.value===""){
    this.setState({
      rfpEmailOfSupplierEmailPatternError: false,
    })
  }
}
handleRfpEmailOfSupplierKeyDownMatchNestedFunc = (emailDuplicate:any) => {
  if(this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) && !emailDuplicate){
    this.setState({
      rfpEmailOfSuppliers: [...this.state.rfpEmailOfSuppliers, this.state.rfpEmailOfSupplier],
      rfpEmailOfSupplier: '',
      rfpEmailOfSupplierEmailPatternError: false,
      rfpEmailOfSupplierEmailDuplicateError: false,
    })
  }
}

handleRfpEmailOfSupplierKeyDown = (event: any) => {
  this.handleRfpEmailOfSupplierKeyDownEventNestedFunc(event)

  const emailDuplicate = this.state.rfpEmailOfSuppliers.find((email: string) => email==this.state.rfpEmailOfSupplier)
  if (["Enter", "Tab", ","].includes(event.key)) {
    event.preventDefault();
    this.handleRfpEmailOfSupplierKeyDownMatchNestedFunc(emailDuplicate)
  }
}

handleRfpAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpAttachment: event.target.value,
  })
} 

handleRfpAttachmentDropdownDisplay = () => {
  this.setState({
    rfpAttachmentDropdownDisplay: true,
  })
}

handleReceiptFileTypeAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptAttachment: event.target.value,
  })
} 

handleReceiptAttachmentDropdownDisplay = () => {
  this.setState({
    receiptAttachmentDropdownDisplay: true,
  })
}

handleEditedReceiptFileTypeAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptAttachment: event.target.value,
  })
} 

handleEditedReceiptAttachmentDropdownDisplay = () => {
  this.setState({
    editedReceiptAttachmentDropdownDisplay: true,
  })
}

handleRfpExistingSuppliersChange = (event: React.ChangeEvent<HTMLInputElement>) => {  
  this.setState({
    rfpSelectExistingSupplier: [...event.target.value]
  })
}


handleAddItemExistingSuppliersChange = (event: any) => {
  this.setState({
    addItemSupplier: event.target.value
  })
}


handleAddItemMultipleSuppliersChange = (event: {supplierId : string, supplierLable :string}) => {
  const { addItemSupplierMultiple, addItemSupplierMultipleLables } = this.state;
  const supplierIdCatch = event.supplierId;

  if (addItemSupplierMultiple?.includes(supplierIdCatch)) {
    this.setState(prevState => ({
      addItemSupplierMultiple: prevState.addItemSupplierMultiple.filter(id => id !== supplierIdCatch),
      addItemSupplierMultipleLables: prevState.addItemSupplierMultipleLables.filter(label => label !== event.supplierLable)
    }));
  } else {
    this.setState(prevState => ({
      addItemSupplierMultiple: [...prevState.addItemSupplierMultiple, supplierIdCatch],
      addItemSupplierMultipleLables: [...prevState.addItemSupplierMultipleLables, event.supplierLable]
    }));
  }
}

handleRfpMultipleSuppliersChange = (supplierId : string, supplierLable :string) => {
  const { rfpSelectExistingSupplier } = this.state;

  if (rfpSelectExistingSupplier?.includes(supplierId)) {
    this.setState(prevState => ({
      rfpSelectExistingSupplier: prevState.rfpSelectExistingSupplier.filter(id => id !== supplierId),
      rfpSelectExistingSupplierLabel: prevState.rfpSelectExistingSupplierLabel.filter(label => label !== supplierLable)
    }));
  } else {
    this.setState(prevState => ({
      rfpSelectExistingSupplier: [...prevState.rfpSelectExistingSupplier, supplierId],
      rfpSelectExistingSupplierLabel: [...prevState.rfpSelectExistingSupplierLabel, supplierLable]
    }));
  }
}

handleEditedRfpMultipleSuppliersChange = (supplierId : string, supplierLable :string) => {
  const { editedRfpSelectExistingSupplier } = this.state;

  if (editedRfpSelectExistingSupplier?.includes(supplierId)) {
    this.setState(prevState => ({
      editedRfpSelectExistingSupplier: prevState.editedRfpSelectExistingSupplier.filter(id => id !== supplierId),
      editedRfpSelectExistingSupplierLabels: prevState.editedRfpSelectExistingSupplierLabels.filter(label => label !== supplierLable)
    }));
  } else {
    this.setState(prevState => ({
      editedRfpSelectExistingSupplier: [...prevState.editedRfpSelectExistingSupplier, supplierId],
      editedRfpSelectExistingSupplierLabels: [...prevState.editedRfpSelectExistingSupplierLabels, supplierLable]
    }));
  }
}

handleAddSuppliersToggle = () => {
  this.setState({
    addItemSupplierMultipleOpenOrClosed : !this.state.addItemSupplierMultipleOpenOrClosed
  })
}

handleAddSupplierPaymentTermCloseDropDown = () => {
  this.setState({
    addItemSupplierMultipleOpenOrClosed : false
  })
}


handleAddSupplierRfpCloseDropDown = () => {
  this.setState({
    addItemSupplierMultipleOpenOrClosed : false
  })
}

handleEditedItemMultipleSuppliersChange  = (event: {editedSupplierId : string, editedSupplierLable :string}) => {
  const { editedItemSupplierMultiple, updatedItemSupplierMultipleLables } = this.state;
  const updatedSupplierIdCatch = event.editedSupplierId;

  if (editedItemSupplierMultiple?.includes(updatedSupplierIdCatch)) {
    this.setState(prevState => ({
      editedItemSupplierMultiple: prevState.editedItemSupplierMultiple.filter(id => id !== updatedSupplierIdCatch),
      updatedItemSupplierMultipleLables: prevState.updatedItemSupplierMultipleLables.filter(label => label !== event.editedSupplierLable)
    }));
  } else {
    this.setState(prevState => ({
      editedItemSupplierMultiple: [...prevState.editedItemSupplierMultiple, updatedSupplierIdCatch],
      updatedItemSupplierMultipleLables: [...prevState.updatedItemSupplierMultipleLables, event.editedSupplierLable]
    }));
  }
}


handleUpdatedSuppliersToggle = () => {
  this.setState({
    isUpdatedItemSupplierMultipleOpenOrClosed : !this.state.isUpdatedItemSupplierMultipleOpenOrClosed
  })
}

handleUpdatedSuppliersCloseDropDown = () => {
  this.setState({
    isUpdatedItemSupplierMultipleOpenOrClosed : false
  })
}


handleRfpFileAttachmentChange = (event: any) => {
  this.setState({
    rfpFileAttachment: event.target.files[0]
  })
}

handleReceiptAttachmentChange = (event: any) => {
  if(this.state.receiptAttachment === ''){
    this.setState({
      receiptSelectFileTypeMessage: 'Please Select Attachment Type **'
    })
  }else{
    this.setState({
      receiptAttachmentFile: event.target.files[0],
    })
  }
}

handleSelectedRow = (id: string) => {
  this.setState({
    selectedRow: id
  })
}


handleRfpSelectedRow = (id: number) => {
  this.setState({
    rfpSelectedRow: id
  })
}

handleRfpIdForPdfCreation = (id: number) => {
  this.setState({
    catchRfpIdForPdfCreate: id
  })
}

handlePurchaseOrderSelectedRow = (id: any) => {
  this.setState({
    purchaseOrderSelectedRow: id
  })
}

handlePoScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    poCreateScreenActiveTabs: value,
  });
}
handlePoViewScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    poViewScreenActiveTabs: value,
  });
}
handlePrPoScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    prPoCreateScreenActiveTabs: value,
    openNextCustomFromRequisitionModal : false,
  });
}

handlePoEditScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    poEditScreenActiveTabs: value,
  });
}

handlePoIdForPdfCreation = (id: number) => {
  this.setState({
    catchPoIdForPdfCreate: id
  })
}

handlePrPoIdForPdfCreation = (id: number) => {
  this.setState({
    catchPrPoIdForPdfCreate: id
  })
}

handlePoCatchMailForSuppliers = (email: any) => {
  this.setState({catchPoMailOfSupplier: email})
}

handlePrPoCatchMailForSuppliers = (email: any) => {
  this.setState({catchPrPoMailOfSupplier: email})
}

handlePoMailSendConfirmation = () => {
  this.setState({
    isPoMailSendConfirmed:true
  })
}

handlePrPoMailSendConfirmation = () => {
  this.setState({
    isPrPoMailSendConfirmed:true
  })
}


handleReceiptSelectedRow = (id: number) => {
  this.setState({
    receiptSelectedRow: id
  })
}

handleSupplierSelectedRow = (id: string) => {
  this.setState({
    supplierSelectedRow: id
  })
}

handleProductSelectedRow = (id: number) => {
  this.setState({
    productSelectedRow: id
  })
}

handleBudgetSelectedRow = (id: number) => {
  this.setState({
    budgetSelectedRow: id
  })
}

handleShowRFPOpenModal = (rfpId: number) => {
  this.setState({
    openShowRFPModal: true,
    catchRfpEmailSendToSupplier : rfpId,
  })
  this.handleRfpSelectedRow(rfpId)
  this.handleShowRFP(rfpId)

}

handleShowPurchaseOrderOpenModal = (purchaseOrderId: number) => {
  this.setState({
    openShowPurchaseOrderModal: true,
    poViewScreenActiveTabs: 0,
    catchPoIdForPdfCreate:purchaseOrderId,
    catchPoMailOfSupplier : purchaseOrderId,
    catchEditedPoMailOfSupplier: purchaseOrderId.toString(),
  })
  this.handlePurchaseOrderSelectedRow(purchaseOrderId)
  this.handleShowPurchaseOrder(purchaseOrderId)
}

handleShowRFPCloseModal = () => {
  this.setState({
    openShowRFPModal: false
  })
}



handleShowReceiptCloseModal = () => {
  this.setState({
    openShowReceiptModal: false
  })
}

handleShowSupplierCloseModal = () => {
  this.setState({
    openShowSupplierModal: false
  })
}

handleShowProductCloseModal = () => {
  this.setState({
    openShowProductModal: false
  })
}

handleShowBudgetCloseModal = () => {
  this.setState({
    openShowBudgetModal: false
  })
}

handleEditedRfpStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpStatus: event.target.value
  })
}

handleEditedRfpCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpCurrency: event.target.value
  })
}

handleEditedRfpCreationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newCreationDate = event.target.value;
  const newDueDate = newCreationDate > this.state.editedRfpDueDate ? '' : this.state.editedRfpDueDate;
  const newDeliveryDate = newCreationDate > this.state.editedRfpDesiredDeliveryDate ? '' : this.state.editedRfpDesiredDeliveryDate;
  
  this.setState({
    editedRfpCreationDate: newCreationDate,
    editedRfpDueDate: newDueDate,
    editedRfpDesiredDeliveryDate : newDeliveryDate
  });

 
}

handleEditedRfpDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpDueDate: event.target.value
  })
}

handleEditedRfpAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpAddress: event.target.value
  })
}

handleEditedRfpDesiredDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpDesiredDeliveryDate: event.target.value
  })
}

handleEditedRfpExistingProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpExistingProduct: event.target.value
  })
}

handleEditedRfpAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpAttachment: event.target.value
  })
}

handleEditedPoMailSendConfirmationCloseBar = () => {
  this.setState({
    isEditedPoMailSendConfirmed:false
  })
}

handleEditedRfpExistingSuppliersChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  this.setState({
    editedRfpSelectExistingSupplier: [...event.target.value]
  })
}

handleEditedRfpFileAttachmentChange = (event: any) => {
  this.setState({
    editedRfpFileAttachment: event.target.files[0],
    isFileEdited: true,
  })
}

handleCloseEditRfpErrorModal = () => {
  this.setState({
    openEditRfpShowErrorModal: false,
  })
}

handleCloseCreateRfpErrorModal = () => {
  this.setState({
    openCreateRfpShowErrorModal: false,
  })
}

handleCloseCreatePoErrorModal = () => {
  this.setState({
    openCreatePoShowErrorModal: false,
  })
}

handlePoMailSendConfirmationCloseBar = () => {
  this.setState({
    isPoMailSendConfirmed:false
  })
}

handlePrPoMailSendConfirmationCloseBar = () => {
  this.setState({
    isPrPoMailSendConfirmed: false,
  })
}

handleCloseCreatePrPoErrorModal = () => {
  this.setState({
    openCreatePrPoShowErrorModal: false,
  })
}

handleCloseEditPoErrorModal = () => {
  this.setState({
    openEditPoShowErrorModal: false,
  })
}

handlePurchaseRequisitionPoPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePurchaseRequisitionPO: value
  })
}

handlePurchaseRequisitionPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePurchaseRequisition: value
  })
}

handleRfpPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageRfp: value
  })
}

handlePurchaseOrderPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePurchaseOrder: value
  })
}

handleSupplierPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageSupplier: value
  })
}

handleProductPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageProduct: value
  })
} 

handleCustomFromRequisitionSelectedRow = (data: any) => {
  this.setState({
    customFromRequisitionSelectedRow: data?.id,
    customFromRequisitionSelectedId: data?.id,
    customFromRequisitionCataItemData: data?.attributes?.requested_items_purchase_requisition,
    customFromRequisitionNonCataItemData: data?.attributes?.non_catalogue_items,
    prPoSelectedRequisitionNumber:data?.attributes?.purchase_requisition_number,
    prPoSelectedRequisitionCreationDate:data?.attributes?.pr_creation_date,
    prPoSelectedRequisitionDeliveryDate:data?.attributes?.delivery_date,
    prPoSelectedRequisitionDepartment:data?.attributes?.department_id,
  })
} 

handleReceiptPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageReceipt: value
  })
}

handleBudgetPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageBudget: value
  })
}

handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPage: value
  })
}

handlePaymentTermsPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePaymentTerms: value,
    payTermsSerialNumber: (value - 1) * this.state.itemsPerPage,
  })
}

handleTaxationsPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageTaxations: value,
    taxSerialNumber: (value - 1) * this.state.itemsPerPage,
  })
}

handleUnitMeasurePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageUnitMeasure: value,
    unitMeasureSerialNumber: (value - 1) * this.state.itemsPerPage,
  })
}

handlePageChangeCategory = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageCategory: value,
    categorySerialNumber: (value - 1) * this.state.itemsPerPage,
  })
}

handlePageChangeSettingsLocation = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageSettingsLocation: value,
    locationSerialNumber: (value - 1) * this.state.itemsPerPage,
  })
}

handleCustomFromRequisitionPOCloseModal = () => {
  this.setState({
    openPurchaseRequisitionListModal: false,
    currentPagePurchaseRequisitionPO: 1,
    selectPoOptions: '',
    customFromRequisitionSelectMessage: '',
  })
  this.handleCustomFromRequisitionSelectedRow(false)
}

handleCustomFromRequisitionPoSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoSupplier: event.target.value
  })
  this.handleShowSupplier(event.target.value)
}

handleCustomFromRequisitionPoOrderDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoOrderDate: event.target.value
  })
}

handleCustomFromRequisitionPoFobPoint = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoFobPoint: event.target.value
  })
}

handleCustomFromRequisitionPoShippingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoShippingAddress: event.target.value
  })
}

handleCustomFromRequisitionPoBillingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoBillingAddress: event.target.value
  })
}

handleCustomFromRequisitionPoTaxId = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoTaxId: event.target.value
  })
}

handleCustomFromRequisitionPoModeOfTransit = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoModeOfTransit: event.target.value
  })
}

handleCustomFromRequisitionPoCommentsForPO = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoCommentForPO: event.target.value
  })
}

handleCustomFromRequisitionPoAmountCalculation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoAmountCalculation: event.target.value
  })
}

handleCustomFromRequisitionPoBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoBudget: event.target.value
  })
}

handleCustomFromRequisitionPoComment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoComment: event.target.value
  })
}

handleReceiptCatelogueItemsStore = (selectedData:any) => {
  if(selectedData?.attributes?.product_purchase_order){
    this.setState({
      receiptCatelogueItemsStore: selectedData?.attributes?.product_purchase_order?.map((items : any) => ({ ...items, quantity_received : '1', comment_received: '' }) ),
    })
  }
}


handleReceiptNonCatelogueItemsStore = (selectedData:any) => {
  if(selectedData?.attributes?.non_catalogue_items){
    this.setState({
      receiptNonCatelogueItemsStore: selectedData?.attributes?.non_catalogue_items?.map((items : any) => ({ ...items, quantity_received : '1', comment_received : ''}) ),
    })
  }
}


handleReceiptSelectedPo = (event: React.ChangeEvent<{}>, value: any) => {
  this.setState({receiptSelectedPOPopulatedData: {},
    receiptCatelogueItemsStore: [],
    receiptNonCatelogueItemsStore: [],
  })

  if(value){
    this.setState({
      receiptSelectedPo: value?.id,
      receiptSelectedPOPopulatedData: value,
      receiptBasedOnStatus: value?.attributes?.status
    })

    this.handleReceiptCatelogueItemsStore(value)
    this.handleReceiptNonCatelogueItemsStore(value)
  }

}

handleReceiptQuantityRecievedChange = (event: React.ChangeEvent<HTMLInputElement>, items:any,  index: number) => {
  let qtyValue : any = event.target.value;
  if (qtyValue === '0') {
    event.preventDefault(); 
    qtyValue = '1';
    event.target.value = qtyValue
  }
  const receiptCatQtyStore = [...this.state.receiptCatelogueItemsStore]
  receiptCatQtyStore[index] = { ...this.state.receiptCatelogueItemsStore[index], ...items, quantity_received: qtyValue}
  this.setState({ receiptCatelogueItemsStore: receiptCatQtyStore })
}

handleReceiptNonCatelogQuantityRecievedChange = (event: React.ChangeEvent<HTMLInputElement>, items:any, index: number) => {
  let nonCatQtyValue : any = event.target.value;
  if (nonCatQtyValue === '0') {
    event.preventDefault(); 
    nonCatQtyValue = '1';
    event.target.value = nonCatQtyValue
  }
  const receiptNonCatQtyStore = [...this.state.receiptNonCatelogueItemsStore]
  receiptNonCatQtyStore[index] = { ...this.state.receiptNonCatelogueItemsStore[index], ...items, quantity_received : nonCatQtyValue}
  this.setState({ receiptNonCatelogueItemsStore: receiptNonCatQtyStore })
}

handleReceiptSelectedPOtSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptQuantityReceived: event.target.value
  })
}

handleReceiptQuantityRequested = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptQuantityRequested: event.target.value
  })
}

handleReceiptReceiptDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  const enteredDate = event.target.value;
  const enteredDateObj = new Date(enteredDate); 
  const currentDate = new Date(); 
  const currentYear = currentDate.getFullYear(); 
  const currentMonth = currentDate.getMonth(); 
  const currentDay = currentDate.getDate(); 
  const curretDateWithOutTime = new Date(currentYear, currentMonth, currentDay);

  if (enteredDateObj >= curretDateWithOutTime) {
  this.setState({
    receiptReceiptDate: enteredDate
  })}
}

handleReceiptInvoiceNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptInvoiceNumber: event.target.value
  })
}

handleReceiptCommentsChange = (event: React.ChangeEvent<HTMLInputElement>, items : any, index: number) => {
  const receiptCatCommentStore = [...this.state.receiptCatelogueItemsStore]
  receiptCatCommentStore[index] = { ...this.state.receiptCatelogueItemsStore[index], ...items, comment_received : event.target.value}
  this.setState({ receiptCatelogueItemsStore: receiptCatCommentStore })
}

handleReceiptNonCatelogCommentsChange = (event: React.ChangeEvent<HTMLInputElement>, items :any, index: number) => {
  const receiptNonCatCommentStore = [...this.state.receiptNonCatelogueItemsStore]
  receiptNonCatCommentStore[index] = { ...this.state.receiptNonCatelogueItemsStore[index], ...items, comment_received : event.target.value}
  this.setState({ receiptNonCatelogueItemsStore: receiptNonCatCommentStore })
}




handleReceiptFileAttachment = (event: any) => {
  this.setState({
    receiptAttachmentFile: event.target.files[0]
  })
}

handleEditedReceiptQuantityRecieved = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptQuantityReceived: event.target.value
  })
}

handleEditedReceiptQuantityRequested = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptQuantityRequested: event.target.value
  })
}

handleEditedReceiptReceiptDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptReceiptDate: event.target.value
  })
}

handleEditedReceiptComments = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptComments: event.target.value
  })
}

handleEditedReceiptDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptReceiptDate: event.target.value
  })
}

handleEditedReceiptAttachmentFile = (event: any) => {
  this.setState({
    editedReceiptAttachmentFile: event.target.files[0],
    isReceiptFileEdited: true,
  })
}

handleCreateReceiptCloseModal = () => {
  this.setState({
    openAddReceiptModal: false,
  })
}

handleSupplierSupplierName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierSupplierName: event.target.value
  })
}

handleSupplierUniqueCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierUniqueCode: event.target.value
  })
}

handleSupplierTermsOfPayment = (event: {paymentTermId : string, paymentTermLable :string}) => {
  const { paymentTermsNameSuppliers, suppliersPaymentTermCatchedLables } = this.state;
  const paymentTermId = event.paymentTermId;

  if (paymentTermsNameSuppliers?.includes(paymentTermId)) {
    this.setState(prevState => ({
      paymentTermsNameSuppliers: prevState.paymentTermsNameSuppliers.filter(id => id !== paymentTermId),
      suppliersPaymentTermCatchedLables: prevState.suppliersPaymentTermCatchedLables.filter(label => label !== event.paymentTermLable)
    }));
  } else {
    this.setState(prevState => ({
      paymentTermsNameSuppliers: [...prevState.paymentTermsNameSuppliers, paymentTermId],
      suppliersPaymentTermCatchedLables: [...prevState.suppliersPaymentTermCatchedLables, event.paymentTermLable]
    }));
  }
}

handleEditedSupplierTermsOfPayment = (event: {editedPaymentTermId : string, editedPaymentTermLable :string}) => {
  const { editedPaymentTermsNameSuppliers, editedSuppliersPaymentTermCatchedLables } = this.state;
  const paymentTermId = event.editedPaymentTermId;

  if (editedPaymentTermsNameSuppliers?.includes(paymentTermId)) {
    this.setState(prevState => ({
      editedPaymentTermsNameSuppliers: prevState.editedPaymentTermsNameSuppliers.filter(id => id !== paymentTermId),
      editedSuppliersPaymentTermCatchedLables: prevState.editedSuppliersPaymentTermCatchedLables.filter(label => label !== event.editedPaymentTermLable)
    }));
  } else {
    this.setState(prevState => ({
      editedPaymentTermsNameSuppliers: [...prevState.editedPaymentTermsNameSuppliers, paymentTermId],
      editedSuppliersPaymentTermCatchedLables: [...prevState.editedSuppliersPaymentTermCatchedLables, event.editedPaymentTermLable]
    }));
  }
}



handleEditedSupplierPaymentTermToggle = () => {
  this.setState({
    isEditedSupplierPaymentListOpenOrClosed : !this.state.isEditedSupplierPaymentListOpenOrClosed
  })
}

handleEditedSupplierPaymentTermCloseDropDown = () => {
  this.setState({
    isEditedSupplierPaymentListOpenOrClosed : false
  })
}

handleSupplierPaymentTermToggleForUpdate = () => {
  this.setState({
    isSupplierPaymentListOpenOrClosedForUpdate : !this.state.isSupplierPaymentListOpenOrClosedForUpdate
  })
}

handleSupplierPaymentTermCloseDropDownForUpdate = () => {
  this.setState({
    isSupplierPaymentListOpenOrClosedForUpdate : false
  })
}

handleSupplierPaymentTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    paymentTermsName: event.target.value
  })
}



handleEditedSupplierPaymentTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoPaymentTermList: event.target.value
  })
}



handleSupplierEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierEmailAddress: event.target.value
  })

}


handleSupplierContactNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  const contactNumber = event.target.value;
  const formattedContactNumber = contactNumber.replace(/\D/g, '');
  this.setState({ supplierContactNumber: formattedContactNumber })
 
};


handleSupplierPointOfContact = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierPointOfContact: event.target.value
  })
}

handleSupplierAddressOfSupplierCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierAddressOfSupplierCompany: event.target.value
  })
}

handleSupplierGstNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierGSTNumber: event.target.value
  })
}

handleSupplierStateProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierStateProvince: event.target.value
  })
}

handleSupplierZipCodePinCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierZipCodePinCode: event.target.value
  })
}

handleSupplierSupplierLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierSupplierLocation: event.target.value
  })
}

handleEditedSupplierSupplierName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierSupplierName: event.target.value
  })
}

handleEditedSupplierUniqueCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierUniqueCode: event.target.value
  })
}


handleEditedSupplierPaymentTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierPaymentList: [...event.target.value]
  })
}

handleEditedSupplierEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierEmailAddress: event.target.value
  })
}

handleEditedSupplierContactNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  const editedContactNumber = event.target.value;
  const editFormattedContactNumber = editedContactNumber.replace(/\D/g, '');
  this.setState({
    editedSupplierContactNumber: editFormattedContactNumber
  })
}

handleEditedSupplierPointOfContact = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierPointOfContact: event.target.value
  })
}

handleEditedSupplierAddressOfSupplierCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierAddressOfSupplierCompany: event.target.value
  })
}

handleEditedSupplierGstNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierGSTNumber: event.target.value
  })
}

handleEditedSupplierSupplierLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierSupplierLocation: event.target.value
  })
}

handleEditedSupplierStateProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierStateProvince: event.target.value
  })
}

handleEditedSupplierZipCodePinCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierZipCodePinCode: event.target.value
  })
}

handleProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productName: event.target.value
  })
}

handleProductDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productDescription: event.target.value
  })
}

handleProductPartId = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productPartId: event.target.value
  })
}

handleProductUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productUnitMeasurement: event.target.value
  })
}

handleProductUnitPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");

  this.setState({
    productUnitPrice: numericValue,
  })
}

handleProductCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
  
  this.setState({
    productCategory: event.target.value
  })
}

handleProductType = (productName: string) => {
  this.setState({
    productTypeSelectedId: productName,
  })
}

handleEditedProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductName: event.target.value
  })
}

handleEditedProductUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductUnitMeasurement: event.target.value
  })
}

handleEditedProductUnitPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    editedProductUnitPrice: numericValue
  })
}

handleEditedProductCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductCategory: event.target.value
  })
}


handleEditedProductType = (event: React.ChangeEvent<HTMLInputElement>, productName: string) => {
  this.setState({
    editedProductTypeSelectedId: productName,
  })
}


handleBudgetName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetName: event.target.value
  })
}


handleBudgetAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(Number(event.target.value) > 0){
    this.setState({
      budgetAmount: Number(event.target.value)
    })
  }else{
    this.setState({
      budgetAmount: ''
    })
  }
}

handleBudgetComment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetComment: event.target.value
  })
}

handleBudgetPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetPeriod: event.target.value
  })
}

handleBudgetProductCategories = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetProductCategories: event.target.value
  })
}

handleEditedBudgetName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetName: event.target.value
  })
}

handleEditedBudgetFor = (budgetId: string) => {
  this.setState({
    editedBudgetForSelectedId: budgetId
  })
}

handleEditedBudgetAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetAmount: event.target.value
  })
}

handleEditedBudgetComment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetComment: event.target.value
  })
}

handleEditedBudgetPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetPeriod: event.target.value
  })
}

handleEditedBudgetProductCategories = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetProductCategories: event.target.value
  })
}

handleInformationSettingsCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsCompanyName: event.target.value
  })
}

handleSettingsLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsLocation: event.target.value
  })
}

handleSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsAddress: event.target.value
  })
}

handleSettingsCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
  const restrictedTextValue = event.target.value.replace(/[^a-z\s]/gi, '');

  if(restrictedTextValue){
    this.setState({
      settingsCategoryFeildError:''
    })
  }
  this.setState({
    settingsCategory: restrictedTextValue
  })
}

handleSettingsUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  const restrictedTextValue = event.target.value.replace(/[^a-z\s]/gi, '');

  this.setState({
    settingsUnitMeasurement: restrictedTextValue
  })
}

handleSettingsIdentificationCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsIdentificationCode: event.target.value
  })
}

handleSettingsGst = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsGst: event.target.value
  })
}

handleEditSettingsGst = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsGst: event.target.value
  })
}

handleEditSettingsCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
  const restrictedTextValue = event.target.value.replace(/[^a-z\s]/gi, '');

  if(restrictedTextValue){
    this.setState({
      settingsCategoryFeildError:''
    })
  }
  this.setState({
    editSettingsCategoryName: restrictedTextValue
  })
}

handleEditSettingsUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsUnitMeasurement: event.target.value
  })
}

handleEditSettingsLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsLocation: event.target.value
  })
}

handleEditSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsAddress: event.target.value
  })
}

handleEditSettingsIdentificationCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsIdentificationCode: event.target.value
  })
}

handleInformationSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsAddress: event.target.value
  })
}

handleInformationSettingValidEmailCheckFunc = (event: React.ChangeEvent<HTMLInputElement>) => {

  if(!RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(event.target.value)){
    this.setState({
      informationSettingEmailInvalidPatternError: true
    });
  } else {
    this.setState({
      informationSettingEmailInvalidPatternError: false
    });
  }
}

handleInformationSettingsEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsEmail: event.target.value
  })

  this.handleInformationSettingValidEmailCheckFunc(event)
}

handleInformationSettingsWebsiteInpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsWebsite: event.target.value
  })
}


handleInformationSettingsPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    informationSettingsPhoneNumber: numericValue
  })
}

handleInformationSettingsOrganisationSize = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValueSelectInfo = event.target.value;
  const numericValueSelectInfo = inputValueSelectInfo.replace(/\D/g, "");
  this.setState({
    informationSettingsSelectInfoSize: numericValueSelectInfo
  })
}

handleInformationSettingNoOfPeopleWhoWillAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValueNoPeople = event.target.value;
  const numericValueNoPeople = inputValueNoPeople.replace(/\D/g, "");
  const { informationSettingsSelectInfoSize } = this.state;
  const numberOfPeople = parseInt(numericValueNoPeople, 10);
  const organizationSize = parseInt(informationSettingsSelectInfoSize, 10);
  if (numberOfPeople > organizationSize) {
  this.setState({
    informationSettingNoOfPeopleWhoWillAccess : numericValueNoPeople
  });
}  else{
    this.setState({
      informationSettingNoOfPeopleWhoWillAccess : numericValueNoPeople
    })
  }
}

handleInformationSettingsPinCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    informationSettingsPinCode: numericValue
  })
}

handleInformationSettingsGstNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsGstNumber: event.target.value
  })
}

handleCurrencySettingsCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    currencySettingsCurrency: event.target.value
  })
}

handleLocationSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>, index:number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.adderss = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleLocationSettingsLocationIdentificationCode = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.location_identification_code = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleLocationGSTNumber = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.location_gst_number = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleLocationSettingsNameOfLocation = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.name_of_location = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleUserSettingsNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const restrictedTextValue = event.target.value.replace(/[^a-z\s]/gi, '');

  this.setState({
    userSettingAddUserName: restrictedTextValue
  })
}

handleUserSettingsEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    userSettingAddUserEmailId : event.target.value
  })
}



handleUserSettingsDepartment = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.users];
  let item = {...items[index]}
  item.department = event.target.value
  items[index] = item
  this.setState({
    users: items
  })
}

handleUserSettingsDesignation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    userSettingAddUserDesignation: event.target.value
  })
}

handleShowInformationSettings = () => {
  this.setState({
    showIsCreateInformationValidation : false,
    showSettings: false,
    showInformation: true,
  })
  this.handleGetInformationSettings()
}

handleGoBackFromInformationSettings = () => {
  this.setState({
    showIsCreateInformationValidation : false,
    informationSettingEmailInvalidPatternError : false,
    infoSettingContactNumberErrorMessage : false,
    showSettings: true,
    showInformation: false,
  })
}

handleShowCurrencySettings = () => {
  this.setState({
    showSettings: false,
    showCurrencySettings: true,
  })
  this.handleGetCurrencySettings()
}

handleGoBackFromCurrencySettings = () => {
  this.setState({
    showIsCreateCurrencyValidation:false,
    showSettings: true,
    showCurrencySettings: false,
    currencySettingsCurrency: '',
  })
}

handleShowLocationSettings = () => {
  const locationStateData = [
    {
      lable: "Location Name",
      key: "name_of_location",
      selected : false,
    },
    {
      lable: "Address",
      key: "adderss",
      selected : false,
    },
    {
      lable: "Identification Code",
      key: "location_identification_code",
      selected : false,
    },
    {
      lable: "GST Number",
      key: "gst_number",
      selected : false,
    },
  ]
  this.handleGetLocations()
  this.setState({
    showSettings: false,
    showLocationSettings: true,
    mainSearchSettingItemsKeysData : locationStateData,
    settingItemsFilterMultiState: "",
    itemsSettingSearchBySpecificValue : "",
    anchorElForSettingsScreens: null,
    isSettingsScreensMenuForSearchOpen : false,
  })
}

handleGoBackFromLocationSettings = () => {
  this.setState({
    showSettings: true,
    showLocationSettings: false,
    location: '',
    settingsAddress: '',
    settingsIdentificationCode: '',
    settingsGst: '',
    locationRequiredError: '',
    locationAddressRequiredError: '',
    locationIdentityCodeRequiredError: '',
    locationGstRequiredError: '',
    mainSearchSettingItemsKeysData : [],
    settingItemsFilterMultiState : "",
    itemsSettingSearchBySpecificValue : "",
    isSettingsScreensMenuForSearchOpen : false,
    anchorElForSettingsScreens: null,
  })
}

handleShowUserSettings = () => {
  const userSettingStateData = [
    {
      lable: "User Name",
      key: "user_name",
      selected : false,
    },
    {
      lable: "Designation",
      key: "designation",
      selected : false,
    },
    {
      lable: "Email Id",
      key: "email",
      selected : false,
    },
    {
      lable: "Role",
      key: "role",
      selected : false,
    },
  ]
  this.handleGetUsers()
  this.setState({
    showSettings: false,
    showUserSettings: true,
    mainSearchSettingItemsKeysData : userSettingStateData,
    settingItemsFilterMultiState: "",
    itemsSettingSearchBySpecificValue : "",
    anchorElForSettingsScreens: null,
    isSettingsScreensMenuForSearchOpen : false,
  })
}

handleShowCategorySettings = () => {
  const categoryStateData = [
    {
      lable: "Serial Number",
      key: "serial_number",
      selected : false,
    },
    {
      lable: "Name of Category",
      key: "name",
      selected : false,
    }
  ]
  this.setState({
    showSettings: false,
    showCategorySettings: true,
    mainSearchSettingItemsKeysData : categoryStateData,
    settingItemsFilterMultiState: "",
    itemsSettingSearchBySpecificValue : "",
    anchorElForSettingsScreens: null,
    isSettingsScreensMenuForSearchOpen : false,
  })
}

handleShowUnitMeasurementSettings = () => {
  const unitMeasurementStateData = [
    {
      lable: "Serial Number",
      key: "sr_number",
      selected : false,
    },
    {
      lable: "Unit of Measurement",
      key: "unit_of_measurment",
      selected : false,
    }
  ]
  this.setState({
    showSettings: false,
    showUnitMeasureSettings: true,
    mainSearchSettingItemsKeysData : unitMeasurementStateData,
    settingItemsFilterMultiState: "",
    itemsSettingSearchBySpecificValue : "",
    anchorElForSettingsScreens: null,
    isSettingsScreensMenuForSearchOpen : false,
  })
}

handleGoBackFromCategorySettings = () => {
  this.setState({
    showSettings: true,
    showCategorySettings: false,
    categorySettingCreatePost: false,
    settingsCategoryRequiredField: '',
    mainSearchSettingItemsKeysData: [],
    settingItemsFilterMultiState: "",
    itemsSettingSearchBySpecificValue : "",
    settingsCategory : "",
    anchorElForSettingsScreens: null,
    isSettingsScreensMenuForSearchOpen : false,
    isCategorySettingGotResponseOrNot: false,
  })
}

handleGoBackFromUnitMeasurementSettings = () => {
  this.setState({
    showSettings: true,
    showUnitMeasureSettings: false,
    settingsUnitMeasurement: '',
    unitMeasurementSettingPost: false,
    settingsUnitMeasurementRequiredField: '',
    mainSearchSettingItemsKeysData : [],
    settingItemsFilterMultiState : "",
    itemsSettingSearchBySpecificValue : "",
    isSettingsScreensMenuForSearchOpen : false,
    isUnitMeasureSettingGotResponseOrNot : false,
    anchorElForSettingsScreens: null,
  })
}

handleGoBackFromUserSettings = () => {
  this.setState({
    showSettings: true,
    showUserSettings: false,
    isUsersSettingGotResponseOrNot : false,
    mainSearchSettingItemsKeysData: [],
    settingItemsFilterMultiState : "",
    anchorElForSettingsScreens : "",
    itemsSettingSearchBySpecificValue : "",
    isSettingsScreensMenuForSearchOpen : false
  })
}

handleShowPaymentTermsSettings = () => {
  const paymentSettingStateData = [
    {
      lable: "Serial Number",
      key: "serial_number",
      selected : false,
    },
    {
      lable: "Payment Terms Name",
      key: "payment_term_name",
      selected : false,
    },
    {
      lable: "Credit Days",
      key: "credit_days",
      selected : false,
    },
  ]
  this.setState({
    showSettings: false,
    showPaymentTermsSettings: true,
    mainSearchSettingItemsKeysData : paymentSettingStateData,
    settingItemsFilterMultiState: "",
    itemsSettingSearchBySpecificValue : "",
    anchorElForSettingsScreens: null,
    isSettingsScreensMenuForSearchOpen : false,
  })
}

handleGoBackFromPaymentTermsSettings = () => {
  this.setState({
    showSettings: true,
    showPaymentTermsSettings: false,
    editCreditDays:'',
    editPaymentTermsName:'',
    paymentTermsName:'',
    creditDays:'',
    currentPagePaymentTerms:1,
    paymentTermNameRequiredField: '',
    paymentTermCreditDaysRequiredField: '',
    paymentTermNameRequiredFieldEdit: '',
    paymentTermCreditDaysRequiredFieldEdit:'',
    mainSearchSettingItemsKeysData : [],
    settingItemsFilterMultiState : "",
    anchorElForSettingsScreens : null,
    itemsSettingSearchBySpecificValue : "",
    isSettingsScreensMenuForSearchOpen : false,
    showEditPaymentTermsSettingBtn: false,
    showEditPaymentTermsSettingField: false,
  })
}

handleCancelPaymentTermsSettings = () => {
  this.setState({ 
    showPaymentTermsSettings: false,
    showSettings: true,
    editCreditDays:'',
    editPaymentTermsName:'',
    paymentTermsName:'',
    creditDays:'',
    currentPagePaymentTerms:1,
    paymentTermNameRequiredField: '',
    paymentTermCreditDaysRequiredField: '',
    paymentTermNameRequiredFieldEdit: '',
    paymentTermCreditDaysRequiredFieldEdit:'',
    isPaymentTermSettingGotResponseOrNot : false,
    showEditPaymentTermsSettingBtn : false,
  })
}

handleShowTaxationSettings = () => {
  const taxationStateData = [
    {
      lable: "Serial Number",
      key: "serial_number",
      selected : false,
    },
    {
      lable: "Tax Name",
      key: "tax_name",
      selected : false,
    },
    {
      lable: "Tax Percentage",
      key: "tax_percentage",
      selected : false,
    },
  ]
  this.setState({
    showSettings: false,
    showTaxationSettings: true,
    mainSearchSettingItemsKeysData : taxationStateData,
    settingItemsFilterMultiState: "",
    itemsSettingSearchBySpecificValue : "",
    anchorElForSettingsScreens: null,
    isSettingsScreensMenuForSearchOpen : false,
  })
}

handleGoBackFromTaxationSettings = () => {
  this.setState({
    showEditTaxationSettingBtn: true,
    showEditTaxationEditField:false,
    showIsCreateTaxationValidation:false,
    showIsUpdateTaxationValidation:false,
    showSettings: true,
    showTaxationSettings: false,
    taxName:'',
    taxPercentage:'',
    editTaxName:'',
    editTaxPercentage:'',
    currentPageTaxations: 1,
    taxNameRequiredField:'',
    taxPercentageRequiredField: '',
    editTaxNameRequiredField:'',
    editTaxPercentageRequiredField:'',
    mainSearchSettingItemsKeysData : [],
    settingItemsFilterMultiState : "",
    anchorElForSettingsScreens : null,
    itemsSettingSearchBySpecificValue : "",
    isSettingsScreensMenuForSearchOpen : false,
  })
}

handleCancelTaxationsSettings = () => {
  this.setState({
    showEditTaxationSettingBtn: true,
    showEditTaxationEditField:false,
    showIsCreateTaxationValidation:false,
    showIsUpdateTaxationValidation:false,
    showTaxationSettings: false,
    showSettings: true,
    taxName:'',
    taxPercentage:'',
    currentPageTaxations: 1,
    editTaxName:'',
    editTaxPercentage:'',
    taxNameRequiredField:'',
    taxPercentageRequiredField: '',
    editTaxNameRequiredField:'',
    editTaxPercentageRequiredField:'',
  })
}

handlePaymentTermsChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({
    paymentTermsName: event.target.value
  })
  if(event.target.value === ''){
    this.setState({paymentTermFoundOrNotMessage: 'Please create Payment term'})
  }else{
    this.setState({paymentTermFoundOrNotMessage: ''})
  }
}

handleCreditDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    creditDays: numericValue
  })
}

handleEditPaymentTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editPaymentTermsName: event.target.value
  })
}

handleEditCreditDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    editCreditDays: numericValue
  })
}

handleTaxNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    taxName: event.target.value
  })
}
handleTaxPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/[^0-9.]/g, "");

  this.setState({
    taxPercentage: numericValue
  })
}

handleEditTaxNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editTaxName: event.target.value
  })
}

handleEditTaxPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/[^0-9.]/g, "");

  this.setState({
    editTaxPercentage: numericValue
  });
}

handleAddLocation = () => {
  this.setState({
    locations: [...this.state.locations, {
        name_of_location: '',
        adderss: '',
        location_identification_code: ''
    }]
  })
}

handleAddUser = () => {
  this.setState({
    users: [...this.state.users, {
      name: "",
      designation: "",
      role: "",
      email: "",
      department: "",
    }]
  })
}

handleOpenUserModal = () => {
  this.setState({
    openAddUserModal: true
  })
}

handleCloseLocationModal = () => {
  this.setState({
    openAddLocationsModal: false,
    locations: [{
      name_of_location: '',
      adderss: '',
      location_identification_code: ''
    }],
    saveLocationsValidation: false,
  })
}

handleCloseUserModal = () => {
  this.setState({
    userSettingAddUserName: '',
    userSettingAddUserDesignation: '',
    userSettingAddUserEmailId: '',
    userSettingAddUserRole: '',
    saveUsersValidation: false,
    userSettingNameRequiredError: '',
    userSettingDesignationRequiredError: '',
    userSettingEmailRequiredError: '',
    userSettingEmailInvalidError: '',
    userSettingRoleRequiredError: '',
    isUserCreating: false,
  })
}

handleCloseUserAccessPermissionsModal = () => {
  this.setState({
    openUserPermissionsModal: false,
    catchUserSettingsCreatedUserId: '',
    selectedUserPermissions: [],
    selectAllPermissions: false,
  })
}

handleEditedCloseUserAccessPermissionsModal = () => {
  this.setState({
    openEditedUserPermissionsModal: false,
    createUserPermissionsValidation : false,
    editedSelectedUserPermissions: [],
    editedSelectAllPermissions: false,
    editedCatchIdForUserPermissionsUpdation: '',
    isUserSettingPermissionsUpdating : false,
    editedCatchCoreUserCreatedAccountId : '',
    userPermissionMissingIdErrorMessage: '',
  })
}


handleUserSettingsSelectedRow = (id:string) => {
  this.setState({
    userSettingSelectedRow: id
  })
}


handleEditedUserAccessPermissions = (data:any) => {
  this.setState({
    createUserPermissionsValidation : false,
    userSettingSelectedRow:data,
  })

  if(data?.attributes?.permission_alloted[0]?.permission){
    const permissionsAlloted = JSON.parse(data?.attributes?.permission_alloted[0]?.permission);
      permissionsAlloted?.map((item: any) => {
        return item.toLowerCase()
    });
    this.setState({
      editedSelectedUserPermissions: permissionsAlloted,
      editedSelectAllPermissions : false,
      editedCatchIdForUserPermissionsUpdation : data?.attributes?.user_permission,
      editedCatchCoreUserCreatedAccountId : data?.attributes?.permission_alloted?.account_id
    })
  } else {
    this.setState({
      editedSelectedUserPermissions: [],
      editedSelectAllPermissions : false,
      editedCatchIdForUserPermissionsUpdation : '',
      editedCatchCoreUserCreatedAccountId : data?.attributes?.permission_alloted?.account_id
    })
  }

  this.handleUserSettingsSelectedRow(data?.id)

  this.setState({
    openEditedUserPermissionsModal: true,
  })

}

handleUsersSettingSelectAllCheckboxChange(event : any, checkBoxPermissionsData:any) {

  const { checked } = event?.target;
  const allPermissionNamesValues = checkBoxPermissionsData.map((permissions : any) => permissions.name);

  this.setState({
    selectedUserPermissions: checked? allPermissionNamesValues : [],
    selectAllPermissions: checked,
  });
}

handleUsersSettingCheckboxChange(permissionName: any, USER_PERMISSIONS_ARRAY:any) {
  
  this.setState((prevState) => {
    let updatedPermissionsValue = [...prevState.selectedUserPermissions];
    if (updatedPermissionsValue.includes(permissionName)) {
      updatedPermissionsValue = updatedPermissionsValue.filter((name) => name !== permissionName);
    } else {
      updatedPermissionsValue.push(permissionName);
    }

    const allPermissionsSelectedValue = updatedPermissionsValue.length === USER_PERMISSIONS_ARRAY.length;

    return { selectedUserPermissions: updatedPermissionsValue, selectAllPermissions : allPermissionsSelectedValue };
  });
}

handleEditedUsersSettingSelectAllCheckboxUpdationChange = (event: any, USER_PERMISSIONS_ARRAY:any) => {
  const { checked } = event.target;
  const allPermissionNames = USER_PERMISSIONS_ARRAY.map((permission : any) => permission.name);

  this.setState({
    editedSelectedUserPermissions: checked ? allPermissionNames : [],
    editedSelectAllPermissions: checked,
  });
};

handleEditedUsersSettingCheckboxUpdationChange = (permissionName:any, USER_PERMISSIONS_ARRAY : any) => {
  this.setState((prevState) => {
    let updatedPermissions = [...prevState.editedSelectedUserPermissions];
    if (updatedPermissions.includes(permissionName)) {
      updatedPermissions = updatedPermissions.filter((name) => name !== permissionName);
    } else {
      updatedPermissions.push(permissionName);
    }

    const allPermissionsSelected = updatedPermissions.length === USER_PERMISSIONS_ARRAY.length;

    return { editedSelectedUserPermissions: updatedPermissions, editedSelectAllPermissions : allPermissionsSelected };
  });
};


getUserProfileDataFromLocalStorage = () => {
  const userLoggedUserEmailStr : any = localStorage.getItem('userProfile_email')
  const userLoggedUserNameStr : any = localStorage.getItem('userProfile_user_Name')

  this.setState({
    loggedUserNameCatch : userLoggedUserNameStr,
    loggedUserEmailCatch : userLoggedUserEmailStr,
    userProfile: userLoggedUserNameStr?.split(/\s+/)
      .map((word : string) => word.slice(0, 1))
      .join("")
  })
}


handleRemoveLocationRow = (index: number) => {
  this.state.locations.splice(index, 1)
  this.setState({
    locations: this.state.locations
  })
}
 
isCreatePurchaseRequisitionValidCheckCreateOrFind = () => {
  let flag=true;
  if(this.state.department === ""){
    this.setState({
      departmentRequiredError: "Enter department *",
    })
    flag=false;
  }
  if(this.state.durationDate === ""){
    this.setState({
      durationDateRequiredError: "Enter Creation date *",
    })
    flag=false;
  }
  if(this.state.deliveryDate === ""){
    this.setState({
      deliveryDateRequiredError: "Enter Delivery date *",
    })
    flag=false;
  }
  if(this.state.location === ""){
    this.setState({
      locationRequiredError: "Enter location *",
    })
    flag=false;
  }
  if(this.state.prProduct.length === 0){
    this.setState({
      productsRequiredError: "Select Products *",
    })
    flag=false;
  }  
  let currentDateCreateOrFind:any = new Date()
  .getFullYear()
  .toString() +
  "-" +
  (new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0") +
  "-" +
  new Date()
    .getDate()
    .toString()
    .padStart(2, "0")

  if(new Date(this.state.durationDate) < new Date(currentDateCreateOrFind)){
    this.setState({creationDateCheckMessagePr: 'Invalid Creation Date *'})
    flag=false
  }
  if(new Date(this.state.durationDate) > new Date(this.state.deliveryDate)){
    this.setState({deliveryDateCheckMessagePr: 'Invalid Delivery Date *'})
    flag=false
  }
  return flag;
}
isCreatePurchaseRequisitionValidCheckDashboard = () => {
  let flag=true;
  if(this.state.department === ""){
    this.setState({
      departmentRequiredError: "Enter department *",
    })
    flag=false;
  }
  if(this.state.durationDate === ""){
    this.setState({
      durationDateRequiredError: "Enter Creation date *",
    })
    flag=false;
  }
  if(this.state.deliveryDate === ""){
    this.setState({
      deliveryDateRequiredError: "Enter Delivery date *",
    })
    flag=false;
  }
  if(this.state.prProduct.length === 0){
    this.setState({
      productsRequiredError: "Select Products *",
    })
    flag=false;
  }
  if(this.state.location === ""){
    this.setState({
      locationRequiredError: "Enter location *",
    })
    flag=false;
  }
  let currentDateDash:any = new Date()
  .getFullYear()
  .toString() +
  "-" +
  (new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0") +
  "-" +
  new Date()
    .getDate()
    .toString()
    .padStart(2, "0")

  if(new Date(this.state.durationDate) < new Date(currentDateDash)){
    this.setState({creationDateCheckMessagePr: 'Invalid Creation Date *'})
    flag=false
  }
  if(new Date(this.state.durationDate) > new Date(this.state.deliveryDate)){
    this.setState({deliveryDateCheckMessagePr: 'Invalid Delivery Date *'})
    flag=false
  }
  return flag;
}

handleCreatePurchaseRequisition = () => {
  if(!this.isCreatePurchaseRequisitionValidCheckCreateOrFind() && window.location.pathname === "/CreateOrFind"){
    this.setState({
      showIsCreatePurchaseRequisitionValidation: true,
    })
    return
  }
  if(!this.isCreatePurchaseRequisitionValidCheckDashboard() && window.location.pathname === "/Dashboard"){
    this.setState({
      showIsCreatePurchaseRequisitionValidation: true,
    })
    return
  }
  this.setState({
    createPurchaseRequisitionLoading: true,
  })
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const httpBody = {
    purchase_requisition:{
      pr_creation_date: this.state.durationDate,
      delivery_date: this.state.deliveryDate,
      location_setting_id: this.state.location,
      department_id: this.state.department,
      account_id: this.state.accountId,
      purchase_requisition_number: this.state.prNumberUnique,
      status: 'pending',
      product_purchase_requisitions_attributes: this.state.prProduct.filter((value: any) => value?.id).map((value: any)=>{
        const { quantity, id: product_id } = value;
        return {
          quantity,
          product_id
        };
      }),
      non_catalogue_items_attributes: this.state.prProduct.filter((value: any) => value?.attributes?.isNew).map((value: any)=>{
        const name = value?.attributes?.name
        const quantity = value?.quantity
        return {
          name,
          quantity,
          is_non_catalague_item: value?.attributes?.isNew
        }
      })
    }
  };

  const createPurchaseRequisitionApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createPurchaseRequisitionAPICallId = createPurchaseRequisitionApiMsg.messageId;

  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_requisitions"
  );

  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  
  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(createPurchaseRequisitionApiMsg.id, createPurchaseRequisitionApiMsg); 
}

// Modification Start

handleSaveAsDraftPurchaseRequisition = () => {
  this.setState({
    draftPurchaseRequisitionLoading: true,
  })
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const httpBody = {
    purchase_requisition:{
      purchase_requisition_number: this.state.prNumberUnique,
      status: 'draft',
      location_setting_id: this.state.location,
      account_id: this.state.accountId,
      department_id: this.state.department,
      delivery_date: this.state.deliveryDate,
      pr_creation_date: this.state.durationDate,
      product_purchase_requisitions_attributes: this.state.prProduct.filter((value: any) => value?.id).map((value: any)=>{
        const { id: product_id, quantity, } = value;
        return {
          product_id,
          quantity,
        };
      }),
      non_catalogue_items_attributes: this.state.prProduct.filter((value: any) => value?.attributes?.isNew).map((value: any)=>{
        const quantity = value?.quantity
        const name = value?.attributes?.name
        return {
          is_non_catalague_item: value?.attributes?.isNew,
          quantity,
          name,
        }
      })
    }
  };

  const saveAsDraftPurchaseRequisitionApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.saveAsDraftPurchaseRequisitionAPICallId = saveAsDraftPurchaseRequisitionApiMsg.messageId;

  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_requisition_draft_creation"
  );

  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  
  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(saveAsDraftPurchaseRequisitionApiMsg.id, saveAsDraftPurchaseRequisitionApiMsg); 
};

isSaveAsDraftPurchaseRequisitionValidCheck = () => {
  return true;
};

isSaveAsDraftRFPValidCheck = () => {
  let flag=true;
  
  if(this.state.rfpEmailOfSupplier && !this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
    this.setState({
      rfpSupplierEmailMatchError: "Enter a valid email",
    })
    flag=false;
  }

  return flag;
};

isSaveAsDraftPurchaseOrderValidCheck = () => {
  return true;
};

handleSaveAsDraftPurchaseOrder = () => {

  this.setState({poSaveDraft: true})
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const nonCatalogueItemsCatched = this.state.filteredPoProducts
    .filter(val => val?.attributes?.theInputIsNonCatalog)
    .map(value => ({
      name: value?.attributes?.name,
      quantity: value?.quantity,
      is_non_catalague_item: value?.attributes?.theInputIsNonCatalog,
    }));

    const productPurchaseOrdersCatched = this.state.filteredPoProducts
    .filter(val => val?.id)
    .map(val => ({
      product_id: val?.id,
      quantity: val?.quantity,
      product_price: (Number(val?.attributes?.unit_price)*Number(val.quantity)).toString(),
      name: val?.attributes?.name,
    }));

  const httpBody = {
    data: {
      attributes: {
          type: "draft_creation", 
          status: "draft",
          location: this.state.poShippingAddress,
          purchase_order_number: this.state.poNumberUnique,
          budget_id: this.state.poBudget,
          supplier_id: this.state.poSupplier,
          order_date: this.state.poOrderDate,
          delivery_date: this.state.deliveryDate,
          shipping_address: this.state.poShippingAddressOptional? this.state.poShippingAddressOptional:  this.state.poShippingAddress,
          billing_address: this.state.poBillingAddress,
          comment: this.state.poComment,
          taxation_id: this.state.taxName,
          department_id: this.state.poDepartment,
          payment_term_id: this.state.paymentTermsName,
          account_id: this.state.accountId,
          non_catalogue_items_attributes:nonCatalogueItemsCatched,
          product_purchase_orders_attributes: productPurchaseOrdersCatched
      }
  }
  };

  const saveAsDraftAddPOPostApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.saveAsDraftPurchaseOrderAPICallId = saveAsDraftAddPOPostApiMsg.messageId;

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_orders"
  );

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(saveAsDraftAddPOPostApiMsg.id, saveAsDraftAddPOPostApiMsg); 
};

handleSaveAsDraftEditedPurchaseOrder = () => {

  this.setState({editedPoSaveDraft: true})
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = { 
    'token': token_localStorage,
    'Content-Type': 'application/json',
  }
 
  const httpBody =  {
    data: {
      attributes: {
        status: "draft",
        type: "draft_creation",               
        order_date: this.state.editedPoOrderDate,
        product_purchase_orders_attributes: this.productPoAttributesValue(),
        supplier_id: this.state.editedPoSupplier,
        delivery_date: this.state.editedPoDeliveryDate,
        shipping_address:  this.refactoredEditedFuncShippingAddress(),
        approved : this.state.poAcceptRejectActionStatus,
        non_catalogue_items_attributes: this.editedPoNonCatalougeItemValue(),
        department_id: this.state.editedPoDepartment,
        comment: this.state.editedPoComment,
        budget_id: this.state.editedPoBudget,
        taxation_id: this.state.editedPoTaxId,
        billing_address: this.state.editedPoBillingAddress,
        amount_calculation: this.state.editedPoAmountCalculation,
        comments_for_po: this.state.editedPoCommentForPO,
        payment_term_id: this.state.editedPoPaymentTermsName,
      }
    }
  };

  const saveAsDraftAddPOPostApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editedPoSaveAsDraftPurchaseOrderAPICallId = saveAsDraftAddPOPostApiMsg.messageId;

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_my_forms/my_forms/purchase_orders/${this.state.editedPoId}`
  );

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );

  runEngine.sendMessage(saveAsDraftAddPOPostApiMsg.id, saveAsDraftAddPOPostApiMsg); 
};

prPoShippingAddressOptionalRefactorFunc = () =>{
  if(this.state.prPoShippingAddressOptional){
    return this.state.prPoShippingAddressOptional;
  }else{
    return this.state.poShippingAddress;
  }
} 

handleSaveAsDraftPrPurchaseOrder = () => {
  if(!this.isSaveAsDraftPurchaseOrderValidCheck()){
    this.setState({
      showIsCreatePrPurchaseOrderValidation: true,
    })
    return
  }
  this.setState({prPoSaveDraft: true})
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const httpBody = {
    data: {
      attributes: {
          type: "draft_creation",             
          account_id: this.state.accountId,
          status: 'draft',
          shipping_address: this.prPoShippingAddressOptionalRefactorFunc(),
          billing_address: this.state.poBillingAddress,
          comment: this.state.poComment,
          department_id: this.state.prPoSelectedRequisitionDepartment?.id,
          payment_term_id: this.state.paymentTermsName,
          budget_id: this.state.poBudget,
          supplier_id: this.state.poSupplier,
          purchase_order_number: this.state.prPoNumberUnique,
          order_date: this.state.prPoSelectedRequisitionCreationDate,
          taxation_id: this.state.taxName,
          delivery_date: this.state.prPoSelectedRequisitionDeliveryDate,
          location: this.state.poBillingAddress,
          non_catalogue_items_attributes:this.state.customFromRequisitionNonCataItemData.filter((val)=>{
            return val?.is_non_catalague_item}).map((value)=>{
            const name = value?.name
            const quantity = value?.quantity
            return {
              name,
              quantity,
              is_non_catalague_item: value?.is_non_catalague_item
            }
          }),
          product_purchase_orders_attributes: this.state.customFromRequisitionCataItemData.filter((val)=>{
            return val?.id;
          }).map((val)=>{
            return {
              product_id: val?.id,
              quantity: val?.quantity,
              product_price: val?.unit_price?.replace(/[$]/g, ''),
              name: val?.name,
            }
          })
      }
  }
  };

  const saveAsDraftPurchaseOrderApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.saveAsDraftPurchaseOrderAPICallId = saveAsDraftPurchaseOrderApiMsg.messageId;

  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_orders"
  );

  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(saveAsDraftPurchaseOrderApiMsg.id, saveAsDraftPurchaseOrderApiMsg); 
};


isSaveAsDraftSupplierValidCheck = () => {
  let flag = true;
  if(this.state.supplierEmailAddress && !RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(this.state.supplierEmailAddress)){
    this.setState({
      supplierEmailAddressEmailMatchError: "Enter valid Email",
    })
    flag=false;
  }
  return flag;
};

isSaveAsDraftProductValidCheck = () => {
 return true;
};


isSaveAsDraftBudgetValidCheck = () => {
  return true;
};

// Modification end

getAllPurchaseRequisitionsList = () => {
  const token_localStorage = localStorage.getItem('authToken') 

  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const finalModifiedAPIURL = this.handleMainModulesSearchByColumnQueryOrOnlyByQuery(`bx_block_my_forms/my_forms/purchase_requisitions${this.state.openPurchaseRequisitionListModal === true?'?custom_from_po=true':''}`)

  const getAllPurchaseRequisitionApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllPurchaseRequisitionListAPICallId = getAllPurchaseRequisitionApiMsg.messageId;

  getAllPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    finalModifiedAPIURL
  );

  getAllPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  getAllPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(getAllPurchaseRequisitionApiMsg.id, getAllPurchaseRequisitionApiMsg); 
}

isEditPurchaseRequisitionValidCheck = () => {
  let flag=true;
  if(this.state.editedDepartment === ""){
    this.setState({
      editedDepartmentRequiredError: "Enter department *",
    })
    flag=false;
  }
  if(this.state.selectedProductIds.length == 0){
    this.setState({
      editedProductsRequiredError: "Select product(s) *",
    })
    flag=false;
  }
  if(this.state.editedDeliveryDate === ""){
    this.setState({
      editedDeliveryDateRequiredError: "Enter Delivery date *",
    })
    flag=false;
  }
  if(this.state.editedDurationDate === ""){
    this.setState({
      editedDurationDateRequiredError: "Enter Creation date *",
    })
    flag=false;
  }
  if(this.state.editedLocation === ""){
    this.setState({
      editedLocationRequiredError: "Enter location *",
    })
    flag=false;
  }
  return flag;
}
  
  handleShowPurchaseRequisition = (purchaseRequisitionId: any) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showPurchaseRequisitionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showPurchaseRequisitionAPICallId = showPurchaseRequisitionApiMsg.messageId;
  
    showPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisitions/${purchaseRequisitionId}`
    );
  
    showPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showPurchaseRequisitionApiMsg.id, showPurchaseRequisitionApiMsg); 
  }


  handleMainModulesSearchByColumnQueryOrOnlyByQuery = (coreApiEndPoint: string) => {
    let apiMainEndpoint = coreApiEndPoint;
  
    if (this.state.filterMultiItemsState) {
      apiMainEndpoint += `?column=${this.state.filterMultiItemsState}`;
    }
  
    if (this.state.itemsSearchBySpecificValue) {
      apiMainEndpoint += apiMainEndpoint.includes('?') ? '&' : '?';
      apiMainEndpoint += `query=${this.state.itemsSearchBySpecificValue}`;
    }
  
    if (this.state.associetedItemNameForMainSearch) {
      apiMainEndpoint += apiMainEndpoint.includes('?') ? '&' : '?';
      apiMainEndpoint += `associated_model=${this.state.associetedItemNameForMainSearch}`;
    }
  
    return apiMainEndpoint;
  }
  

  handleApiParamsForSearchByColumnQueryOrOnlyByQuery = (coreApiEndPoint: string) => {
    let apiEndpoint = coreApiEndPoint;
  
    if (this.state.settingItemsFilterMultiState || this.state.itemsSettingSearchBySpecificValue) {
      apiEndpoint += apiEndpoint.includes('?') ? '&' : '?';
      if (this.state.settingItemsFilterMultiState) {
        apiEndpoint += `column=${this.state.settingItemsFilterMultiState}`;
      }
      if (this.state.itemsSettingSearchBySpecificValue) {
        apiEndpoint += apiEndpoint.includes('?') ? '&' : '';
        apiEndpoint += `query=${this.state.itemsSettingSearchBySpecificValue}`;
      }
    }
  
    return apiEndpoint;
  }


  handleCOFApiParamsForItemsSearchByColumnQueryOrOnlyByQuery = (coreApiEndPoint: string) => {
    let apiItemsEndpoint = coreApiEndPoint;
  
    if (this.state.cofItemsFilterMultiState || this.state.itemsCofSearchBySpecificValue || this.state.associetedItemNameForItemProductsSearch) {
      apiItemsEndpoint += apiItemsEndpoint.includes('?') ? '&' : '?';
  
      if (this.state.cofItemsFilterMultiState) {
        apiItemsEndpoint += `column=${this.state.cofItemsFilterMultiState}`;
      }
  
      if (this.state.itemsCofSearchBySpecificValue) {
        apiItemsEndpoint += apiItemsEndpoint.includes('?') ? '&' : '';
        apiItemsEndpoint += `query=${this.state.itemsCofSearchBySpecificValue}`;
      }
  
      if (this.state.associetedItemNameForItemProductsSearch) {
        apiItemsEndpoint += apiItemsEndpoint.includes('?') ? '&' : '';
        apiItemsEndpoint += `associated_model=${this.state.associetedItemNameForItemProductsSearch}`;
      }
    }
  
    return apiItemsEndpoint;
  }
  
  
  handleGetSettingsLocation = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleApiParamsForSearchByColumnQueryOrOnlyByQuery(`bx_block_settings/location_settings`)
  
    const showSettingsLocationListing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsLocationAPICall = showSettingsLocationListing.messageId;
  
    showSettingsLocationListing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    showSettingsLocationListing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showSettingsLocationListing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsLocationListing.id, showSettingsLocationListing); 
  }
  
  handleGetDataCategorySettings = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleApiParamsForSearchByColumnQueryOrOnlyByQuery(`bx_block_settings/category_settings`)
  
    const showSettingsCategoryListing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsCategoryAPICall = showSettingsCategoryListing.messageId;
  
    showSettingsCategoryListing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    showSettingsCategoryListing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showSettingsCategoryListing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsCategoryListing.id, showSettingsCategoryListing); 
  }
  
  handleGetDataUnitMeasurementSettings = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleApiParamsForSearchByColumnQueryOrOnlyByQuery(`bx_block_settings/unit_of_measure_settings`)
  
    const showSettingsUnitMeasurementListing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsUnitMeasurementAPICall = showSettingsUnitMeasurementListing.messageId;
  
    showSettingsUnitMeasurementListing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    showSettingsUnitMeasurementListing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showSettingsUnitMeasurementListing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsUnitMeasurementListing.id, showSettingsUnitMeasurementListing); 
  }
  handleDeleteSettingUnitMeasurement = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const deleteSettingUnitMeasurementApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteSettingUnitMeasurementAPICallId = deleteSettingUnitMeasurementApiMsg.messageId;
  
    deleteSettingUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/unit_of_measure_settings/${id}`
    );
  
    deleteSettingUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteSettingUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteSettingUnitMeasurementApiMsg.id, deleteSettingUnitMeasurementApiMsg);

  }
  handleDeleteSettingUnitMeasurementSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        unitMeasurementDeleteMessage: 'Unit Measurement is not deleted',
      })
    } else {
      this.setState({
        unitMeasurementDeleteMessage: 'Unit Measurement is deleted',
      })
      this.handleGetDataUnitMeasurementSettings()
    }
  }

  handleDeleteSettingUnitMeasurementAPICallIdResponse = (message:Message) => {
    if (this.deleteSettingUnitMeasurementAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteSettingUnitMeasurementSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleCOFItemsSearchValidation = () => {
    if(this.state.itemsCofSearchBySpecificValue === ""){
      this.setState({
        cofItemsSearchQuerryEmptyPopup : "Please enter querry to search!",
      })
    }
   else {
      this.getAllProductsList()
    }
  }

  getAllProductsList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
   
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleCOFApiParamsForItemsSearchByColumnQueryOrOnlyByQuery(`bx_block_my_forms/my_forms/products`)
  
    const getAllProductsListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllProductsListAPICallId = getAllProductsListApiMsg.messageId;
  
    getAllProductsListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getAllProductsListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllProductsListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getAllProductsListApiMsg.id, getAllProductsListApiMsg); 
  }

  handleGetSearchedProductsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllProductsList API', apiResponse?.errors)
    } else {
      this.setState({
        productsSearch: apiResponse
      })
      this.handleGetAllProductsListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetAllSearchProductsListAPICallIdResponse = (message:Message) => {
    if (this.getSearchedProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetSearchedProductsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  getSearchedNonCatalougeItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      prNonCatalougeItems: event.target.value
    })
  }

  getSearchedEditedNonCatalougeItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      prEditNonCatalougeItems: event.target.value
    })
  }

  getAllSuppliersList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleMainModulesSearchByColumnQueryOrOnlyByQuery(`all_suppliers`)
  
    const getAllSuppliersListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllSuppliersListAPICallId = getAllSuppliersListApiMsg.messageId;
  
    getAllSuppliersListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getAllSuppliersListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllSuppliersListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getAllSuppliersListApiMsg.id, getAllSuppliersListApiMsg); 
  }

  handleRfpRecordSupplierMailSend = () => {
    this.setState({isRfpMailSending : true})

    const token_localStorage = localStorage.getItem('authToken')

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRfpSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `send_email_to_supplier?id=${this.state.catchRfpEmailSendToSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }


  handleEditedRfpRecordSupplierMailSend = () => {
    this.setState({isEditedRfpMailSending : true})

    const token_localStorage = localStorage.getItem('authToken')

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleEditedSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditedRfpSupplierMailSendApiCallId = handleEditedSupplierMailSendApi.messageId;

    handleEditedSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `send_email_to_supplier?id=${this.state.catchEditedRfpEmailSendToSupplier}`
    );

    handleEditedSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleEditedSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleEditedSupplierMailSendApi.id, handleEditedSupplierMailSendApi);
  }





  getAllRFP = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleMainModulesSearchByColumnQueryOrOnlyByQuery(`list_of_proposal`)


    const getAllRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllRFPListAPICallId = getAllRFPListApiMsg.messageId;
  
    getAllRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getAllRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(getAllRFPListApiMsg.id, getAllRFPListApiMsg); 
  }


  isCreateRFPValidCheck = () => {
    let flag = true;
    if (this.state.rfpNumberUnique === "") {
      this.setState({
        rfpUniqNumberRequiredError: "RFP Number Is Not Generated *",
      })
      flag = false;
    }
    if (this.state.rfpCurrency === "") {
      this.setState({
        rfpCurrencyRequiredError: "Enter Currency *",
      })
      flag = false;
    }
    if (this.state.rfpDueDate === "") {
      this.setState({
        rfpDueDateRequiredError: "Enter Due Date *",
      })
      flag = false;
    }
    if (this.state.rfpCreationDate === "") {
      this.setState({
        rfpCreationDateRequiredError: "Enter Creation Date *",
      })
      flag = false;
    }
    if (this.state.rfpDesiredDeliveryDate === "") {
      this.setState({
        rfpDesiredDeliveryDateRequiredError: "Enter Desired Delivery Date *",
      })
      flag = false;
    }
    if (this.state.filteredRfpProducts.length === 0) {
      this.setState({
        rfpExistingProductRequiredError: "Select Products *",
      })
      flag = false;
    }
    if (this.state.rfpFileAttachment === "") {
      this.setState({
        rfpFileAttachmentRequiredError: "Select File *",
      })
      flag = false;
    }
    if (this.state.locationDropdownList && this.state.rfpLocation === "") {
      this.setState({
        rfpLocationRequiredError: "Select Location *",
      })
      flag = false;
    }
    if (this.state.rfpDepartments === "") {
      this.setState({
        rfpDepartmentRequiredError: "Select Department *",
      })
      flag = false;
    }
    if (this.state.rfpSelectExistingSupplier.length === 0) {
      this.setState({
        rfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: "Select supplier *",
      })
      flag = false;
    }
    if(this.state.rfpEmailOfSupplier && !this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        rfpSupplierEmailMatchError: "Enter a valid email",
      })
      flag=false;
    }
    if (!this.state.locationDropdownList) {
      this.setState({
        locationExistOrNotCheckingRequiredError: "location can't be select! Create location from setting first *",
      })
      flag = false;
    }
    return flag;
  }


  handleRFPPdfSupplierScreenOpenModal = () => {
    this.setState({
      openRfpPdfSupplierScreenModal:true
    })
  }
  
  handleCreateRFP = async () => {
    if (!this.isCreateRFPValidCheck()) {
      this.setState({
        showIsCreateRFPValidation: true,
        openCreateRfpShowErrorModal: true,
      })
      return
    }

    this.setState({isRfpCreating : true})

    const token_localStorage = localStorage.getItem('authToken')

    let headers = {
      'token': token_localStorage,
    }

    const filteredFinalcatelog : any[] = this.state.filteredRfpProducts.filter((value: any) => value?.id).map((value: any)=>{
          const { quantity, id: product_id } = value;
          return {
            product_id,
            quantity 
          };
    })

    const filteredFinalNonCatelog : any[] = this.state.filteredRfpProducts.filter((value: any) => value?.attributes?.theInputIsNonCatalog).map((value: any)=>{
          const name = value?.attributes?.name
          const quantity = value?.quantity
          return {
            name,
            quantity,
            is_non_catalague_item: value?.attributes?.theInputIsNonCatalog
          }
    })

    

    let formdata = new FormData();
    formdata.append("type", "order_creation");
    formdata.append("status", "pending");
    formdata.append("currency", this.state.rfpCurrency);
    formdata.append("creation_date", this.state.rfpCreationDate);
    formdata.append("due_date", this.state.rfpDueDate);
    formdata.append("desired_delivery_date", this.state.rfpDesiredDeliveryDate);
    formdata.append("file", this.state.rfpFileAttachment);
    formdata.append("location_setting_id", this.state.rfpLocation);
    formdata.append("department_id", this.state.rfpDepartments);
    formdata.append("account_id", this.state.accountId);
    formdata.append("rfp_number", this.state.rfpNumberUnique);

    for(let i=0; i<this.state.rfpSelectExistingSupplier.length;i++){
      const suppliers = this.state.rfpSelectExistingSupplier[i];
      formdata.append(`supplier_ids[]`,suppliers)
    }

    formdata.append("optional_email_of_supplier", this.state.rfpEmailOfSupplier);
    
    for(let i=0; i<filteredFinalcatelog.length; i++){
      const product = filteredFinalcatelog[i];
      formdata.append(`request_for_proposal_items_attributes[${i}][product_id]`, product?.product_id);
      formdata.append(`request_for_proposal_items_attributes[${i}][quantity]`, product?.quantity);
    }

    for(let i=0; i<filteredFinalNonCatelog.length; i++){
      const product = filteredFinalNonCatelog[i];
      formdata.append(`rfp_non_catelogues_attributes[${i}][name]`, product?.name);
      formdata.append(`rfp_non_catelogues_attributes[${i}][quantity]`, product?.quantity);
    }

    const createRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createRfpAPICallId = createRFPListApiMsg.messageId;

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "request_for_proposal"
    );

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(createRFPListApiMsg.id, createRFPListApiMsg);
  }


  handleShowRFP = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken')
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const showRfpApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showRfpAPICallId = showRfpApiMsg.messageId;

    showRfpApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `show_rpf?id=${id}`
    );

    showRfpApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    showRfpApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(showRfpApiMsg.id, showRfpApiMsg);
  }

  isEditRFPValidCheck = (): boolean => {
    let flag = true;
    if (this.state.editedRfpCurrency === "") {
      this.setState({
        editedRfpCurrencyRequiredError: "Select Currency *",
      })
      flag = false;
    }
    if (this.state.editedRfpCreationDate === "") {
      this.setState({
        editedRfpCreationDateRequiredError: "Enter Creation date *",
      })
      flag = false;
    }
    if (this.state.editedRfpDueDate === "") {
      this.setState({
        editedRfpDueDateRequiredError: "Enter Due date *",
      })
      flag = false;
    }
    if (this.state.editedRfpDesiredDeliveryDate === "") {
      this.setState({
        editedRfpDesiredDeliveryDateRequiredError: "Enter Desired date *",
      })
      flag = false;
    }
    if (this.state.editedRfpLocation === "") {
      this.setState({
        editedRfpLocationRequiredError: "Select Location *",
      })
      flag = false;
    }
    if (this.state.editedRfpDepartment === "") {
      this.setState({
        editedRfpDepartmentRequiredError: "Select Department *",
      })
      flag = false;
    }
    if (!this.state.editedRfpSelectExistingSupplier?.length) {
      this.setState({
        editedRfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: "Select Supplier *",
      })
      flag = false;
    }
    if (this.state.editedRfpFileAttachment === "") {
      this.setState({
        editedRfpFileAttachmentRequiredError: "Select File *",
      })
      flag = false;
    }

    if (this.state.editedRfpCatelogItems?.length === 0) {
      this.setState({
        editedRfpExistingProductRequiredError: "Select Products *",
      })
      flag = false;
    }
    if(this.state.editedRfpEmailOfSupplier && !this.state.editedRfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        rfpSupplierEmailMatchError: "Enter a valid email",
      })
      flag=false;
    }
    

    return flag;
  }


  handleRfpShowActionTriggered = (result:any) => {
    this.setState({
      rfpData: result,
      openShowRFPModal: true,
      catchEditedRfpEmailSendToSupplier: result.id,
    });
  }


  handleEditRFP = () => {
    if(!this.isEditRFPValidCheck()){
      this.setState({
        showIsEditRFPValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'token': token_localStorage,
    }

    let formdata = new FormData();
    formdata.append("currency", this.state.editedRfpCurrency);
    formdata.append("due_date", this.state.editedRfpDueDate);
    formdata.append("desired_delivery_date", this.state.editedRfpDesiredDeliveryDate);
    formdata.append("address", this.state.editedRfpAddress);
    formdata.append("creation_date", this.state.editedRfpCreationDate);
    if(this.state.editedRfpFileAttachment?.name){
      formdata.append("file", this.state.editedRfpFileAttachment);
    }
    formdata.append("pdf_attach", this.state.editedRfpAttachment);
    formdata.append("product_id", this.state.editedRfpExistingProduct);
    let a = this.state.editedRfpSelectExistingSupplier
    
    for (let supplierId of a) {
      formdata.append("supplier_id[]", supplierId);
    }

    for (let pair of formdata.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }

    const editRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editRfpAPICallId = editRFPListApiMsg.messageId;

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_request_proposal/${this.state.editRfp?.id}`
    );
  
    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editRFPListApiMsg.id, editRFPListApiMsg); 
  }

  handleGetAllPurchaseOrders = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleMainModulesSearchByColumnQueryOrOnlyByQuery(`bx_block_my_forms/my_forms/purchase_orders`)
  
    const getAllPurchaseOrderListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllPurchaseOrderListAPICallId = getAllPurchaseOrderListApiMsg.messageId;
  
    getAllPurchaseOrderListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getAllPurchaseOrderListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllPurchaseOrderListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(getAllPurchaseOrderListApiMsg.id, getAllPurchaseOrderListApiMsg);
  }

  isCreatePurchaseOrderValidCheck = () => {
    let flag = true
    if(this.state.paymentTermsName === ""){
      this.setState({
        purchaseOrderPaymentTermsRequiredField: "Select the PaymentTerms *",
      })
      flag=false;
    }
    if(this.state.taxName === ""){
      this.setState({
        purchaseOrderTaxationNameRequiredField: "Select the Taxations *",
      })
      flag=false;
    }
    if(this.state.poSupplier === ""){
      this.setState({
        purchaseOrderSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.poOrderDate === ""){
      this.setState({
        purchaseOrderOrderDateRequiredField: "Select the Order Date *",
      })
      flag=false;
    }
    if(this.state.poBillingAddress === ""){
      this.setState({
        purchaseOrderBillingAddressRequiredField: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.poBudget === ""){
      this.setState({
        purchaseOrderBudgetRequiredField: "Enter the Budget *",
      })
      flag=false;
    }
    if(this.state.poDepartment === ""){
      this.setState({
        purchaseOrderDepartmentRequiredField: "Select the Department *",
      })
      flag=false;
    }
    if(this.state.filteredPoProducts.length === 0){
      this.setState({
        purchaseOrderProductsRequiredField: "Select the Products *",
      })
      flag=false;
    }

    if(this.state.deliveryDate === ""){
      this.setState({
        purchaseOrderDeliveryDateRequiredField: "Enter the Delivery Date *",
      })
      flag=false;
    }

    const deliveryDate = new Date(this.state.deliveryDate)
    const poOrderDate = new Date(this.state.poOrderDate)

    if(deliveryDate < poOrderDate){
      this.setState({
        purchaseOrderDeliveryDateErrorFormat: 'Delivery Date must be bigger'
      })
      return flag
    }
    return flag
  }

  handlePOPdfSupplierScreenOpenModal = () => {
    this.setState({
      openPoPdfSupplierScreenModal:true
    })
  }

  handleCreatePurchaseOrder = () => {
    if(!this.isCreatePurchaseOrderValidCheck()){
      
      this.setState({
        showIsCreatePurchaseOrderValidation: true,
        openCreatePoShowErrorModal: true
      })
      return;
    }
    this.handlePOPdfSupplierScreenOpenModal();

    const refactoredFuncShippingAddressPO = () =>{
      if(this.state.poShippingAddressOptional){
        return this.state.poShippingAddressOptional;
      }else{
        return this.state.poShippingAddress
      }
    }

    const nonCatalogueItemsAddPOCatched = this.state.filteredPoProducts
    .filter(val => val?.attributes?.theInputIsNonCatalog)
    .map(value => ({
      name: value?.attributes?.name,
      quantity: value?.quantity,
      is_non_catalague_item: value?.attributes?.theInputIsNonCatalog,
    }));

    const productPurchaseOrdersAddCatched = this.state.filteredPoProducts
    .filter(val => val?.id)
    .map(val => ({
      product_id: val?.id,
      quantity: val?.quantity,
      product_price: (Number(val?.attributes?.unit_price)*Number(val.quantity)).toString(),
      name: val?.attributes?.name,
    }));
    
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",
            location: this.state.poBillingAddress,
            purchase_order_number: this.state.poNumberUnique,
            budget_id: this.state.poBudget,
            supplier_id: this.state.poSupplier,
            order_date: this.state.poOrderDate,
            delivery_date: this.state.deliveryDate,
            payment_term_id: this.state.paymentTermsName,
            shipping_address: refactoredFuncShippingAddressPO(),
            billing_address: this.state.poBillingAddress,
            comment: this.state.poComment,
            taxation_id: this.state.taxName,
            department_id: this.state.poDepartment,
            account_id: this.state.accountId,
            non_catalogue_items_attributes: nonCatalogueItemsAddPOCatched,
            product_purchase_orders_attributes: productPurchaseOrdersAddCatched
        }
    }
    };
  
    const createPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createPurchaseOrderAPICallId = createPurchaseOrderApiMsg.messageId;
  
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/purchase_orders"
    );
  
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(createPurchaseOrderApiMsg.id, createPurchaseOrderApiMsg); 
  }

  refactoredEditedFuncShippingAddress = () =>{
    if(this.state.editedPoShippingAddressOptional){
      return this.state.editedPoShippingAddressOptional;
    }else{
      return this.state.editedPoShippingAddress
    }
  }

  isEditedNonCatalougeType = (val: any) =>{
    if(val?.attributes?.theInputIsNonCatalog === true){
      return val?.attributes?.theInputIsNonCatalog
    }else if(val?.is_non_catalague_item === true){
      return val?.is_non_catalague_item 
    }else{
      return Number(val?.id)
    }
  }

  nonCatalougeNameVal = (value: any) =>{
    if(value?.attributes?.name){
      return value?.attributes?.name
    }else{
      return value?.name
    }
  }

  isNonCata = (value: any) =>{
    if(value?.attributes?.theInputIsNonCatalog){
      return value?.attributes?.theInputIsNonCatalog
    }else{
      return value?.theInputIsNonCatalog
    }
  }

  catalougeProductId = (val: any) =>{
    if(Number(val?.product_id)){
      return Number(val?.product_id)
    }else{
      return Number(val?.id)
    }
  }

  productPriceCatalouge = (val: any) => {
    return val?.attributes ? (Number(val?.attributes?.unit_price)*Number(val?.quantity)).toString() : (Number(val?.actual_product_price)*Number(val?.quantity)).toString()
  }

  editedPoNonCatalougeItemValue=  () =>{
   return this.state.editedPoProductNonCatalouge.filter((value)=> {
      return this.isEditedNonCatalougeType(value)
    }).map((value)=>{
       const name = this.nonCatalougeNameVal(value)
       const quantity = value?.quantity
       if(value?._destroy === 0 || value?._destroy || value?.is_non_catalague_item === true || value?.id){ 
        return {
          id: value?.id,
          name,
          quantity,
          is_non_catalague_item: this.isNonCata(value),
          _destroy: value?._destroy
        }
       }else if(value?.attributes?.theInputIsNonCatalog === true){
         return {
           name,
           quantity,
           is_non_catalague_item: this.isNonCata(value),
         }
       }
    })
  }

  productPoAttributesValue = () =>{
    return this.state.editedPoProductCatalouge.filter((val)=>{
      return Number(val?.product_id)?Number(val?.product_id):Number(val?.id)
    }).map((val)=>{
      
      if(val?._destroy === 0 || val?._destroy){
          return {
            id: val?.id,
            product_id: this.catalougeProductId(val),
            quantity: val?.quantity,
            product_price: this.productPriceCatalouge(val),
            name: val?.attributes?.name || val?.name,
            _destroy: val?._destroy
        }
      }else if(Number(val?.product_id)){
        return{
          id: val?.id,
            product_id: this.catalougeProductId(val),
            quantity: val?.quantity,
            product_price: this.productPriceCatalouge(val),
            name: val?.attributes?.name || val?.name,
            _destroy: 0
        }
      }else if(Number(val?.id)){
        return{
          product_id: this.catalougeProductId(val),
          quantity: val?.quantity,
          product_price: this.productPriceCatalouge(val),
          name: val?.attributes?.name || val?.name,
        }
      }
    })
  }

  handleDashboardEditPurchaseOrder = () => {
    if(!this.isDashboardEditPurchaseOrderValidCheck()){
      this.setState({
        showIsEditPurchaseOrderValidation: true,
      })
      return
    }

    this.setState({poEditing: true})
    
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",             
            status: "open",
            supplier_id: this.state.editedPoSupplier,
            order_date: this.state.editedPoOrderDate,
            delivery_date: this.state.editedPoDeliveryDate,
            shipping_address: this.refactoredEditedFuncShippingAddress(),
            billing_address: this.state.editedPoBillingAddress,
            comments_for_po: this.state.editedPoCommentForPO,
            amount_calculation: this.state.editedPoAmountCalculation,
            department_id: this.state.editedPoDepartment,
            budget_id: this.state.editedPoBudget,
            comment: this.state.editedPoComment,
            taxation_id: this.state.editedPoTaxId,
            payment_term_id: this.state.editedPoPaymentTermsName,
            approved : this.state.poAcceptRejectActionStatus,
            non_catalogue_items_attributes: this.editedPoNonCatalougeItemValue(),
            product_purchase_orders_attributes: this.productPoAttributesValue()
        }
      }
    };

    const editPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardEditPurchaseOrderAPICallId = editPurchaseOrderApiMsg.messageId;
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${this.state.editedPoId}`
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(editPurchaseOrderApiMsg.id, editPurchaseOrderApiMsg); 
  };


  downloadPOPdfBlob = (blob : Blob) => {
    const url = window.URL.createObjectURL(blob);
  
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `PO_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
    );
  
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  handleGetDownloadPdfPo = async () => {
    this.setState({ isPdfDownloaded: true });
  
    const token_localStorage: any = await localStorage.getItem('authToken');
    this.setState({
      poFinalPdfDownloadOrNotMessage: ``
    });
  
    fetch(`${baseURL}/bx_block_my_forms/my_forms/generate_purchase_order_pdf?purchase_order=${this.state.catchPoIdForPdfCreate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      this.downloadPOPdfBlob(blob);
  
      this.setState({
        poFinalPdfDownloadOrNotMessage: `PO file is downloaded`,
        isPdfDownloaded: false
      });
    })
    .catch(error => {
      this.setState({
        poFinalPdfDownloadOrNotMessage: error?.message,
        isPdfDownloaded: true
      });
    });
  };


  downloadEditedPOPdfBlob = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);

        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `PO_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
        );

        document.body.appendChild(link);
        link.click()
        link.parentNode.removeChild(link);
  }

  handleEditedGetDownloadPdfPo = async () => {
    this.setState({isEditedPdfDownloaded: true})
    const token_localStorage: any = await localStorage.getItem('authToken')

    this.setState({
      editedPoFinalPdfDownloadOrNotMessage: ``
    })
    fetch(`${baseURL}/bx_block_my_forms/my_forms/generate_purchase_order_pdf?purchase_order=${this.state.editedPoId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        this.downloadEditedPOPdfBlob(blob);

        this.setState({
          editedPoFinalPdfDownloadOrNotMessage: `PO file is downloaded`,
          isEditedPdfDownloaded: false
        })
      })
      .catch(error => {
        this.setState({
          editedPoFinalPdfDownloadOrNotMessage: error?.message,
          isEditedPdfDownloaded: true
        })
      });
  }


  downloadPRPOPdfBlob = (blob : Blob) => {
    const urlPrPo = window.URL.createObjectURL(blob);

    const linkPrPo: any = document.createElement('a');
    linkPrPo.href = urlPrPo;
    linkPrPo.setAttribute(
      'download',
      `PO_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
    );

    document.body.appendChild(linkPrPo);
    linkPrPo.click()
    linkPrPo.parentNode.removeChild(linkPrPo);
  }

  handleGetDownloadPdfPrPo = async () => {
    this.setState({isPdfDownloaded: true})

    const token_localStorage: any = await localStorage.getItem('authToken')
    this.setState({
      prPoFinalPdfDownloadOrNotMessage: ``
    })

    fetch(`${baseURL}/bx_block_my_forms/my_forms/generate_purchase_order_pdf?purchase_order=${this.state.catchPrPoIdForPdfCreate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
    .then((response) =>{
      return response.blob()
      })
      .then((blob) => {
        this.downloadPRPOPdfBlob(blob)
        this.setState({
          prPoFinalPdfDownloadOrNotMessage: `PO file is downloaded`,
          isPdfDownloaded: false
        })
      })
      .catch(error => {
        this.setState({
          prPoFinalPdfDownloadOrNotMessage: error?.message,
          isPdfDownloaded: true
        })
      });
  }

  downloadReceiptPdfBlob = (blob : Blob) => {
    const urlReceipt = window.URL.createObjectURL(blob);
  
    const linkReceipt: any = document.createElement('a');
    linkReceipt.href = urlReceipt;
    linkReceipt.setAttribute(
      'download',
      `Receipt_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
    );
  
    document.body.appendChild(linkReceipt);
    linkReceipt.click();
    linkReceipt.parentNode.removeChild(linkReceipt);
  };

  handleDownloadReceiptPdf = async () => {
    this.setState({isReceiptPdfDownloaded: true})
    const token_localStorage: any = await localStorage.getItem('authToken')
    this.setState({
      receiptPdfSuccessMessage: ""
    })

    fetch(`${baseURL}/generate_receipt_pdf/${this.state.receiptSelectedRow}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })?.then((response) =>{
      return response.blob()
      })
      .then((blob) => {
        this.downloadReceiptPdfBlob(blob)
        this.setState({
          receiptPdfSuccessMessage: `Reciept PDF is downloaded`,
          isReceiptPdfDownloaded: false,
          isShowReceiptOpenPdfModalSuccess: true,
          isReadOnlyReceiptField: false
        })
      })
      .catch(error => {
        this.setState({
          receiptPdfSuccessMessage: error?.message,
          isReceiptPdfDownloaded: true,
          isShowReceiptOpenPdfModalSuccess: true,
          isReadOnlyReceiptField: false
        })
      });
  }


  handleEditedPoMailSendConfirmation = () => {
    this.setState({
      isEditedPoMailSendConfirmed:true
    })
  }

  handlePoRecordSupplierMailSend = () => {
    this.setState({isPoMailSending: true, })

    const token_localStorage = localStorage.getItem('authToken')

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPoSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `email_send_to_supplier?id=${this.state.catchPoMailOfSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }

  handleEditedPoRecordSupplierMailSend = () => {
    this.setState({isPoMailSending : true, editedPoEmailToSupplierSentOrNotErrorMessage:'Email send Succesfully'})

    const token_localStorage = localStorage.getItem('authToken')

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPoEditSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `email_send_to_supplier?id=${this.state.catchEditedPoMailOfSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }


  handlePrPoRecordSupplierMailSend = () => {
    this.setState({isPrPoMailSending: true, })

    const token_localStorage = localStorage.getItem('authToken')

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrPoSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `email_send_to_supplier?id=${this.state.catchPoMailOfSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }

  isCreatePurchaseRequistionOrderValidCheck = () => {
    let flag = true
    if(this.state.paymentTermsName === ""){
      this.setState({
        prPoSelectedRequisitionPaymentError: "Select the PaymentTerms *",
      })
      flag=false;
    }
    if(this.state.taxName === ""){
      this.setState({
        prPoSelectedRequisitionTaxationError: "Select the Taxations *",
      })
      flag=false;
    }
    if(this.state.poSupplier === ""){
      this.setState({
        prPoSelectedRequisitionSupplierError: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.poBillingAddress === ""){
      this.setState({
        prPoSelectedRequisitionBillingError: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.poBudget === ""){
      this.setState({
        prPoSelectedRequisitionBudgetError: "Enter the Budget *",
      })
      flag=false;
    }
    return flag
  }

  handleCreateRequisitionPurchaseOrder = () => {
    if(!this.isCreatePurchaseRequistionOrderValidCheck()){
      this.setState({
        showIsCreatePrPurchaseOrderValidation: true,
        openCreatePrPoShowErrorModal: true
      })
      return;
    }

    this.setState({prPoCreating: true})
    
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",
            location: this.state.poBillingAddress,
            shipping_address: this.prPoShippingAddressOptionalRefactorFunc(),
            billing_address: this.state.poBillingAddress,
            comment: this.state.poComment,
            taxation_id: this.state.taxName,
            department_id: this.state.prPoSelectedRequisitionDepartment?.id,
            payment_term_id: this.state.paymentTermsName,
            non_catalogue_items_attributes:this.state.customFromRequisitionNonCataItemData.filter((val)=>{
              return val?.is_non_catalague_item}).map((value)=>{
                const name = value?.name
                const quantity = value?.quantity
                return {
                  name,
                  quantity,
                  is_non_catalague_item: value?.is_non_catalague_item
                }
              }),
              account_id: this.state.accountId,
            product_purchase_orders_attributes: this.state.customFromRequisitionCataItemData.filter((val)=>{
              return val?.id;
            }).map((val)=>{
              return {
                product_id: val?.id,
                quantity: val?.quantity,
                product_price: val?.price?.replace(/[$]/g, '')* val?.quantity,
                name: val?.name,
              }
            }),
            purchase_order_number: this.state.prPoNumberUnique,
            budget_id: this.state.poBudget,
            supplier_id: this.state.poSupplier,
            order_date: this.state.prPoSelectedRequisitionCreationDate,
            delivery_date: this.state.prPoSelectedRequisitionDeliveryDate,
        }
      }
    };
  
    const createPurchaseRequisitionOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createPurchaseRequisitionOrderAPICallId = createPurchaseRequisitionOrderApiMsg.messageId;
  
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisition/${this.state.customFromRequisitionSelectedId}/purchase_order_from_requisition`
    );
  
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(createPurchaseRequisitionOrderApiMsg.id, createPurchaseRequisitionOrderApiMsg); 
  }

  isEditPurchaseOrderValidCheck = () => {
    let flag = true;
    if(this.state.editedPoLocation === ""){
      this.setState({
        editedPurchaseOrderLocationRequiredField: "Enter the Location *",
      })
      flag=false;
    }
    if(this.state.editedPoSupplier === ""){
      this.setState({
        editedPurchaseOrderSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.editedPoOrderDate === ""){
      this.setState({
        editedPurchaseOrderOrderDateRequiredField: "Select the Order Date *",
      })
      flag=false;
    }
    if(this.state.editedPoBillingAddress === ""){
      this.setState({
        editedPurchaseOrderBillingAddressRequiredField: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.editedPoShippingAddress === ""){
      this.setState({
        editedPurchaseOrderShippingAddressRequiredField: "Enter the Shipping Address *",
      })
      flag=false;
    }
    if(this.state.editedPoAmountCalculation === ""){
      this.setState({
        editedPurchaseOrderAmountCalculationRequiredField: "Enter the Amount Calculation *",
      })
      flag=false;
    }
    if(this.state.editedPoFobPoint === ""){
      this.setState({
        editedPurchaseOrderFobPointRequiredField: "Enter the FOB Point *",
      })
      flag=false;
    }
    if(this.state.editedPoBudget === ""){
      this.setState({
        editedPurchaseOrderBudgetRequiredField: "Enter the Budget *",
      })
      flag=false;
    }
    if(this.state.editedPoDepartment === ""){
      this.setState({
        editedPurchaseOrderDepartmentRequiredField: "Select the Department *",
      })
      flag=false;
    }
    if(this.state.editedPoCommentForPO === ""){
      this.setState({
        editedPurchaseOrderCommentsForPoRequiredField: "Enter the Comment for PO *",
      })
      flag=false;
    }
    if(this.state.editedPoComment === ""){
      this.setState({
        editedPurchaseOrderCommentRequiredField: "Enter the Comment *",
      })
      flag=false;
    }
    if(this.state.editedPoModeOfTransit === ""){
      this.setState({
        editedPurchaseOrderModeOfTransitRequiredField: "Enter the Mode of Transit *",
      })
      flag=false;
    }
    if(this.state.editedPoProduct.length === 0){
      this.setState({
        editedPurchaseOrderProductsRequiredField: "Select the Products *",
      })
      flag=false;
    }
    if(this.state.editedPoGstNumber === ""){
      this.setState({
        editedPurchaseOrderGstNumberRequiredField: "Enter the GST Number *",
      })
      flag=false;
    }
    return flag;
  }  

  handleEditPurchaseOrder = () => {
    if(!this.isEditPurchaseOrderValidCheck()){
      this.setState({
        showIsEditPurchaseOrderValidation: true,
      })
      return
    }

    this.setState({poEditing: true})
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",             
            location: this.state.editedPoLocation,
            supplier_id: this.state.editedPoSupplier,
            order_date: this.state.editedPoOrderDate,
            fob_point: this.state.editedPoFobPoint,
            shipping_address: this.state.poShippingAddress,
            billing_address: this.state.poBillingAddress,
            gst_number: this.state.poGstNumber,
            mode_of_transit: this.state.editedPoModeOfTransit,
            comments_for_po: this.state.editedPoCommentForPO,
            amount_calculation: this.state.editedPoAmountCalculation,
            department_id: this.state.editedPoDepartment,
            budget: this.state.editedPoBudget,
            comment: this.state.editedPoComment,
            product_ids: this.state.editedPoProduct,
            approved : this.state.poAcceptRejectActionStatus
        }
      }
    };

    const editPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editPurchaseOrderAPICallId = editPurchaseOrderApiMsg.messageId;
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${this.state.editedPoId}`
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(editPurchaseOrderApiMsg.id, editPurchaseOrderApiMsg); 
  }

  handleShowPurchaseOrder = (purchaseOrderId: any) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const showPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showPurchaseOrderAPICallId = showPurchaseOrderApiMsg.messageId;
  
    showPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${purchaseOrderId}`
    );
  
    showPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showPurchaseOrderApiMsg.id, showPurchaseOrderApiMsg); 
  }

  handleCustomFromRequisitionCreatePO = () => {
    this.handleGetInformationSettings()
    this.getAllPurchaseRequisitionsList()
    if(this.state.customFromRequisitionSelectedRow){
      this.setState({
        openCustomFromRequisitionCreatePoModal: true,
        prPoNumberUnique: Math.floor(Math.random() * 900000000) + 100000000,
      })
    }
    if(!this.state.customFromRequisitionSelectedRow){
      this.setState({
        customFromRequisitionSelectMessage: 'Please select a Requisition',
      })
    }
  }

  handleClosePurchaseFromRequisitionCreatePO = () => {
    this.setState({
      openCustomFromRequisitionCreatePoModal: false,
      prPoSelectedRequisitionBudgetError:'',
      prPoSelectedRequisitionSupplierError:'',
      prPoSelectedRequisitionPaymentError:'',
      prPoSelectedRequisitionTaxationError:'',
      prPoSelectedRequisitionShippingError:'',
      prPoSelectedRequisitionBillingError:'',
      prPoSelectedRequisitionDepartment:'',
      prPoSelectedRequisitionNumber:'',
      prPoSelectedRequisitionCreationDate:'',
      prPoSelectedRequisitionDeliveryDate:'',
      purchaseOrderCommentRequiredField: '',
      poBudget:'',
      poSupplier:'',
      paymentTermsName:'',
      taxName:'',
      poShippingAddress:'',
      poBillingAddress:'',
      prPoCreateScreenActiveTabs: 0,
      poComment: '',
      openPrPoPdfSupplierScreenModal: false,
      openPrPoSupplierMailSeccessModal: false,
      prPoSaveDraft: false,
      prPoShippingAddressOptional: '',
      prPoCreating: false,
      poCreateMessage: '',
      openPurchaseRequisitionListModal: false,
      getPaymentTermListSupplier: [],
      customFromRequisitionSelectMessage: '',
      poCreating: false,
      openPoPdfSupplierScreenModal: false,
      openPoSupplierMailSeccessModal: false,
      selectPoOptions: ''
    })
    this.handleCustomFromRequisitionSelectedRow(false)
  }

  handleCustomFromRequisitionSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    } this.setState({
      customFromRequisitionSelectMessage: '',
    })
  }

  handlePrPoNotItemsFoundMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoItemsNotFoundMessage: '',
    })
  }

  handlePurchaseRequisitionCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      purchaseRequisitionCreateMessage: '',
    })
  }

  handlePurchaseRequisitionDraftSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      purchaseRequisitionDraftMessage: '',
    })
  }

  handleQuantityErrorPREditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      quantityErrorPREditMessage: '',
    })
  }
  handleQuantityErrorPRCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      quantityErrorPRCreateMessage: '',
    })
  }

  handlePurchaseRequisitionEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      purchaseRequisitionEditMessage: '',
    })
  }

  handlePrAcceptRejectActionStatusSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prAcceptRejectActionStatusMessage: '',
    })
  }

  handleDashboardNoRecordMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      dashboardNoRecordMessage: '',
    })
  }


  handleNoDashModulePermitMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      noDashModulePermitMessage: '',
    })
  }

  handleNoCreateOrFindTabModulesPermitMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      noCreateOrFindTabModulesPermitMessage: '',
    })
  }

  handleSearchFilterEmptyPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      searchFilterEmptyPopup: '',
    })
  }

  handleFormSubmit = (e: { preventDefault: () => void; }) =>{
    e.preventDefault();
    this.handleMainSearchValidation();
  }

  handleFormSubmitForCOF = (e: { preventDefault: () => void; }) =>{
    e.preventDefault();
    this.handleCOFItemsSearchValidation();
  }

  handleFormSubmitForSettingsSearch = (e: { preventDefault: () => void; }) =>{
    e.preventDefault();
    this.handleSettingsAllCoreSearchValidation();
  }

  handleItemsSearchValueEmptyPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      itemsSearchValueEmptyPopup: '',
    })
  }


  handleCofItemsSearchFilterEmptyPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      cofItemsSearchFilterEmptyPopup: '',
    })
  }

  handleCofItemsSearchQuerryEmptyPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      cofItemsSearchQuerryEmptyPopup : '',
    })
  }


  handleSettingsSearchFilterEmptyPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      settingsSearchFilterEmptyPopup: '',
    })
  }

  handleSettingsSearchQuerryEmptyPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      settingsItemsSearchValueEmptyPopup: '',
    })
  }

  handleSettingsNoRecordsFoundPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      settingsNoRecordsFoundPopup : ''
    })
  }
  
  handleRfpCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpCreateMessage: '',
    })
  }


  handleRfpEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpEditMessage: '',
    })
  }

  handleCreateOrFindNoRecordsFoundPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      createOrFindNoRecordsFoundPopup: '',
    })
  }

  handleCreateOrFindItemsNoRecordsFoundPopupSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      createOrFindItemsNoRecordsFoundPopup: '',
    })
  }


  handlePoCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poCreateMessage: '',
      paymentTermsName: '',
    })
  }


  handlePaymentTermsFetchSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      paymentTermsFetchMessage: '',
    })
  }

  handleSelectBudgetIsBlankMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      selectBudgetIsBlankMessage: '',
    })
  }

  handleSelectTaxationIsBlankMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      selectTaxationIsBlankMessage: '',
    })
  }

  handlePrPoCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoCreateMessage: '',
    })
  }

  handlePoFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poFinalPdfDownloadOrNotMessage: '',
    })
  }

  handlePrPoFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoFinalPdfDownloadOrNotMessage: '',
    })
  }

  handlePoEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handlePrPoEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handlePoEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poEditMessage: '',
    })
  }

  handleReceiptCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      receiptCreateMessage: '',
      receiptQuantityError: '',

    })
  }

  handleReceiptFileTypeSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      receiptSelectFileTypeMessage: '',
    })
  }

  handleReceiptPdfSuccessMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      receiptPdfSuccessMessage: '',
    })
  }

  handleReceiptEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      receiptEditMessage: '',
    })
  }

  handleSupplierCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      supplierCreateMessage: '',
    })
  }

  handleSupplierEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      supplierEditMessage: '',
    })
  }

  handleProductCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      productCreateMessage: '',
    })
  }

  handleProductEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      productEditMessage: '',
    })
  }

  handleBudgetCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      budgetCreateMessage: '',
    })
  }

  handleBudgetEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      budgetEditMessage: '',
    })
  }

  handleInformationSettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      informationSettingsEditMessage: '',
    })
  }

  handleCurrencySettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      currencySettingsEditMessage: '',
    })
  }

  handleLocationSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationCreateMessage: '',
    })
  }

  handleEditedPoEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedPoEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handleEditedPoSuccessOrNotSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedPoSuccessOrNotMessage: '',
    })
  }


  handleSupplierFoundNotSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      supplierFoundOrNotMessage: '',
    })
  }

  handlePurchaseOrderDraftSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      purchaseOrderDraftMessage: '',
    })
  }

  handleEditedPurchaseOrderDraftSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedPurchaseOrderDraftMessage: '',
    })
  }


  handleProductFoundNotSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      productsFoundOrNotMessage: '',
    })
  }

  handleEditedPoFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedPoFinalPdfDownloadOrNotMessage: '',
    })
  }

  handleLocationSettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationDeleteMessage: '',
    })
  }

  handleLocationSettingsUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationUpdateMessage: '',
    })
  }
  
  handleUnitMeasurementDeleteSettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementDeleteMessage: '',
    })
  }


  handleUnitMeasurementDeleteSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementCreateMessage: '',
    })
  }

  handleUnitMeasurementDeleteSettingsUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementUpdateMessage: '',
    })
  }

  handleUnitMeasurementSettingsDuplicasySnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementSettingsDuplicasyMessage: '',
    })
  }

  handleCategorySettingsAddSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryAddMessage: '',
    })
  }

  handleCategorySettingsUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryUpdateMessage: '',
    })
  }


  handleCategorySettingsDuplicasyErrorSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryDuplicasyErrorMessage : '',
    })
  }


  handleCategorySettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryDeleteMessage: '',
    })
  }

  handleUserSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userCreateMessage: '',
    })
  }



  handlePaymentTermsSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      paymentTermsCreateMessage: '',
    })
  }

  handlePaymentTermsSettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      paymentTermsEditMessage: '',
    })
  }


  handlePaymentTermsSettingsDeletSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      PaymentTermDeleteMessage: '',
    })
  }

  handleTaxationSettingsDeletSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      taxationDeleteMessage: '',
    })
  }

  handleTaxationSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      taxationsCreateMessage: '',
    })
  }

  handleTaxationSettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      taxationEditMessage: '',
    })
  }

  handleUserSettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userDeleteMessage: '',
    })
  }


  handleRfpEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handleRfpFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpFinalPdfDownloadOrNotMessage: '',
    })
  }


  handleRfpUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpUpdateMessage: '',
    })
  }

  handleEditedRfpEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedRfpEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handleLocationPrCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationCreateMessagePr: '',
    })
  }

  handleDeliveryOrDurationDateCheckPrSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      deliveryOrDurationDateCheckMessagePr: '',
    })
  }




  handleLocationExistOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationExistOrNotErrorMessage: '',
    })
  }


  handleUserSettingsPermissionsUpdationSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userSettingPermissionUpdatedMessage: '',
    })
  }

  handleUserSettingsPermissionsCreatedSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userPermissionsCreatedMessage: '',
    })
  }



  handleUserPermissionMissingIdErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userPermissionMissingIdErrorMessage: '',
    })
  }


  handleGetAllReceipts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
  
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleMainModulesSearchByColumnQueryOrOnlyByQuery(`all_receipt`)
  
    const getAllReceiptsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllReceiptsAPICallID = getAllReceiptsApiMsg.messageId;
  
    getAllReceiptsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getAllReceiptsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getAllReceiptsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getAllReceiptsApiMsg.id, getAllReceiptsApiMsg);
  }

  handleGetUserProfile = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getUserProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserProfileAPICallId = getUserProfile.messageId;
  
    getUserProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/user_profile`
    );
  
    getUserProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUserProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getUserProfile.id, getUserProfile);
  }



  handleGetUserModulePermissions = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getUserPermissions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserModulePermissionsAPICallId = getUserPermissions.messageId;
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/view_account`
    );
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getUserPermissions.id, getUserPermissions);
  }


  handleGetUserPermissions = () => {
    const token_localStorage = localStorage.getItem('authToken')
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getUserPermissions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserPermissionsAPICallId = getUserPermissions.messageId;
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/user_permision_details`
    );
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getUserPermissions.id, getUserPermissions);
  }

  isCreateReceiptValidCheck = () => {
    let flag = true;
    if(this.state.receiptNumberUnique === ""){
      this.setState({
        receiptNumberUniqueRequiredField: "Receipt number is not generated *",
      })
      flag=false;
    }
    if(this.state.receiptSelectedPo === ""){
      this.setState({
        receiptSelectedPoRequiredField: "Select the Purchase Order *",
      })
      flag=false;
    }
    if(this.state.receiptReceiptDate === ""){
      this.setState({
        receiptReceiptDateRequiredField: "Enter the Receipt Date *",
      })
      flag=false;
    }
    if(this.state.receiptSelectedPOPopulatedData?.attributes?.supplier?.data?.attributes?.supplier_name === ""){
      this.setState({
        receiptSupplierNameRequiredField: "Failed to Fetch Supplier Name *",
      })
      flag=false;
    }
    if(this.state.receiptInvoiceNumber === ""){
      this.setState({
        receiptInvoiceNumberRequiredField: "Required Invoice Number *",
      })
      flag=false;
    }

    if(this.state.receiptAttachmentFile === ""){
      this.setState({
        receiptAttachmentFileRequiredField: "Select the file *",
      })
      flag=false;
    }
    if(this.state.receiptAttachment === "" ){
      this.setState({
        receiptAttachmentRequiredError : "Select Attachment Type *"
      })

      flag=false
    }

    if(this.state.receiptAttachment !== "" && this.state.receiptAttachmentFile?.name?.split('.').pop().trim() !== this.state.receiptAttachment){
      this.setState({
        receiptAttachmentTypeMatchError : "File attached type not matching with the uploaded file *"
      })

      flag=false
    }

    return flag;
  }

  handleCreateReceipt = () => {
    if(!this.isCreateReceiptValidCheck()){
      this.setState({
        showIsCreateReceiptValidation: true,
        openReceiptCreateShowErrorModal: true
      })
      return 
    }

    

    const catQuantitiesReceived = this.state.receiptCatelogueItemsStore?.map(elem => elem?.quantity_received) ?? []
    const nonCatQuantitiesReceived = this.state.receiptNonCatelogueItemsStore?.map(elem => elem?.quantity_received) ?? []

    const totalQtyReceived = [...catQuantitiesReceived, ...nonCatQuantitiesReceived]
    .map(Number)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);


    const catQuantitiesRequired = this.state.receiptCatelogueItemsStore?.map(elem => elem?.quantity) ?? []
    const nonCatQuantitiesRequired = this.state.receiptNonCatelogueItemsStore?.map(elem => elem?.quantity) ?? []

    const totalQtyRequired = [...catQuantitiesRequired, ...nonCatQuantitiesRequired]
    .map(Number)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);


    if (totalQtyReceived > totalQtyRequired) {
      this.setState({
        receiptQuantityError: 'Received quantity exceeds Required Quantity',
        showIsCreateReceiptValidation: true,
        isReceiptCreating: false,
      });
      return;
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'token': token_localStorage,
    }


    this.setState({isReceiptCreating : true, isReadOnlyReceiptField:true})

    let formdata = new FormData();
    formdata.append("total_quantity_received", JSON.stringify(totalQtyReceived));
    formdata.append("total_quantity_required", JSON.stringify(totalQtyRequired));
    formdata.append("receipt_date", this.state.receiptReceiptDate);
    formdata.append("file", this.state.receiptAttachmentFile);
    formdata.append("pdf_attach", this.state.receiptAttachment);
    formdata.append("purchase_order_number", this.state.receiptSelectedPOPopulatedData?.attributes?.purchase_order_number);
    formdata.append("supplier_id", this.state.receiptSelectedPOPopulatedData?.attributes?.supplier?.data?.id);
    formdata.append("account_id", this.state.receiptSelectedPOPopulatedData?.attributes?.account?.id);
    formdata.append("receipt_invoice_number", this.state.receiptInvoiceNumber);
    formdata.append("receipt_number", this.state.receiptNumberUnique);
    
    if(this.state.receiptCatelogueItemsStore && this.state.receiptCatelogueItemsStore.length > 0){
      for (let i = 0; i < this.state.receiptCatelogueItemsStore.length; i++) {
        formdata.append(`receipt_purchase_order_products_attributes[${i}][product_id]`, this.state.receiptCatelogueItemsStore[i]?.product_id)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][quantity_received]`, this.state.receiptCatelogueItemsStore[i]?.quantity_received)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][quantity_required]`, this.state.receiptCatelogueItemsStore[i]?.quantity)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][comments]`, this.state.receiptCatelogueItemsStore[i]?.comment_received)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][purchase_order_id]`, this.state.receiptCatelogueItemsStore[i]?.purchase_order_id)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][unique_id]`, this.state.receiptCatelogueItemsStore[i]?.id)
      }
    }

    if(this.state.receiptNonCatelogueItemsStore && this.state.receiptNonCatelogueItemsStore.length > 0){
      for (let i = 0; i < this.state.receiptNonCatelogueItemsStore.length; i++) {
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][non_cat_id]`, this.state.receiptNonCatelogueItemsStore[i]?.id)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][name]`, this.state.receiptNonCatelogueItemsStore[i]?.name)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][quantity]`, this.state.receiptNonCatelogueItemsStore[i]?.quantity)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][quantity_received]`, this.state.receiptNonCatelogueItemsStore[i]?.quantity_received)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][is_non_catalogue_item]`, this.state.receiptNonCatelogueItemsStore[i]?.is_non_catalague_item)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][comments]`, this.state.receiptNonCatelogueItemsStore[i]?.comment_received)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][purchase_order_id]`, this.state.receiptNonCatelogueItemsStore[i]?.purchase_order_id)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][unique_id]`, this.state.receiptNonCatelogueItemsStore[i]?.id)
      }
    }

    
    for (let pair of formdata.entries()) {
      console.log("formdataReceipt",pair[0]+ ', ' + pair[1]);
    }

    const createReceiptApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createReceiptAPICallID = createReceiptApiMsg.messageId;
  
    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'receipt'
    );
  
    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
  
    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(createReceiptApiMsg.id, createReceiptApiMsg);
  }

  isEditReceiptValidCheck = () => {
    let flag = true;
    let fileValidate = this.state.isReceiptFileEdited ? this.state.editedReceiptAttachmentFile?.name?.split('.').pop().trim() : this.state.editedReceiptAttachmentFile?.split('.').pop().trim()
    if(this.state.editedReceiptAttachment?.toLowerCase() != fileValidate?.toString().toLowerCase() && this.state.editedReceiptAttachment !== ""){
      this.setState({
        editedReceiptAttachmentTypeMatchError: "Check the file attachment type"
      })
      flag=false;
    }
    if(this.state.editedReceiptQuantityReceived === ""){
      this.setState({
        editedReceiptQuantityReceivedRequiredField: "Enter the Quantity Received *",
      })
      flag=false;
    }
    if(this.state.editedReceiptQuantityRequested === ""){
      this.setState({
        editedReceiptQuantityRequestedRequiredField: "Enter the Quantity Requested *",
      })
      flag=false;
    }
    if(this.state.editedReceiptReceiptDate === ""){
      this.setState({
        editedReceiptReceiptDateRequiredField: "Enter the Receipt Date *",
      })
      flag=false;
    }
    if(this.state.editedReceiptComments === ""){
      this.setState({
        editedReceiptCommentsRequiredField: "Enter the Comments *",
      })
      flag=false;
    }
    return flag;
  }

  handleShowReceipt = (receiptId: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showReceiptApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showReceiptAPICallId = showReceiptApiMsg.messageId;
  
    showReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `show_receipt/${receiptId}`
    );
  
    showReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showReceiptApiMsg.id, showReceiptApiMsg);
  }

  handleGetCurrencyList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getCurrencyListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getCurrencyListAPICallId = getCurrencyListApiMsg.messageId;
  
    getCurrencyListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/request_for_proposals/currency_codes`
    );
  
    getCurrencyListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getCurrencyListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getCurrencyListApiMsg.id, getCurrencyListApiMsg);
  }

  handleDownloadRFP = async () => {
    const token_localStorage: any = await localStorage.getItem('authToken')
    this.setState({
      rfpFinalPdfDownloadOrNotMessage: ``
    })

    fetch(`${baseURL}/generate_request_for_proposals?request_for_proposals=${this.state.catchRfpIdForPdfCreate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );

        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `RFP_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click()

        this.setState({
          rfpFinalPdfDownloadOrNotMessage: `RFP File Is Downloaded`
        })

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        this.setState({
          rfpFinalPdfDownloadOrNotMessage: error?.message
        })
      });
  }

  handleEditedDownloadRFP = async () => {
    const token_localStorage: any = await localStorage.getItem('authToken')

    this.setState({
      editedRfpFinalPdfDownloadOrNotMessage:'',
      isEditedRFPFileDownloaded: true,
    })

    fetch(`${baseURL}/generate_request_for_proposals?request_for_proposals=${this.state.catchEditedRfpIdForPdfUpdation}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );

        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `RFP_Details-Edited-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
        );

        document.body.appendChild(link);

        link.click()


        this.setState({
          editedRfpFinalPdfDownloadOrNotMessage: `Edited RFP file is downloaded`
        })
        this.setState({
          isEditedRFPFileDownloaded: false,
        })

        link.parentNode.removeChild(link);

      })
      .catch(error => {
        this.setState({
          editedRfpFinalPdfDownloadOrNotMessage: error?.message
        })
      });
  }

  isCreateSupplierValidCheck = (): boolean => {
    let flag = true;
  
    const setErrorState = (field: string, message: string) => {
      this.setState((prevState) => ({
        ...prevState,
        [field]: message,
      }));
      flag = false;
    };
  
    const validateRequiredField = (fieldName: string, field: keyof S, errorState: keyof S) => {
      if (this.state[field] === "") {
        this.setState((prevState: S) => ({
          ...prevState,
          [errorState]: fieldName,
        }));
        flag = false;
      }
    };
    
  
    const validateContactNumber = () => {
      if (this.state.supplierContactNumber === "") {
        setErrorState("supplierContactNumberRequiredField", "Enter the Contact Number *");
      } else if (!this.state.supplierContactNumber.match(/^\d+/) || this.state.supplierContactNumber.length !== 10) {
        setErrorState("supplierContactNumberValidationError", "Enter a valid 10-digit Contact Number");
      }
    };

    const validateEmail = () => {
      if (this.state.supplierEmailAddress === "") {
        setErrorState("supplierEmailAddressRequiredField", "Enter the Email *");
      } else if (!RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zAZ0-9-]+)+.[a-zA-Z]*$/).test(this.state.supplierEmailAddress)) {
        setErrorState("supplierEmailAddressEmailMatchError", "Enter a valid Email");
      }
    };
  
    const validateGSTNumber = () => {
      if (this.state.supplierGSTNumber === "") {
        setErrorState("supplierGstNumberRequiredField", "Enter the GST Number *");
      } else if (this.state.supplierGSTNumber.length < 15 || this.state.supplierGSTNumber.length > 20) {
        setErrorState("supplierValidGstLengthError", "GST number should be between 15-20 characters *");
      }
    };

    const requiredFields :  { fieldName: string; field: keyof S; errorState: keyof S }[] = [
      {
        fieldName: "Enter the Address of Supplier Company *",
        field: "supplierAddressOfSupplierCompany",
        errorState: "supplierAddressOfSupplierCompanyRequiredField",
      },
      {
        fieldName: "Enter the Supplier Name *",
        field: "supplierSupplierName",
        errorState: "supplierNameRequiredField"
      },
      {
        fieldName: "Enter the Point of Contact *",
        field: "supplierPointOfContact",
        errorState: "supplierPointOfContactRequiredField"
      },
      {
        fieldName: "Enter the State / Province *",
        field: "supplierStateProvince",
        errorState: "supplierStateProvinceRequiredField"
      },
      {
        fieldName: "Enter the Zip Code/ Pin Code *",
        field: "supplierZipCodePinCode",
        errorState: "supplierZipCodePinCodeRequiredField"
      },
      {
        fieldName: "Select the Terms of Payment *",
        field: "supplierTermsOfPaymentRequiredField",
        errorState: "supplierTermsOfPaymentRequiredField"
      },
    ];

    for (const { fieldName, field, errorState } of requiredFields) {
      validateRequiredField(fieldName, field, errorState);
    }

    validateContactNumber();
    validateEmail();
    validateGSTNumber();
  
    return flag;
  };
  
  

  handleCreateSupplier = () => {
    if(!this.isCreateSupplierValidCheck()){
      this.setState({
        showIsCreateSupplierValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'token': token_localStorage,
    }

    let formdata = new FormData();
    formdata.append("supplier[supplier_name]", this.state.supplierSupplierName);
    formdata.append("supplier[email_address]", this.state.supplierEmailAddress);
    formdata.append("supplier[contact_number]", this.state.supplierContactNumber);
    formdata.append("supplier[point_of_contact]", this.state.supplierPointOfContact);
    formdata.append("supplier[address_of_supplier_company]", this.state.supplierAddressOfSupplierCompany);
    formdata.append("supplier[gst_number]", this.state.supplierGSTNumber);
    formdata.append("supplier[state]", this.state.supplierStateProvince);
    formdata.append("supplier[zip_code]", this.state.supplierZipCodePinCode);


    for (const paymentTerm of this.state.paymentTermsNameSuppliers) {
      formdata.append(`supplier[payment_term_ids][]`, paymentTerm);
    }

    
    const createSupplierApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createSupplierAPICallId = createSupplierApiMsg.messageId;
  
    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "add_supplier"
    );
  
    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createSupplierApiMsg.id, createSupplierApiMsg); 
  }

  isEditSupplierValidCheck = () => {
    let flag = true;
    if(this.state.editedSupplierAddressOfSupplierCompany === ""){
      this.setState({
        editedSupplierAddressOfSupplierCompanyRequiredField: "Enter the Address of Supplier Company *",
      })
      flag=false;
    }
    if(this.state.editedSupplierContactNumber === ""){
      this.setState({
        editedSupplierContactNumberRequiredField: "Enter the Contact Number *",
      })
      flag=false;
    }
    if((this.state.editedSupplierContactNumber && this.state.editedSupplierContactNumber.length > 10) || (this.state.editedSupplierContactNumber && this.state.editedSupplierContactNumber.length < 10)){
      this.setState({
        editedSupplierContactNumberMatchError: "Contact Number should be 10 digits *",
      })
      flag=false;
    }
    if(this.state.editedSupplierEmailAddress === ""){
      this.setState({
        editedSupplierEmailAddressRequiredField: "Enter the email *",
      })
      flag=false;
    }

    if(!this.state.editedSupplierEmailAddress.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        editedSupplierEmailAddressEmailMatchError: "Enter valid Email",
      })
      flag=false;
    }
    if(this.state.editedSupplierGSTNumber === ""){
      this.setState({
        editedSupplierGstNumberRequiredField: "Enter the GST Number *",
      })
      flag=false;
    }
    if(this.state.editedSupplierStateProvince === ""){
      this.setState({
        editedSupplierStateProvinceRequiredField: "Enter the State *",
      })
      flag=false;
    }
    if(this.state.editedSupplierZipCodePinCode === ""){
      this.setState({
        editedSupplierZipCodePinCodeRequiredField: "Enter the Zip Code *",
      })
      flag=false;
    }
    if(this.state.editedSupplierSupplierName === ""){
      this.setState({
        editedSupplierNameRequiredField: "Enter the Supplier Name *",
      })
      flag=false;
    }
    if(this.state.editedSupplierPointOfContact === ""){
      this.setState({
        editedSupplierPointOfContactRequiredField: "Enter the Point of Contact *",
      })
      flag=false;
    }
    if(this.state.editedPaymentTermsNameSuppliers?.length === 0){
      this.setState({
        editedSupplierTermsOfPaymentRequiredField: "Select the Terms of Payment *",
      })
      flag=false;
    }
    if(this.state.editedSupplierGSTNumber && this.state.editedSupplierGSTNumber.length < 15 || this.state.editedSupplierGSTNumber.length > 20){
      this.setState({
        editedSupplierValidGstLengthError: "GST number should b/w 15-20 characters *",
      })
      flag=false;
    }
    return flag;
  }

  handleEditSupplierapi = () => {
    if(!this.isEditSupplierValidCheck()){
      this.setState({
        showIsEditSupplierValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'token': token_localStorage,
    }

    let formdata = new FormData();
    formdata.append("supplier[gst_number]", this.state.editedSupplierGSTNumber);
    formdata.append("supplier[email_address]", this.state.editedSupplierEmailAddress);
    formdata.append("supplier[contact_number]", this.state.editedSupplierContactNumber);
    formdata.append("supplier[address_of_supplier_company]", this.state.editedSupplierAddressOfSupplierCompany);
    formdata.append("supplier[supplier_name]", this.state.editedSupplierSupplierName);
    formdata.append("supplier[point_of_contact]", this.state.editedSupplierPointOfContact);
    formdata.append("supplier[state]", this.state.editedSupplierStateProvince);
    formdata.append("supplier[zip_code]", this.state.editedSupplierZipCodePinCode);

    for (const paymentTerm of this.state.editedPaymentTermsNameSuppliers) {
      formdata.append(`supplier[payment_term_ids][]`, paymentTerm);
    }

    
    const editSupplierApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSupplierAPICallID = editSupplierApiMsg.messageId;
  
    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_supplier/${this.state.editedSupplierId}`
    );
  
    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editSupplierApiMsg.id, editSupplierApiMsg); 
  }

  handleShowSupplier = (supplierId: string) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showSupplierApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSupplierAPICallId = showSupplierApiMsg.messageId;
  
    showSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `show_supplier/${supplierId}`
    );
  
    showSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSupplierApiMsg.id, showSupplierApiMsg);
  }

  isCreateProductValidCheck = () => {
    let flag = true;
    if(this.state.productCategoriesList?.length === 0){
      this.setState({
        productCategoryRequiredField: "Select the Product Category *",
      })
      flag=false;
    }
    if(this.state.productName === ""){
      this.setState({
        productNameRequiredField: "Enter the Product Name *",
      })
      flag=false;
    }
    if(this.state.productTypeSelectedId === ""){
      this.setState({
        productTypeRequiredField: "Enter the Product Type *",
      })
      flag=false;
    }
    if(this.state.productUnitMeasurement === ""){
      this.setState({
        productUnitMeasurementRequiredField: "Enter the Unit Measurement *",
      })
      flag=false;
    }
    if(this.state.productUnitPrice === ""){
      this.setState({
        productUnitPriceRequiredField: "Enter the Unit Price *",
      })
      flag=false;
    }
    if(this.state.productUnitPrice && !this.state.productUnitPrice.match(/^\d+$/)){
      this.setState({
        productUnitPriceMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.addItemSupplierMultiple.length === 0){
      this.setState({
        productSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.productCategory.length===0){
      this.setState({
        productCategoryRequiredField: "Select the Category *",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateProduct = () => {
    if(!this.isCreateProductValidCheck()){
      this.setState({
        showIsCreateProductValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    this.getAllSuppliersList()

    const httpBody = {
      data: {
        attributes: {
          item_id: this.state.addItemSupplierNoUnique,
          name: this.state.productName,
          unit_of_measure_setting_id: this.state.productUnitMeasurement,
          unit_price: this.state.productUnitPrice,
          item_type: this.state.productTypeSelectedId,
          category_setting_id: this.state.productCategory,
          supplier_ids:this.state.addItemSupplierMultiple
        }
      }
    };


    const createProductApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createProductAPICallId = createProductApiMsg.messageId;
  
    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/products"
    );
  
    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createProductApiMsg.id, createProductApiMsg); 
  }

  isEditProductValidCheck = () => {
    let flag = true;
    if(this.state.editedProductCategory === ""){
      this.setState({
        editedProductCategoryRequiredField: "Select the Product Category *",
      })
      flag=false;
    }
    if(this.state.editedProductDescription === ""){
      this.setState({
        editedProductDescriptionRequiredField: "Enter the Description *",
      })
      flag=false;
    }
    if(this.state.editedProductName === ""){
      this.setState({
        editedProductNameRequiredField: "Enter the Product Name *",
      })
      flag=false;
    }
    if(this.state.editedProductPartId === ""){
      this.setState({
        editedProductPartIdRequiredField: "Enter Part ID *",
      })
      flag=false;
    }
    if(this.state.editedProductTypeSelectedId === ""){
      this.setState({
        editedProductTypeRequiredField: "Enter the Product Type *",
      })
      flag=false;
    }
    if(this.state.editedProductUnitMeasurement === ""){
      this.setState({
        editedProductUnitMeasurementRequiredField: "Enter the Unit Measurement *",
      })
      flag=false;
    }
    if(this.state.editedProductUnitPrice === ""){
      this.setState({
        editedProductUnitPriceRequiredField: "Enter the Unit Price *",
      })
      flag=false;
    }
    if(this.state.editedProductUnitPrice && !this.state.editedProductUnitPrice.match(/^\d+$/)){
      this.setState({
        editedProductUnitPriceMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.editedItemSupplierMultiple.length === 0){
      this.setState({
        editedSupplierMultipleRequiredField: "Select Supplier"
      })
      flag=false;
    }
    return flag;
  }

  handleEditProduct = () => {
    if(!this.isEditProductValidCheck()){
      this.setState({
        showIsEditProductValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          type: "product_creation", 
          name: this.state.editedProductName,
          unit_of_measure_setting_id: this.state.editedProductUnitMeasurement,
          unit_price: this.state.editedProductUnitPrice,
          category_setting_id: this.state.editedProductCategory,
          item_type: this.state.editedProductTypeSelectedId,
          supplier_ids: this.state.editedItemSupplierMultiple,
        }
      }
    };

    const editProductApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editProductAPICallId = editProductApiMsg.messageId;
  
    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/products/${this.state.editedProductId}`
    );
  
    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editProductApiMsg.id, editProductApiMsg);
  }

  handleShowProduct = (productId: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showProductApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showProductAPICallId = showProductApiMsg.messageId;
  
    showProductApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/products/${productId}`
    );
  
    showProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showProductApiMsg.id, showProductApiMsg);
  } 

  handleGetAllBudgets = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showBudgetAPICallId = getBudgetApiMsg.messageId;
  
    getBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/show_budgets/${this.state.editedDepartment}`
    );
  
    getBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getBudgetApiMsg.id, getBudgetApiMsg);
  }

  isCreateBudgetValidCheck = () => {
    let flag = true;
    if(this.state.budgetAmount === ""){
      this.setState({
        budgetAmountRequiredField: "Enter the Amount *",
      })
      flag=false;
    }
    if(this.state.startingFromDate === ""){
      this.setState({
        startingFromDateRequiredField: "Enter the Starting Date *",
      })
      flag=false;
    }
    if(this.state.budgetAmount === ""){
      this.setState({
        budgetAmountMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.budgetName === ""){
      this.setState({
        budgetNameRequiredField: "Enter for Budget Name *",
      })
      flag=false;
    }
    if(this.state.budgetPeriod === ""){
      this.setState({
        budgetPeriodRequiredField: "Enter the Budget Period *",
      })
      flag=false;
    }
    if(this.state.budgetProductCategories === ""){
      this.setState({
        budgetProductCategoriesRequiredField: "Enter the Product Category *",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateBudget = () => {
    if(!this.isCreateBudgetValidCheck()){
      this.setState({
        showIsCreateBudgetValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      budget: {
        "type": "budget_creation", 
        starting_from: this.state.startingFromDate,
        name: this.state.budgetName,
        amount: this.state.budgetAmount,
        period: this.state.budgetPeriod,
        comment: this.state.budgetComment,
        category_setting_id: this.state.budgetProductCategories,
      }
    };

    const createBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createBudgetAPICallId = createBudgetApiMsg.messageId;
  
    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budgets`
    );
  
    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createBudgetApiMsg.id, createBudgetApiMsg);
  }

  isEditBudgetValidCheck = () => {
    let flag = true;
    if(this.state.editedBudgetAmount === ""){
      this.setState({
        editedBudgetAmountRequiredField: "Enter the Amount *",
      })
      flag=false;
    }
    if(this.state.editedBudgetAmount && !this.state.editedBudgetAmount.match(/^\d+$/)){
      this.setState({
        editedBudgetAmountMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.editedBudgetComment === ""){
      this.setState({
        editedBudgetCommentRequiredField: "Enter the Comment *",
      })
      flag=false;
    }
    if(this.state.editedBudgetForSelectedId === ""){
      this.setState({
        editedBudgetForRequiredField: "Enter Budget For *",
      })
      flag=false;
    }
    if(this.state.editedBudgetName === ""){
      this.setState({
        editedBudgetNameRequiredField: "Enter for Budget Name *",
      })
      flag=false;
    }
    if(this.state.editedBudgetPeriod === ""){
      this.setState({
        editedBudgetPeriodRequiredField: "Enter the Budget Period *",
      })
      flag=false;
    }
    if(this.state.editedBudgetProductCategories === ""){
      this.setState({
        editedBudgetProductCategoriesRequiredField: "Enter the Product Category *",
      })
      flag=false;
    }
    return flag;
  }

  handleEditBudget = () => {
    if(!this.isEditBudgetValidCheck()){
      this.setState({
        showIsEditBudgetValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          type: "budget_creation",
          starting_from: this.state.editedBudgetStartingDate,
          name: this.state.editedBudgetName,
          product_category_id: this.state.editedBudgetProductCategories,
          period: this.state.editedBudgetPeriod, 
          amount: this.state.editedBudgetAmount,
          comment: this.state.editedBudgetComment,
        }
      }
    };

    const editBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editBudgetAPICallId = editBudgetApiMsg.messageId;
  
    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budgets/${this.state.editedBudgetId}`
    );
  
    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editBudgetApiMsg.id, editBudgetApiMsg);
  }

  isEditLocationSettingsValidCheck = () => {
    let flag=true;

    if(this.state.editSettingsLocation === ""){
      this.setState({
        editedLocationRequiredError: "Enter location *",
      })
      flag=false;
    }
    if(this.state.editSettingsLocation && this.state.editSettingsLocation.length <= 1){
      this.setState({
        editedLocationCharError: "Location name seems wrong! must be more than one character*",
      })
      flag=false;
    }
    if(this.state.editSettingsAddress === ""){
      this.setState({
        editedLocationAddressRequiredError: "Enter Location Address *",
      })
      flag=false;
    }
    if(this.state.editSettingsIdentificationCode === ""){
      this.setState({
        editedLocationIdentityCodeRequiredError: "Enter Location IdentificationCode *",
      })
      flag=false;
    }
    if(this.state.editSettingsGst && this.state.editSettingsGst.length < 15 || this.state.editSettingsGst.length > 20){
      this.setState({
        editedLocationValidGstLengthError: "GST number should b/w 15-20 characters *",
      })
      flag=false;
    }
    return flag;
  }

  handleUpdateSettingsLocation = () => {
    if(!this.isEditLocationSettingsValidCheck()){
      this.setState({
        editSaveLocationsValidation: true,
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      location_setting: {
        name_of_location: this.state.editSettingsLocation,
        location_identification_code: this.state.editSettingsIdentificationCode,
        adderss: this.state.editSettingsAddress,
        gst_number: this.state.editSettingsGst
    }
    };

    const editsettingsLocationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSettingsLocationAPICallId = editsettingsLocationApiMsg.messageId;
  
    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings/${this.state.editSettingsId}`
    );
  
    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editsettingsLocationApiMsg.id, editsettingsLocationApiMsg);
  }

  isEditCategorySettingsValidCheck = () => {
    let flag = true
    if(this.state.editSettingsCategoryName === ""){
      this.setState({
        editSettingsCategoryRequiredField: "Select the Category *",
      })
      flag=false;
    }
    return flag
  }

  handleUpdateSettingsCategoryData = () => {
    if(!this.isEditCategorySettingsValidCheck()){
      this.setState({categorySettingUpdation: true});
      return true
    }

    const token_localStorage = localStorage.getItem('authToken') 

    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      categories_setting:{
        name: this.state.editSettingsCategoryName
    }
    };

    const editsettingsCategoryApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSettingsCategoryAPICallId = editsettingsCategoryApiMsg.messageId;
  
    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings/${this.state.editSettingsCategoryId}`
    );
  
    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editsettingsCategoryApiMsg.id, editsettingsCategoryApiMsg);
  }

  handleUpdateSettingsUnitMeasurementData = () => {

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      unit_of_measure_settings:{
        unit_of_measurment: this.state.editSettingsUnitMeasurement
    }
    };

    const editsettingsUnitMeasurementApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSettingsUnitMeasurementAPICallId = editsettingsUnitMeasurementApiMsg.messageId;
  
    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/unit_of_measure_settings/${this.state.editSettingsUnitMeasurementId}`
    );
  
    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editsettingsUnitMeasurementApiMsg.id, editsettingsUnitMeasurementApiMsg);
  }

  handleShowBudget = (budgetId: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showBudgetAPICallId = showBudgetApiMsg.messageId;
  
    showBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budgets/${budgetId}`
    );
  
    showBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showBudgetApiMsg.id, showBudgetApiMsg);
  } 

  handleShowSettingsLocation = (data: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showSettingsLocationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsLocationAPICallId = showSettingsLocationApiMsg.messageId;
  
    showSettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings/${data}`
    );
  
    showSettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showSettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsLocationApiMsg.id, showSettingsLocationApiMsg);
  } 

  
  
  

  handleInformationSettingsEmailNested = (flag : boolean) => {
    if(this.state.informationSettingsEmail && !RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)?.exec(this.state.informationSettingsEmail)){
      this.setState({
        informationSettingsEmailMatchError: "Enter a valid email *",
      })
      flag=false;
    }
    if(this.state.informationSettingsSelectInfoSize < this.state.informationSettingNoOfPeopleWhoWillAccess){
      this.setState({
        informationSettingsNoPeopleLengthValidationField : "Should be less than or equal to organization size *"
      })
      flag=false;
    }
    if(!this.state.informationSettingNoOfPeopleWhoWillAccess){
      this.setState({
        informationSettingsNoPeopleRequiredField : "Enter No Of people Who Will Access *"
      })
      flag=false;
    }
    if(!this.state.informationSettingsGstNumber){
      this.setState({
        informationSettingsGSTNumberRequiredField: "Enter GST Number *",
      })
      flag=false;
    }
    if(!this.state.informationSettingsSelectInfoSize){
      this.setState({
        informationSettingsSelectInfoSizeRequiredField : "Enter Organization Size *"
      })
      flag=false;
    }
    if((this.state.informationSettingsPhoneNumber && this.state.informationSettingsPhoneNumber?.length > 10) || (this.state.informationSettingsPhoneNumber !=='' && this.state.informationSettingsPhoneNumber?.length < 10)){
      this.setState({
        informationSettingsPhoneNumberDigitLimitError: "Contact Number is wrong *",
      })
      flag=false;
    }

    return flag
  }


  isCreateInformationValidCheck = () => {
    let flag = true;

    const gstNumberRegex = /^[0-9A-Za-z]{15,20}$/;
    
    if(!this.state.informationSettingsCompanyName){
      this.setState({
        informationSettingsComapanyNameRequiredField: "Enter the Company Name *",
      })
      flag=false;
    }
    if(!this.state.informationSettingsAddress){
      this.setState({
        informationSettingsAddressRequiredField: "Enter the Address *",
      })
      flag=false;
    }
    if(!this.state.informationSettingsPhoneNumber){
      this.setState({
        informationSettingsPhoneNumberRequiredField: "Enter the Phone Number *",
      })
      flag=false;
    }
    if(!this.state.informationSettingsPinCode){
      this.setState({
        informationSettingsPinCodeRequiredField: "Enter the PIN code *",
      })
      flag=false;
    }
    if(!this.state.informationSettingsEmail){
      this.setState({
        informationSettingsEmailRequiredField: "Enter the Email *",
      })
      flag=false;
    }
   
    if(this.state.informationSettingsWebsite && !RegExp(/^(https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]*\.[a-zA-Z0-9.]+$/)?.exec(this.state.informationSettingsWebsite)){
      this.setState({
        informationSettingsWebsiteMatchError: "Enter a valid website *"
      })
      flag=false;
    }
    if(this.state.informationSettingsPinCode && !RegExp(/^\d+$/)?.exec(this.state.informationSettingsPinCode)){
      this.setState({
        informationSettingsPinCodeMatchError: "Enter a valid Pin Code *"
      })
      flag=false;
    }
    if (this.state.informationSettingsGstNumber && !gstNumberRegex.test(this.state.informationSettingsGstNumber)) {
      this.setState({
        infoSettingGstLengthError: "GST number should b/w 15-20 characters *",
      })
      flag=false;
    }

    flag = this.handleInformationSettingsEmailNested(flag)

    
    return flag;
  }



  handleInformationsettingAddCancelClose = () => {
    this.setState({showIsCreateInformationValidation: false, 
      showInformation: false, 
      showSettings: true,
      informationSettingEmailInvalidPatternError : false,
      infoSettingContactNumberErrorMessage : false,
    })
  }

  handleCreateInformationSettings = () => {
    if(!this.isCreateInformationValidCheck()){
      this.setState({
        showIsCreateInformationValidation: true,
        informationSettingEmailInvalidPatternError : false,
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {

      company_name: this.state.informationSettingsCompanyName,
      phone_number: this.state.informationSettingsPhoneNumber,
      address: this.state.informationSettingsAddress,
      email: this.state.informationSettingsEmail,
      pin_code: this.state.informationSettingsPinCode,
      website: this.state.informationSettingsWebsite,
      gst_number: this.state.informationSettingsGstNumber,
      organization_size : this.state.informationSettingsSelectInfoSize,
      no_of_people : this.state.informationSettingNoOfPeopleWhoWillAccess

    };


    const createInformationSettingsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createInformationSettingsAPICallId = createInformationSettingsApiMsg.messageId;
  
    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/information_settings/update_information`
    );
  
    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(createInformationSettingsApiMsg.id, createInformationSettingsApiMsg);
  }

  isLocationSettingsValidCheck = () => {
    let flag=true;

    if(this.state.location === ""){
      this.setState({
        locationRequiredError: "Enter location *",
      })
      flag=false;
    }
    if(this.state.location && this.state.location.length <= 1){
      this.setState({
        locationCharError: "Location name must be more than one character *",
      })
      flag=false;
    }
    if(this.state.settingsAddress === ""){
      this.setState({
        locationAddressRequiredError: "Enter Location Address *",
      })
      flag=false;
    }
    if(this.state.settingsIdentificationCode === ""){
      this.setState({
        locationIdentityCodeRequiredError: "Enter Location Identification Code *",
      })
      flag=false;
    }
    if(this.state.settingsGst && this.state.settingsGst.length < 15 || this.state.settingsGst.length > 20){
      this.setState({
        locationValidGstLengthError: "GST number should b/w 15-20 characters *",
      })
      flag=false;
    }
    return flag;
  }

  handleLocationSettings = () => {
    if(!this.isLocationSettingsValidCheck()){
      this.setState({
        saveLocationsValidation: true,
      })
      return
    }
    
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      location_setting: {
        name_of_location: this.state.location,
        location_identification_code: this.state.settingsIdentificationCode,
        adderss: this.state.settingsAddress,
        gst_number: this.state.settingsGst
      }
    };

    const createLocationApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createLocationSettingsAPICall = createLocationApiCall.messageId;
  
    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings`
    );
  
    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createLocationApiCall.id, createLocationApiCall);
  }

  isPostCategorySettingsValidCheck = () => {
    let flag = true
    if(this.state.settingsCategory === ""){
      this.setState({
        settingsCategoryRequiredField: "Select the Category *",
      })
      flag=false;
    }
    return flag
  }

  handlePostCategorySettings = () => {
    if(!this.isPostCategorySettingsValidCheck()){
      this.setState({categorySettingCreatePost: true});
      return true
    }
    const token_localStorage = localStorage.getItem('authToken') 

    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    

    const httpBody = {
      categories_setting:
      {
           name: this.state.settingsCategory,
           serial_number: Math.floor((Math.random()*1000000000)+1)
      }
    };


    const createCategoryApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createCategorySettingsAPICall = createCategoryApiCall.messageId;
  
    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings`
    );
  
    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createCategoryApiCall.id, createCategoryApiCall);
  }

  isPostUnitMeasurementSettingsValidCheck = () => {
    let flag = true
    if(this.state.settingsUnitMeasurement === ""){
      this.setState({
        settingsUnitMeasurementRequiredField: "Enter Unit Measurement *",
      })
      flag=false;
    }
    return flag
  }

  handlePostUnitMeasurementSettings = () => {
    if(!this.isPostUnitMeasurementSettingsValidCheck()){
      this.setState({
        unitMeasurementSettingPost: true
      })
      return true;
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      unit_of_measure_settings:
      {
        unit_of_measurment: this.state.settingsUnitMeasurement,
      }
    };

    const createUnitMeasurementApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createUnitMeasurementSettingsAPICall = createUnitMeasurementApiCall.messageId;
  
    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/unit_of_measure_settings`
    );
  
    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createUnitMeasurementApiCall.id, createUnitMeasurementApiCall);
  }

  

  handleCancelCurrencySettings = () => {
    this.setState({ 
      showIsCreateCurrencyValidation : false,
      showCurrencySettings: false,
      showSettings: true,
      currencySettingsCurrency: '',
    })
  }

  isCreateCurrencyValidCheck = () => {
    let flag = true;
    if(!this.state.currencySettingsCurrency){
      this.setState({
        currencySettingsCurrencyRequiredField: "Enter the Currency",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateCurrencySettings = () => {
    if(!this.isCreateCurrencyValidCheck()){
      this.setState({
        showIsCreateCurrencyValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          currency: this.state.currencySettingsCurrency
        }
      }
    };

    const createCurrencySettingsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createCurrencySettingsAPICallId = createCurrencySettingsApiMsg.messageId;
  
    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/currency_lists/update_currency`
    );
  
    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(createCurrencySettingsApiMsg.id, createCurrencySettingsApiMsg);
  }

  handleGetInformationSettings = () => {
      const token_localStorage = localStorage.getItem('authToken') 
      
      let headers = { 
        'Content-Type': 'application/json',
        'token': token_localStorage,
      }
      
      const getInformationSettingsApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        
    this.getInformationSettingsAPICallId = getInformationSettingsApiMsg.messageId;
    
    getInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/information_settings/show_information`
      );
      
      getInformationSettingsApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
        );
        
        getInformationSettingsApiMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
    );
    
    runEngine.sendMessage(getInformationSettingsApiMsg.id, getInformationSettingsApiMsg);

  }

  handleGetCurrencySettings = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    
    const getCurrencySettingsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.getCurrencySettingsAPICallId = getCurrencySettingsApiMsg.messageId;
      
      getCurrencySettingsApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/currency_lists/show_currency`
    );
  
    getCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getCurrencySettingsApiMsg.id, getCurrencySettingsApiMsg);
  }

  isSaveLocationValidCheck = () => {
    let flag = true;
    this.state.locations.forEach((i: any) => {
      if(i.name_of_location === ""){
        i.name_of_location_validation = 'Enter the Name of the Location'
        flag=false;
      }
      if(i.adderss === ""){
        i.address_validation = 'Enter the Address'
        flag=false;
      }
      if(i.location_identification_code === ""){
        i.location_identification_code_validation = 'Enter the Location Identification Code'
        flag=false;
      }
    })
    return flag;
  }

  selectFewerProperties = (show: any) => {
    const {name_of_location, adderss, location_identification_code} = show;
    return {name_of_location, adderss, location_identification_code};
  }

  handleGetLocations = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getLocationsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getLocationsAPICallId = getLocationsApiMsg.messageId;
  
    getLocationsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings/all_locations`
    );
  
    getLocationsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getLocationsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getLocationsApiMsg.id, getLocationsApiMsg);

  }

  handleDeleteLocation = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const deleteLocationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteLocationAPICallId = deleteLocationApiMsg.messageId;
  
    deleteLocationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `delete_location/${id}`
    );
  
    deleteLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteLocationApiMsg.id, deleteLocationApiMsg);

  }

  handleGetUsers = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleApiParamsForSearchByColumnQueryOrOnlyByQuery(`bx_block_settings/user_settings/all_users`)
  
    const getUsersApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUsersAPICallId = getUsersApiMsg.messageId;
  
    getUsersApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getUsersApiMsg.id, getUsersApiMsg);

  }

  handleDeleteUser = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const deleteUserApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteUserAPICallId = deleteUserApiMsg.messageId;
  
    deleteUserApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/user_settings/${id}`
    );
  
    deleteUserApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteUserApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteUserApiMsg.id, deleteUserApiMsg);

  }

  

  isSaveUsersValidCheck = () => {
    let flag = true;
    if(this.state.userSettingAddUserName === ""){
      this.setState({
        userSettingNameRequiredError : 'Enter the Name *'
      })
      flag=false;
    }
    if(this.state.userSettingAddUserDesignation === ""){
      this.setState({
        userSettingDesignationRequiredError : 'Enter the Designation *'
      })
      flag=false;
    }
    if(this.state.userSettingAddUserEmailId === ""){
      this.setState({
        userSettingEmailRequiredError : 'Enter the Email *'
      })
      flag=false;
    }

    
    if(this.state.userSettingAddUserEmailId && !this.state.userSettingAddUserEmailId.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        userSettingEmailInvalidError : 'Email is invalid *'
      })
      flag=false;
    }

    return flag;
  }

  handleSaveUsers = () => {
    
    const token_localStorage = localStorage.getItem('authToken') 
    
    
    if(!this.isSaveUsersValidCheck()){
      
      this.setState({
        saveUsersValidation: true,
      })
      return
    }
    this.setState({isUserCreating : true})
    this.setState({loading:true})

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      name: this.state.userSettingAddUserName,
      designation : this.state.userSettingAddUserDesignation,
      email : this.state.userSettingAddUserEmailId
    };

    
    const saveUsersApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.saveUsersAPICallId = saveUsersApiMsg.messageId;
  
    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/user_settings/add_user_to_organization`
    );
  
    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(saveUsersApiMsg.id, saveUsersApiMsg);
  }

  getProductCategories = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getProductCategoriesApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getProductCategoriesAPICallId = getProductCategoriesApiMsg.messageId;
  
    getProductCategoriesApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings`
    );
  
    getProductCategoriesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getProductCategoriesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getProductCategoriesApiMsg.id, getProductCategoriesApiMsg);
  }
  handleDeleteCategory = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const deleteCategoryApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteCategoryAPICallId = deleteCategoryApiMsg.messageId;
  
    deleteCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings/${id}`
    );
  
    deleteCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteCategoryApiMsg.id, deleteCategoryApiMsg);

  }
  handleDeleteCategorySuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        categoryDeleteMessage: 'Category is not deleted',
      })
    } else {
      this.setState({
        categoryDeleteMessage: 'Category is deleted',
      })
      this.handleGetDataCategorySettings()
    }
  }

  handleDeleteCategoryAPICallIdResponse = (message:Message) => {
    if (this.deleteCategoryAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteCategorySuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  getDepartmentList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getDepartmentListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getDepartmentListAPICallId = getDepartmentListApiMsg.messageId;
  
    getDepartmentListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/departments`
    );
  
    getDepartmentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getDepartmentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getDepartmentListApiMsg.id, getDepartmentListApiMsg);
  }

  getBudgetList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleMainModulesSearchByColumnQueryOrOnlyByQuery(`bx_block_my_forms/my_forms/budgets`)
  
    const getBudgetListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetListAPICallId = getBudgetListApiMsg.messageId;
  
    getBudgetListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getBudgetListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getBudgetListApiMsg.id, getBudgetListApiMsg);
  }

  handleGetSupplierListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetList API', apiResponse?.errors)
    } else {
      this.setState({
        supplierList: apiResponse?.data,
      })
      if(this.state.supplierList?.length === 0){
        this.setState({supplierFoundOrNotMessage: `Supplier Not Found First Create It`})
      }else{
        this.setState({supplierFoundOrNotMessage: ``})
      }
    }
  }

  handleGetSupplierListAPICallIdResponse = (message:Message) => {
    if (this.getSupplierListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetSupplierListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  getSupplierList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getSupplierListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSupplierListAPICallId = getSupplierListApiMsg.messageId;
  
    getSupplierListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_suppliers`
    );
  
    getSupplierListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getSupplierListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSupplierListApiMsg.id, getSupplierListApiMsg);
  }

  handleGetPaymentTermsItemsPerPageSetSubNestedFunc = () => {
    if(this.state.paymentTermsList?.length>0 && this.state.paymentTermsList?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesPaymentTerms: this.state.paymentTermsList?.length/this.state.itemsPerPage,
        currentPagePaymentTerms : 1,
        payTermsSerialNumber : 0,
      })
    }
    else{
      this.setState({
        noOfPagesPaymentTerms: Math.floor(this.state.paymentTermsList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  
handleSettingsPaymentTermsListNoRecordPopup = (message : string) => { 
  if(this.state.paymentTermsList?.length === 0){
    this.setState({
      settingsNoRecordsFoundPopup : message
    })
  }
}

  
  handleGetPaymentTermsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetList API', apiResponse?.errors)
    } else {
      this.setState({
        paymentTermsList: apiResponse?.data,
        isPaymentTermSettingGotResponseOrNot : true,
      })
      this.handleSettingsPaymentTermsListNoRecordPopup("No Expected Record Found!")
      this.handleGetPaymentTermsItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetPaymentTermsListAPICallIdResponse = (message:Message) => {
    if (this.getPaymentListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetPaymentTermsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  getPaymentTermList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleApiParamsForSearchByColumnQueryOrOnlyByQuery(`bx_block_my_forms/my_forms/payment_terms`)
  
    const getPaymentListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getPaymentListAPICallId = getPaymentListApiMsg.messageId;
  
    getPaymentListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getPaymentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getPaymentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getPaymentListApiMsg.id, getPaymentListApiMsg);
  }

  handleDeletePaymentTermList = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const deletePaymentTermListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deletePaymentTermListAPICallId = deletePaymentTermListApiMsg.messageId;
  
    deletePaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/payment_terms/${id}`
    );
  
    deletePaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deletePaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletePaymentTermListApiMsg.id, deletePaymentTermListApiMsg);

  }

  handleDeletePaymentTermListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        PaymentTermDeleteMessage: 'Payment Term Is Not Deleted',
      
      })
    } else {
      this.setState({
        PaymentTermDeleteMessage: 'PaymentTerm Is Deleted',
      })
      this.getPaymentTermList()
    }
  }

  handleDeletePaymentTermListAPICallIdResponse = (message:Message) => {
    if (this.deletePaymentTermListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeletePaymentTermListSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleShowPaymentTermListViewApi = (show_id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const showPaymentTermListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showViewPaymentTermListAPICallId =  showPaymentTermListApiMsg.messageId;
  
    showPaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/payment_terms/${show_id}`
    );
  
    showPaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showPaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(showPaymentTermListApiMsg.id, showPaymentTermListApiMsg);

  }

  handleShowViewPaymentTermListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for show PaymentTermList API', apiResponse?.errors)
    } else {
      this.setState({
        paymentTermShowViewApiRespObj: apiResponse?.data,
      })
    }
  }

  handleShowViewPaymentTermListAPICallIdResponse = (message:Message) => {
    if (this.showViewPaymentTermListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowViewPaymentTermListSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleShowTaxationListViewApi = (show_id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 


    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const showTaxationListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showViewTaxationApiCallId =  showTaxationListApiMsg.messageId;
  
    showTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/taxations/${show_id}`
    );
  
    showTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(showTaxationListApiMsg.id, showTaxationListApiMsg);
  }

  handleShowViewTaxationSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for show Taxation API', apiResponse?.errors)
    } else {
      this.setState({
        taxationShowViewApiRespObj : apiResponse?.data,
      })
    }
  }

  handleShowViewTaxationListAPICallIdResponse = (message:Message) => {
    if (this.showViewTaxationApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowViewTaxationSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleDeletTaxationModal = (delet_id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const deleteTaxationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteTaxationAPICallId = deleteTaxationApiMsg.messageId;
  
    deleteTaxationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/taxations/${delet_id}`
    );
  
    deleteTaxationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteTaxationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteTaxationApiMsg.id, deleteTaxationApiMsg);

  }

  handleDeleteTaxationSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        taxationDeleteMessage: apiResponse?.errors,
      })
    } else {
      this.setState({
        taxationDeleteMessage: "Taxation Is Deleted",
      })
      this.getTaxationList()
    }
  }

  handleDeleteTaxationAPICallIdResponse = (message:Message) => {
    if (this.deleteTaxationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteTaxationSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }
  
  handleCreatePaymentTermsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        paymentTermsCreateMessage: 'Payment Terms not created',
      })
    } else {
      this.setState({
        paymentTermsName:"",
        creditDays:"",
        showIsCreatePaymentTermsValidation : false,
        paymentTermsCreateMessage: 'Payment Terms created',
        currentPagePaymentTerms: 1,
      })
      this.getPaymentTermList()
    }
  }

  handleCreatePaymentTermsListAPICallIdResponse = (message:Message) => {
    if (this.paymentTermsPostApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePaymentTermsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }
  
  isCreatePaymentTermsValidCheck = () => {
    let flag = true;
    if(this.state.paymentTermsName === ""){
      this.setState({
        paymentTermNameRequiredField: "Enter the PaymentTerms Name",
      })
      flag=false;
    }

    if(this.state.creditDays === ""){
      this.setState({
        paymentTermCreditDaysRequiredField: "Enter the Credit Days",
      })
      flag=false;
    }

    if(this.state.creditDays && !this.state.creditDays.match(/^[0-9 .!?"-]+$/)){
      this.setState({
        paymentTermCreditDaysInvalidMatchRequiredField: "credit days must be in numbers*",
      })
      flag=false;
    }


    return flag;
  }
  
  handleCreatePaymentTermSettings = () => {
    if(!this.isCreatePaymentTermsValidCheck()){
      this.setState({
        showIsCreatePaymentTermsValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      payment_term: {
        payment_term_name: this.state.paymentTermsName,
        credit_days: this.state.creditDays,
        is_activated: null
      }
    };


    const paymentTermsPostApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentTermsPostApiId = paymentTermsPostApi.messageId;

    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/payment_terms"
    );

    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    this.setState({
      openCreateNewModal : false
    })
  
    runEngine.sendMessage(paymentTermsPostApi.id, paymentTermsPostApi);
  }

  handleShowPaymentTermsOpenModal = (paymentTermsId: number) => {
    this.handleShowPaymentTermListViewApi(paymentTermsId)
    this.setState({
      openShowPaymentTermsModal : true
    })
  }

  handleShowPaymentTermsloseModal = () => {
    this.setState({
      openShowPaymentTermsModal : false,
      paymentTermShowViewApiRespObj : {}
    })
  }


  handleShowViewTaxationcloseModal = () => {
    this.setState({
      showViewTaxationSetting: false,
      taxationShowViewApiRespObj : {}
    })
  }

  handleEditPaymentTerms = (editedPaymentTerms: any): void => {
    this.setState({ 
      showEditPaymentTermsSettingField: true,
      showEditPaymentTermsSettingBtn: true,
      editPaymentTermsName: editedPaymentTerms?.attributes?.payment_term_name,
      editCreditDays: editedPaymentTerms?.attributes?.credit_days,
      paymentTermId: editedPaymentTerms?.id
    })
  }

  handleEditPaymentTermsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        paymentTermsEditMessage: 'Payment Terms not updated',
      })
    } else {
      this.setState({
        editPaymentTermsName:"",
        editCreditDays:"",
        showIsEditPaymentTermsValidation : false,
        showIsCreatePaymentTermsValidation:false,
        showEditPaymentTermsSettingField : false,
        paymentTermsEditMessage: 'Payment Terms updated',
      })
      this.getPaymentTermList()
    }
  }

  handleEditPaymentTermsListAPICallIdResponse = (message:Message) => {
    if (this.editPaymentTermsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditPaymentTermsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  isUpdatePaymentTermsValidCheck = () => {
    let flag = true;
    if(this.state.editPaymentTermsName === ""){
      this.setState({
        paymentTermNameRequiredFieldEdit: "Enter the PaymentTerms Name",
      })
      flag=false;
    }
    if(this.state.editCreditDays === ""){
      this.setState({
        paymentTermCreditDaysRequiredFieldEdit: "Enter the Credit Days",
      })
      flag=false;
    }
    return flag;
  }

  handleEditPaymentTermsSettings = () =>{
    if(!this.isUpdatePaymentTermsValidCheck()){
      this.setState({
        showIsEditPaymentTermsValidation: true
      })
      return
    }

    this.setState({
      showEditPaymentTermsSettingBtn: false,
      editCreditDays: '',
      editPaymentTermsName:'',
      currentPagePaymentTerms: 1
    })
    
    const token_localStorage = localStorage.getItem('authToken')
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      payment_term: {
        payment_term_name: this.state.editPaymentTermsName,
        credit_days: this.state.editCreditDays
      }
    };

    const editPaymentTermsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editPaymentTermsApiCallId = editPaymentTermsApiMsg.messageId;

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/payment_terms/${this.state.paymentTermId}`
    );

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(editPaymentTermsApiMsg.id, editPaymentTermsApiMsg);
  }

  handleGetTaxationsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(this.state.taxations?.length>0 && this.state.taxations?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesTaxations: this.state.taxations?.length/this.state.itemsPerPage,
        currentPageTaxations : 1,
        taxSerialNumber: 0,
      })
    }
    else{
      this.setState({
        noOfPagesTaxations: Math.floor(this.state.taxations?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleSettingsTaxationsNoRecordPopup = (message:string) => { 
    if(message){
      this.setState({
        settingsNoRecordsFoundPopup : "No Expected Record Found!"
      })
    }
  }

  handleGetTaxationsListAPICallIdResponse = (message:Message) => {
    if (this.getTaxationListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        taxations: apiResponse?.data,
      }, () => {

        this.handleSettingsTaxationsNoRecordPopup(apiResponse?.message)
        this.handleGetTaxationsListSuccessAndErrorsNestedFunc(apiResponse)
      }
      
      )
    }
  }

  getTaxationList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const finalModifiedAPIURL = this.handleApiParamsForSearchByColumnQueryOrOnlyByQuery(`bx_block_my_forms/my_forms/taxations`)
  
    const getTaxationListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getTaxationListAPICallId = getTaxationListApiMsg.messageId;
  
    getTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalModifiedAPIURL
    );
  
    getTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTaxationListApiMsg.id, getTaxationListApiMsg);
  }

  handleCreateTaxationsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        taxationsCreateMessage: 'Taxation not created',
      })
    } else {
      this.setState({
        showIsCreateTaxationValidation: false,
        taxName : "",
        taxPercentage : "",
        taxationsCreateMessage: 'Taxation created',
      })
      this.getTaxationList()
    }
  }

  handleCreateTaxationAPICallIdResponse = (message:Message) => {
    if (this.createTaxationListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateTaxationsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  isCreateTaxationValidCheck = () => {
    let flag = true;
    if(this.state.taxName === ""){
      this.setState({
        taxNameRequiredField: "Enter the Tax Name",
      })
      flag=false;
    }
    if(this.state.taxPercentage === ""){
      this.setState({
        taxPercentageRequiredField: "Enter the Tax %",
      })
      flag=false;
    }
    return flag;
  }

  createTaxationSettings = () => {
    if(!this.isCreateTaxationValidCheck()){
      this.setState({
        showIsCreateTaxationValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const httpBody = {
      taxation: {
        tax_name: this.state.taxName,
        tax_percentage: this.state.taxPercentage,
        text_type: 'percentage'
      }
    };

    const createTaxationListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createTaxationListAPICallId = createTaxationListApiMsg.messageId;
  
    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/taxations`
    );
  
    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(createTaxationListApiMsg.id, createTaxationListApiMsg);
  }

  handleShowViewTaxationOpenModal = (taxationId: number) => {
    this.handleShowTaxationListViewApi(taxationId)
    this.setState({
      showViewTaxationSetting : true
    })
  }

  handleEditTaxationModal = (editedTaxations: any): void => {
    this.setState({ 
      showEditTaxationEditField: true,
      showEditTaxationSettingBtn: false,
      editTaxName: editedTaxations?.attributes?.tax_name,
      editTaxPercentage: editedTaxations?.attributes?.tax_percentage,
      taxationId: editedTaxations?.id
    })
  }

  handleEditTaxationListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.setState({
        taxationEditMessage: 'Taxation not Updated',
      })
    } else {
      this.setState({
        showEditTaxationSettingBtn: true,
        editTaxName: '',
        editTaxPercentage:'',
        currentPageTaxations: 1,
        showEditTaxationEditField:false,
        taxationEditMessage: 'Taxation Updated',
      })
      
      this.getTaxationList()
    }
  }

  handleEditTaxationAPICallIdResponse = (message:Message) => {
    if (this.editTaxationListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditTaxationListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  isUpdateTaxationValidCheck = () => {
    let flag = true;
    if(this.state.editTaxName === ""){
      this.setState({
        editTaxNameRequiredField: "Enter the Tax Name",
      })
      flag=false;
    }
    if(this.state.editTaxPercentage === ""){
      this.setState({
        editTaxPercentageRequiredField: "Enter the Tax %",
      })
      flag=false;
    }
    return flag;
  }

  
  editTaxationSettings = () => {
    if(!this.isUpdateTaxationValidCheck()){
      this.setState({
        showIsUpdateTaxationValidation: true
      })
      return
    }
  
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const httpBody = {
      taxation: {
        tax_name: this.state.editTaxName,
        tax_percentage: this.state.editTaxPercentage,
      }
    };
  
    const editTaxationListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editTaxationListAPICallId = editTaxationListApiMsg.messageId;
  
    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/taxations/${this.state.taxationId}`
    );
  
    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(editTaxationListApiMsg.id, editTaxationListApiMsg);
  }

  getLocationDropdownList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getLocationDropdownListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getLocationDropdownListAPICallId = getLocationDropdownListApiMsg.messageId;
  
    getLocationDropdownListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings`
    );
  
    getLocationDropdownListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getLocationDropdownListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getLocationDropdownListApiMsg.id, getLocationDropdownListApiMsg);
  }


  handleGetReciptsPurchaseOrdersForSelection = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllReceiptsPurchaseOrdersForSelectionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllReceiptsPurchaseOrdersForSelectionAPICallId = getAllReceiptsPurchaseOrdersForSelectionApiMsg.messageId;
  
    getAllReceiptsPurchaseOrdersForSelectionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "purchase_order_status_open"
    );
  
    getAllReceiptsPurchaseOrdersForSelectionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllReceiptsPurchaseOrdersForSelectionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(getAllReceiptsPurchaseOrdersForSelectionApiMsg.id, getAllReceiptsPurchaseOrdersForSelectionApiMsg);
  }

  handleGetReciptsPoForRemainingQuantity = () => {
    const token_localStorage = localStorage.getItem('authToken')
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllReceiptsPoForRemainingQuantityForQuantityApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllReceiptsPoForRemainingQuantityAPICallId = getAllReceiptsPoForRemainingQuantityForQuantityApiMsg.messageId;
  
    getAllReceiptsPoForRemainingQuantityForQuantityApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "purchase_order_status_partially_received"
    );
  
    getAllReceiptsPoForRemainingQuantityForQuantityApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllReceiptsPoForRemainingQuantityForQuantityApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(getAllReceiptsPoForRemainingQuantityForQuantityApiMsg.id, getAllReceiptsPoForRemainingQuantityForQuantityApiMsg);
  }


  isUserCreatPermissionsValidCheck = () => {
    let flag = true;
    if(this.state.editedCatchIdForUserPermissionsUpdation === ""){
      this.setState({
        userPermissionMissingIdErrorMessage : 'User Permission Id Not Exist. Cant Be Edit!'
      })
      flag=false;
    }

    return flag
  }

  getEditedUserAccessPermissionUpdation = (permissionsId: string) => {
    if(!this.isUserCreatPermissionsValidCheck()){
      this.setState({
        createUserPermissionsValidation: true,
      })
      return
    }

    this.setState({
      isUserSettingPermissionsUpdating : true,
    })

    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      user_permission : {
        permission : this.state.editedSelectedUserPermissions,
        account_id : this.state.editedCatchCoreUserCreatedAccountId,
        user_settings_id : this.state.editedCatchCoreUserCreatedAccountId,
      }
    };
  
    const getUserAccessPermissionApiMsg  = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserAccessPermissionAPICallId = getUserAccessPermissionApiMsg.messageId;
  
    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_user_permission/user_permissions/${permissionsId}`
    );
  
    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(getUserAccessPermissionApiMsg.id, getUserAccessPermissionApiMsg);
  }

  handleSaveUserAccessPermissions = () => {
    this.setState({isUserPermissionsCreating:true})
    const token_localStorage = localStorage.getItem('authToken')

    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
        user_permission: {
          permission : this.state.selectedUserPermissions,
          account_id : this.state.catchUserSettingsCreatedUserId,
          user_settings_id : this.state.catchUserSettingsCreatedUserId,
      }
    }
  

    

    const saveUserAccessPermissionMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.saveUserAccessPermissionAPICallId = saveUserAccessPermissionMsg.messageId;
  
    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_user_permission/user_permissions`
    );
  
    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(saveUserAccessPermissionMsg.id, saveUserAccessPermissionMsg);
  }

  getBudgetForList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetForListApiMsg  = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetForListAPICallId = getBudgetForListApiMsg.messageId;
  
    getBudgetForListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budget_fors`
    );
  
    getBudgetForListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetForListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getBudgetForListApiMsg.id, getBudgetForListApiMsg);
  }

  getRolesList = () => {
    const token_localStorage = localStorage.getItem('authToken')
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getRolesListApiMsg  = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getRolesListAPICallId = getRolesListApiMsg.messageId;
  
    getRolesListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/user_settings/role_list`
    );
  
    getRolesListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getRolesListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getRolesListApiMsg.id, getRolesListApiMsg);
  }

  hasPermission =(permission: string) => {
    const permissions:any = localStorage.getItem('user_permissions')
    if(permissions == "all permission allowed") {
      return true;
    } else {
      const permissionsArr:any[] = JSON.parse(permissions);
      permissionsArr?.map((item: any) => {
        return item.toLowerCase();
      });
      if(permissionsArr?.includes(permission)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkTabOnInit = () => {
    const tabs = ["purchase requisitions", "request for proposal", "purchase orders", "receipts", "supplier and items", "budgets"]
    let flag=true;
    tabs.forEach((item)=>{
      if(this.hasPermission(item) && flag) {
        this.setState({
          subTabValueCof:item,
        }, () => this.handleMainSearchClickFuncCall())
        flag=false;
      }
    })
  }

  checkDashboardTabOnInit = () => {
    const tabsDashboard = ["purchase requisitions", "request for proposal", "purchase orders"]
    let flag=true;
    tabsDashboard.forEach((item)=>{
      if(this.hasPermission(item) && flag) {
        this.setState({
          subTabValueDashboard:item,
        }, () => this.handleMainSearchClickFuncCall())
        flag=false;
      }
    })
  }
  
  handleHeaderAccessPermission = () => {
    const permissions = localStorage.getItem('user_permissions')
    if(permissions == "all permission allowed") {
      return true;
    } 

    const possiblePermissions = ["supplier and items", "purchase orders","request for proposal", "budgets", "purchase requisitions"]
    const permissionsArr = JSON.parse(permissions || "[]");

    for(const permission of possiblePermissions){
      if(permissionsArr.includes(permission)) return true
    }

    return false
  }
  // Customizable Area End

}
