import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isValidPassword: boolean,
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  name: string;
  nameError: string;
  confirmPassword: string;
  showSignUp: boolean;
  showSignUpNext: boolean;
  noOfPeopleAccess: string;
  position: string;
  showProfile: boolean;
  organizationSize: string;
  goal: string;
  ERPSoftware: string;
  showSignUpValidation: boolean;
  matchingConfirmPasswordFieldError: string;
  allPositions: any[];
  allGoals: any[];
  allErpSoftware: any[];
  emailPatternError: string;
  phoneNumberPatternError: string;
  allOrganizationSize: any[];
  validOrganizationSizeInputError: string;
  requiredNoOfPeopleAccessFieldError: string;
  selectedErpSoftware: string;
  selectedOrganizationSize: string;
  requiredPositionFieldError: string;
  requiredGoalFieldError: string;
  requiredOrganizationSizeFieldError: string;
  requiredERPSoftwareFieldError: string;
  profileValidation: boolean;
  selectedPosition: string;
  selectedGoal: string;
  inputTypePassword: string;
  requiredNameFieldError: string;
  requiredEmailFieldError: string;
  requiredPhoneFieldError: string;
  requiredPasswordFieldError: string;
  requiredConfirmPasswordFieldError: string;
  inputTypeConfirmPassword: string;
  emailAlreadyExistError: string;
  phoneNumberAlreadyExistError: string;
  errorMessage: string;
  signUpSuccessMessage: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  signupApiCallId: string = "";
  getAllPositionOptionsAPICallId: string = "";
  getAllGoalOptionsAPICallId: string = "";
  getAllErpSoftwareOptionsAPICallId: string = "";
  getAllOrganizationSizeOptionsAPICallId: string = "";
  submitProfileAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      name: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isValidPassword: false,
      reTypePassword: "",
      nameError: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showSignUp: true,
      showSignUpNext: false,
      position: "",
      showProfile: false,
      organizationSize: "",
      noOfPeopleAccess: "",
      goal: "",
      ERPSoftware: "",
      requiredNameFieldError: "",
      requiredEmailFieldError: "",
      requiredPhoneFieldError: "",
      requiredPasswordFieldError: "",
      requiredConfirmPasswordFieldError: "",
      emailPatternError: "",
      phoneNumberPatternError: "",
      showSignUpValidation: false,
      matchingConfirmPasswordFieldError: "",
      allPositions: [],
      allGoals: [],
      allErpSoftware: [],
      allOrganizationSize: [],
      selectedPosition: "",
      selectedGoal: "",
      selectedErpSoftware: "",
      selectedOrganizationSize: "",
      requiredPositionFieldError: "",
      requiredGoalFieldError: "",
      requiredOrganizationSizeFieldError: "",
      validOrganizationSizeInputError: "",
      requiredNoOfPeopleAccessFieldError: "",
      requiredERPSoftwareFieldError: "",
      profileValidation: false,
      inputTypePassword: "password",
      inputTypeConfirmPassword: "password",
      emailAlreadyExistError: "",
      phoneNumberAlreadyExistError: "",
      errorMessage: "",
      signUpSuccessMessage: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.validationApiCallId) {
        this.validationHandler(responseJson)
      }
      if (apiRequestCallId === this.createAccountApiCallId) {
        this.createAccountResponseHandler(responseJson, errorReponse)
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    this.apiResponseHandler(messageId, message)

    // Customizable Area End
  }

  // Customizable Area Start
  saveLoggedInUserData = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailReg.test(email);
  };

  createAccount = () => {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    const phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentType
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: `+${this.state.countryCodeSelected}${this.state.phone}`
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    this.createAccountApiCallId = this.apiCall(
      configJSON.apiMethodTypeGet,
      configJSON.urlGetValidationsEndpoint,
      header,
      httpBody);

    return true;
  }

  getValidations = () => {
    const headers = {
      "Content-Type": configJSON.contentType
    };

    this.validationApiCallId = this.apiCall(
      configJSON.apiMethodTypeGet,
      configJSON.urlGetValidationsEndpoint,
      headers);
  }

  isNonNullAndEmpty = (value: string) => {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber = (countryCode: string, phoneNumber: string) => {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleNameChange = (event: any) => {
    const newName = event.target.value
    this.setState({
      name: newName,
      nameError: newName.length > 20 ? "Name should be 20 characters or less*" : "",
    })
  };

  handleEmailChange = (event: any) => {
    this.setState({
      email: event.target.value
    })
  }

  handlePhoneChange = (event: any) => {
    this.setState({
      phone: event.target.value
    })
  }

  handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    const isValidPassword = passwordPattern.test(newPassword);
    this.setState({
      password: newPassword,
      isValidPassword: isValidPassword,
    })
  }

  handleConfirmPasswordChange = (event: any) => {
    const text = event.target.value
    this.setState({
      confirmPassword: text,
      showSignUpValidation: text === this.state.password ? false : true
    })
  }

  isValidCheck = () => {
    let flag = true;

    if (this.state.name === "") {
      this.setState({
        requiredNameFieldError: "Required field *"
      })
      flag = false;
    }else if (this.state.name.length > 20 ){
      this.setState({
        requiredNameFieldError: "Name should be 20 characters or less*"
      })
      flag = false;
    }
    if (this.state.email === "") {
      this.setState({
        requiredEmailFieldError: "Required field *"
      })
      flag = false;
    }
    if (!this.state.email.match(configJSON.validEmailRegex)) {
      this.setState({
        emailPatternError: "Enter a valid email"
      })
      flag = false;
    }
    else {
      this.setState({
        emailPatternError: ""
      })
    }
    if (this.state.phone === "") {
      this.setState({
        requiredPhoneFieldError: "Required field *"
      })
      flag = false;
    }
    if (!this.state.phone.match(configJSON.validPhoneNumberRegex)) {
      this.setState({
        phoneNumberPatternError: "Enter a valid phone number"
      })
      flag = false;
    }
    else {
      this.setState({
        phoneNumberPatternError: ""
      })
    }
    if (this.state.password === "") {
      this.setState({
        requiredPasswordFieldError: "Required field *"
      })
      flag = false;
    }
    if (!this.state.isValidPassword) {
      this.setState({
        requiredPasswordFieldError: "Password must meet the criteria*"
      });
      flag = false;
    }
    if (this.state.confirmPassword === "") {
      this.setState({
        requiredConfirmPasswordFieldError: "Required field *"
      })
      flag = false;
    }
    if (this.state.password.toLowerCase() !== this.state.confirmPassword.toLowerCase()) {
      this.setState({
        matchingConfirmPasswordFieldError: "Password did not match *"
      })
      flag = false;
    }
    else {
      this.setState({
        matchingConfirmPasswordFieldError: ""
      })
    }
    return flag;
  }

  handleSignUp = () => {
    if (!this.isValidCheck()) {
      this.setState({
        showSignUpValidation: true
      })
      return
    }

    let headers = {
      'Content-Type': configJSON.contentType
    }

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          user_name: this.state.name,
          email: this.state.email,
          full_phone_number: this.state.phone,
          password: this.state.password
        }
      }
    };

    this.signupApiCallId = this.apiCall(
      configJSON.apiMethodTypePost,
      configJSON.signUpEndpoint,
      headers,
      httpBody)
  }

  handleChangePosition = (e: any) => {
    this.setState({
      position: e.target.value
    })
  }

  handlePasswordVisibilityIcon = () => {
    if (this.state.inputTypePassword === "password") {
      this.setState({
        inputTypePassword: "text"
      })
    }
    if (this.state.inputTypePassword === "text") {
      this.setState({
        inputTypePassword: "password"
      })
    }
  }

  handleConfirmPasswordVisibilityIcon = (event: any) => {
    if (this.state.inputTypeConfirmPassword === "password") {
      this.setState({
        inputTypeConfirmPassword: "text"
      })
    }
    if (this.state.inputTypeConfirmPassword === "text") {
      this.setState({
        inputTypeConfirmPassword: "password"
      })
    }
  }

  handleGetAllPositionOptions = () => {
    const TOKEN = localStorage.getItem('authToken')
    let headers = {
      'Content-Type': configJSON.contentType,
      'token': TOKEN,
    }

    this.getAllPositionOptionsAPICallId = this.apiCall(
      configJSON.apiMethodTypeGet,
      configJSON.allPositionsEndpoint,
      headers);

  }

  apiResponseHandler = (messageId: string, message: Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.signupApiCallId === messageId) {
      this.signupHandler(apiResponse)
    }

  }

  validationHandler = (responseJson: any) => {
    this.arrayholder = responseJson.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  createAccountResponseHandler = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  signupHandler = (apiResponse: any) => {
    if (apiResponse?.errors) {
      this.setState({
        errorMessage: apiResponse?.errors
      })
    } else {
      this.saveLoggedInUserData(apiResponse)
      if (apiResponse?.meta?.token) {
        this.handleGetAllPositionOptions()
      }
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  }

  closeToaster = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      errorMessage: '',
    })
  }

  handleSignUpSuccessMessageSnackbarClose = () => {
    this.setState({
      signUpSuccessMessage: ""
    })
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  apiCall = (method: string, endpoint: string, header?: object, body?: object) => {
    const apiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    apiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(apiMsg.id, apiMsg);
    return apiMsg.messageId
  }

  handleGotoLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  // Customizable Area End
}
