import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Input,
  Button,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { createTheme, withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/Lock'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PeopleIcon from '@material-ui/icons/People';
import AdjustIcon from '@material-ui/icons/Adjust';
import AirplayIcon from '@material-ui/icons/Airplay';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { imgLogo } from "../../email-account-registration/src/assets";
import ToastMessageDisplay from "../../dashboard/src/ToastMessageDisplay";

const StyledSelect = withStyles({
  root: {
    paddingLeft: 15,
    '&:focus': {
      backgroundColor: 'transparent',
    }
  },
})((props: any) => <Select {...props} />);

const StyledTextField = withStyles({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent"
    },
    "& input::placeholder": {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '14',
      fontWeight: 400,
    }
  }
})((props: any) => <TextField {...props} />);
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
  configJSON,
  S
} from "./EmailAccountRegistrationController";
import { colors } from "react-select/src/theme";


interface ShowProfileProps {
  state: S,
  handleSubmitProfile: Function,
  handleGoBack: Function
}

interface SignupFormProps {
  state: S,
  handleNameChange: Function,
  handleEmailChange: Function,
  handlePhoneChange: Function,
  handlePasswordChange: Function,
  handlePasswordVisibilityIcon: Function,
  handleConfirmPasswordChange: Function,
  handleSignUp: Function,
  handleConfirmPasswordVisibilityIcon: Function
}

interface SignUpDetailsFormProps {
  state: S,
  handleChangePosition: Function,
  handleOrganizationSize: Function,
  handleNoOfPeopleAccess: Function,
  handleERPSoftware: Function,
  handleViewProfile: Function,
  handleGoBackToSignUp: Function,
  handleGoal: Function
}

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  signUpStyle = () => {
    if (this.state.showSignUp) {
      return newStyles.contentContainer1
    } 
  }

  renderSignupForm() {
    if (!this.state.showSignUp) {
      return <></>;
    }
    return (
      <Box>
        {this.renderBackButton()}
        {this.renderNameField()}
        {this.renderEmailField()}
        {this.renderPhoneField()}
        {this.renderPasswordField()}
        {this.renderConfirmPasswordField()}
        {this.renderSignUpButton()}
      </Box>
    );
  }

  renderBackButton() {
    return (
      <Box data-test-id="btnBack" style={newStyles.backWithArrow} onClick={this.handleGotoLogin}>
      <ArrowBackIcon style={newStyles.backIcon}/>
      <Typography style={newStyles.backText}>Back</Typography> 
  </Box>
    );
  }
  
  renderNameField() {
    const nameValidationMessage=()=>{
      const {name, showSignUpValidation, nameError } = this.state;
      return name === "" && showSignUpValidation ? this.state.requiredNameFieldError : nameError;
    }

    return(
      <>
      <StyledTextField
          required
          testID="txtInputName"
          InputLabelProps={{ required: true, style: { color: 'black', fontSize: 'medium' }, shrink: true }}
          placeholder={configJSON.placeHolderName}
          variant="filled"
          value={this.state.name}
          onChange={this.handleNameChange}
          style={newStyles.textFields}
          inputProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Plus Jakarta Display'
            }
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: <InputAdornment
              style={styles.textFieldIcon}
              position="start">
              <PersonIcon />
            </InputAdornment>,
          }}
        />
        <Typography
          style={newStyles.validationText}>
          {nameValidationMessage()}
        </Typography>
       </>
    )
  }

  renderEmailField(){
    return(
    <>
    <StyledTextField
    required
    testID="txtInputEmail"
    InputLabelProps={{ style: { color: 'black', fontSize: 'medium' } }}
    placeholder={configJSON.placeHolderEmail}
    variant="filled"
    value={this.state.email}
    onChange={this.handleEmailChange}
    style={newStyles.textFields}
    inputProps={{
      style: {
        fontSize: 14,
        fontFamily: 'Plus Jakarta Display'
      }
    }}
    InputProps={{
      disableUnderline: true,
      startAdornment: <InputAdornment
        style={styles.textFieldIcon}
        position="start">
        <PersonIcon />
      </InputAdornment>,
    }}
  />
  <Typography
    style={newStyles.validationText}>
    {this.state.email === "" && this.state.showSignUpValidation ? this.state.requiredEmailFieldError : ""}
  </Typography>
  <Typography
    style={newStyles.validationText}>
    {this.state.email !== "" && !this.isValidEmail(this.state.email) && "Enter valid Email"}
  </Typography>
  </>
  )
  }

  renderPhoneField() {
    return(
    <>
            <StyledTextField
          required
          testID="txtInputPhone"
          InputLabelProps={{ style: { color: 'black', fontSize: 'medium' } }}
          placeholder={configJSON.placeHolderPhone}
          variant="filled"
          value={this.state.phone}
          onChange={this.handlePhoneChange}
          style={newStyles.textFields}
          inputProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Plus Jakarta Display'
            }
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: <InputAdornment
              style={styles.textFieldIcon}
              position="start">
              <PhoneIcon />
            </InputAdornment>,
          }}
        />
        <Typography
          style={newStyles.validationText}>
          {this.state.phone === "" && this.state.showSignUpValidation ? this.state.requiredPhoneFieldError : ""}
        </Typography>
        <Typography
          style={newStyles.validationText}>
          {this.state.phone !== "" && !this.state.phone.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) && "Enter valid phone number"}
        </Typography>
    </>
    )
  }

  renderPasswordField() {
    return(
      <>
       <StyledTextField
          required
          testID="txtInputPassword"
          InputLabelProps={{ style: { color: 'black', fontSize: 'medium' } }}
          type={this.state.inputTypePassword === "password" ? "password" : "text"}
          placeholder={configJSON.placeHolderPassword}
          value={this.state.password}
          variant="filled"
          onChange={ this.handlePasswordChange}
          style={newStyles.textFields}
          inputProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Plus Jakarta Display'
            }
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: <InputAdornment
              style={styles.textFieldIcon}
              position="start">
              <LockIcon />
            </InputAdornment>,
            endAdornment: <InputAdornment
              data-test-id="btnTogglePasswordIcon"
              style={this.state.password === "" ? styles.textFieldSuffixIconInitially : styles.textFieldSuffixIcon}
              position="end"
              onClick={this.handlePasswordVisibilityIcon}>
              {this.state.inputTypePassword === "password" ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>

          }}
        />
        <Typography
          style={newStyles.validationText}>
          {this.state.password.length < 6 && this.state.password.length > 0 ? "Password must be of at least 6 characters long*" : ""}
          {this.state.password.length >= 6 && !this.state.isValidPassword ? "Password must include one capital letter, one digit, and one special character*" : ""}
        </Typography>
 
      </>
    )
  }

  renderConfirmPasswordField() {
    return(
      <>
       <StyledTextField
          required
          testID="txtInputConfirmPassword"
          InputLabelProps={{ style: { color: 'black', fontSize: 'medium' } }}
          type={this.state.inputTypeConfirmPassword === "password" ? "password" : "text"}
          placeholder={configJSON.placeHolderConfirmPassword}
          value={this.state.confirmPassword}
          variant="filled"
          onChange={this.handleConfirmPasswordChange}
          style={newStyles.textFields}
          inputProps={{
            style: {
              fontSize: 14,
              fontFamily: 'Plus Jakarta Display'
            }
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: <InputAdornment
              style={styles.textFieldIcon}
              position="start">
              <LockIcon />
            </InputAdornment>,
            endAdornment: <InputAdornment
              data-test-id="btnToggleConfirmPasswordIcon"
              style={this.state.confirmPassword === "" ? styles.textFieldSuffixIconInitially : styles.textFieldSuffixIcon}
              position="end"
              onClick={this.handleConfirmPasswordVisibilityIcon}>
              {this.state.inputTypeConfirmPassword === "password" ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          }}
        />
        <Typography
          style={newStyles.validationText}>
          {this.state.confirmPassword === "" && this.state.showSignUpValidation ? this.state.requiredConfirmPasswordFieldError : ""}
        </Typography>
        <Typography
          style={newStyles.validationText}>
          {this.state.showSignUpValidation ? this.state.matchingConfirmPasswordFieldError : ""}
        </Typography>

      </>
    )
  }

  renderSignUpButton() {
    return (
      <Button data-test-id="btnSignUp" onClick={this.handleSignUp} style={newStyles.button} variant="contained">
        {configJSON.btnTextSignUp}
      </Button>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <Box style={newStyles.mainContainer}>
        <ToastMessageDisplay message={this.state.errorMessage} handleClose={this.closeToaster} />
        <ToastMessageDisplay message={this.state.signUpSuccessMessage} duration={1000} handleClose={this.handleSignUpSuccessMessageSnackbarClose} />
        <Box style={newStyles.imageContainer}>
          <img src={imgLogo} style={newStyles.image} />
        </Box>
        <Box style={this.signUpStyle()}>
          {this.renderSignupForm()}
        </Box>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start



const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFF00',
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  }
});

const styles = {
  box: {
    backgroundColor: '#F2F2EC',
  },
  selectBoxLabel: {
    fontFamily: 'myFirstFont',
    color: '#B1B1AE',
    backgroundColor: '#F2F2EC',
    minHeight: '60px',
    borderRadius: '10px',
    paddingLeft: '7px',
  },
  selectBox: {
    backgroundColor: '#F2F2EC',
    minHeight: '60px',
    borderRadius: '10px',
    paddingLeft: '7px',
  },
  textFieldBox: {
    fontFamily: 'myFirstFont',
    backgroundColor: '#F2F2EC',
    minHeight: '40px',
    borderRadius: '10px',
    paddingBottom: '10px',
  },
  textFieldIcon: {
    fontSize: 'small',
    paddingRight: '12px',
  },
  selectIcon: {
    fontSize: 'small',
    paddingLeft: '3px',
    color: 'black',
  },
  textFieldSuffixIconInitially: {
    fontSize: 'small',
    paddingTop: '12px',
    cursor: 'pointer'
  },
  textFieldSuffixIcon: {
    fontSize: 'small',
    paddingTop: '12px',
    cursor: 'pointer'
  },
  validationText: {
    textAlign: 'right' as const,
    position: 'relative' as const,
    width: '103px',
    height: '16px',
    opacity: '1',
    margin: 'auto',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    boxSizing: 'border-box' as const,
  },
}

const newStyles = {
  imageContainer: {
    position: 'relative' as const,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '10px solid #f2f2ec',
    borderRadius: '15px',
    zIndex: 1,
    boxSizing: 'border-box' as const,
    flex: '1',
    flexWrap: 'nowrap' as const,
    flexDirection: 'row' as const,
    overflow: 'hidden',
    margin: 'auto',
    height: '130px',
    marginTop: '10px',
    opacity: '1',
    width: '264px',
  },
  mainContainer: {
    position: 'absolute' as const,
    backgroundColor: 'rgba(242, 242, 236, 1)',
    flex: '1',
    alignItems: "flex-start",
    boxSizing: 'border-box' as const,
    width: '100%',
    top: '25px',
    height: 'auto',
  },
  contentContainer1: {
    position: 'relative' as const,
    width: '700px',
    margin: '0 auto',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '10px',
    zIndex: 0,
    boxSizing: 'border-box' as const,
    paddingTop: '90px',
    paddingBottom: '50px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as const,
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
  },
  contentContainer2: {
    position: 'relative' as const,
    width: '700px',
    margin: '0 auto',
    height: '650px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '10px',
    zIndex: 0,
    boxSizing: 'border-box' as const,
    paddingTop: '90px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as const,
    flexDirection: 'column' as const,
    alignItems: 'center',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
    justifyContent: 'center',
  },
  contentContainer3: {
    position: 'relative' as const,
    width: '700px',
    margin: '0 auto',
    height: '600px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '15px',
    zIndex: 0,
    boxSizing: 'border-box' as const,
    paddingTop: '90px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as const,
    flexDirection: 'column' as const,
    alignItems: 'center',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
    justifyContent: 'center',
  },
  image: {
    position: 'relative' as const,
    width: '176px',
    height: '54px',
    marginLeft: '32px',
    marginTop: '30px',
    opacity: '1',
    resizeMode: 'contain',
    boxSizing: 'border-box' as const,
  },
  textFields: {
    backgroundColor: '#F2F2EC',
    width: '328px',
    minHeight: '56px',
    borderRadius: '10px',
    paddingBottom: '10px',
    margin: '0 auto',
    position: 'relative' as const,
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: 300,
    boxSizing: 'border-box' as const,
  },
  selectBox: {
    backgroundColor: '#F2F2EC',
    width: '324px',
    minHeight: '60px',
    borderRadius: '10px',
    paddingLeft: '7px',
    paddingBottom: '10px',
    paddingTop: '15px',
    margin: '0 auto',
    position: 'relative' as const,
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: 300,
    boxSizing: 'border-box' as const,
  },
  selectBoxLabel: {
    color: '#B1B1AE',
    backgroundColor: '#F2F2EC',
    width: '328px',
    minHeight: '60px',
    borderRadius: '10px',
    paddingLeft: '7px',
    paddingBottom: '10px',
    paddingTop: '15px',
    margin: '0 auto',
    position: 'relative' as const,
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: 300,
    boxSizing: 'border-box' as const,
  },
  validationText: {
    textAlign: 'right' as const,
    position: 'relative' as const,
    width: '328px',
    margin: '0 auto',
    marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'red',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12px',
    fontStyle: 'italic' as const,
    fontWeight: 300,
    boxSizing: 'border-box' as const,
  },
  button: {
    backgroundColor: '#FFFF00',
    width: '328px',
    height: '56px',
    padding: '5px',
    margin: 'auto',
    marginTop: '16px',
    marginLeft: '0px',
    marginBottom: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    textTransform: 'none' as const,
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as const,
  },
  tableButton: {
    backgroundColor: '#FFFF00',
    width: '324px',
    height: '56px',
    padding: '5px',
    margin: 'auto',
    marginTop: '16px',
    marginLeft: '60px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    textTransform: 'none' as const,
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as const,
  },
  buttonText: {
    position: 'absolute',
    width: '104',
    height: '16',
    marginLeft: '16',
    marginTop: '14',
    opacity: '1',
    backgroundColor: 'transparent',
    includeFontPadding: false,
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as const,
  },
  table: {
    marginTop: '-40px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  dataCell: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    color: 'black',
    fontWeight: 'bolder' as const,
    textAlign: 'right' as const,
  },
  placeholderCell: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    color: '#ACACAC',
  },
  backWithArrow: {
    display: 'flex',
    alignItems: 'center',
    cursor:"pointer"
  },
  arrow: {
    display: 'flex',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: 600,
  },
  backIcon: {
    width: '20px',
    height: '14px',
  },
  backText: {
    width: '32px',
    height: '17px',
    color: '#1c1c1a',
    fontFamily: "Plus Jakarta Display",
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '-0.15px',
  },
}
// Customizable Area End
