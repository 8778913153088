Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Enter Email *";
exports.placeHolderPhone = "Enter Phone Number *"
exports.placeHolderName = "Enter Your Name *";
exports.placeHolderPassword = "Enter Password *";
exports.placeHolderConfirmPassword = "Confirm Password *";
exports.btnTextSignUp = "Sign Up";

exports.placeHolderPosition = "Select Your Position *";
exports.placeHolderOrganizationSize = "Select Organization Size *";
exports.placeHolderNoOfPeopleAccess = "No. of People who will access *";
exports.placeHolderGoal = "Goal for using Sourceasy *";
exports.placeHolderERPSoftware = "Which ERP Software do you use? *";
exports.btnTextNext = "Next";
exports.btnTextBack = "Back"

exports.name = "Name"
exports.emailOrPhone = "Email Or Phone"
exports.positionInCompany = "Position In Company"
exports.organizationSize = "Organization Size"
exports.ERPSoftware = "ERP Software You Use"
exports.btnTextSubmit = "Submit"

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";

// Header
exports.contentType = "application/json";

// Endpoints
exports.accountsAPiEndPoint = "account/accounts";
exports.updateProfileEndpoint= "update_profile"
exports.allOrganizationSizeEndpoint= "all_organization_size"
exports.ERPSoftwareEndpoint= "/all_erp"
exports.allGoalEndpoint= "/all_goal"
exports.urlGetValidationsEndpoint = "profile/validations";
exports.allPositionsEndpoint= "/all_position"
exports.signUpEndpoint= "account_block/accounts"

// Methods
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePut= "PUT"

// Regex
exports.validEmailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
exports.validPhoneNumberRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// Customizable Area End
