 import React from "react";

// Customizable Area Start
import { 
  Paper,
  Container, 
  Box, 
  TextField,
  Input,  
  Button,
  InputLabel,
  Typography, 
  InputAdornment, 
  IconButton,
  Grid,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Link from '@material-ui/core/Link'
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { imgLogo } from "../../email-account-login/src/assets";
import ToastMessageDisplay from "../../dashboard/src/ToastMessageDisplay";

const StyledTextField = withStyles({
  root: {
    //fontFamily: 'PlusJakartaDisplay-Regular',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '14',
      fontWeight: 400,
    }
  }
})((props: any) => <TextField {...props} />);

// Customizable Area End

import ForgotPasswordController, {
    Props, 
    configJSON
  } from "./EmailAccountLoginController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  newpasswordVisibleStyle = () =>{
    return this.state.newPassword===""? styles.textFieldIconInitially : styles.textFieldIcon
  }

  forgotEmailPwdErrorFunc = () => {
    if(this.state.showForgotPasswordEmailSubmitValidation && this.state.emailForForgotPassword===""){
      return (
        this.state.requiredEmailForForgotPasswordFieldError
      )
    } 
    else if ( this.state.showForgotPasswordEmailSubmitValidation && this.state.emailForForgotPassword!=="" && !this.state.emailForForgotPassword?.match(configJSON.emailRegExp) && this.state.emailForForgotPasswordPatternError) {
      return (
        this.state.emailForForgotPasswordPatternError
      )
    } else{return ""}
  }

  otpWrongInputErrorFunc = () => {
    if (this.state.showOtpSubmitValidation && this.state.otpEntered===""){
      return (
        this.state.requiredOtpFieldError
      )
    }else {return ""}
  }
  // Customizable Area End

  render() {
    //const { navigation } = this.props;
    return (
      // Customizable Area Start
        
        <Box style={newStyles.mainContainer}>
          <ToastMessageDisplay message={this.state.loginFailedError} handleClose={this.handleLoginFailedErrorMessageSnackbarClose}/>
          <Box style={newStyles.imageContainer}>
            <img src={imgLogo} style={newStyles.image}/>
          </Box>
          {/* <Box style={this.state.showSetNewPasswordScreen ? newStyles.contentContainer : newStyles.contentContainerSmall}> */}
          <Box style={newStyles.contentContainer}>
            
          {this.state.showForgotPasswordEmailScreen && <Box>
            <Box data-test-id="btnBack" style={newStyles.backWithArrow} onClick={this.handleGotoLogin}>
                <ArrowBackIcon style={newStyles.backIcon}/>
                <Typography style={newStyles.backText}>Back</Typography> 
            </Box>
            <StyledTextField
                required
                testID="txtInputEmail"
                style={styles.textFieldBox}
                //InputLabelProps={{style : {color : 'black'}}} 
                inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                }}}
                type="text" 
                placeholder={configJSON.placeHolderEmail} 
                value={this.state.emailForForgotPassword} 
                variant="filled" 
                onChange={this.handlePhoneOrEmailForForgotPasswordChange}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: 
                    <InputAdornment 
                        style={this.newpasswordVisibleStyle()} 
                        position="start">
                          <PersonIcon/>
                    </InputAdornment>,
                }}
            />
            <Typography data-testid={"wrongEmailErrorMsg"} style={newStyles.validationText}>{this.forgotEmailPwdErrorFunc()}</Typography>
            <Button data-test-id="btnForgotPasswordSubmit" style={styles.button} onClick={this.handleForgotPasswordEmailSubmit} variant="contained">{configJSON.btnForgotEmailSubmit}</Button>
          </Box>}

          {this.state.showOtpScreen && <Box>
            <Box style={newStyles.backWithArrow} onClick={this.handleEnterForgotPassword}>
                <ArrowBackIcon style={newStyles.backIcon}/>
                <Typography style={newStyles.backText}>Back</Typography> 
            </Box>
            <StyledTextField
                required
                testID="txtInputOtp"
                style={styles.textFieldBox}
                //InputLabelProps={{style : {color : 'black'}}} 
                inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                }}}
                type="text" 
                placeholder={configJSON.placeHolderEnterOtp} 
                value={this.state.otpEntered} 
                variant="filled" 
                onChange={this.handleOtpChange}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: 
                    <InputAdornment 
                        style={styles.textFieldIcon} 
                        position="start">
                            <PersonIcon/>
                    </InputAdornment>,
                }}
            />
            <Typography style={newStyles.validationText}>{this.otpWrongInputErrorFunc}</Typography>
            <Typography style={newStyles.validationText}>{this.state.requiredOtpFieldError}</Typography>
            <span style={{ display: 'flex', alignItems: 'center', marginTop: '5px', justifyContent: 'end', }}>
              <Typography style={{fontFamily: 'Plus Jakarta Display', fontSize: '16px',}}>Did not receive OTP?</Typography>
              <Button data-test-id="btnOtpSubmit" style={styles.resendButton} onClick={this.handleForgotPasswordEmailSubmit} variant="contained">Resend</Button>
            </span>
            <Button data-test-id="btnOtpSubmit" style={styles.button} onClick={this.handleOtpSubmit} variant="contained">{configJSON.btnForgotEmailSubmit}</Button>
          </Box>}

          {this.state.showResetPasswordScreen && <Box>
            <Box style={newStyles.backWithArrow} onClick={this.handleGotoEnterOtp}>
                <ArrowBackIcon style={newStyles.backIcon}/>
                <Typography style={newStyles.backText}>Back</Typography> 
            </Box>
            <StyledTextField
              testID="txtInputResetPassword"
              required
              style={styles.textFieldBox}
              inputProps={{style: {fontSize: 14,
                                  fontFamily: 'Plus Jakarta Display'
              }}}
              type={this.state.newPasswordType==="password" ? "password" : "text"} 
              placeholder={configJSON.placeHolderNewPassword} 
              value={this.state.newPassword} 
              variant="filled" 
              onChange={this.handleNewPasswordChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: 
                  <InputAdornment 
                      style={this.state.newPassword==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                      position="start">
                          <LockIcon/>
                  </InputAdornment>,
                endAdornment: 
                  <InputAdornment 
                    test-id="btnNewPasswordShowHide"
                    style={newStyles.textFieldSuffixIcon}
                    position="end" 
                    onClick={this.handleNewPasswordVisibilityIcon}>
                      {this.state.newPasswordType==="password" ? <VisibilityOff/> : <Visibility/>}
                  </InputAdornment>
              }}
            />
            <Typography style={newStyles.validationText}>
            {this.state.newPassword.length<6 ? "Password must be of at least 6 characters long" : "" }
            {this.state.newPassword.length >= 6 && !this.state.isValidNewPassword ? "Password must include one capital letter, one digit, and one special character*" : ""}
            </Typography>
            <Typography style={newStyles.validationText}>{this.state.showResetPasswordValidation && this.state.newPassword==="" ? this.state.requiredNewPasswordFieldError : "" }</Typography>
            <StyledTextField
                testID="txtInputConfirmResetPassword"
                required
                style={styles.textFieldBox}
                //InputLabelProps={{style : {color : 'black'}}} 
                inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                }}}
                type={this.state.confirmNewPasswordType==="password" ? "password" : "text"}
                placeholder='Confirm new password'
                value={this.state.confirmNewPassword} 
                variant="filled" 
                onChange={this.handleConfirmNewPasswordChange}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: 
                    <InputAdornment 
                        style={this.state.confirmNewPassword==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                        position="start">
                            <LockIcon/>
                    </InputAdornment>, 
                  endAdornment: 
                    <InputAdornment 
                      test-id="btnConfirmNewPasswordShowHide"
                      style={newStyles.textFieldSuffixIcon}
                      position="end" 
                      onClick={this.handleConfirmNewPasswordVisibilityIcon}>
                        {this.state.confirmNewPasswordType==="password" ? <VisibilityOff/> : <Visibility/>}
                    </InputAdornment>  
                }}
            />
            <Typography style={newStyles.validationText}>{this.state.showResetPasswordValidation && this.state.confirmNewPassword==="" ? this.state.requiredConfirmNewPasswordFieldError : "" }</Typography>
            <Typography style={newStyles.validationText}>{this.state.showResetPasswordValidation && this.state.confirmNewPassword!==this.state.newPassword && this.state.confirmNewPasswordNotMatchError ? this.state.confirmNewPasswordNotMatchError : "" }</Typography>
            <Button data-test-id="btnResetPasswordSubmit" style={styles.button} onClick={this.handleResetPassword} variant="contained">{configJSON.btnResetPassword}</Button>
        </Box>}

      </Box> 
    </Box>
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      // main: '#fff',
      // contrastText: '#fff',
      main: '#FFFF00',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  },
  button: {
    color: '#ffff'
  }
};

const styles = {
  container: {
    position: 'absolute',
    bgColor: '#F2F2EC',
    height: '100%',
    width: '100%'
  },
  box: {
    backgroundColor: '#F2F2EC',
  },
  selectBox: {
    backgroundColor: '#F2F2EC', 
    minHeight: '60px', 
    borderRadius: '10px',
    paddingLeft: '7px',
  },
  selectBoxLabel: {
    color: '#B1B1AE',
    backgroundColor: '#F2F2EC', 
    minHeight: '60px', 
    borderRadius: '10px',
    paddingLeft: '7px',
  },
  textFieldBox: {
    backgroundColor: '#F2F2EC', 
    width: '328px',
    minHeight: '56px',
    borderRadius: '10px',
    paddingBottom: '10px',
    margin: '0 auto',
    position: 'relative' as 'relative',
    // width: '224px',
    // height: '24px',
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: '400',
    boxSizing: 'border-box' as 'border-box',
  },
  resendButton: {
    backgroundColor: 'white', 
    marginLeft: '20px',
    boxShadow: 'none',
    justifyContent: 'center',
    textTransform: 'none' as 'none',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16px',
    fontWeight: 400,
    color: 'blue',
    fontStyle: 'italic',
    textDecoration: 'underline',
  },
  button: {
    backgroundColor: '#FFFF00', 
    width: '328px',
    height: '56px', 
    padding: '5px', 
    margin: 'auto',
    marginTop: '16px',  
    marginLeft: '0px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    textTransform: 'none' as 'none',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    //height: '16px',
    margin: '0 auto',
    marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Italic',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    fontStyle: 'italic' ,
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  textFieldIconInitially: {
    fontSize: 'small', 
    paddingRight: '12px',
    color: 'black',
  },
  textFieldIcon: {
    fontSize: 'small', 
    paddingRight: '12px',
  }
}

const newStyles = {
  mainContainer: {
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(242, 242, 236, 1)',
    width: '100%',
    top: '60px',
    paddingBottom: '50px',
    //width: '0 auto',
    height: 'auto',
    flex: '1',
    alignItems: "flex-start",
    //boxSizing: 'border-box' as 'border-box',
  },
  imageContainer: {
      position: 'relative' as 'relative',
      width: '264px',
      margin: 'auto',
      height: '130px',
      marginTop: '10px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      border: 'rgba(242, 242, 236, 1) 10px solid',
      borderRadius: '15px',
      //borderWidth: '10px',
      zIndex: 1,
      boxSizing: 'border-box' as 'border-box',
      flex: '1',
      flexWrap: 'nowrap' as 'nowrap',
      flexDirection: 'row' as 'row',
  },
  contentContainer: {
      position: 'relative' as 'relative',
      width: '700px',
      margin: '0 auto',
      marginBottom: '20px',
      paddingBottom: '90px',
      //height: '475px',
      //marginLeft: '370px',
      //marginTop: '332px',
      marginTop: '-70px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderRadius: '10px',
      //borderWidth: '0px',
      zIndex: 0,
      boxSizing: 'border-box' as 'border-box',
      paddingTop: '90px',
      //paddingLeft: '190px',
      display: 'flex',
      flex: '1',
      flexWrap: 'nowrap' as 'nowrap',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'center',
      //paddingBottom: '20px',
  },
  contentContainerSmall: {
    position: 'relative' as 'relative',
    width: '700px',
    margin: '0 auto',
    height: '350px',
    //marginLeft: '370px',
    //marginTop: '332px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '10px',
    //borderWidth: '0px',
    zIndex: 0,
    boxSizing: 'border-box' as 'border-box',
    paddingTop: '90px',
    paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
      position: 'relative' as 'relative',
      width: '176px',
      height: '54px',
      //marginLeft: '632px',
      //marginTop: '305px',
      marginLeft: '32px',
      marginTop: '30px',
      opacity: '1',
      resizeMode: 'contain',
      boxSizing: 'border-box' as 'border-box',
  },
  textFields: {
      position: 'absolute',
      width: '224',
      height: '24',
      marginLeft: '58',
      marginTop: '16',
      opacity: '1',
      backgroundColor: 'transparent',
      fontStyle: 'normal',
      fontWeight: 400,
      //includeFontPadding: false,
      //padding: '0',
      padding: '10px',
      color: 'rgba(128, 128, 128, 1)',
      textAlign: 'left',
      textAlignVertical: 'top',
      //fontFamily: 'PlusJakartaDisplay-Regular',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '14',
      boxSizing: 'border-box' as 'border-box',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    //height: '16px',
    margin: '0 auto',
    marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'red',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Italic',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
  },
  button: {
      //position: 'absolute',
      position: 'relative' as 'relative',
      width: '328px',
      height: '56px',
      marginLeft: '556px',
      marginTop: '645px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderRadius: '10px',
      justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
  },
  buttonText: {
      position: 'absolute',
      width: '104',
      height: '16',
      marginLeft: '16',
      marginTop: '14',
      opacity: '1',
      backgroundColor: 'transparent',
      fontStyle: 'normal',
      fontWeight: 'normal',
      //includeFontPadding: false,
      padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center',
      textAlignVertical: 'top',
      //fontFamily: 'PlusJakartaDisplay-Medium',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
      boxSizing: 'border-box' as 'border-box',	
  },
  signUpText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    margin: '0 auto',
    marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
    marginBottom: '10px',
  },
  signUp: {
    backgroundColor: 'transparent',
    position: 'relative' as 'relative',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14px',
    fontStyle: 'normal' as 'normal',
    fontWeight: 300,
    textDecoration: 'underline',
    boxSizing: 'border-box' as 'border-box',
  },
  backWithArrow: {
    display: 'flex', 
    flexDirection: 'row' as 'row', 
    alignItems: 'baseline',
    cursor: 'pointer',
  },
  backIcon: {
    width: '20px',
    height: '14px',
  },
  backText: {
    width: '32px',
    height: '17px',
    color: '#1c1c1a',
    fontFamily: "Plus Jakarta Display",
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '-0.15px',
  },
  passwordChangeMessageContainer: {
    position: 'relative' as 'relative',
    //top: '367px',
    margin: '0 auto',
    height: '355px',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
    width: '700px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    //backgroundColor: 'rgba(255, 255, 255, 1)',
    zIndex: 0,
    boxSizing: 'border-box' as 'border-box',
    paddingTop: '90px',
    paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldSuffixIcon: {
    fontSize: 'small',
    paddingTop: '12px',
    cursor: 'pointer'
  },
}

// Customizable Area End
