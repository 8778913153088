import React from "react";

// Customizable Area Start
import {  
  IconButton,
  Snackbar
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { createTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';


  const StyledSnackbar = withStyles(() => ({
    root: {
    "& .MuiSnackbarContent-root": {
        backgroundColor: "FFFF00",
        color: 'black',
        marginTop: '20px',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
    }
    }
  }))((props: any) => <Snackbar {...props} />);


// Customizable Area End

export default class ToastMessageDisplay extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
        
        <StyledSnackbar
            testID={this.props.testID}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            open={this.props.message? true : false}
            autoHideDuration={this.props.duration || 5000}
            onClose={this.props.handleClose}
            message={this.props.message}
            action={[
                <IconButton
                  id="iconBtn"
                  onClick={this.props.handleClose}
                >
                  <CloseOutlinedIcon />
                </IconButton>,
              ]}
        />
     
        
    );
  }
}

// Customizable Area Start
// Customizable Area End
