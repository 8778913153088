import { Route, Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";

const isAuthenticated = () => {
  const authToken = localStorage.getItem('authToken');
  return authToken !== null;
};

function getPathsFromRouteMap(routeMap) {
  const pathsArray = [];

  for (const routeKey in routeMap) {
    if (Object.prototype.hasOwnProperty.call(routeMap, routeKey)) {
      const route = routeMap[routeKey];
      if (route.path) {
        pathsArray.push(route.path);
      }
    }
  }

  return pathsArray;
}


function Wrapper({ element, history, match, routeMap, closeModal, path }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  const allowedPaths = ["/", "/EmailAccountRegistration", "/ForgotPassword", "/ForgotPasswordOTP", "/CreatePassword"];

  if (allowedPaths.includes(window.location.pathname)) {
    return React.cloneElement(element, {
      navigation: { navigate, getParam, goBack },
      closeModal
    });
  } else {
    if (isAuthenticated()) {
      return React.cloneElement(element, {
        navigation: { navigate, getParam, goBack },
        closeModal
      });
    } else {
      return <Redirect to="/" />
    }
  }
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;

    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      const pathsArray = getPathsFromRouteMap(routeMap);
      if (pathsArray.includes(window.location.pathname)) {
        return (
          <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => (
              <Wrapper element={<Component />} {...props} routeMap={routeMap} path={currentRoute.path} />
            )}
          />
        );
      } else {
        return isAuthenticated() ? <Redirect to="/Dashboard" /> : <Redirect to="/" />
      }
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
