Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Enter Email";
exports.placeHolderPassword = "Enter Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "Login";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.placeHolderPhoneOrEmail = "Enter Phone or Email"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.emailRegExp = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
exports.placeHolderNewPassword = "Enter New Password";
exports.placeHolderConfirmNewPassword = "ReEnter New Password";
exports.btnTxtLogin = "Login"
exports.btnDone = "Done"
exports.btnResetPassword = "Reset"
exports.btnForgotEmailSubmit = "Submit"
exports.btnOtpSubmit = "Submit"
exports.placeHolderEnterOtp = "Enter OTP"

exports.tableDataName = "Name";
exports.tableDataEmail = "Email";
exports.tableDataPhone = "Phone";
exports.placeHolderPosition = "Enter Your Position *";
exports.placeHolderOrganizationSize = "Enter Organization Size *";
exports.placeHolderNoOfPeopleAccess = "No. of People who will access *";
exports.placeHolderGoal = "Goal for using Sourceasy *";
exports.placeHolderERPSoftware = "Which ERP Software do you use? *";
exports.btnTextNext = "Next";
exports.btnTextBack = "Back";
exports.tableDataGoal = "Goal for using Sourceasy";
exports.tableDataNoOfPeopleAccess = "No. of People who will access"
exports.name = "Name";
exports.emailOrPhone = "Email Or Phone";
exports.positionInCompany = "Position In Company";
exports.organizationSize = "Organization Size";
exports.ERPSoftware = "ERP Software You Use";
exports.btnTextSubmit = "Submit";

// Methods
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT"

// EndPoints
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.loginAPiEndPoint = "login/login";
exports.loginApiContentType = "application/json";
exports.webLoginEndpoitn = "bx_block_login/logins";
exports.forgetPasswordEndpoint = "account_block/accounts/forgot_password";
exports.confirmOTPEndpoint = "account_block/accounts/confirm_otp";
exports.updateProfileEndpoint = "update_profile";
exports.resetPasswordEndpoint = "account_block/accounts/reset_password";

// Customizable Area End
