import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Input,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import ToastMessageDisplay from "./ToastMessageDisplay";


const StyledSelect = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      border: "0.05px solid #B1B1AE",
      backgroundColor: "transparent",
    },
    paddingLeft: 15,
    "& .MuiOutlinedInput-root:hover": {
      border: "0.05px solid #B1B1AE",
      "@media (hover: none)": {
        backgroundColor: "transparent",
        border: "0.05px solid #B1B1AE",
      },
      backgroundColor: "transparent",
    },
    "&:focus": {
      borderColor: "#B1B1AE",
      backgroundColor: "transparent",
    },
    "&:after": {
      borderColor: "#B1B1AE",
    },
    "&:before": {
      borderColor: "#B1B1AE",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: "transparent",
      border: "0.05px solid #B1B1AE",
    },
  },
})((props: any) => <Select {...props} />);

const StyledTextField: any = withStyles({
  root: {
    fontSize: "16",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "10px",
      },
      backgroundColor: "transparent",
    },
    fontFamily: "Plus Jakarta Display",

    "& .MuiOutlinedInput-root:hover": {
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontWeight: 400,
      fontSize: "16",
      fontFamily: "Plus Jakarta Display",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      border: "0.05px solid black",
      backgroundColor: "transparent",
    },
    "& .Mui-focused": {
      maxHeight: "57px",
      borderRadius: "10px",
    },
    "&:focus": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
})((props: any) => <TextField {...props} />);

export interface Props{
  state:any,
  handleTaxNameChange:(event: React.ChangeEvent<HTMLInputElement>)=>void,
  handleGetDownloadPdfPrPo: ()=>Promise<void>,
  handlePrPoMailSendConfirmation: ()=> void,
  handleSaveAsDraftPrPurchaseOrder: ()=> void,
  handleCreateRequisitionPurchaseOrder: ()=> void,
  handleClosePurchaseFromRequisitionCreatePO: ()=>void,
  handleDeliveryDateChange:(event: React.ChangeEvent<HTMLInputElement>)=>void,
  handlePoBudgetChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handlePoSupplierChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handlePrPoShippingAddressOptionalChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  checkBudgetFetchOrNot: ()=>void,
  checkTaxationFetchOrNot:()=>void,
  handleSupplierPaymentTerms: (event: React.ChangeEvent<HTMLInputElement>) => void,
  checkFetchPaymentTermsOrNot: () => void,
  handlePoBillingAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleCustomFromRequisitionCreatePONextModal: () => void,
  handlePrPoNotItemsFoundMessageSnackbarClose: (event: Event | React.SyntheticEvent<Element, Event>, reason?: string | undefined) => void,
  handlePrPoProductsCataQuantityChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => void,
  handlePrPoProductsNonCataQuantityChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => void,
  handlePoCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleCloseCreatePrPoErrorModal:()=>void,
  handlePrPoRecordSupplierMailSend:()=>void,
  handlePrPoMailSendConfirmationCloseBar:()=>void,
  handlePrPoSupplierMailSeccessCloseModal:()=>void,
  downloadPRPOPdfBlob:(blob:Blob)=>void,
}

// Customizable Area End

export default class CustomPOComp extends React.Component<Props,any,{}>{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  quantityHandleDefault = (pro: any) => {
    if (pro?.quantity > 0) {
      return pro?.quantity;
    }
  }

  unitPriceRender = (prod: any) => {
    if (prod !== null) {
      return prod?.price?.replace(/[$]/g, '') * prod?.quantity
    } else {
      return 0;
    }
  }

  purchaseRequisitionPOValidationError = (itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState === "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  styledSelectStyle = () => {
    if (
      this.props.state.editedLocation === "" ||
      this.props.state.poDepartment === "" ||
      this.props.state.poBudget === "" ||
      this.props.state.poSupplier === "" ||
      this.props.state.paymentTermsName === "" ||
      this.props.state.products?.length === 0 ||
      this.props.state.location === "" ||
      this.props.state.department === "" ||
      this.props.state.prStatusList?.length === 0 ||
      this.props.state.editedPayment === "" ||
      this.props.state.poOrderDate === '' ||
      this.props.state.poShippingAddress === "" ||
      this.props.state.poBillingAddress === "" ||
      this.props.state.prPoSelectedRequisitionDepartment?.name === "" ||
      this.props.state.prPoSelectedRequisitionDeliveryDate === "" ||
      this.props.state.prPoSelectedRequisitionCreationDate === "" ||
      this.props.state.paymentTermsNameSuppliers?.length === 0 ||
      this.props.state.editedSupplierPaymentList?.length === 0
    ) {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  taxationPoRenderFunc  =() =>{
    return  <StyledSelect
    displayEmpty
    input={<Input />}
    variant="outlined"
    style={newStyles.selectBox}
    label="Select Product"
    onChange={this.props.handleTaxNameChange}
    id={"po_taxation_select"}
    disableUnderline
    value={this.props.state.taxName}
  >
    <MenuItem disabled={true} selected value="">Select Taxation</MenuItem>
    {this.props.state.taxations?.map((tax: any) => {
      return (
        <MenuItem className="taxation_name" key={tax?.id} value={tax?.id}>
          {tax?.attributes?.taxetion_name_and_percengate}
        </MenuItem>
      );
    })}
   </StyledSelect>
  }

  prPoPlaceHolder = () => {
    if (this.props.state.poShippingAddress === null) {
      return "No Data";
    }else{
      return ''
    }
  };

  handleOpenPrPoPdfSupplierScreenModal = () => {
    if (this.props.state.openPrPoPdfSupplierScreenModal) {
      return (
        <Box style={newStyles.prPoScreenBtnWrapper}>
          <Button data-testid="getDownloadPdfPrPo" style={newStyles.modalButton} onClick={this.props.handleGetDownloadPdfPrPo} variant="contained">
            {this.props.state.isPdfDownloaded ? <CircularProgress size={24} color="inherit" /> : 'Download as PDF'}
          </Button>
          {this.props.state.userType === 'Organization'?<Button data-testid="prPoMailSendConfirmation" style={newStyles.modalButton} onClick={this.props.handlePrPoMailSendConfirmation} variant="contained">
            {this.props.state.isPrPoMailSending ? <CircularProgress size={24} color="inherit" /> : 'Send to Supplier'}
          </Button>:<Button data-testid="closePurchaseFromRequisitionCreatePO" onClick={this.props.handleClosePurchaseFromRequisitionCreatePO} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>}
        </Box>
      )
    } else if (!this.props.state.openPrPoPdfSupplierScreenModal && !this.props.state.openPrPoSupplierMailSeccessModal) {
      return (
        <Box display={"flex"} justifyContent={"space-between"} width="100%">
          <Box style={newStyles.prPoScreenBtnWrapper}>
            <Button
              data-testid="saveAsDraftPo"
              onClick={this.props.handleSaveAsDraftPrPurchaseOrder} style={newStyles.modalButton} variant="contained">
              {this.props.state.prPoSaveDraft ? <CircularProgress size={24} color="inherit" /> : 'Save as Draft'}
            </Button>
          </Box>

          <Box style={newStyles.prPoScreenBtnWrapper}>
            <Button
              data-testid="createPrPO"
              disabled={this.props.state.isPrPoCreationAndApiSuccessDone ? true : false}
              onClick={this.props.handleCreateRequisitionPurchaseOrder} style={newStyles.modalButton} variant="contained">
              {this.props.state.prPoCreating ? <CircularProgress size={24} color="inherit" /> : 'Create'}
            </Button>
            <Button data-testid="closePurchaseFromRequisitionCreatePO" onClick={this.props.handleClosePurchaseFromRequisitionCreatePO} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
          </Box>
        </Box>
      )
    }
  }

  subpurchaseRequisitionMainOrderAllFieldsRenderFunc = () => {
    if (this.props.state.prPoCreateScreenActiveTabs === 0) {
      return <>
        <Grid container data-testid="mainContainer">
          <Grid item lg={4} md={6} sm={6}>

            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}
              >
                Creation Date
              </Typography>
              <StyledTextField
                variant="outlined"
                type={"date"}
                value={this.props.state.prPoSelectedRequisitionCreationDate}
                style={newStyles.textFieldBox}
                InputProps={{
                  readOnly:true,
                  inputProps: {
                    min:
                      new Date()
                        .getFullYear()
                        .toString()
                        .padStart(4, "0") +
                      "-" +
                      (new Date().getMonth() + 1)
                        .toString()
                        .padStart(2, "0") +
                      "-" +
                      new Date()
                        .getDate()
                        .toString()
                        .padStart(2, "0"),
                  },
                }}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Delivery Date
              </Typography>
              <StyledTextField
                type={"date"}
                value={this.props.state.prPoSelectedRequisitionDeliveryDate}
                onChange={this.props.handleDeliveryDateChange}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                InputProps={{
                  readOnly:true,
                  inputProps: { min: this.props.state.durationDate },
                }}
                variant="outlined"
              />
              <Typography style={newStyles.validationText}>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Department
              </Typography>
              <StyledTextField
                type={"text"}
                value={this.props.state.prPoSelectedRequisitionDepartment?.name}
                InputProps={{
                  readOnly:true,
                }}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Select Budget
              </Typography>
              <StyledSelect
                data-testid={"po_pr_budget_select"}
                disableUnderline
                variant="outlined"
                value={this.props.state.poBudget}
                onClick={this.props.checkBudgetFetchOrNot}
                input={<Input />}
                displayEmpty
                style={this.styledSelectStyle()}
                label="Select Product"
                onChange={this.props.handlePoBudgetChange}
              >
                <MenuItem disabled selected value="">Select Budget</MenuItem>
                {this.props.state.budgetList?.map((budget: any) => {
                  return (
                    <MenuItem className="pr_po_budget" key={budget?.id} value={budget?.id}>
                      {budget?.attributes?.name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.props.state.showIsCreatePrPurchaseOrderValidation, this.props.state.poBudget, this.props.state.prPoSelectedRequisitionBudgetError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Supplier
              </Typography>
              <StyledSelect
                disableUnderline={true}
                input={<Input />}
                value={this.props.state.poSupplier}
                id={"po_supplier_select"}
                variant="outlined"
                onChange={this.props.handlePoSupplierChange}
                displayEmpty={true}
                label="Select Product"
                style={this.styledSelectStyle()}
              >
                <MenuItem disabled value="" selected>Select Supplier</MenuItem>
                {this.props.state.supplierList?.map((supplier1: any) => {
                  return (
                    <MenuItem className="suppliers_name_po" key={supplier1?.id} value={supplier1?.id}>
                      {supplier1?.attributes?.supplier_name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.props.state.showIsCreatePrPurchaseOrderValidation, this.props.state.poSupplier, this.props.state.prPoSelectedRequisitionSupplierError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Payment Terms
              </Typography>
              <StyledSelect
                disableUnderline={true}
                displayEmpty={true}
                onChange={this.props.handleSupplierPaymentTerms}
                variant="outlined"
                onClick={this.props.checkFetchPaymentTermsOrNot}
                className={"pr_po_paymentterm_select"}
                input={<Input style={{ flexWrap: "wrap" }} />}
                label="Select Category"
                value={this.props.state.paymentTermsName}
                style={this.styledSelectStyle()}
              >
                <MenuItem disabled={true}
                 value="" selected={true} >Select Payment Terms</MenuItem>
                {
                  this.props.state.getPaymentTermListSupplier?.map((w: any) => {
                    return (<MenuItem className="pr_get_payment_term" key={w?.id} value={w?.id}>{w?.attributes?.payment_term_name}</MenuItem>)
                  })
                }
              </StyledSelect>
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.props.state.showIsCreatePrPurchaseOrderValidation, this.props.state.paymentTermsName, this.props.state.prPoSelectedRequisitionPaymentError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Taxation
              </Typography>
                {this.taxationPoRenderFunc()}
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.props.state.showIsCreatePrPurchaseOrderValidation, this.props.state.taxName, this.props.state.prPoSelectedRequisitionTaxationError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Location & Shipping Address
              </Typography>
              <StyledTextField
                id={"po_shipping_address"}
                required
                value={this.props.state.poShippingAddress}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                InputProps={{
                  readOnly:true,
                  disableUnderline: true,
                  style: { paddingRight: '98px'},
                }}
                variant="outlined"
                placeholder={this.prPoPlaceHolder()}
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Location & Shipping Address Optional
              </Typography>
              <StyledTextField
                style={newStyles.textFieldBox}
                required
                value={this.props.state.prPoShippingAddressOptional}
                onChange={this.props.handlePrPoShippingAddressOptionalChange}
                inputProps={{
                  style: {
                    fontFamily: "Plus Jakarta Display",
                    fontSize: 16,
                    fontWeight: 400,
                  },
                }}
                id={"pr_po_shipping_address_optional"}
                InputProps={{
                  style: { paddingRight: '98px', color: 'black' },
                  disableUnderline: true,
                }}
                placeholder={"Enter the Location & Shipping Address Optional"}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Billing Address
              </Typography>
              <StyledTextField
                required
                value={this.props.state.poBillingAddress}
                onChange={this.props.handlePoBillingAddressChange}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                variant="outlined"
                placeholder={"Enter the Billing Address"}
              />
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.props.state.showIsCreatePrPurchaseOrderValidation, this.props.state.poBillingAddress, this.props.state.prPoSelectedRequisitionBillingError)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            data-testid={"dash_pr_create_next_btn"}
            onClick={this.props.handleCustomFromRequisitionCreatePONextModal}
            style={newStyles.modalButton}
            variant="contained"
          >
            Next
          </Button>
          <Button
            data-testid={"dash_pr_cancel_btn"}
            onClick={this.props.handleClosePurchaseFromRequisitionCreatePO}
            style={newStyles.modalCancelButton}
            variant="contained"
          >
            Cancel
          </Button>
        </Box>
      </>
    } else {
      return <Box>
        <ToastMessageDisplay message={this.props.state.prPoItemsNotFoundMessage} handleClose={this.props.handlePrPoNotItemsFoundMessageSnackbarClose} />
        <Grid container>
          <TableContainer
            style={newStyles.productsTable}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      width: "100px",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Product Id
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "100px",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Product Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "10%",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "10%",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "100px",
                      padding: "16px 30rem 25px 30px;",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "scroll" }}>
                {this.props.state.customFromRequisitionCataItemData?.map((pro: any, index: number) => {
                  return (
                    <TableRow key={pro?.id} data-testid="customCataProducts">
                      <TableCell
                        style={{
                          width: "100px",
                          padding: "16px 65rem 25px 30px;",
                        }}
                        className="customPoTableCell"
                        align="left"
                      >
                        {pro?.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "16px 65rem 25px 30px;",
                          width: "100px",
                        }}
                        className="customPoTableCell"
                      >
                        {pro?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          outline: "none",
                          padding: "16px 65rem 25px 30px;",
                          width: "100px",
                          border: "none",
                          borderBottom: "1px solid lightgrey",
                        }}
                        className="customPoTableCell"
                      >
                        <TextField
                          value={this.quantityHandleDefault(pro)}
                          id="prPoCataChange"
                          onChange={(e:any) =>
                            this.props.handlePrPoProductsCataQuantityChange(e, pro, index)
                          }
                          type="text"
                          style={{
                            borderRadius: "10px",
                            padding: "10px 15px",
                            width: "80px",
                            border: "2px solid rgb(128 128 128 / 20%)",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            inputProps: { min: 1, maxLength:8 },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className="customPoTableCell"
                        style={{
                          width: "100px",
                          paddingTop: '16px',
                          paddingRight: '30rem',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                        }}
                      >
                        {this.unitPriceRender(pro)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="customPoTableCell"
                        style={{
                          padding: "2px 80px",
                          justifyContent: "flex-end",
                          marginTop: "25px",
                          height: "58px",
                          display: "flex",
                        }}
                      >
                        <Chip
                          style={{ width: "0px" }}
                          size="medium"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {this.props.state.customFromRequisitionNonCataItemData?.map((pro: any, index: number) => {
                  return (
                    <TableRow key={pro?.id}>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: '16px',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                          width: "100px",
                          paddingRight: '65rem',
                        }}
                        className="customPoNonCata"
                      >
                        {pro?.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "100px",
                          paddingRight: '65rem',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                          paddingTop: '16px',
                        }}
                        className="customPoNonCata"
                      >
                        {pro?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="customPoNonCata"
                        style={{
                          borderBottom: "1px solid lightgrey",
                          paddingTop: '16px',
                          paddingRight: '65rem',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                          border: "none",
                          outline: "none",
                          width: "100px",
                        }}
                      >
                        <TextField
                        id="prPoNonCataChange"
                          onChange={(e:any) =>
                            this.props.handlePrPoProductsNonCataQuantityChange(e, pro, index)
                          }
                          InputProps={{
                            disableUnderline: true,
                            inputProps: { min: 1, maxLength:8 },
                          }}
                          type="number"
                          style={{
                            width: "80px",
                            borderRadius: "10px",
                            padding: "10px 15px",
                            border: "2px solid rgb(128 128 128 / 20%)",
                          }}
                          value={pro?.quantity}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          paddingLeft: '30px',
                          paddingTop: '16px',
                          paddingRight: '65rem',
                          paddingBottom: '25px',
                          width: "100px",
                        }}
                        className="customPoNonCata"
                        align="left"
                      >
                        {this.unitPriceRender(pro?.unit_price)}
                      </TableCell>
                      <TableCell
                        style={{
                          marginTop: "25px",
                          justifyContent: "flex-end",
                          display: "flex",
                          height: "58px",
                          padding: "2px 80px",
                        }}
                        align="left"
                        className="customPoNonCata"
                      >
                        <Chip
                          style={{
                            width: "0px"
                          }}
                          size="medium"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: "20px" }}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Add notes
              </Typography>
              <TextField
                required
                value={this.props.state.poComment}
                onChange={this.props.handlePoCommentChange}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                variant="outlined"
                placeholder="Add notes"
              />
              <Typography style={newStyles.validationText}>{this.purchaseRequisitionPOValidationError(this.props.state.showIsCreatePrPurchaseOrderValidation, this.props.state.poComment, this.props.state.purchaseOrderCommentRequiredField)}</Typography>
            </Box>
          </div>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"baseline"}
            width={"100%"}
            id="handleOpenPrPoPdfModal"
          >
            {this.handleOpenPrPoPdfSupplierScreenModal()}
          </Box>
        </Grid>
      </Box>
    }
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
          {this.subpurchaseRequisitionMainOrderAllFieldsRenderFunc()}
          <Dialog maxWidth="xl" style={newStyles.prPoErrorDialog} open={this.props.state.openCreatePrPoShowErrorModal}>
              <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '15px', textAlign: 'center', padding: '20px' }}>
                <Typography style={{ color: 'red' }}>All fields are required **</Typography>
                <Typography>Please check Details and Add Items section again</Typography>
                <Box>
                  <Button style={newStyles.prPoErrorModalButton} onClick={this.props.handleCloseCreatePrPoErrorModal}>OK</Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              open={this.props.state.isPrPoMailSendConfirmed}
              onClose={this.props.handlePrPoMailSendConfirmationCloseBar}
              aria-describedby="Po mail send confirmation"
            >
              <DialogTitle>{"Are you sure to send a mail?"}</DialogTitle>
              <DialogActions>
                <Button style={newStyles.prPoAlertBoxBtn} onClick={this.props.handlePrPoRecordSupplierMailSend}>Yes</Button>
                <Button style={newStyles.prPoAlertBoxBtn} onClick={this.props.handlePrPoMailSendConfirmationCloseBar}>No</Button>
              </DialogActions>
            </Dialog>

            <Dialog maxWidth="xl" style={newStyles.prPoEmailScreenDialog} open={this.props.state.openPrPoSupplierMailSeccessModal}>
              <Box style={{ textAlign: 'center', padding: '20px' }}>
                <Typography>An email with Purchase Order : {this.props.state.prPoNumberUnique}</Typography>
                <Typography>has been successfully sent to the supplier</Typography>
                <Box>
                  <Button onClick={this.props.handlePrPoSupplierMailSeccessCloseModal} style={newStyles.modalButton} variant="contained">Close</Button>
                </Box>
              </Box>
            </Dialog>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const newStyles = {
  gridItem: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  modalLabel: {
    position: "relative" as "relative",
    bottom: "10px",
    height: "15px",
    color: "#808080",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
  },
  prPoEmailScreenDialog: {
    margin: '0 auto',
    borderRadius: '15px',
    boxSizing: 'border-box' as 'border-box',
  },
  prPoAlertBoxBtn: {
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '8px',
    justifyContent: 'center',
    padding: '2px',
  },
  prPoErrorDialog: {
    margin: '0 auto',
    borderRadius: '15px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  prPoErrorModalButton: {
    position: "relative" as "relative",
    width: "100px",
    height: "45px",
    margin: "auto 0px",
    marginTop: "15px",
    marginBottom: "15px",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    boxSizing: "border-box" as "border-box",
    justifyContent: "center",
    padding: "0",
    opacity: "1",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontSize: "18",
    includeFontPadding: false,
    fontFamily: "Plus Jakarta Display",
  },
  textFieldBox: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    minHeight: "60px",
    borderRadius: "10px",
    position: "relative" as "relative",
    marginBottom: "10px",
    opacity: "1",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },
  validationText: {
    textAlign: "right" as "right",
    position: "relative" as "relative",
    width: "325px",
    margin: "0 auto",
    opacity: "1",
    backgroundColor: "transparent",
    padding: "0px",
    color: "red",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12",
    fontStyle: "italic",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },
  selectBoxLabel: {
    color: 'Black',
    opacity: '1',
    width: "100%",
    minHeight: "56px",
    borderRadius: "10px",
    border: "#B1B1AE 0.1px solid",
    marginBottom: "10px",
    boxSizing: "border-box" as "border-box",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 350,
  },
  selectBox: {
    width: "100%",
    minHeight: "56px",
    borderRadius: "10px",
    border: "#B1B1AE 0.1px solid",
    marginBottom: "10px",
    color: "Black",
    boxSizing: "border-box" as "border-box",
  },
  modalCancelButton: {
    position: "relative" as "relative",
    width: "160px",
    height: "56px",
    margin: "20px",
    opacity: "1",
    backgroundColor: "#FAEBE6",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "0",
    color: "#FD6533",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },
  productsTable: {
    width: '96%',
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
    border: '1px solid lightgrey',
    borderRadius: '15px',
    margin: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  modalButton: {
    position: "relative" as "relative",
    width: "180px",
    height: "56px",
    margin: "20px",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "8px",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },
  prPoScreenBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    boxSizing: 'border-box' as 'border-box',
  },
};
// Customizable Area End
