import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Input,
    Button,
    Grid,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Tabs,
    Tab,
    Typography
} from '@material-ui/core';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import SystemUpdateAltRoundedIcon from '@material-ui/icons/SystemUpdateAltRounded';

import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Header from "./Header";
import Charts from "./Charts";
import ToastMessageDisplay from "./ToastMessageDisplay";
import PaginationDisplay from "./PaginationDisplay";





const commonInputStyles = {
    backgroundColor: "transparent",
    border: "0.05px solid #B1B1AE",
  };
  
  const commonHoverStyles = {
    backgroundColor: "transparent",
    border: "0.05px solid #B1B1AE",
    "@media (hover: none)": {
      backgroundColor: "transparent",
      border: "0.05px solid #B1B1AE",
    },
  };
  
  const StyledSelect = withStyles({
    root: {
      paddingLeft: 15,
      "& .MuiOutlinedInput-root": {
        ...commonInputStyles,
      },
      "& .MuiOutlinedInput-root:hover": {
        ...commonInputStyles,
        ...commonHoverStyles,
      },
      "& .MuiOutlinedInput-root.Mui-focused": {
        ...commonInputStyles,
      },
      "& .MuiSelect-nativeInput":{
        fontWeight:500
    },
      "&:focus": {
        backgroundColor: "transparent",
        borderColor: "#B1B1AE",
      },
      "&:before": {
        borderColor: "#B1B1AE",
      },
      "&:after": {
        borderColor: "#B1B1AE",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "white",
      },
    },
  })((props: any) => <Select data-testId='styledSelect_test' {...props} />);

  const StyledTab = withStyles({
    root:{
        "&:not(.Mui-selected)":{
            fontWeight: 400,
        }
    }
  })((props:any)=> <Tab data-testId='styledTab_test' {...props} />)
  

interface SS {
    id: any;
}

// Customizable Area End

import MyReportsController, {
    Props,
    configJSON
} from "./MyReportsController";

export default class MyReports extends MyReportsController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    handleDateRangeForSelectBox = (currentDate : any) => {
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + 10);

        const futureDateString =
        futureDate.getFullYear().toString().padStart(4, '0') +
        '-' +
        (futureDate.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        futureDate.getDate().toString().padStart(2, '0');


        return futureDateString
    }


    handleSpendAnalysisDatesFiltering = (stateValue : string) => {
        if(stateValue === "Department vs Rupee Spend"){
            return this.state.spendAnalysisList?.map((item: any, index:any) => {
                let formattedDate = new Date(item).toISOString().split('T')[0];
                let anotherDateRange = this.handleDateRangeForSelectBox(new Date(formattedDate))
                return <MenuItem className='department_dates' onClick={() => this.handleReportsDepartmentRupeeFilteredDatesAfterUserSelect(formattedDate, anotherDateRange)} style={{justifyContent: 'center'}} key={Date.now().toLocaleString()} value={formattedDate} > {`${formattedDate} - ${anotherDateRange}`}</MenuItem>
            })
        } else if(stateValue === "Supplier vs Rupee Spend"){
            return this.state.spendAnalysisList?.map((item: any, index:any) => {
                let formattedDate = new Date(item).toISOString().split('T')[0];
                let anotherDateRange = this.handleDateRangeForSelectBox(new Date(formattedDate))
                return <MenuItem className='supplier_dates' onClick={() => this.handleReportsSuppliersRupeeFilteredDatesAfterUserSelect(formattedDate, anotherDateRange)} style={{justifyContent: 'center'}} key={Date.now().toLocaleString()} value={formattedDate} >{`${formattedDate}  -  ${anotherDateRange}`}</MenuItem>
            })
        } else if(stateValue === "Item Number vs Rupee Spend"){
            return this.state.spendAnalysisList?.map((item: any, index:any) => {
                let formattedDate = new Date(item).toISOString().split('T')[0];
                let anotherDateRange = this.handleDateRangeForSelectBox(new Date(formattedDate))
                return <MenuItem className='item_number_dates' onClick={() => this.handleReportsItemNumbersRupeeFilteredDatesAfterUserSelect(formattedDate, anotherDateRange)} style={{justifyContent: 'center'}} key={Date.now().toLocaleString()} value={formattedDate} >  {`${formattedDate} - ${anotherDateRange}`}</MenuItem>
            })
        }
        else{
            return this.state.spendAnalysisList?.map((item: any, index:any) => {
                let formattedDate = new Date(item).toISOString().split('T')[0];
                let anotherDateRange = this.handleDateRangeForSelectBox(new Date(formattedDate))
                return <MenuItem className='category_dates' onClick={() => this.handleReportsCategoryRupeeFilteredDatesAfterUserSelect(formattedDate, anotherDateRange)} style={{justifyContent: 'center'}} key={Date.now().toLocaleString()} value={formattedDate} >{`${formattedDate}  -  ${anotherDateRange}`}</MenuItem>
            })
        }
    }

    handleBudgetDatesFiltering = (stateValue : string) => {
        if(stateValue === "Department Budget vs Actual Spend"){
            return this.state.spendAnalysisList?.map((item: any, index:any) => {
                let formattedDate = new Date(item).toISOString().split('T')[0];
                let anotherDateRange = this.handleDateRangeForSelectBox(new Date(formattedDate))
                return <MenuItem className="budget_department_dates" onClick={() => this.handleReportsDepartmentRupeeFilteredDatesAfterUserSelect(formattedDate, anotherDateRange)} style={{justifyContent: 'center'}} key={Date.now().toLocaleString()} value={formattedDate} >{`${formattedDate}  -  ${anotherDateRange}`}</MenuItem>
            })
        } else{
            return this.state.spendAnalysisList?.map((item: any, index:any) => {
                let formattedDate = new Date(item).toISOString().split('T')[0];
                let anotherDateRange = this.handleDateRangeForSelectBox(new Date(formattedDate))
                return <MenuItem className="budget_category_dates" onClick={() => this.handleReportsCategoryRupeeFilteredDatesAfterUserSelect(formattedDate, anotherDateRange)} style={{justifyContent: 'center'}} key={Date.now().toLocaleString()} value={formattedDate} >{`${formattedDate}  -  ${anotherDateRange}`}</MenuItem>
            })
        }
    }

    handleSupplierPerformanceDatesFiltering = ()=>{
        return this.state.spendAnalysisList?.map((item: any, index:any) => {
            let formattedDate = new Date(item).toISOString().split('T')[0];
            let anotherDateRange = this.handleDateRangeForSelectBox(new Date(formattedDate))
            return <MenuItem className='supplier_performance_dates' onClick={() => this.handleSupplierPerformanceFilteredDatesAfterUserSelect(formattedDate, anotherDateRange)} style={{justifyContent: 'center'}} key={Date.now().toLocaleString()} value={formattedDate} >{`${formattedDate}  -  ${anotherDateRange}`}</MenuItem>
        })
    }

    handleDriverMyReportsDateSelectFiltering = (stateValue : string) => {
        if(this.state.subTabValue === 0){
            return this.handleSpendAnalysisDatesFiltering(stateValue)
        }
        else if(this.state.subTabValue === 1){
            return this.handleBudgetDatesFiltering(stateValue)
        }else if(this.state.subTabValue === 2){
            return this.handleSupplierPerformanceDatesFiltering()
        }
    }

    handleDriverMyReportsFeatureSelectFiltering = () => {
        if(this.state.subTabValue === 0){
            return this.state.myReportsMainFeaturesNamesData?.filter((elem : any) => elem?.report_type === 'spend analysis')?.map((item : any) => {
                return <MenuItem className="features_names" style={{padding:"0.4rem"}} onClick={() => item?.handlingFunc()} key={item?.id} value={item?.report_status} >{item?.report_status}</MenuItem>
            })
        }
        else if(this.state.subTabValue === 1){
            return this.state.myReportsMainFeaturesNamesData?.filter((elem : any) => elem?.report_type === 'budget')?.map((item : any) => {
                return <MenuItem className="features_names2" style={{padding:"0.4rem"}} onClick={() => item?.handlingFunc()} key={item?.id} value={item?.report_status} >{item?.report_status}</MenuItem>
            })
        }
    }

    handleCategoryBtnRendered = (givenClassName:string, dataElem : any)=> {
        return <Button
        className= {givenClassName}
            variant={"contained"}
            style={newStyles.downloadButton}
            startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
            onClick={this.handleCategoryReportDownloadPdf.bind(this, dataElem?.id)}>
            Download Report</Button>
    }

    handleCategoryFeatureBodyDisplayNestedFunc = () => {
        if(this.state.isCategoryDateSelected){
            return this.state.categoryDatesFilteredResponseData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                return (
                    <TableRow key={elem?.id}>
                        <TableCell>{elem?.attributes?.department?.name}</TableCell>
                        <TableCell>{`₹${elem?.attributes?.department?.rupee_spend }`}</TableCell>
                        <TableCell style={{ display: "flex" }}>
                            {
                                this.handleCategoryBtnRendered("category_filtered_report_btn", elem)
                            }
                        </TableCell>
                    </TableRow>
                )
            })
        } else {
            return this.state.mainSpendAnalysisCategoriesData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                return (
                    <TableRow key={elem?.id}>
                        <TableCell>{elem?.attributes?.name}</TableCell>
                        <TableCell>{`₹${elem?.attributes?.ruppee_spend}`}</TableCell>
                        <TableCell style={{ display: "flex" }}>
                            {
                                this.handleCategoryBtnRendered("category_report_btn", elem)
                            }
                        </TableCell>
                    </TableRow>
                )
            })
        }
    }

    handleCategoryFeatureDisplay = () => {
        if(this.state.spendAnalysis === "Category vs Rupee Spend" || this.state.isCategoryDateSelected){
            let newChartData = this.state.categoryChartsData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
            return (<Box display="flex" flexDirection={"row"} justifyContent="space-evenly" mb={2}  marginLeft={"40px"} width={"97.2%"} >
                <Grid item xl={6} lg={6} style={newStyles.reportsListContainerData}>
                    <Box style={newStyles.gridItem}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableCell style={newStyles.tableHeadText}>Categories</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Rupee Spend</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Download Reports</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.handleCategoryFeatureBodyDisplayNestedFunc()
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xl={6} lg={6} style={newStyles.reportsListContainerChart}>
                    <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.graphTitle}>Category vs Rupee Spend</Typography>
                        <Charts totalData={newChartData} dataKeyX='attributes.name' dataKeyY='attributes.ruppee_spend' />
                    </Box>
                </Grid>
            </Box>)
        }
    }

    handleSupplierFeaturesBodyDisplayNestedFunc = () => {
        const { isSupplierDateSelected, supplierDatesFilteredResponseData, mainSpendAnalysisSuppliersCategoryData } = this.state;
    
        const data = isSupplierDateSelected ? supplierDatesFilteredResponseData : mainSpendAnalysisSuppliersCategoryData;
    
        return data?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem) => {
          const { id, supplier_name, rupee_spend } = elem;
    
          return (
            <TableRow key={id}>
              <TableCell>{supplier_name}</TableCell>
              <TableCell>{`₹${rupee_spend}`}</TableCell>
              <TableCell style={{ display: "flex" }}>
                <Button
                  className={isSupplierDateSelected ? "supplier_filtered_report_btn" : "supplier_report_btn"}
                  variant="contained"
                  style={newStyles.downloadButton}
                  startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
                  onClick={this.handleSupplierReportDownloadPdf.bind(this, id)}
                >
                  Download Report
                </Button>
              </TableCell>
            </TableRow>
          );
        });
    };


    

    handleSupplierFeatureDisplay = () => {
        if(this.state.spendAnalysis === "Supplier vs Rupee Spend" || this.state.isSupplierDateSelected){
            let newData = this.state.mainSpendAnalysisSuppliersCategoryData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
            return (
                <Box display="flex" flexDirection={"row"} justifyContent="space-evenly" mb={2} marginLeft={"40px"} width={"97.2%"} >
                        <Grid item xl={6} lg={8} style={newStyles.reportsListContainerData}>
                            <Box style={newStyles.gridItem}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableCell style={newStyles.tableHeadText}>Supplier</TableCell>
                                            <TableCell style={newStyles.tableHeadText}>Rupee Spend</TableCell>
                                            <TableCell style={newStyles.tableHeadText}>Download Reports</TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.handleSupplierFeaturesBodyDisplayNestedFunc()
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={8} style={newStyles.reportsListContainerChart}>
                            <Box style={newStyles.gridItem}>
                                <Typography style={newStyles.graphTitle}>Supplier vs Rupee Spend</Typography>
                                <Charts totalData={newData} dataKeyX='supplier_name' dataKeyY='rupee_spend'/>
                            </Box>
                        </Grid>
                </Box>
            )
        }
    }

    handleItemsNumberFeatureBodyDisplayNestedFunc = () => {
        if(this.state.isItemNumbersDateSelected){
            return (
                this.state.itemNumbersDatesFilteredResponseData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    let item_products = elem?.attributes?.products
                    return (
                        item_products?.map((item:any, index:any) => {
                            return (
                                <TableRow key={elem?.id + item?.id}>
                                    <TableCell>{item?.id}</TableCell>
                                    <TableCell>{`₹${elem?.attributes?.department?.rupee_spend}`}</TableCell>
                                    <TableCell style={{ display: "flex" }}><Button
                                    className="items_filtered_report_btn"
                                        variant={"contained"}
                                        style={newStyles.downloadButton}
                                        startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
                                        onClick={this.handleItemsNumberReportDownloadPdf.bind(this, elem?.id)}>
                                        Download Report</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    )
                })
            )                
        } else {
            return (
                this.state.mainSpendAnalysisItemNumberData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    let item_products = elem?.attributes?.products
                    return (
                        item_products?.map((item:any, index:any) => {
                            return (
                                <TableRow key={elem?.id + item?.id}>
                                    <TableCell>{item?.id}</TableCell>
                                    <TableCell>{`₹${elem?.attributes?.ruppee_spend}`}</TableCell>
                                    <TableCell style={{ display: "flex" }}><Button
                                    className="items_report_btn"
                                        variant={"contained"}
                                        style={newStyles.downloadButton}
                                        startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
                                        onClick={this.handleItemsNumberReportDownloadPdf.bind(this, elem?.id)}>
                                        Download Report</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    )
                    
                })
            )
        }
    }

    handleItemsNumberFeatureDisplay = () => {
        if(this.state.spendAnalysis === "Item Number vs Rupee Spend" || this.state.isItemNumbersDateSelected){
            let newData = this.state.itemsNumberChartsData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))

            return (
                <Box display="flex" flexDirection={"row"} justifyContent="space-evenly" mb={2} marginLeft={"40px"} width={"97.2%"} >
                    <Grid item xl={6} lg={8} style={newStyles.reportsListContainerData}>
                        <Box style={newStyles.gridItem}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableCell style={newStyles.tableHeadText}>Item No</TableCell>
                                        <TableCell style={newStyles.tableHeadText}>Rupee Spend</TableCell>
                                        <TableCell style={newStyles.tableHeadText}>Download Reports</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.handleItemsNumberFeatureBodyDisplayNestedFunc()
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={8} style={newStyles.reportsListContainerChart}>
                        <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.graphTitle}>Item Number vs Rupee Spend</Typography>
                            <Charts totalData={newData} dataKeyX='item_id' dataKeyY='ruppee_spend' />
                        </Box>
                    </Grid>
                </Box>
            )
        }
    }

    handleDepartmentFeatureDisplayNestedFunc = () => {
        if(this.state.isDepartmentDateSelected){   
            return (
                this.state.departmentDatesFilteredResponseData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    return (
                        <TableRow key={elem?.id}>
                            <TableCell>{elem?.attributes?.department?.name }</TableCell>
                            <TableCell>{`₹${elem?.attributes?.department?.rupee_spend }`}</TableCell>
                            <TableCell style={{ display: "flex" }}><Button
                                variant={"contained"}
                                style={newStyles.downloadButton}
                                startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
                                onClick={this.handleDepartmentsReportDownloadPdf.bind(this, elem?.id)}>
                                Download Report</Button>
                            </TableCell>
                        </TableRow>
                    )
                })
            )                             
        } else {
            return (
                this.state.mainSpendAnalysisDepartmentCategoryData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    return (
                        <TableRow key={elem?.id}>
                            <TableCell>{elem?.attributes?.name}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.rupee_spend }`}</TableCell>
                            <TableCell style={{ display: "flex" }}><Button
                                variant={"contained"}
                                style={newStyles.downloadButton}
                                startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
                                onClick={this.handleDepartmentsReportDownloadPdf.bind(this, elem?.id)}>
                                Download Report</Button>
                            </TableCell>
                        </TableRow>
                    )
                })
            )
        }
    }

    handleDepartmentFeatureDisplay = () => {
        if(this.state.spendAnalysis === "Department vs Rupee Spend" || this.state.isDepartmentDateSelected){
            let newChartData = this.state.departmentChartsData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
            return (
                <Box display="flex" flexDirection={"row"} justifyContent="space-evenly" mb={2} marginLeft={"40px"} width={"97.2%"} >
                    <Grid item xl={6} lg={8} style={newStyles.reportsListContainerData}>
                        <Box style={newStyles.gridItem}>
                            <TableContainer>
                                <Table>
                                        <TableHead>
                                            <TableCell style={newStyles.tableHeadText}>Department</TableCell>
                                            <TableCell style={newStyles.tableHeadText}>Rupee Spend</TableCell>
                                            <TableCell style={newStyles.tableHeadText}>Download Reports</TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.handleDepartmentFeatureDisplayNestedFunc()
                                            }
                                        </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={8} style={newStyles.reportsListContainerChart}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.graphTitle}>Department vs Rupee Spend</Typography>
                            <Charts totalData={newChartData} dataKeyX='attributes.name' dataKeyY='attributes.rupee_spend'/>
                        </Box>
                    </Grid>
                </Box>
            )
        }
    }


    handleBudgetCategoryBodyFeatureDisplayNestedFunc = () => {
        if(this.state.isCategoryDateSelected){
            return (
                this.state.categoryDatesFilteredResponseData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    return (
                        <TableRow key={elem?.id}>
                            <TableCell>{elem?.attributes?.department?.name}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.department?.rupee_spend }`}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.department?.total_budget_amount }`}</TableCell>
                            <TableCell style={{ display: "flex" }}>
                                {
                                    this.handleCategoryBtnRendered('budget_category_filtered_report_btn', elem)
                                }
                            </TableCell>
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                this.state.mainBudgetCategoriesData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    return (
                        <TableRow key={elem?.id}>
                            <TableCell>{elem?.attributes?.name}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.ruppee_spend}`}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.total_budget_amount}`}</TableCell>
                            <TableCell style={{ display: "flex" }}>
                            {
                                this.handleCategoryBtnRendered('budget_category_report_btn', elem)
                            }
                            </TableCell>
                        </TableRow>
                    )
                }) 
            )
        }
    }


    handleBudgetCategoryFeatureDisplay = () => {
        if(this.state.spendAnalysis === "Category Budget vs Category Spend" || this.state.isCategoryDateSelected){
            let chartData = this.state.budgetCategoryChartsData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
            return (<Box display="flex" flexDirection={"row"} justifyContent="space-evenly" mb={2} marginLeft={"40px"} width={"97.2%"} >
                <Grid item xl={6} lg={8} style={newStyles.reportsListContainerData}>
                    <Box style={newStyles.gridItem}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableCell style={newStyles.tableHeadText}>Category</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Spend</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Budget</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Download Reports</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.handleBudgetCategoryBodyFeatureDisplayNestedFunc()
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xl={6} lg={8} style={newStyles.reportsListContainerChart}>
                    <Box style={newStyles.gridItem}>
                        <Box style={newStyles.graphIdentifierWrapper}>
                            <Typography style={{fontSize: '16px', fontWeight:500}}>Category Budget vs Category Spend</Typography>
                            <Box style={newStyles.graphIdentifierBox}>
                                <Box style={newStyles.graphSquareBlackBox} />
                                <span style={{fontSize: '16px', fontWeight:500}}>Category Budget</span>
                            </Box>
                            <Box style={newStyles.graphIdentifierBox}>
                                <Box style={newStyles.graphSquareYellowBox} />
                                <span style={{fontSize: '16px', fontWeight:500}}>Category Spend</span>
                            </Box>
                        </Box>

                        <Box>
                                <ResponsiveContainer width="100%" height={'85%'}>
                                <BarChart margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }} width={730} height={550} data={chartData} barCategoryGap={10} barGap={5} barSize={35} >
                                    <CartesianGrid strokeDasharray="1" />
                                    <XAxis dataKey='attributes.name' />
                                    <YAxis />
                                    <Tooltip contentStyle={{color:"red"}}   itemStyle={{color:"#000000"}} />
                                    <Bar dataKey='attributes.total_budget_amount' fill="black" />
                                    <Bar dataKey='attributes.ruppee_spend' fill="yellow"  />
                                </BarChart>
                                </ResponsiveContainer>
                            </Box>
                    </Box>
                </Grid>
            </Box>)
        }
    }

    handleBudgetDepartmentBodyFeatureDisplayNestedFunc = () => {
        if(this.state.isDepartmentDateSelected){
            return (
                this.state.departmentDatesFilteredResponseData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    return (
                        <TableRow key={elem?.id}>
                            <TableCell>{elem?.attributes?.department?.name}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.department?.rupee_spend }`}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.department?.total_budget_amount}`}</TableCell>
                            <TableCell style={{ display: "flex" }}><Button
                            className="budget_department_filtered_report_btn"
                                variant={"contained"}
                                style={newStyles.downloadButton}
                                startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
                                onClick={this.handleDepartmentsReportDownloadPdf.bind(this, elem?.id)}>
                                Download Report</Button>
                            </TableCell>
                        </TableRow>
                    )
                })
            )
        } else {
            return (
                this.state.mainBudgetDepartmentData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                    return (
                        <TableRow key={elem?.id}>
                            <TableCell>{elem?.attributes?.name}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.rupee_spend}`}</TableCell>
                            <TableCell>{`₹${elem?.attributes?.total_budget_amount}`}</TableCell>
                            <TableCell style={{ display: "flex" }}><Button
                            className = 'budget_department_report_btn'
                                variant={"contained"}
                                style={newStyles.downloadButton}
                                startIcon={<SystemUpdateAltRoundedIcon style={newStyles.iconStyling} />}
                                onClick={this.handleDepartmentsReportDownloadPdf.bind(this, elem?.id)}>
                                Download Report</Button>
                            </TableCell>
                        </TableRow>
                    )
                }) 
            )
        }
    }

    handleBudgetDepartmentFeatureDisplay = () => {
        if(this.state.spendAnalysis === "Department Budget vs Actual Spend" || this.state.isDepartmentDateSelected){
            let chartData = this.state.budgetDepartmentChartsData?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
            return (
                <Box display="flex" flexDirection={"row"} justifyContent="space-evenly" mb={2} marginLeft={"40px"} width={"97.2%"} >
                    <Grid item xl={6} lg={8} style={newStyles.reportsListContainerData}>
                        <Box style={newStyles.gridItem}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableCell style={newStyles.tableHeadText}>Department</TableCell>
                                        <TableCell style={newStyles.tableHeadText}>Spend</TableCell>
                                        <TableCell style={newStyles.tableHeadText}>Budget</TableCell>
                                        <TableCell style={newStyles.tableHeadText}>Download Reports</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.handleBudgetDepartmentBodyFeatureDisplayNestedFunc()
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={8} style={newStyles.reportsListContainerChart}>
                        <Box style={newStyles.gridItem}>
                            <Box style={newStyles.graphIdentifierWrapper}>
                                <Typography style={{fontSize: '16px', fontWeight:500}}>Department Budget vs Actual Spend</Typography>
                                <Box style={newStyles.graphIdentifierBox}>
                                    <Box style={newStyles.graphSquareBlackBox} />
                                    <span style={{fontSize: '16px', fontWeight:500}}>Department</span>
                                </Box>
                                <Box style={newStyles.graphIdentifierBox}>
                                    <Box style={newStyles.graphSquareYellowBox} />
                                    <span style={{fontSize: '16px', fontWeight:500}}>Actual Spend</span>
                                </Box>
                            </Box>

                            <Box>
                                {this.budgetDepartmentChartData(chartData)}
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            )
        }
    }
    budgetDepartmentChartData = (chartData:any)=>{
        return (<ResponsiveContainer width="100%" height={'85%'}>
        <BarChart margin={{
            right: 30,
            bottom: 5,
            top: 20,
            left: 20,
        }} width={730} height={550} data={chartData} barCategoryGap={10} barGap={5} barSize={35} >
            <CartesianGrid strokeDasharray="1" />
            <XAxis dataKey='attributes.name' />
            <YAxis />
            <Tooltip contentStyle={{color:"red"}} itemStyle={{color:"#000000"}} />
            <Bar dataKey='attributes.total_budget_amount' fill="black" />
            <Bar dataKey='attributes.rupee_spend' fill="yellow"  />
        </BarChart>
        </ResponsiveContainer>)
    }
     formatYAxisTick = (tickValue:number) => `${tickValue}%`;

    handleSupplierPerformanceFeatureDisplay =()=>{
        let data = this.state.isSupplierPerformanceDateSelected ? this.state.supplierPerformanceFilteredChartData :this.state.supplierPerformanceChartsResData;
        let chartData = data?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
        return (<Box display="flex" flexDirection={"row"} justifyContent="space-evenly" mb={2} marginLeft={"40px"} width={"97.2%"} >
                <Grid item xl={6} lg={8} style={newStyles.reportsListContainerData}>
                    <Box style={newStyles.gridItem}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableCell style={newStyles.tableHeadText}>Suppliers</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Delayed</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Timely</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Grand Total</TableCell>
                                    <TableCell style={newStyles.tableHeadText}>Adherance</TableCell>
                                    <TableCell></TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.handleSupplierPerformanceBodyDisplayNestedFun()
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xl={6} lg={8} style={newStyles.reportsListContainerChart}>
                    <Box style={newStyles.gridItem}>
                        <Box style={newStyles.graphIdentifierWrapper}>
                            <Typography style={{fontSize: '16px', fontWeight:500}}>Supplier Performance</Typography>
                        </Box>

                        <Box>
                                <ResponsiveContainer width="100%" height={'85%'}>
                                <BarChart margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }} width={730} height={550} data={chartData} barCategoryGap={10} barGap={5} barSize={35} >
                                    <CartesianGrid strokeDasharray="1" />
                                    <XAxis dataKey='attributes.supplier_name' />
                                    <YAxis tickFormatter={this.formatYAxisTick} />
                                    <Tooltip contentStyle={{color:"red"}} itemStyle={{color:"#000000"}} />
                                    <Bar dataKey='attributes.supplier_percentage' fill="yellow"  />
                                </BarChart>
                                </ResponsiveContainer>
                            </Box>
                    </Box>
                </Grid>
            </Box>)
    }

    handleSupplierPerformanceBodyDisplayNestedFun = ()=>{
        let data = this.state.isSupplierPerformanceDateSelected ? this.state.supplierPerformanceFilteredData :  this.state.supplierPerformanceResData;
        return (
            data?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage))).map((elem : any, index:any) => {
                return (
                    <TableRow key={elem?.id}>
                        <TableCell style={newStyles.tableCellText}>{elem?.supplier_name}</TableCell>
                        <TableCell>{`${elem?.delayed}`}</TableCell>
                        <TableCell>{`${elem?.timely}`}</TableCell>
                        <TableCell>
                            {elem?.delayed + elem?.timely}
                        </TableCell>
                        <TableCell>
                            {elem?.total_result ? elem?.total_result+'%' : 0+'%'}
                        </TableCell>
                        <TableCell>
                        <Button
                                 variant={"contained"}
                                 id="supplier_performance_report_btn"
                                 onClick={this.handleSupplierPerformanceReportDownload.bind(this,elem?.id)}
                                style={newStyles.downloadButton}
                                startIcon={<SystemUpdateAltRoundedIcon  style={newStyles.iconStyling} 
                                />}>
                                Download Report</Button>
                        </TableCell>
                    </TableRow>
                )
            })
        )
    }

    handleCoreFeaturesDisplayDriverFunc = () => {
        if (this.state.subTabValue === 1){
            return (
                <>
                    {this.handleBudgetCategoryFeatureDisplay()}
                    {this.handleBudgetDepartmentFeatureDisplay()}
                    <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <PaginationDisplay
              totalPages={this.state.totalPages}
              currentPage={this.state.currentPage}
              handlePageChange={this.handlePageChange}
            />
          </Box>
                </>
            )
        }else if (this.state.subTabValue === 2){
            return (
                <>
                    {this.handleSupplierPerformanceFeatureDisplay()}
                    <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <PaginationDisplay
              totalPages={this.state.totalPages}
              currentPage={this.state.currentPage}
              handlePageChange={this.handlePageChange}
            />
          </Box>
                </>
            )
        }
         else {
            return (
                <>
                    {this.handleCategoryFeatureDisplay()}
                    {this.handleSupplierFeatureDisplay()}
                    {this.handleItemsNumberFeatureDisplay()}
                    {this.handleDepartmentFeatureDisplay()}
                    <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <PaginationDisplay
              totalPages={this.state.totalPages}
              currentPage={this.state.currentPage}
              handlePageChange={this.handlePageChange}
            />
          </Box>
                </>
            );
        }
    }

    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({
          currentPage: value
        })
      }



    // Customizable Area End

    render() {

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 10;
        const reportsMenuProps = {
            PaperProps: {
                style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 100,
                marginTop: 40
                },
            },
        };



        

        return (
            // Customizable Area Start
            
            <ThemeProvider theme={theme}>
          <ToastMessageDisplay message={this.state.categoryFinalPdfDownloadOrNotMessage} handleClose={this.handleCategoryFinalPdfDownloadOrNotMessageClose} />
          <ToastMessageDisplay message={this.state.supplierFinalPdfDownloadOrNotMessage} handleClose={this.handleSupplierFinalPdfDownloadOrNotMessageClose} />
          <ToastMessageDisplay message={this.state.itemsNumberFinalPdfDownloadOrNotMessage} handleClose={this.handleItemsNumberFinalPdfDownloadOrNotMessageClose} />
          <ToastMessageDisplay message={this.state.departmentFinalPdfDownloadOrNotMessage} handleClose={this.handleDepartmentFinalPdfDownloadOrNotMessageClose} />

                <Container maxWidth="xl" style={{ position: 'relative', width: '100%', margin: '0px', padding: '0px' }}>
                    <Box style={newStyles.mainContainer}>
                        <Header navigation={this.props.navigation} active="reports" profile={this.state.userNameProfile} 
                        loggedUserName = {this.state.loggedUserNameCatchReport}
                        loggedUserEmail = {this.state.loggedUserEmailCatchReport}
                        id="" permissionLoginUserDetailsState= {this.state.userLoginDetailsCatch}/>
                        <Box style={newStyles.settingsListContainer}>
                            <Box style={{ padding:'1rem', width: '96vw', display: 'flex', alignItems:"center", justifyContent:"space-between", flexWrap: 'wrap' }}>
                                <Tabs
                                    value={this.state.subTabValue}
                                    data-test-id="txtSubValue"
                                    onChange={this.handleSubTabValueChange}
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        }
                                    }}>
                                    <StyledTab style={newStyles.menuListItems} label="Spend Analysis"  wrapped />
                                    <StyledTab style={newStyles.menuListItems} label="Budget" wrapped />
                                    <StyledTab style={newStyles.menuListItems} label="Supplier Performance" wrapped />
                                </Tabs>
                                <Box>
                                {this.state.subTabValue !== 2 && <StyledSelect
                                    variant="filled"
                                    label="Select Items"
                                    testID="txtSelectField"
                                    defaultValue="Department vs Rupee Spend"
                                    style={newStyles.textFieldBox1}
                                    value={this.state.spendAnalysis }
                                    onChange={this.handleSelectFieldChange}
                                    MenuProps={
                                        reportsMenuProps
                                    }
                                    disableUnderline
                                    displayEmpty
                                    input={<Input />}
                                >
                                    {
                                        this.handleDriverMyReportsFeatureSelectFiltering()
                                    }
                                </StyledSelect> }

                                <StyledSelect
                                testID="dateSelectField"
                                    variant="outlined"
                                    label="Filter By dates"
                                    style={newStyles.textFieldBox1}
                                    value={this.state.spendAnaysisDateValue}
                                    onChange={this.handleSelectSpendAnaysisDatesChange}
                                    MenuProps={
                                        reportsMenuProps
                                    }
                                    disableUnderline
                                    displayEmpty
                                    input={<Input />}
                                >
                                    <MenuItem value="" disabled style={{ fontSize: '16px'}}>Filter By Date</MenuItem>
                                    {
                                        this.handleDriverMyReportsDateSelectFiltering(this.state.spendAnalysis)
                                    }
                                </StyledSelect>
                                </Box>
                            </Box>
                        </Box>
                        {
                            this.handleCoreFeaturesDisplayDriverFunc()
                        }
                    </Box>

                </Container>
            </ThemeProvider>
        );
    }
}




// Customizable Area Start
const theme = createTheme({
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: '20px 0px',
        },
        button: {
            textTransform: 'none',
        }
    },
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#fff',
        },
    },

});

const newStyles = {
    
    mainContainer: {
        backgroundColor: 'rgba(242, 242, 236, 1)',
        width: '100%',
        flex: '1',
        alignItems: "flex-start",
        boxSizing: 'border-box' as 'border-box',
    },
    textFieldBox1: {
        backgroundColor: '#F2F2EC',
        width: '328px',
        height: '56px',
        borderRadius: '10px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: '500',
        boxSizing: 'border-box' as 'border-box',
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
        border: '1px solid #ffffff',
        focusDisable:{
            backgroundColor:"transparent" as "transparent",
        }
    },
    
    logoImg: {
        width: '129.63px',
        height: '40px',
        paddingLeft: '20px',
        paddingTop: '40px',
    },
    headerContainer: {
        display: 'flex',
        displayDirection: 'column',
        flex: '1',
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        margin: 'auto',
        height: '120px',
        width: '100vw',
        boxSizing: 'border-box' as 'border-box',
    },
    
    avatar: {
        color: 'white',
        backgroundColor: 'blue',
        flex: '1',
    },

    avatarContainer: {
        display: 'flex',
        displayDirection: 'column',
        height: '40px',
        paddingRight: '20px',
        paddingTop: '40px',
        justifyContent: 'baseline',
    },
    
    tabsContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#F8F8F5',
        margin: 'auto',
        height: '96px',
        width: '100vw',
        boxShadow: '#F0F0ED 0px 60px 60px -80px inset',
        boxSizing: 'border-box' as 'border-box',
    },
    avatarDropdownMenu: {
        margin: 0,
        paddingLeft: '50px',
        flex: '1',
    },
    
    menuListContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        height: '86px',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '35px',
        display: 'flex',
        boxSizing: 'border-box' as 'border-box',
    },
    tabMenu: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        fontWeight: 400,
        paddingTop: '35px',
        marginLeft: '40px',
        lineSpacing: '29',
        letterSpacing: '-0.5',
    },
    
    dropDown: {
        minWidth: '328px',
        maxHeight: '56px',
        marginLeft: '20px',
        marginTop: '15px',
        backgroundColor: '#F2F2EC',
        fontSize: '16',
        margin: 'dense',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    menuListItems: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        lineSpacing: '19',
        letterSpacing: '-0.33',
        justifyContent: 'space-between',
        opacity: 1,
    },
    
    divider: {
        width: '1px',
        height: '30px',
        marginLeft: '70px',
        marginTop: '28px',
    },
    dropDownItems: {
        lineSpacing: '19',
        letterSpacing: '-0.33',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    createButton: {
        marginLeft: 'auto',
        position: 'relative' as 'relative',
        width: '289px',
        height: '56px',
        marginRight: '2%',
        marginBottom: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        boxSizing: 'border-box' as 'border-box',
        color: 'rgba(28, 28, 26, 1)',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    
    tableHeaderRows: {
        lineSpacing: '17',
        letterSpacing: '-0.29',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    tableContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginBottom: '20px',
        marginTop: '5px',
        paddingBottom: '50px',
        paddingLeft: '20px',
        paddingRight: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    
    icons: {
        fontSize: 'medium',
        paddingRight: '10px',
        cursor: 'pointer',
    },
    tableBodyRows: {
        lineSpacing: '19',
        letterSpacing: '0',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    
    button: {
        position: 'relative' as 'relative',
        width: '328px',
        height: '56px',
        marginLeft: '81vw',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    iconColumn: {
        whiteSpace: 'nowrap' as 'nowrap',
    },
    
    fileAttachmentButtonIcon: {
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        fontWeight: 600,
        boxSizing: 'border-box' as 'border-box',
    },
    
    createLongTextButtonIcon: {
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        marginRight: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    
    buttonText: {
        position: 'absolute',
        width: '104',
        height: '16',
        marginLeft: '16',
        marginTop: '14',
        opacity: '1',
        backgroundColor: 'transparent',
        fontStyle: 'normal',
        fontWeight: 'normal',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        boxSizing: 'border-box' as 'border-box',
    },
    createButtonIcon: {
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        marginRight: '70px',
        boxSizing: 'border-box' as 'border-box',
    },


    userAccessPermissionDialog: {
        margin: '0 auto',
        width: '965px',
        boxSizing: 'border-box' as 'border-box',
    },

    errorBox: {
        padding: '0 30px 0 30px',
        marginTop: '30px',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,

    },
    attachmentButton: {
        width: '210px',
        height: '56px',
        opacity: '1',
        backgroundColor: '#FAEBE6',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    createButton1: {
        marginLeft: '10px',
        position: 'relative' as 'relative',
        width: '289px',
        height: '56px',
        marginRight: '2%',
        marginBottom: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        boxSizing: 'border-box' as 'border-box',
        color: 'rgba(28, 28, 26, 1)',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },

    textFieldBox: {
        backgroundColor: '#FFFFFF',
        width: '400px',
        minHeight: '60px',
        borderRadius: '10px',
        position: 'relative' as 'relative',
        marginBottom: '10px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    errorModalButton: {
        position: 'relative' as 'relative',
        width: '100px',
        height: '45px',
        margin: 'auto 0px',
        marginTop: '15px',
        marginBottom: '15px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },

    dialog: {
        margin: '0 auto',
        width: '1360px',
        boxSizing: 'border-box' as 'border-box',
    },
    modalCancelButton: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        margin: '20px',
        opacity: '1',
        backgroundColor: '#FAEBE6',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',

        includeFontPadding: false,
        padding: '0',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    errorDialog: {
        padding: '100px',
        width: '1200px',
        marginTop: '100px',
        marginBottom: '100px',
    },
    
    DateBox: {
        marginTop: '15px',
        backgroundColor: '#FFFFFF',
        width: '400px',
        minHeight: '60px',
        borderRadius: '10px',
        paddingBottom: '10px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    modalHeading: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        fontWeight: 600,
        padding: '20px',
        marginBottom: '30px',
        boxSizing: 'border-box' as 'border-box',
        color: 'black',
    },
    textFieldBoxSmall: {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        width: '182px',
        minHeight: '60px',
        borderRadius: '10px',
        position: 'relative' as 'relative',
        marginBottom: '10px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    
    gridItem: {
        paddingLeft: '20px',
        paddingBottom: '20px',
    },
    modalHeadingArrow: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        fontWeight: 600,
        marginBottom: '30px',
        boxSizing: 'border-box' as 'border-box',
        color: 'black',
        paddingTop: '24px',
    },
    validationText: {
        textAlign: 'right' as 'right',
        position: 'relative' as 'relative',
        width: '325px',
        margin: '0 auto',
        opacity: '1',
        backgroundColor: 'transparent',
        padding: '0px',
        color: 'rgba(28, 28, 26, 1)',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '12',
        fontStyle: 'italic',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    
    gridItemLabel: {
        marginBottom: '10px',
        fontFamily: 'Plus Jakarta Display',
    },
    
    selectBox: {
        width: '400px', 
        minHeight: '56px',
        borderRadius: '10px',
        border: '#B1B1AE 0.005px solid',
        marginBottom: '10px',
        boxSizing: 'border-box' as 'border-box',
    },
    
    selectBoxLabel: {
        color: '#B1B1AE',
        width: '400px',
        minHeight: '56px',
        borderRadius: '10px',
        border: '#B1B1AE 0.005px solid',
        marginBottom: '10px',
        boxSizing: 'border-box' as 'border-box',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 350,
    },
    gridItemforUserPermissions: {
        paddingLeft: '20px',
        paddingBottom: '20px',
        marginBottom: '5%',
    },
    
    modalButton: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        margin: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    
      
    textFieldEndAdornment: {
        color: '#B1B1AE',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 300,
    },
    termsHeadingTop: {
        height: '22px',
        color: '#1c1c1a',
        fontFamily: "Plus Jakarta Display",
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.375px',
    },
    
    
    termsBodyTop: {
        height: '17px',
        color: '#808080',
        fontFamily: "Plus Jakarta Display",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '-0.29166666px',
        marginTop: '15px',
        marginBottom: '20px',
    },
    modalLabel: {
        position: 'relative' as 'relative',
        bottom: '10px',
        height: '15px',
        color: '#808080',
        fontFamily: "Plus Jakarta Display",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '15px',
    },
    termsHeadingBottom: {
        height: '22px',
        color: '#1c1c1a',
        fontFamily: "Plus Jakarta Display",
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.375px',
        marginTop: '5px',
    },
    settingsListContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '35px',
        boxSizing: 'border-box' as 'border-box',
        marginBottom: '20px',
    },
    termsBodyBottom: {
        height: '17px',
        color: '#808080',
        fontFamily: "Plus Jakarta Display",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '-0.29166666px',
        marginTop: '15px',
        marginBottom: '15px',
    },

    productText: {
        marginTop: '30px',
        marginLeft: '25px',
        width: '82px',
        height: '24px',
        color: '#1c1c1a',
        fontFamily: "Plus Jakarta Display",
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '-0.41666666px',
    },
    reportsListContainerData: {
        display: "flex",
        flexDirection: "column" as "column",
        backgroundColor: '#FFFFFF',
        width: '90% !important',
        height:"85vh",
        overflow: 'auto',
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '55px',
        boxSizing: 'border-box' as 'border-box',
        marginBottom: '20px',
        marginRight: '28px',
        // marignLeft: '28px' as "28px",

    },
    settingsTextField: {
        backgroundColor: '#F2F2EC',
        width: '328px',
        minHeight: '56px',
        borderRadius: '10px',
        paddingBottom: '10px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        marginLeft: '0px',
        marginTop: '16px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '14',
        fontWeight: 300,
        boxSizing: 'border-box' as 'border-box',
    },
    reportsListContainerChart: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        display: "flex",
        flexDirection: "column" as "column",
        width: 'auto',
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '55px',
        boxSizing: 'border-box' as 'border-box',
        marginBottom: '20px',
        marginRight: '28px',
        marignLeft: '28px' as '28px',
        overflow: 'scroll',
    },

    backWithArrow: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        marginRight: 'auto',
        paddingLeft: '180px',
        cursor: 'pointer',
    },

    downloadButton: {
        position: 'relative' as 'relative',
        width: '180px',
        height: '50px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        boxSizing: 'border-box' as 'border-box',
        color: 'rgba(28, 28, 26, 1)',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '14px',
    },
    iconStyling: {
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        color: 'black',
        textAlign: 'center' as 'center',
        fontWeight: 600,
        boxSizing: 'border-box' as 'border-box',
    },

    dateSelectionBox: {
        marginLeft: '20px', 
        display: 'flex', 
        justifyContent: 'end', 
        alignItems: 'center', 
        gap: '30px', 
    },
    
    graphIdentifierBox : {
        display: 'flex', gap:'3px'
    },
    graphSquareBlackBox : {
        backgroundColor: 'black', width: 14, height: 14, marginRight: 8, border: '1px solid #000' 
    },
    graphIdentifierWrapper : {
        display: 'flex', justifyContent:'space-between', alignContent: 'center', margin: '20px 20px 20px 0' 
    },
    
    graphTitle : {
        fontSize: '17px', margin: '20px' ,
        fontFamily: 'Plus Jakarta Display',
    },
    graphTitleBlack : {
        fontSize: '14px', margin: '20px 20px 20px 0' 
    },
    graphSquareYellowBox : {
        backgroundColor: 'yellow', width: 14, height: 14, marginRight: 8, border: '1px solid #000' 
    },
    bar: {
        position: 'unset' as 'unset',
        width: '798px',
        height: '100px',
    },

    cartesianGrid: {
        height: "100%",
    },

    barChart: {
        position: "unset",
        width: "808px",
        height: "425px",
        display: "flex",
        flexDirection: "column-reverse"
    },

    tableCellText:{
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "30px",
  maxWidth:"30px"
    },
    
    tableHeadText: {
        fontSize: '17px',
        fontWeight:500,
        fontFamily: 'Plus Jakarta Display',
    }

}
// Customizable Area End
