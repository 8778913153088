import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock'
import { createTheme, withStyles } from '@material-ui/core/styles';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { imgLogo } from "./assets";

const StyledTextField = withStyles({
  root: {
    //fontFamily: 'PlusJakartaDisplay-Regular',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '14',
      fontWeight: 400,
    }
  }
})((props: any) => <TextField {...props} />);

// Customizable Area End

import CreatePasswordController, {
  Props,
} from "./EmailAccountLoginController";

export default class CreatePassword extends CreatePasswordController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  // Customizable Area End

  // Customizable Area Start

  render() {
    return (
      <div>
        <Box style={newStyles.mainContainer}>
          <Box style={newStyles.imageContainer}>
            <img src={imgLogo} style={newStyles.image} />
          </Box>
          <Box style={newStyles.contentContainer}>
            <Box style={{ display: 'grid', gap: '5px' }}>
              <StyledTextField
                test-id="txtInputCreatePassword"
                style={styles.textFieldBox}
                required
                inputProps={{
                  style: {
                    fontSize: 14,
                    fontFamily: 'Plus Jakarta Display',
                  },
                }}
                type={this.state.createPasswordType === 'password' ? 'password' : 'text'}
                value={this.state.createPassword}
                placeholder="Create Password"
                variant="filled"
                onChange={this.handleCreatePasswordChange}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment
                      style={
                        this.state.createPassword === ''
                          ? styles.textFieldIconInitially
                          : styles.textFieldIcon
                      }
                      position="start"
                    >
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      test-id="btnCreatePasswordShowHide"
                      position="end"
                      style={newStyles.textFieldSuffixIcon}
                      onClick={this.handleCreatePasswordChangeVisibilityIcon}
                    >
                      {this.state.createPasswordType === 'password' ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Typography style={newStyles.validationText}>
                {this.state.showCreatePasswordSubmitValidation && this.state.createPassword === '' ? this.state.createPasswordFieldError : ''}
              </Typography>
              <div style={{display: 'grid', gap: '5px'}}>
                <Typography><b>Password must contain the following:</b></Typography>
                <Typography
                  style={{
                    color:
                      this.state.createPassword.length >= 6 ? 'green' : 'red',
                  }}
                >
                  {this.state.createPassword.length >= 6 ? '✓ Minimum of 6 characters*' : 'x Minimum of 6 characters*'}
                </Typography>
                <Typography
                  style={{
                    color: this.state.specialCharacterMet ? 'green' : 'red',
                  }}
                >
                  {this.state.specialCharacterMet ? '✓ At least one special character*' : 'x At least one special character*'}
                </Typography>
                <Typography
                  style={{
                    color: this.state.numberMet ? 'green' : 'red',
                  }}
                >
                  {this.state.numberMet ? '✓ At least one number*' : 'x At least one number*'}
                </Typography>
                <Typography
                  style={{
                    color: this.state.capitalLetterMet ? 'green' : 'red',
                  }}
                >
                  {this.state.capitalLetterMet ? '✓ At least one capital (Uppercase) letter*' : 'x At least one capital (Uppercase) letter*'}
                </Typography>
              </div>
              <Button
                onClick={this.handleCreatePassword}
                data-test-id="btnCreatePasswordSubmit"
                style={styles.button}
                variant="contained"
              >
                Create Password
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      // main: '#fff',
      // contrastText: '#fff',
      main: '#FFFF00',
    },
  },
  typography: {
    subtitle1: {
      margin: '20px 0px',
    },
    h6: {
      fontWeight: 500
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto-Medium',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonStyle: {
    width: '100%',
    backgroundColor: 'rgb(98, 0, 238)',
    height: '45px',
    marginTop: '40px',
    border: 'none',
  },
  button: {
    color: '#ffff'
  }
};

const styles = {
  container: {
    position: 'absolute',
    bgColor: '#F2F2EC',
    height: '100%',
    width: '100%'
  },
  box: {
    backgroundColor: '#F2F2EC',
  },
  selectBox: {
    backgroundColor: '#F2F2EC',
    borderRadius: '10px',
    minHeight: '60px',
    paddingLeft: '7px',
  },
  selectBoxLabel: {
    color: '#B1B1AE',
    backgroundColor: '#F2F2EC',
    minHeight: '60px',
    borderRadius: '10px',
    paddingLeft: '7px',
  },
  textFieldBox: {
    width: '328px',
    minHeight: '56px',
    borderRadius: '10px',
    backgroundColor: '#F2F2EC',
    paddingBottom: '10px',
    margin: '0 auto',
    position: 'relative' as 'relative',
    // width: '224px',
    // height: '24px',
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: '400',
    boxSizing: 'border-box' as 'border-box',
  },
  button: {
    backgroundColor: '#FFFF00',
    width: '328px',
    height: '56px',
    margin: 'auto',
    marginTop: '16px',
    marginLeft: '0px',
    padding: '5px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    textTransform: 'none' as 'none',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },

  textFieldIconInitially: {
    fontSize: 'small',
    paddingRight: '12px',
    color: 'black',
  },
  textFieldIcon: {
    paddingRight: '12px',
    fontSize: 'small',
  }
}

const newStyles = {
  mainContainer: {
    position: 'absolute' as 'absolute',
    width: '100%',
    top: '60px',
    paddingBottom: '50px',
    backgroundColor: 'rgba(242, 242, 236, 1)',
    //width: '0 auto',
    height: 'auto',
    flex: '1',
    alignItems: "flex-start",
    //boxSizing: 'border-box' as 'border-box',
  },
  imageContainer: {
    position: 'relative' as 'relative',
    width: '264px',
    margin: 'auto',
    height: '130px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: 'rgba(242, 242, 236, 1) 10px solid',
    borderRadius: '15px',
    marginTop: '10px',
    //borderWidth: '10px',
    zIndex: 1,
    boxSizing: 'border-box' as 'border-box',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'row' as 'row',
  },
  contentContainer: {
    position: 'relative' as 'relative',
    width: '700px',
    margin: '0 auto',
    paddingBottom: '90px',
    //height: '475px',
    //marginLeft: '370px',
    //marginTop: '332px',
    marginBottom: '20px',
    marginTop: '-70px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '10px',
    //borderWidth: '0px',
    zIndex: 0,
    boxSizing: 'border-box' as 'border-box',
    paddingTop: '90px',
    //paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    //paddingBottom: '20px',
  },
  contentContainerSmall: {
    position: 'relative' as 'relative',
    width: '700px',
    margin: '0 auto',
    height: '350px',
    //marginLeft: '370px',
    //marginTop: '332px',
    marginTop: '-70px',
    marginBottom: '20px',
    borderRadius: '10px',
    //borderWidth: '0px',
    zIndex: 0,
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxSizing: 'border-box' as 'border-box',
    paddingTop: '90px',
    paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    position: 'relative' as 'relative',
    width: '176px',
    height: '54px',
    opacity: '1',
    resizeMode: 'contain',
    //marginLeft: '632px',
    //marginTop: '305px',
    marginLeft: '32px',
    marginTop: '30px',
    boxSizing: 'border-box' as 'border-box',
  },
  textFields: {
    position: 'absolute',
    width: '224',
    height: '24',
    marginLeft: '58',
    marginTop: '16',
    opacity: '1',
    fontStyle: 'normal',
    fontWeight: 400,
    //includeFontPadding: false,
    //padding: '0',
    padding: '10px',
    color: 'rgba(128, 128, 128, 1)',
    backgroundColor: 'transparent',
    textAlign: 'left',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Regular',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    boxSizing: 'border-box' as 'border-box',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    //height: '16px',
    margin: '0 auto',
    marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'red',
    textAlignVertical: 'top',
    boxSizing: 'border-box' as 'border-box',
    //fontFamily: 'PlusJakartaDisplay-Italic',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
  },
  button: {
    //position: 'absolute',
    position: 'relative' as 'relative',
    width: '328px',
    height: '56px',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    marginLeft: '556px',
    marginTop: '645px',
    opacity: '1',
    borderRadius: '10px',
    justifyContent: 'center',
    boxSizing: 'border-box' as 'border-box',
  },
  buttonText: {
    position: 'absolute',
    width: '104',
    height: '16',
    marginLeft: '16',
    backgroundColor: 'transparent',
    marginTop: '14',
    opacity: '1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    //includeFontPadding: false,
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    boxSizing: 'border-box' as 'border-box',
    textAlign: 'center',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  signUpText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    margin: '0 auto',
    marginLeft: '0px',
    opacity: '1',
    color: 'rgba(28, 28, 26, 1)',
    backgroundColor: 'transparent',
    padding: '0px',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
    marginBottom: '10px',
  },
  signUp: {
    backgroundColor: 'transparent',
    position: 'relative' as 'relative',
    fontFamily: 'Plus Jakarta Display',
    boxSizing: 'border-box' as 'border-box',
    fontSize: '14px',
    fontStyle: 'normal' as 'normal',
    fontWeight: 300,
    textDecoration: 'underline',
  },
  backWithArrow: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'baseline',
    cursor: 'pointer',
  },
  backIcon: {
    width: '20px',
    height: '14px',
  },
  backText: {
    width: '32px',
    fontFamily: "Plus Jakarta Display",
    height: '17px',
    color: '#1c1c1a',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '-0.15px',
  },
  passwordChangeMessageContainer: {
    position: 'relative' as 'relative',
    //top: '367px',
    margin: '0 auto',
    height: '355px',
    borderRadius: '10px',
    width: '700px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    //backgroundColor: 'rgba(255, 255, 255, 1)',
    zIndex: 0,
    boxSizing: 'border-box' as 'border-box',
    backgroundColor: '#ffffff',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
    paddingTop: '90px',
    paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldSuffixIcon: {
    fontSize: 'small',
    paddingTop: '12px',
    cursor: 'pointer'
  },
}

// Customizable Area End
