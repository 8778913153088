import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    TextField,
    Input,
    Button,
    Typography,
    Grid,
    Select,
    MenuItem,
    TableContainer,
    Table,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Dialog,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    InputAdornment,
    Paper,
    IconButton,
    InputBase,
    Divider,
    Menu,
} from '@material-ui/core';
import { checkedRadioButton,uncheckedRadioButton,checkedUserPermissionIcon,uncheckedUserPermissionIcon } from '../src/assets'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import { DeleteOutline, EditOutlined,  } from '@material-ui/icons';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Header from "./Header";
import ToastMessageDisplay from "./ToastMessageDisplay";
import TableDisplay from "./TableDisplay";
import PaginationDisplay from "./PaginationDisplay";
import Loading from "./Loading";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';



const StyledTextField = withStyles({
    root: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            '& fieldset': {
                borderRadius: "10px",
            },
        },
        "& input::placeholder": {
            fontFamily: 'Plus Jakarta Display',
            fontSize: '16',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
            backgroundColor: "transparent",
            border: "0.05px solid black"
        },
        "& .Mui-focused": {
            border: "0.05px solid black",
            borderRadius: '10px',
            maxHeight: '57px',
        }
    }
})((props: any) => <TextField {...props} />);

const StyledTextField1 = withStyles({
    root: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '14',
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiInputBase-input": {
            height: '0px'
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: 'Plus Jakarta Display',
            fontSize: '14',
            fontWeight: 400,
        }
    }
})((props: any) => <TextField {...props} />);

const StyledSelect = withStyles({
    root: {
        paddingLeft: 15,
        "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            border: "0.05px solid #B1B1AE"
        },
        "& .MuiOutlinedInput-root:hover": {
            backgroundColor: "transparent",
            border: "0.05px solid #B1B1AE",
            "@media (hover: none)": {
                backgroundColor: "transparent",
                border: "0.05px solid #B1B1AE"
            }
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
            backgroundColor: "transparent",
            border: "0.05px solid #B1B1AE"
        },
        '&:focus': {
            backgroundColor: 'white',
            borderColor: '#B1B1AE'
        },
        '&:before': {
            borderColor: '#B1B1AE',
        },
        '&:after': {
            borderColor: '#B1B1AE',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white',
        },
    },
})((props: any) => <Select {...props} />);

// Customizable Area End

import DashboardController, {
    Props,
    configJSON
} from "./DashboardController";
import Loader from "../../../components/src/Loader";
import ViewDetailsDialog from "./ViewDetailsDialog";

export default class Settings extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    addInformationSettingsValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any) => {
      if(itemsToBeValidated && fieldState==""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }


    paymentTermTextSettingsCreateValidationErrorRendered = (itemsToBeValidated: boolean,  fieldState:string, errorToBeShow : string) => {
        if(itemsToBeValidated && fieldState ===""){
            return errorToBeShow
        }
        else{
            return ''
        }
    }

    paymentTermCreadiInvalidMatchSettingsErrorRendered = (itemsToBeValidated: boolean,  fieldState:string, errorToBeShow : string) => {
        if(itemsToBeValidated && fieldState && !fieldState.match(/^[0-9 .!?"-]+$/)){
            return errorToBeShow
        } else {return ""}
    }


    taxationSettingsUpdateValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : string) => {
      if(itemsToBeValidated && fieldState ==="" && errorToBeShow !== ""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }

    taxationSettingsCreateValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : string) => {
      if(itemsToBeValidated && !fieldState){
          return errorToBeShow
      }
      else{
          return ''
      }
    }

    
    handleInformationSettingsPinCodeRender = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsPinCode && !RegExp(/^\d+$/)?.exec(this.state.informationSettingsPinCode)){
            return this.state.informationSettingsPinCodeMatchError
        }
        else {
            return ""
        }
    }

    saveLocationsValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any,type?:string) => {
        let pattern = /^[a-zA-Z0-9 ]*$/;
        const commonRegex= /^[a-zA-Z0-9 ]*$/
        switch (type) {
            case "address":
            case "code":
            case "gst":
                pattern = commonRegex
                break;
        }
        if(itemsToBeValidated && fieldState==="" ){
            return errorToBeShow
        }else if(itemsToBeValidated &&  !pattern.test(fieldState)){
            return errorToBeShow
        }
        else{
            return ''
        }
    }

    LocationsValidationGSTLengthErrorRendered = (itemsToBeValidated:boolean,  fieldState:string, errorToBeShow : string) => {
        if(itemsToBeValidated && fieldState && fieldState.length < 15 || fieldState.length > 20){
            return errorToBeShow
        } else {
            return ""
        }
    }


    infoGSTNumberLengthErroRendered = () => {
        const {informationSettingsGstNumber, infoSettingGstLengthError} = this.state
        const gstNumberRegex = /^[0-9A-Za-z]{15,20}$/;

        if (informationSettingsGstNumber && !gstNumberRegex.test(informationSettingsGstNumber)) {
            return infoSettingGstLengthError
        }
    }

    saveLocationsCharLimitsErrorRendered = (elemsToBeValidated: boolean,  fieldState:any, errorToBeShow : string) => {
        if(elemsToBeValidated && fieldState && fieldState.length <= 1 ){
            return errorToBeShow
        }else{
            return ''
        }
    }


    styledSelectStyle = (style: any) => {
      if(style === ""){
          return newStyles.selectBoxLabel; 
      }else{
          return newStyles.selectBox;
      }
    }


    userListPermissionStyleSelectAll = () => {
      if(Object.values(this.state.userPermissionsList1).every(value => value)){
        return newStyles.userPermissionTextSelectAll
      }else{
        return newStyles.userPermissionsUncheckedSelectAll
      }
    }

    


    userListPermissionStyle = (permissionStyle: any) => {
      if(permissionStyle){
        return newStyles.userPermissionText
      }else{
        return newStyles.userPermissionsUnchecked
      }
    }

    addCurrencySettingCurrency = (currency: any) => {
      if(!currency){
        return "";
      }else{
        return currency;
      }
    }

    

    addCurrencySettingValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any) => {
      if(itemsToBeValidated && !fieldState){
          return errorToBeShow
      }
      else{
          return ''
      }
    }
    handleCategorySettingsClick = ()=>{
        this.handleShowCategorySettings()
        this.handleGetDataCategorySettings()
    }
    
    measurementsClickHandle = ()=>{
        this.handleShowUnitMeasurementSettings()
        this.handleGetDataUnitMeasurementSettings()
    }
    
    showSettingsButtonRendered = () => {
      if(this.state.showSettings){
        return (
            <Box  style={{width: '96%', margin: '32px auto 20px', paddingTop: '20px'}}>
                <Grid container>
                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowCurrencySettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<MonetizationOnOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{position:"absolute",right:"10px",top:"20px"}}/>}
                            onClick={this.handleShowCurrencySettings}
                        >
                            Currency Settings
                        </Button>
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowLocationSettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<LocationOnOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{ position: "absolute", right: "10px", top: "20px" }} />}
                            onClick={() => {
                                this.handleShowLocationSettings()
                                this.handleGetSettingsLocation()
                            }}
                        >
                            Location Settings
                        </Button>
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowUnitMeasurementSettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<AssessmentOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{ position: "absolute", right: "10px", top: "20px" }} />}
                            onClick={this.measurementsClickHandle.bind(this)}
                        >
                            Unit of Measurement Settings
                        </Button>
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowCategorySettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<PieChartOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{ position: "absolute", right: "10px", top: "20px" }} />}
                            onClick={this.handleCategorySettingsClick.bind(this)}
                        >
                            Category Settings
                        </Button>
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowPaymentTermsSettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<PaymentOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{ position: "absolute", right: "10px", top: "20px" }} />}
                            onClick={this.handleShowPaymentTermsSettings}
                        >
                            Payment Terms Settings
                        </Button>
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowUserSettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<PermIdentityOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{ position: "absolute", right: "10px", top: "20px" }} />}
                            onClick={this.handleShowUserSettings}
                        >
                            User Settings
                        </Button>
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowInformationSettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<InfoOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{ position: "absolute", right: "10px", top: "20px" }} />}
                            onClick={this.handleShowInformationSettings}
                        >
                            Information Settings
                        </Button>
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginTop: '10px' }}>
                        <Button
                            data-test-id="btnShowTaxationSettings"
                            style={newStyles.tabBtn}
                            disableRipple
                            startIcon={<NoteOutlinedIcon style={{paddingLeft:"10px"}}/>}
                            endIcon={<ChevronRightIcon style={{ position: "absolute", right: "10px", top: "20px" }} />}
                            onClick={this.handleShowTaxationSettings}
                        >
                            Taxation Settings
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
      }
    }

    handleShowIsCreateInformationValidationRender = (stateValidValue:boolean, inpStateValue : any, errorTobeShow : string) => {
        if(stateValidValue && !inpStateValue){
          return  errorTobeShow
        }
        else {
            return ""
        }
    }
    

    handleshowIsCreateInformationValidationRegexRender = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsEmail && !RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)?.exec(this.state.informationSettingsEmail)) {
            return this.state.informationSettingsEmailMatchError
        }
        else {
            return ""
        }
    }

    


    handleInformationSettingsWebsite = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsWebsite && !RegExp(/^(https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]*\.[a-zA-Z0-9.]+$/)?.exec(this.state.informationSettingsWebsite)){
            return this.state.informationSettingsWebsiteMatchError
        }
        else {
            return ""
        }
    }

    paymentTermsInputFieldRendered = () => {
      if(this.state.showEditPaymentTermsSettingField === true){
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Payment Terms Name</Typography>
                      <StyledTextField
                        required
                        id="editBtnpaymentTermName"
                        value={this.state.editPaymentTermsName}
                        onChange={this.handleEditPaymentTermsChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Payment Terms Name"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermTextSettingsCreateValidationErrorRendered(this.state.showIsEditPaymentTermsValidation, this.state.editPaymentTermsName, this.state.paymentTermNameRequiredFieldEdit)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Credit Days</Typography>
                      <StyledTextField
                        required
                        id="editCreditDays"
                        value={this.state.editCreditDays}
                        onChange={this.handleEditCreditDaysChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Number of Credit Days"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermTextSettingsCreateValidationErrorRendered(this.state.showIsEditPaymentTermsValidation, this.state.editCreditDays, this.state.paymentTermCreditDaysRequiredFieldEdit)}</Typography>
                </Box>
            </Grid>
          </>
        )
      }else{
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Payment Terms Name</Typography>
                    <StyledTextField
                        required
                        id="addPaymentTermName"
                        value={this.state.paymentTermsName}
                        onChange={this.handlePaymentTermsChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Payment Terms Name"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermTextSettingsCreateValidationErrorRendered(this.state.showIsCreatePaymentTermsValidation, this.state.paymentTermsName, this.state.paymentTermNameRequiredField)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
            <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Credit Days</Typography>
                    <StyledTextField
                        required
                        id="addCreditDays"
                        value={this.state.creditDays}
                        onChange={this.handleCreditDaysChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Number of Credit Days"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermTextSettingsCreateValidationErrorRendered(this.state.showIsCreatePaymentTermsValidation, this.state.creditDays, this.state.paymentTermCreditDaysRequiredField)}</Typography>
                    <Typography style={newStyles.validationText}>{this.paymentTermCreadiInvalidMatchSettingsErrorRendered(this.state.showIsCreatePaymentTermsValidation, this.state.creditDays, this.state.paymentTermCreditDaysInvalidMatchRequiredField)}</Typography>
                </Box>
            </Grid>
          </>
        )
      }
    }

    paymentTermSendBtn = () => {
      if(this.state.showEditPaymentTermsSettingBtn){
        return (
            <Button id="btnEditPaymentSettings" onClick={this.handleEditPaymentTermsSettings} style={newStyles.modalButton} variant="contained">Update Terms</Button>
        )
      }else{
        return (
          <Button id="btnAddPaymentSettings" onClick={this.handleCreatePaymentTermSettings} style={newStyles.modalButton} variant="contained">Add Payment Terms</Button>
        )
      }
    }

    
    paymentTermListTableRendered = () => {
      if(this.state?.paymentTermsList?.length > 0){
        return(
          <TableContainer style={newStyles.tableContainer}>
            <Table> 
            {this.state.paymentTermsList?.slice((this.state.itemsPerPage * (this.state.currentPagePaymentTerms - 1)), (this.state.itemsPerPage * (this.state.currentPagePaymentTerms)))
                        .map((paymentTerms: any, index: number) => {
                        return <TableDisplay 
                            key={paymentTerms?.id}
                            className={'suppliers_records'}
                            data={paymentTerms} 
                            index={index} 
                            headerData={['Serial Number', 'Requester', 'Payment Terms Name', 'Credit Days', '']} 
                            displayData={[this.state.payTermsSerialNumber + index +1, paymentTerms?.attributes?.account?.user_name, paymentTerms?.attributes?.payment_term_name, paymentTerms?.attributes?.credit_days,]}
                            icons={[
                                {
                                    icon: VisibilityOutlinedIcon,
                                    handleOnClick: this.handleShowPaymentTermsOpenModal,
                                    parameter: paymentTerms?.id,
                                },
                                {
                                    icon: EditOutlined,
                                    handleOnClick: this.handleEditPaymentTerms,
                                    parameter: paymentTerms,
                                },
                                {
                                    icon:DeleteOutline,
                                    handleOnClick: this.handleDeletePaymentTermList,
                                    parameter:paymentTerms?.id
                                }
                            ]}

                        />
            })}
            </Table>
          </TableContainer>
        )
      }else{
        return (
            <>
                <Loading data-testid={'loader_active'} variant="rect" animation="wave" styling={newStyles.tableContainer}/>
                {this.state.isPaymentTermSettingGotResponseOrNot && <Typography style={{color: 'red', textAlign: 'center'}}>No Record Found !</Typography>}
            </>
        )
        
      }
    }

    taxationInputFieldRendered = () => {
      if(this.state.showEditTaxationEditField === true){
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Tax Name</Typography>
                      <StyledTextField
                        required
                        id="editTaxNameSettings"
                        value={this.state.editTaxName}
                        onChange={this.handleEditTaxNameChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Tax Name"
                    />
                    <Typography style={newStyles.validationText}>{this.taxationSettingsUpdateValidationErrorRendered(this.state.showIsUpdateTaxationValidation,this.state.editTaxName,this.state.editTaxNameRequiredField)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
              <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Tax Percentage%</Typography>
                        <StyledTextField
                          required
                          id="editTaxPercentageSettings"
                          value={this.state.editTaxPercentage}
                          onChange={this.handleEditTaxPercentageChange}
                          style={newStyles.textFieldBox}
                          inputProps={
                              {
                                  style:
                                  {
                                      fontSize: 16,
                                      fontWeight: 400,
                                      fontFamily: 'Plus Jakarta Display'
                                  },
                              }}
                          variant="outlined"
                          placeholder="Enter Tax %"
                      />
                      <Typography style={newStyles.validationText}>{this.taxationSettingsUpdateValidationErrorRendered(this.state.showIsUpdateTaxationValidation,this.state.editTaxPercentage,this.state.editTaxPercentageRequiredField)}</Typography>
              </Box>
            </Grid>
          </>
        )
      }else{
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Tax Name</Typography>
                      <StyledTextField
                        required
                        id="createTaxNameSettings"
                        value={this.state.taxName}
                        onChange={this.handleTaxNameChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Tax Name"
                    />
                    <Typography style={newStyles.validationText}>{this.taxationSettingsCreateValidationErrorRendered(this.state.showIsCreateTaxationValidation,this.state.taxName,this.state.taxNameRequiredField)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Tax Percentage%</Typography>
                      <StyledTextField
                        required
                        id="createTaxPercentageSettings"
                        value={this.state.taxPercentage}
                        onChange={this.handleTaxPercentageChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                input:{

                                }
                            }}
                        variant="outlined"
                        placeholder="Enter Tax %"
                    />
                    <Typography data-test-id="createTaxPerValidation" style={newStyles.validationText}>{this.taxationSettingsCreateValidationErrorRendered(this.state.showIsCreateTaxationValidation,this.state.taxPercentage,this.state.taxPercentageRequiredField)}</Typography>
                </Box>
            </Grid> 
          </>
        )
      }
    }

    taxationSendBtnRender = () => {
      if(this.state.showEditTaxationSettingBtn){
        return (
          <Button id="btnCreateTaxationSettings" onClick={this.createTaxationSettings} style={newStyles.modalButton} variant="contained">Add Tax</Button>
        )
      }else{
        return <Button id="btnEditTaxationSettings" onClick={this.editTaxationSettings} style={newStyles.modalButton} variant="contained">Update Tax</Button>
      }
    }

    taxationListTableRendered = () => {
      if(this.state.taxations?.length > 0){
        return <>
          <TableContainer style={newStyles.tableContainer}>
            <Table> 
            {this.state.taxations?.slice((this.state.itemsPerPage * (this.state.currentPageTaxations - 1)), (this.state.itemsPerPage * (this.state.currentPageTaxations)))
                        .map((taxation: any, index: number) => {
                        return <TableDisplay 
                            key={taxation?.id}
                            className={'taxations_record'}
                            data={taxation} 
                            index={index} 
                            headerData={['Serial Number', 'Requester', 'Tax Name', 'Tax Percentage%', '']} 
                            displayData={[this.state.taxSerialNumber + index +1, taxation?.attributes?.account?.user_name, taxation?.attributes?.tax_name, taxation?.attributes?.tax_percentage]}
                            icons={[
                                {
                                    icon: VisibilityOutlinedIcon,
                                    handleOnClick: this.handleShowViewTaxationOpenModal,
                                    parameter: taxation?.id
                                },
                                {
                                    icon: EditOutlined,
                                    handleOnClick: this.handleEditTaxationModal,
                                    parameter: taxation,
                                },
                                {
                                    icon:DeleteOutline,
                                    handleOnClick: this.handleDeletTaxationModal,
                                    parameter: taxation?.id
                                }
                            ]}

                        />
            })}
            </Table>
          </TableContainer> 
        </>
      }else{
        return <>
            <Loading data-testid={'loader_active'} variant="rect" animation="wave" styling={newStyles.tableContainer}/>
            <Typography style={{color: 'red', textAlign: 'center'}}>No Record Found !</Typography>
        </>
      }
    }

    showPaymentTermsRenderedFields = () => {
      if(this.state.showPaymentTermsSettings){
        return (
          <Box style={newStyles.outerContainer}>
              <ToastMessageDisplay id="btnPaymentTermsCreateSnackbar" message={this.state.paymentTermsCreateMessage} handleClose={this.handlePaymentTermsSettingsCreateSnackbarClose} />
              <ToastMessageDisplay id="btnPaymentTermsEditSnackbar" message={this.state.paymentTermsEditMessage} handleClose={this.handlePaymentTermsSettingsEditSnackbarClose} />
              <ToastMessageDisplay id="btnPaymentTermsDeletSnackbar" message={this.state.PaymentTermDeleteMessage} handleClose={this.handlePaymentTermsSettingsDeletSnackbarClose} />
              <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                  <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackCurrencySettings" onClick={this.handleGoBackFromPaymentTermsSettings} /></Typography>
                  <Typography style={newStyles.modalHeading} variant='h5'>Payment Terms Settings</Typography>
              </Box>
              <Box>
              <Box style={newStyles.innerContainer}>
                  <Grid container alignItems="center">
                    {this.paymentTermsInputFieldRendered()}
                      <Grid item lg={4} sm={12}>
                          <Box style={newStyles.gridItem}>
                              <Button
                                  id="btnCancelPaymentSetting"
                                  style={newStyles.modalCancelButton}
                                  onClick={this.handleCancelPaymentTermsSettings}
                                  variant="contained">
                                  Cancel
                              </Button>
                              {this.paymentTermSendBtn()}
                          </Box>
                      </Grid>
                  </Grid>
                  </Box>
                  <Box style={newStyles.innerContainer} id='inner-box-9'>
                  <Box style={{paddingTop:'1px'}}>
                    <ToastMessageDisplay message={this.state.settingsSearchFilterEmptyPopup} handleClose={this.handleSettingsSearchFilterEmptyPopupSnackbarClose} />
                    <ToastMessageDisplay message={this.state.settingsItemsSearchValueEmptyPopup} handleClose={this.handleSettingsSearchQuerryEmptyPopupSnackbarClose} />
                    <ToastMessageDisplay message={this.state.settingsNoRecordsFoundPopup} handleClose={this.handleSettingsNoRecordsFoundPopupSnackbarClose} />
                        {
                            this.handleMultiSearchCommonJsxRendered()
                        }
                  </Box>
                  {this.paymentTermListTableRendered()}
              <PaginationDisplay
                  data-test-id="btnPageChangeDisp1"
                  totalPages={this.state.noOfPagesPaymentTerms}
                  isNoRecordFound = {this.state.paymentTermsList?.length === 0}
                  currentPage={this.state.currentPagePaymentTerms}
                  handlePageChange={this.handlePaymentTermsPageChange}
                  styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
              />
              </Box>
              </Box>
          </Box>
        )
      }
    }

    showTaxationAllFieldsRendered = () => {
      if(this.state.showTaxationSettings){
        return (
          <Box style={newStyles.outerContainer}>
              <ToastMessageDisplay id="btnTaxationsSettingsCreateSnackbar" message={this.state.taxationsCreateMessage} handleClose={this.handleTaxationSettingsCreateSnackbarClose} />
              <ToastMessageDisplay id="taxationEditMessageSnackbar" message={this.state.taxationEditMessage} handleClose={this.handleTaxationSettingsEditSnackbarClose} />
              <ToastMessageDisplay id="btnTaxationDeletSnackbar" message={this.state.taxationDeleteMessage} handleClose={this.handleTaxationSettingsDeletSnackbarClose} />
              <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                  <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackTaxationSettings" onClick={this.handleGoBackFromTaxationSettings} /></Typography>
                  <Typography style={newStyles.modalHeading} variant='h5'>Taxation Settings</Typography>
              </Box>
              <Box>
                  <Box style={newStyles.innerContainer} id='inner-box-8'>
                  <Grid container alignItems="center">
                      {this.taxationInputFieldRendered()}
                      <Grid item lg={4} sm={12}>
                          <Box style={newStyles.gridItem}>
                              <Button
                                  id="btnCancelTaxationSettings"
                                  style={newStyles.modalCancelButton}
                                  onClick={this.handleCancelTaxationsSettings}
                                  variant="contained">
                                  Cancel
                              </Button>
                              {this.taxationSendBtnRender()}
                          </Box>
                      </Grid>
                  </Grid>
                  </Box>

                  <Box id='inner-box-7' style={newStyles.innerContainer}>
                  <Box 
                    style ={ { paddingTop: '1px' }} >
                  <ToastMessageDisplay message={this.state.settingsSearchFilterEmptyPopup} handleClose={this.handleSettingsSearchFilterEmptyPopupSnackbarClose} />
                  <ToastMessageDisplay message={this.state.settingsItemsSearchValueEmptyPopup} handleClose={this.handleSettingsSearchQuerryEmptyPopupSnackbarClose} />
                  <ToastMessageDisplay message={this.state.settingsNoRecordsFoundPopup} handleClose={this.handleSettingsNoRecordsFoundPopupSnackbarClose} />
                        {this.handleMultiSearchCommonJsxRendered()}
                  </Box>
                  {this.taxationListTableRendered()}
              <PaginationDisplay
                  data-test-id="btnPageChangeDisp2"
                  isNoRecordFound = {this.state.taxations?.length === 0}
                  totalPages={this.state.noOfPagesTaxations}
                  currentPage={this.state.currentPageTaxations}
                  handlePageChange={this.handleTaxationsPageChange}
                  styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
              />
              </Box>
              </Box>
          </Box>
        )
      }
    }

    // Customizable Area End


    handleShowInformationTableRender = () => {
        if(this.state.showInformation){
            return <Box style={newStyles.settingsListContainer}>
            <ToastMessageDisplay testID="btnInformationSettingsSnackbar" message={this.state.informationSettingsEditMessage} handleClose={this.handleInformationSettingsEditSnackbarClose} />
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography data-test-id="btnGoBack" style={newStyles.modalHeadingArrow}><ArrowBackIosIcon onClick={this.handleGoBackFromInformationSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Information Settings</Typography>
            </Box>
            <Box>
                <Grid container>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Company Name</Typography>
                            <StyledTextField
                                required
                                testID="btnInformationSettingCompanyName"
                                value={this.state.informationSettingsCompanyName}
                                onChange={this.handleInformationSettingsCompanyName}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display',
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Company Name"
                            />
                            <Typography style={newStyles.validationText}>
                            {this.handleShowIsCreateInformationValidationRender(
                                this.state.showIsCreateInformationValidation,
                                this.state.informationSettingsCompanyName,
                                this.state.informationSettingsComapanyNameRequiredField
                            )}
                    </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Phone Number</Typography>
                            <StyledTextField
                                required
                                testID="btnInformationSettingsPhoneNo"
                                value={this.state.informationSettingsPhoneNumber}
                                onChange={this.handleInformationSettingsPhoneNumber}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Phone Number"
                            />
                            <Typography style={newStyles.validationText}>
                                {
                                this.handleShowIsCreateInformationValidationRender(this.state.showIsCreateInformationValidation, this.state.informationSettingsPhoneNumber,this.state.informationSettingsPhoneNumberRequiredField)
                                }
                            </Typography>
                            <Typography style={newStyles.validationText}>
                                {this.infoSettingsContactNumbersValidationErrorDisplay(
                                    this.state.showIsCreateInformationValidation,
                                    this.state.informationSettingsPhoneNumber,
                                    this.state.informationSettingsPhoneNumberDigitLimitError
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Address</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsAddress"
                                value={this.state.informationSettingsAddress}
                                onChange={this.handleInformationSettingsAddress}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Address"
                            />
                            <Typography style={newStyles.validationText}>
                            {this.handleShowIsCreateInformationValidationRender(
                                this.state.showIsCreateInformationValidation,
                                this.state.informationSettingsAddress,
                                this.state.informationSettingsAddressRequiredField
                            )}
                    </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Email</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsEmail"
                                value={this.state.informationSettingsEmail}
                                onChange={this.handleInformationSettingsEmail}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Email"
                            />
                            <Typography style={newStyles.validationText}>
                                {
                                    this.handleShowIsCreateInformationValidationRender(
                                        this.state.showIsCreateInformationValidation,
                                        this.state.informationSettingsEmail,
                                        this.state.informationSettingsEmailRequiredField
                                    )
                                }
                            </Typography>
                            <Typography style={newStyles.validationText}>{this.handleshowIsCreateInformationValidationRegexRender()}</Typography>
                            
                    <Typography style={newStyles.validationText}>
                      {
                        this.handleInformationSettingsFieldCheckValidTrueFalseError(this.state.informationSettingEmailInvalidPatternError, "Invalid Email *")
                      }
                    </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Pincode</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsPinCode"
                                value={this.state.informationSettingsPinCode}
                                onChange={this.handleInformationSettingsPinCode}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Pincode"
                            />
                            <Typography style={newStyles.validationText}>
                                {this.handleShowIsCreateInformationValidationRender(
                                    this.state.showIsCreateInformationValidation,
                                    this.state.informationSettingsPinCode,
                                    this.state.informationSettingsPinCodeRequiredField
                                )}
                            </Typography>
                            <Typography style={newStyles.validationText}>{this.handleInformationSettingsPinCodeRender()}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Website</Typography>
                            <StyledTextField
                                testID="btnInfoSettingsWebsite"
                                value={this.state.informationSettingsWebsite}
                                onChange={this.handleInformationSettingsWebsiteInpChange}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Website"
                            />
                            <Typography style={newStyles.validationText}>{this.handleInformationSettingsWebsite()}</Typography>
                        </Box>
                    </Grid>

                    {/* <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} flexWrap={"wrap"}> */}
                        <Grid item lg={4}>
                            <Box style={newStyles.gridItem}>
                                <Typography style={newStyles.modalLabel}>GST Number</Typography>
                                <StyledTextField
                                    required
                                    testID="btnInfoSettingsGstNumber"
                                    value={this.state.informationSettingsGstNumber}
                                    onChange={this.handleInformationSettingsGstNumber}
                                    style={newStyles.textFieldBox}
                                    inputProps={
                                        {
                                            style:
                                            {
                                                fontSize: 16,
                                                fontWeight: 400,
                                                fontFamily: 'Plus Jakarta Display'
                                            },
                                        }}
                                    variant="outlined"
                                    placeholder="Enter GST Number"
                                />
                                <Typography style={newStyles.validationText}>
                                    {this.handleShowIsCreateInformationValidationRender(
                                        this.state.showIsCreateInformationValidation,
                                        this.state.informationSettingsGstNumber,
                                        this.state.informationSettingsGSTNumberRequiredField
                                    )}
                                </Typography>

                                <Typography style={newStyles.validationText}>{this.infoGSTNumberLengthErroRendered()}</Typography>


                            </Box>
                        </Grid>

                        {/* work in progress */}

                        <Grid item lg={4}>
                            <Box style={newStyles.gridItem}>
                                <Typography style={newStyles.modalLabel}>Enter Organization Size</Typography>
                                <StyledTextField
                                    required
                                    data-testid="informationSettingSelectOrgSize"
                                    value={this.state.informationSettingsSelectInfoSize}
                                    onChange={this.handleInformationSettingsOrganisationSize}
                                    style={newStyles.textFieldBox}
                                    inputProps={
                                        {
                                            style:
                                            {
                                                fontSize: 16,
                                                fontWeight: 400,
                                                fontFamily: 'Plus Jakarta Display'
                                            },
                                        }}
                                    variant="outlined"
                                    placeholder="Enter Organization Size"
                                />

                                <Typography style={newStyles.validationText}>
                                    {
                                    this.handleShowIsCreateInformationValidationRender(this.state.showIsCreateInformationValidation, this.state.informationSettingsSelectInfoSize, this.state.informationSettingsSelectInfoSizeRequiredField)
                                    }
                                </Typography>
                                
                            </Box>
                        </Grid>


                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>No. of people who will access</Typography>
                            <StyledTextField
                                required
                                data-testid="informationSettingNoOfPeopleWhoWillAccess"
                                value={this.state.informationSettingNoOfPeopleWhoWillAccess}
                                onChange={this.handleInformationSettingNoOfPeopleWhoWillAccess}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="No. of people who will access"
                            />
                            <Typography style={newStyles.validationText}>
                                {
                                    this.handleShowIsCreateInformationValidationRender(this.state.showIsCreateInformationValidation, this.state.informationSettingNoOfPeopleWhoWillAccess, this.state.informationSettingsNoPeopleRequiredField)
                                }
                            </Typography>
                            <Typography style={newStyles.validationText}>
                                {
                                    this.handleInfoSettingNoOfPeopleValidationRender()
                                }
                            </Typography>
                        </Box>
                    </Grid>

                        <Grid item lg={4} sm={12} style={{marginTop:"12px"}}>
                            <Box style={newStyles.gridItem}>
                                <Button data-test-id="btnCancel" style={newStyles.modalCancelButton} onClick={this.handleInformationsettingAddCancelClose} variant="contained">Cancel</Button>
                                <Button data-test-id="btnCreateInfoSettings" onClick={this.handleCreateInformationSettings} style={newStyles.modalButton} variant="contained">Update</Button>
                            </Box>
                        </Grid>
                    {/* </Box> */}
                </Grid>
            </Box>
        </Box>
        }
        else {
            return ""
        }
    }

    handleShowCurrencySettingsRender = () => {
        if(this.state.showCurrencySettings){
           return <Box style={newStyles.settingsListContainer}>
                                <ToastMessageDisplay testID="btnCurrencySettingsEdit" message={this.state.currencySettingsEditMessage} handleClose={this.handleCurrencySettingsEditSnackbarClose} />
                                <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                                    <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-test-id="btnGoBackCurrencySettings" onClick={this.handleGoBackFromCurrencySettings} /></Typography>
                                    <Typography style={newStyles.modalHeading} variant='h5'>Currency Settings</Typography>
                                </Box>
                                <Box>
                                    <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
                                        <Grid item lg={4}>
                                            <Box style={newStyles.gridItem}>
                                                <Typography style={newStyles.modalLabel}>Currency</Typography>
                                                <StyledSelect
                                                    testID="txtCurrencySettings"
                                                    variant="outlined"
                                                    label="Select Currency"
                                                    value={this.addCurrencySettingCurrency(this.state.currencySettingsCurrency)}
                                                    onChange={this.handleCurrencySettingsCurrencyChange}
                                                    disableUnderline
                                                    displayEmpty
                                                    style={this.styledSelectStyle(this.state.currencySettingsCurrency)}
                                                    input={<Input />}
                                                >
                                                    <MenuItem value="" disabled>Select Currency</MenuItem>
                                                    {this.state.currencyList?.map((currency: any) => {
                                                        return <MenuItem data-test-id="txtCurrencyItem" key={currency?.iso} value={`${currency?.name} - ${currency?.currency}`} onChange={(e)=>{return (e)}}>{`${currency?.name} - ${currency?.currency}`}</MenuItem>
                                                    })}
                                                </StyledSelect>
                                                <Typography style={newStyles.validationText}>{this.addCurrencySettingValidationErrorRendered(this.state.showIsCreateCurrencyValidation,this.state.currencySettingsCurrency,this.state.currencySettingsCurrencyRequiredField)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} sm={12}>
                                            <Box style={newStyles.gridItem}>
                                                <Button
                                                    data-test-id="btnCancelCurrencySettings"
                                                    style={newStyles.modalCancelButton}
                                                    onClick={this.handleCancelCurrencySettings}
                                                    variant="contained">
                                                    Cancel
                                                </Button>
                                                <Button data-test-id="btnCreateCurrencySettings" onClick={this.handleCreateCurrencySettings} style={newStyles.modalButton} variant="contained">Save Details</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
        }
        else {
            return ""
        }
    }

    handleShowLocationSettingsRender = () => {
        if(this.state.showLocationSettings){
            return <Box style={newStyles.outerContainer}>
            <ToastMessageDisplay testID="btnLocationSettingsSnackbar" message={this.state.locationCreateMessage} handleClose={this.handleLocationSettingsCreateSnackbarClose} />
            <ToastMessageDisplay testID="btnLocationSettingsDeleteSnackbar" message={this.state.locationDeleteMessage} handleClose={this.handleLocationSettingsDeleteSnackbarClose} />
            <ToastMessageDisplay id="btnLocationSettingsUpdateSnackbar" message={this.state.locationUpdateMessage} handleClose={this.handleLocationSettingsUpdateSnackbarClose} />
            <Box id='inner-box-5' style={newStyles.innerContainer}>
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-test-id="btnGoBackFromLocationSettings" onClick={this.handleGoBackFromLocationSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Location Settings</Typography>
            </Box>
                <Box style={{ marginRight: '20px' }}>
                <Grid container>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Location Name</Typography>
                            <StyledTextField
                                type="text"
                                required
                                testID="btnLocationSettingsName"
                                value={this.state.location}
                                onChange={this.handleLocationChange}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Location Name"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.location, this.state.locationRequiredError,"location")}</Typography>
                            <Typography style={newStyles.validationText}>{this.saveLocationsCharLimitsErrorRendered(this.state.saveLocationsValidation,  this.state.location, this.state.locationCharError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Address</Typography>
                            <StyledTextField
                                required
                                id="btnLocationSettingsAddress"
                                value={this.state.settingsAddress}
                                onChange={this.handleSettingsAddress}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Address"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.settingsAddress, this.state.locationAddressRequiredError,"address")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Location Identification Code</Typography>
                            <StyledTextField
                                required
                                id="btnLocationSettingsIdentifyCode"
                                value={this.state.settingsIdentificationCode}
                                onChange={this.handleSettingsIdentificationCode}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Location Identification Code"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.settingsIdentificationCode, this.state.locationIdentityCodeRequiredError,"code")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>GST</Typography>
                            <StyledTextField
                                required
                                id="btnLocationSettingsGST"
                                value={this.state.settingsGst}
                                onChange={this.handleSettingsGst}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter GST Number"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.settingsGst, this.state.locationGstRequiredError,"gst")}</Typography>
                            <Typography style={newStyles.validationText}>{this.LocationsValidationGSTLengthErrorRendered(this.state.saveLocationsValidation, this.state.settingsGst, this.state.locationValidGstLengthError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid lg={4} style={{marginLeft:"6px"}}>
                        <Box display={"flex"} marginLeft={"12px"} marginTop={"12px"}>
                            <Button data-testID="btnCloseLocationModal" style={newStyles.modalCancelButton} onClick={this.handleGoBackFromLocationSettings} variant="contained">Cancel</Button>
                            <Button data-test-id="btnOpenLocationsModal" onClick={()=>{this.handleLocationSettings()}} style={newStyles.modalButtonRight} variant="contained">Add Location</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            </Box>

            <Box id='inner-box-4' style={newStyles.innerContainer} >
            <ToastMessageDisplay message={this.state.settingsSearchFilterEmptyPopup} handleClose={this.handleSettingsSearchFilterEmptyPopupSnackbarClose} />
            <ToastMessageDisplay message={this.state.settingsNoRecordsFoundPopup} handleClose={this.handleSettingsNoRecordsFoundPopupSnackbarClose} />
            <ToastMessageDisplay message={this.state.settingsItemsSearchValueEmptyPopup} handleClose={this.handleSettingsSearchQuerryEmptyPopupSnackbarClose} />
            <Box style={
                {paddingTop:  '1px'}
                }>
                {
                    this.handleMultiSearchCommonJsxRendered()
                }
            </Box>
            <TableContainer style={newStyles.tableContainer} >
    <Table>
        <TableHead>
            <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>Serial Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Requester</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Location Name</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Address</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Identification Code</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>GST Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {this?.state?.showSettingsLocation && this.state.showSettingsLocation?.slice((this.state.itemsPerPage * (this.state.currentPageSettingsLocation - 1)), (this.state.itemsPerPage * (this.state.currentPageSettingsLocation))).map((data: any, index: number) => {
            return <TableRow key={data?.id} >
                <TableCell align='left' className="location_items_row" style={newStyles.tableBodyRows}>{this.state.locationSerialNumber + index +1}</TableCell>
                <TableCell align='left' className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.account?.user_name}</TableCell>
                <TableCell align='left' className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.name_of_location}</TableCell>
                <TableCell align='left' className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.adderss}</TableCell>
                <TableCell align='left' className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.location_identification_code}</TableCell>
                <TableCell align="left" className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.gst_number}</TableCell>
                <TableCell align="right" className="location_items_row" style={newStyles.iconColumn}><VisibilityOutlinedIcon onClick={this.handleShowSettingLocationOpenModal.bind(this,data?.id)}  style={newStyles.icons}/><EditOutlinedIcon id="editLocationOpenModal" onClick={() => {this.handleEditSettingLocationOpenModal(data)}} style={newStyles.icons}/> <DeleteOutline id="deleteLocationOpenModal" onClick={() => {this.handleDeleteLocation(data.id)}} style={newStyles.icons} /></TableCell>
            </TableRow>
            })}   
        </TableBody>
    </Table>
</TableContainer>
        {
            this.handleToRenderNoRecordsTypoForNoDataExist(this.state.isLocationSettingGotResponseOrNot, this.state.showSettingsLocation)
        }
        <PaginationDisplay
            data-test-id="btnPageChangeDisp3"
            isNoRecordFound = {this.state.showSettingsLocation?.length===0}
            totalPages={this.state.noOfPagesSettingsLocation}
            currentPage={this.state.currentPageSettingsLocation}
            handlePageChange={this.handlePageChangeSettingsLocation}
            styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
        />
        </Box>
        </Box>
        }
        else {
            return ""
        }
    }

    handleShowCategorySettingsRender = () => {
        if(this.state.showCategorySettings){
            return <Box style={newStyles.outerContainer} id='outer-1'>
            <ToastMessageDisplay id="btnCategorySettingsAddSnackbar" message={this.state.categoryAddMessage} handleClose={this.handleCategorySettingsAddSnackbarClose} />
            <ToastMessageDisplay id="btnCategorySettingsDeleteSnackbar" message={this.state.categoryDeleteMessage} handleClose={this.handleCategorySettingsDeleteSnackbarClose} />
            <ToastMessageDisplay id="btnCategorySettingsUpdateSnackbar" message={this.state.categoryUpdateMessage} handleClose={this.handleCategorySettingsUpdateSnackbarClose} />
            <ToastMessageDisplay message={this.state.categoryDuplicasyErrorMessage} handleClose={this.handleCategorySettingsDuplicasyErrorSnackbarClose} />
            <Box style={newStyles.innerContainer} id='inner-box-4'>
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackFromCategorySettings" onClick={this.handleGoBackFromCategorySettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Category Settings</Typography>
            </Box>
   
            <Box style={{ marginRight: '20px' }}>
              
                <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Category</Typography>
                            <StyledTextField
                                required
                                id="btnCategorySettingsName"
                                value={this.state.settingsCategory}
                                onChange={this.handleSettingsCategory}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Name of Category"
                            />
                            <Typography style={newStyles.validationText}>{this.state.settingsCategoryFeildError}</Typography>
                        </Box>
                        <Typography style={newStyles.validationText}>{this.state.categorySettingCreatePost && this.state.settingsCategory === "" && this.state.settingsCategoryRequiredField}</Typography>
                    </Grid>
                    <Box style={newStyles.gridItem}>
                        <Button data-testID="btnCloseCategoryModal" style={newStyles.modalCancelButton} onClick={this.handleGoBackFromCategorySettings} variant="contained">Cancel</Button>
                        <Button id="btnOpenCategoryModalPostAPI" variant="contained" onClick={this.handlePostCategorySettings} style={newStyles.modalButtonRight}>Add Category</Button>
                    </Box>
                </Grid>
            </Box>
            </Box>
            
            <Box style={newStyles.innerContainer} id='inner-box-3'>
            <ToastMessageDisplay message={this.state.settingsSearchFilterEmptyPopup} handleClose={this.handleSettingsSearchFilterEmptyPopupSnackbarClose} />
            <ToastMessageDisplay message={this.state.settingsItemsSearchValueEmptyPopup} handleClose={this.handleSettingsSearchQuerryEmptyPopupSnackbarClose} />
            <ToastMessageDisplay message={this.state.settingsNoRecordsFoundPopup} handleClose={this.handleSettingsNoRecordsFoundPopupSnackbarClose} />
            <Box style={{paddingTop: '1px'}}>
                {
                    this.handleMultiSearchCommonJsxRendered()
                }
            </Box>
            <TableContainer style={newStyles.tableContainer} >
    <Table>
        <TableHead>
            <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>Serial Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Requester</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Name of Category</TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {this.state.showSettingsCategory?.slice((this.state.itemsPerPage * (this.state.currentPageCategory - 1)), (this.state.itemsPerPage * (this.state.currentPageCategory)))?.map(this.showCategorySettingsTableBodyRender)}   
        </TableBody>
    </Table>
            </TableContainer>
            {
                this.handleToRenderNoRecordsTypoForNoDataExist(this.state.isCategorySettingGotResponseOrNot, this.state.showSettingsCategory)
            }
            <PaginationDisplay
                data-test-id="btnPageChangeDisp4"
                isNoRecordFound = {this.state.showSettingsCategory?.length===0}
                totalPages={this.state.noOfPagesCategory}
                currentPage={this.state.currentPageCategory}
                handlePageChange={this.handlePageChangeCategory}
                styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
            />
        </Box>
        </Box>
        }
        else {
            return ""
        }
    }

    handleShowUnitMeasureSettingsRender = () => {
        if(this.state.showUnitMeasureSettings) {
            return <Box id='outerBox-2' style={newStyles.outerContainer}>
            <ToastMessageDisplay id="unitMeasurementDeleteMess" message={this.state.unitMeasurementDeleteMessage} handleClose={this.handleUnitMeasurementDeleteSettingsDeleteSnackbarClose} />
            <ToastMessageDisplay id="unitMeasurementCreateMess" message={this.state.unitMeasurementCreateMessage} handleClose={this.handleUnitMeasurementDeleteSettingsCreateSnackbarClose} />
            <ToastMessageDisplay id="unitMeasurementUpdateMess" message={this.state.unitMeasurementUpdateMessage} handleClose={this.handleUnitMeasurementDeleteSettingsUpdateSnackbarClose} />
            <ToastMessageDisplay message={this.state.unitMeasurementSettingsDuplicasyMessage} handleClose={this.handleUnitMeasurementSettingsDuplicasySnackbarClose} />
            <Box style={newStyles.innerContainer} id='inner-box-2'>
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackFromUnitMeasureSettings" onClick={this.handleGoBackFromUnitMeasurementSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Unit of Measurement Settings</Typography>
            </Box>
   
            <Box style={{ marginRight: '20px' }}>
              
              <Grid container alignItems={"center"} style={{justifyContent: 'space-between'}}>
                <Box display={"flex"} flexDirection={"column"}  sx={{ ml:'30px'}}>
                    <Typography style={newStyles.modalLabel}>Unit</Typography>
                    <StyledTextField
                        required
                        id="btnUnitMeasureSettings"
                        value={this.state.settingsUnitMeasurement}
                        onChange={this.handleSettingsUnitMeasurement}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                }
                            }}
                        variant="outlined"
                        placeholder="Add Unit of Measurement"
                    />
                  <Typography style={newStyles.validationText}>{this.state.unitMeasurementSettingPost &&this.state.settingsUnitMeasurement === '' && this.state.settingsUnitMeasurementRequiredField}</Typography>
                </Box>
                <Box style={newStyles.gridItem}>
                  <Button id="btnCloseUnitMeasureModal" style={newStyles.modalCancelButton} onClick={this.handleGoBackFromUnitMeasurementSettings} variant="contained">Cancel</Button>
                  <Button id="btnOpenUnitMeasureModal" onClick={this.handlePostUnitMeasurementSettings} style={newStyles.modalButtonRight} variant="contained">Add Unit</Button>
                </Box>
              </Grid>
            </Box>
            </Box>

            <Box style={newStyles.innerContainer} id='inner-box-1'>
            <Box style=
                {
                { paddingTop: '1px' }
                }>
            <ToastMessageDisplay message={this.state.settingsNoRecordsFoundPopup} handleClose={this.handleSettingsNoRecordsFoundPopupSnackbarClose} />
            <ToastMessageDisplay message={this.state.settingsItemsSearchValueEmptyPopup} handleClose={this.handleSettingsSearchQuerryEmptyPopupSnackbarClose} />
            <ToastMessageDisplay message={this.state.settingsSearchFilterEmptyPopup} handleClose={this.handleSettingsSearchFilterEmptyPopupSnackbarClose} />
                {
                    this.handleMultiSearchCommonJsxRendered()
                }
            </Box>

            <TableContainer style={newStyles.tableContainer} >
    <Table>
        <TableHead>
            <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>Serial Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Requester</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Unit of Measurement</TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {this.state.showSettingsUnitMeasurement && this.state.showSettingsUnitMeasurement?.slice(
                  this.state.itemsPerPage *
                  (this.state.currentPageUnitMeasure - 1),
                  this.state.itemsPerPage *
                  this.state.currentPageUnitMeasure
                )?.map((data: any, index:number) => {
                return <TableRow key={data?.id} className='show_unitMeasure_table_row'>
                <TableCell align="left" className="tableUnitMeasurement" style={newStyles.tableBodyRows}>{this.state.unitMeasureSerialNumber + index +1}</TableCell>
                <TableCell align="left" className="tableUnitMeasurement" style={newStyles.tableBodyRows}>{data?.attributes?.account?.user_name}</TableCell>
                <TableCell align="left" className="tableUnitMeasurement" style={newStyles.tableBodyRows}>{data?.attributes?.unit_of_measurment}</TableCell>
                <TableCell align="right" className="tableUnitMeasurement" style={newStyles.iconColumn}><EditOutlinedIcon id="editUnitMesaurement" onClick={this.handleEditSettingUnitMeasurementOpenModal.bind(this,data)} style={newStyles.icons}/> <DeleteOutline id="deleteUnitMesaurementr" onClick={() => {this.handleDeleteSettingUnitMeasurement(data?.id)}} style={newStyles.icons}/></TableCell>
            </TableRow>
            })}   
        </TableBody>
    </Table>
</TableContainer>
    {
        this.handleToRenderNoRecordsTypoForNoDataExist(this.state.isUnitMeasureSettingGotResponseOrNot, this.state.showSettingsUnitMeasurement)
    }
            <PaginationDisplay
                data-test-id="btnPageChangeDisp5"
                isNoRecordFound = {this.state.showSettingsUnitMeasurement?.length===0}
                totalPages={this.state.noOfPagesUnitMeasure}
                currentPage={this.state.currentPageUnitMeasure}
                handlePageChange={this.handleUnitMeasurePageChange}
                styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
            />
        </Box>
        </Box>
        }
        else {
            return ""
        }
    }


    // users permissions
    handleShowUserSettingsRender = () => {
        if(this.state.showUserSettings){
            return <Box style={newStyles.settingsListContainer}>
            <ToastMessageDisplay id="btnUserSettingsSnackBar" message={this.state.userCreateMessage} handleClose={this.handleUserSettingsCreateSnackbarClose} />
            <ToastMessageDisplay id="btnCreatePermissionMessage" message={this.state.userPermissionsCreatedMessage} handleClose={this.handleUserSettingsPermissionsCreatedSnackbarClose} />
            <ToastMessageDisplay id="btnUserSettingsDeleteSnackbar" message={this.state.userDeleteMessage} handleClose={this.handleUserSettingsDeleteSnackbarClose} />
            <ToastMessageDisplay testID="btnCloseAlreadyUsersSnackBar" style={{ margin: '15px' }} message={this.state.alreadyCreatedUsers.length > 0 && this.state.alreadyCreatedUsers.map((user: string, index: number) => <span key={user}>{this.state.alreadyCreatedUsers?.length - 1 === index ? `${user} already exists` : `${user}, `} </span>)} handleClose={this.handleCloseAlreadyCreatedUsersSnackbar} />
            <ToastMessageDisplay id="btnUserUpdateSettingsSnackBar" message={this.state.userSettingPermissionUpdatedMessage} handleClose={this.handleUserSettingsPermissionsUpdationSnackbarClose} />
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-test-id="btnGoBackFromUser" onClick={this.handleGoBackFromUserSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>User Settings</Typography>
            </Box>

            <Box>
                <Grid container>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Name</Typography>
                            <StyledTextField
                                required
                                testID="btnUserSettingsName"
                                value={this.state.userSettingAddUserName}
                                onChange={this.handleUserSettingsNameChange}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Name"
                            />
                            <Typography style={newStyles.validationText}>{this.addInformationSettingsValidationErrorRendered(this.state.saveUsersValidation, this.state.userSettingAddUserName, this.state.userSettingNameRequiredError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Designation</Typography>
                            <StyledTextField
                                required
                                testID="btnUserSettingsDesignation"
                                value={this.state.userSettingAddUserDesignation}
                                onChange={this.handleUserSettingsDesignation}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Designation"
                            />
                            <Typography style={newStyles.validationText}>{this.addInformationSettingsValidationErrorRendered(this.state.saveUsersValidation, this.state.userSettingAddUserDesignation, this.state.userSettingDesignationRequiredError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Email Id</Typography>
                            <StyledTextField
                                required
                                testID="btnUserSettingsEmail"
                                value={this.state.userSettingAddUserEmailId}
                                onChange={this.handleUserSettingsEmail}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Email"
                            />
                            <Typography style={newStyles.validationText}>{this.addInformationSettingsValidationErrorRendered(this.state.saveUsersValidation, this.state.userSettingAddUserEmailId,  this.state.userSettingEmailRequiredError)}</Typography>
                            <Typography style={newStyles.validationText}>{ this.state.userSettingAddUserEmailId != "" && !this.state.userSettingAddUserEmailId.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) ? "Email Not Valid" : ''}</Typography>
                        </Box>
                    </Grid>
                        <Grid sm={12} lg={4}>
                            <Box style={{marginLeft:"12px",marginTop:"12px", marginBottom:"30px"}}>
                                <Button data-test-id="btnCloseUserModal" style={newStyles.modalCancelButton} onClick={this.handleCloseUserModal} variant="contained">Clear</Button>
                                <Button style={newStyles.modalButton} data-test-id="btnAddUser" onClick={this.handleSaveUsers} variant="contained">{this.handleCircularProgressBarr(this.state.isUserCreating, "Add User")}</Button>
                            </Box>
                        </Grid>
                </Grid>
            </Box>
        </Box>
        }
        else {
            return ""
        }
    }


    handleCircularProgressBarr = (stateValue : any, textContent: any) => {
        if(stateValue){
            return <CircularProgress style={{ color: "black" }} />
        } else {
            return textContent
        }
    }



    handleInformationSettingsFieldCheckValidTrueFalseError  = (stateValue: boolean, errorToBeShow: string) => {
        if (stateValue) {
          return errorToBeShow
        } else {
          return '';
        }
      }



      infoSettingsContactNumbersValidationErrorDisplay = (validState: boolean, inpValueState: any, errorToBeShow: string) => {
        if ((validState && inpValueState && inpValueState?.length > 10) || (validState && inpValueState && inpValueState?.length < 10)) {
          return errorToBeShow
        } else {
          return '';
        }
      }



      infoSettingsInputFieldsValidationErrorRendered = (
        itemsToBeValidated: any,
        fieldState: any,
        errorToBeShow: any
      ) => {
        if (itemsToBeValidated && fieldState == "") {
          return errorToBeShow;
        } else {
          return "";
        }
      };


      handleMultiSearchCommonJsxRendered = () => {
        return (
            <Box style={newStyles.searchItemsListContainer}>
                <form onSubmit={this.handleFormSubmitForSettingsSearch} style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
                    <Box style={newStyles.searchBoxWrapper}>
                    {this.handleSettingItemsFilterMultiItemsState()}
                    </Box>
                    <Box style = {{marginTop: '20px'}}>
                    <Paper component="form" style={newStyles.searchPaperStyle}>
                        <IconButton
                        data-testid="settings_search_menus"
                        aria-label="choose category for search.."
                        onClick={this.handleSettingsSearchMenuOpen}
                        >
                        <MenuIcon />
                        </IconButton>
                        <InputBase
                        value={this.state.itemsSettingSearchBySpecificValue }
                        placeholder="Search by value.."
                        inputProps={{ 'aria-label': 'search by value according to category selected..' }}
                        onChange={this.handleSettingsScreensInputChangeForSearchValue}
                        />
                        <IconButton aria-label="search">
                        <SearchIcon className="settings_search_clicks" onClick={this.handleSettingsAllCoreSearchValidation} />
                        </IconButton>
                        <Divider orientation="vertical" />
                        <IconButton aria-label="clear">
                        <ClearIcon onClick={this.handleSettingScreensMainSearchClearAllClickFuncCall}/>
                        </IconButton>
                    </Paper>
                    <Menu
                    data-testid="setting_search_menu"
                    style={{marginTop:"2.4rem"}}
                        anchorEl={this.state.anchorElForSettingsScreens}
                        open={this.state.isSettingsScreensMenuForSearchOpen}
                        onClose={this.handleSettingsSearchMenuClose}
                    >
                        {this.state.mainSearchSettingItemsKeysData?.map((data) => (
                        <MenuItem
                        className="setting_search_options"
                            key={data?.key}
                            value={data?.key}
                            onClick={() => this.handleSettingsSearchMenuItemSelect(data?.key)}
                        >
                            <Checkbox checked={data?.selected} color="secondary"/>
                            {data?.lable}
                        </MenuItem>
                        ))}
                    </Menu>
                    </Box>
                </form>
            </Box>
        )
      }

      handleSettingItemsFindLabelByKeySubNested = (keyToFind: string)=>{
        const itemsFoundKeyData = this.state.mainSearchSettingItemsKeysData?.find((data) => data.key === keyToFind);
        return itemsFoundKeyData ? itemsFoundKeyData.lable : '';
      }

      handleSettingItemsFilterMultiItemsState = () => {
        if(this.state.settingItemsFilterMultiState){
          return this.handleSettingItemsFindLabelByKeySubNested(this.state.settingItemsFilterMultiState)
        } else {
          return "All Records"
        }
      }


      handleToRenderNoRecordsTypoForNoDataExist = (isApiRunningForRespState : boolean, settingsDataState:any[])=> {
        if(isApiRunningForRespState && settingsDataState?.length===0){
            return <Typography style={{color: 'red', textAlign: 'center'}}>No Record Found !</Typography>
        } else {
            return ""
        }
      }

      showCategorySettingsTableBodyRender = (data: any, index : number) => {
        return <TableRow key={data?.id} className='show_category_table_row'>
            <TableCell align="left" className="show_category_table_cells" style={newStyles.tableBodyRows}>{this.state.categorySerialNumber + index +1}</TableCell>
            <TableCell align="left" className="show_category_table_cells" style={newStyles.tableBodyRows}>{data?.attributes?.account?.user_name}</TableCell>
            <TableCell align="left" className="show_category_table_cells" style={newStyles.tableBodyRows}>{data?.attributes?.name}</TableCell>
            <TableCell align="right" className="show_category_table_cells" style={newStyles.iconColumn}>
                <EditOutlinedIcon id="editCategory"  onClick={this.handleEditSettingCategoryOpenModal.bind(this,data)} style={newStyles.icons}/>
                <DeleteOutline id="deleteCategory"  onClick={this.handleDeleteCategory.bind(this,data?.id)} style={newStyles.icons}/>
            </TableCell>
        </TableRow>
    }


    handleInfoSettingNoOfPeopleValidationRender = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsSelectInfoSize  <  this.state.informationSettingNoOfPeopleWhoWillAccess){
            return this.state.informationSettingsNoPeopleLengthValidationField
        }  return ""
    }
      




    // Customizable Area End


    
    render() {

        const USER_PERMISSIONS_ARRAY : any = [
            { name: 'purchase requisitions', label: 'Purchase Requisitions' },
            { name: 'request for proposal', label: 'Request For Proposal' },
            { name: 'purchase orders', label: 'Purchase Orders' },
            { name: 'receipts', label: 'Receipts' },
            { name: 'settings', label: 'Settings' },
            { name: 'reports', label: 'My Reports' },
            { name: 'budgets', label: 'Budgets' },
            { name: 'supplier and items', label: 'Items & Suppliers' },
        ];

        return (
            // Customizable Area Start

            <>
                <ThemeProvider theme={theme} >
                    <Container maxWidth="xl" style={{ position: 'relative', width: '100%', margin: '0px', padding: '0px' }}>

                        <Box style={newStyles.mainContainer}>
                            <Header navigation={this.props.navigation} active="settings" profile={this.state.userProfile} 
                            loggedUserName = {this.state.loggedUserNameCatch}
                            loggedUserEmail = {this.state.loggedUserEmailCatch}
                            id="" permissionLoginUserDetailsState ={this.state.userLoginDetailsCatch} />
                            <Box style={newStyles.settingsListContainer}>

                                {this.showSettingsButtonRendered()}

                                {this.handleShowInformationTableRender()}

                                {this.handleShowCurrencySettingsRender()}

                                {this.handleShowLocationSettingsRender()}

                                {this.showTaxationAllFieldsRendered()}

                                {this.showPaymentTermsRenderedFields()}

                                {this.handleShowCategorySettingsRender()}

                                {this.handleShowUnitMeasureSettingsRender()}

                            </Box>
                        </Box>
                            <ViewDetailsDialog
                            id={'show_budget_dialog'}
                                heading = "Show Location"
                                open = {this.state.openShowSettingLocationModal}
                                close={this.handleShowSettingLocationCloseModal}
                                data = {
                                    [
                                        {
                                            displayName: 'Requester',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.account?.user_name,
                                        },
                                        {
                                            displayName: 'Location Name',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.name_of_location,
                                        },
                                        {
                                            displayName: 'Address',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.adderss,
                                        },
                                        {
                                            displayName: 'Identification Code',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.location_identification_code,
                                        },
                                        {
                                            displayName: 'GST Number',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.gst_number,
                                        },
                                        {
                                            displayName: 'Created At',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.created_at?.update_created_at,
                                        },
                                        {
                                            displayName: 'Last Updated At',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.updated_at?.record_updated_at,
                                        },
                                    ]
                                }
                                buttonsInfo = {
                                    [
                                        {
                                            name: 'Cancel',
                                            disabled: false,
                                            variant: 'contained',
                                            onClickHandle: this.handleShowSettingLocationCloseModal,
                                            styling: newStyles.showLocationCancelBtn,
                                        },

                                    ]
                                }
                            />

                            <ViewDetailsDialog
                                id={'show_paymentTerms_dialog'}
                                heading={`Show Payment Terms`}
                                open={this.state.openShowPaymentTermsModal}
                                close={this.handleShowPaymentTermsloseModal}
                                data={
                                    [
                                    {
                                        displayName: 'Requester',
                                        displayData: this.state.paymentTermShowViewApiRespObj?.attributes?.account?.user_name,
                                    },
                                    {
                                        displayName: 'Created At',
                                        displayData: this.state.paymentTermShowViewApiRespObj?.attributes?.created_at?.update_created_at,
                                    },
                                    {
                                        displayName: 'Last Updated At',
                                        displayData: this.state.paymentTermShowViewApiRespObj?.attributes?.updated_at?.record_updated_at,
                                    },
                                    {
                                        displayName: 'Payment Terms Name',
                                        displayData: this.state.paymentTermShowViewApiRespObj?.attributes?.payment_term_name,
                                    },
                                    {
                                        displayName: 'Credit Days',
                                        displayData: this.state.paymentTermShowViewApiRespObj?.attributes?.credit_days,
                                    }
                                    ]
                                }
                                buttonsInfo={
                                    [
                                    {
                                        name: 'Cancel',
                                        disabled: false,
                                        variant: 'contained',
                                        onClickHandle: this.handleShowPaymentTermsloseModal,
                                        styling: newStyles.modalShowPaymentCancelButton,
                                    },

                                    ]
                                }
                            />

                            <ViewDetailsDialog
                                id={'show_taxation_dialog'}
                                heading={`Show Taxation`}
                                open={this.state.showViewTaxationSetting}
                                close={this.handleShowViewTaxationcloseModal}
                                data={
                                    [
                                    {
                                        displayName: 'Requester',
                                        displayData: this.state.taxationShowViewApiRespObj?.attributes?.account?.user_name,
                                    },
                                    {
                                        displayName: 'Tax Name',
                                        displayData: this.state.taxationShowViewApiRespObj?.attributes?.tax_name,
                                    },
                                    {
                                        displayName: '	Tax Percentage%',
                                        displayData: this.state.taxationShowViewApiRespObj?.attributes?.tax_percentage,
                                    },
                                    {
                                        displayName: 'Created At',
                                        displayData: this.state.taxationShowViewApiRespObj?.attributes?.created_at?.update_created_at,
                                    },
                                    {
                                        displayName: 'Last Updated At',
                                        displayData: this.state.taxationShowViewApiRespObj?.attributes?.updated_at?.record_updated_at,
                                    },
                                    ]
                                }
                                buttonsInfo={
                                    [
                                    {
                                        name: 'Cancel',
                                        disabled: false,
                                        variant: 'contained',
                                        onClickHandle: this.handleShowViewTaxationcloseModal,
                                        styling: newStyles.modalShowPaymentCancelButton,
                                    },

                                    ]
                                }
                            />




                            {this.handleShowUserSettingsRender()}




                <Dialog maxWidth="xl" style={newStyles.locationUpdateDialogue} open={this.state.openEditSettingLocationModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Update Location</Typography> 
                    <Grid container>
                    <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>Name of Location</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnLocationName"
                                                        value={this.state.editSettingsLocation}
                                                        onChange={this.handleEditSettingsLocation}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Address"
                                                    />
                                                    <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.editSaveLocationsValidation, this.state.editSettingsLocation, this.state.editedLocationRequiredError)}</Typography>
                                                    <Typography style={newStyles.validationText}>{this.saveLocationsCharLimitsErrorRendered(this.state.editSaveLocationsValidation, this.state.editSettingsLocation, this.state.editedLocationCharError)}</Typography>
                                                </Box>
                                               
                                            </Grid>

                                            <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>Address</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnEditLocationSettingsAddress"
                                                        value={this.state.editSettingsAddress}
                                                        onChange={this.handleEditSettingsAddress}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Address"
                                                    />
                                                     <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.editSaveLocationsValidation, this.state.editSettingsAddress, this.state.editedLocationAddressRequiredError)}</Typography>
                                                </Box>
                                               
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>Location Identification Code</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnLocationSettingsIdentifyCode"
                                                        value={this.state.editSettingsIdentificationCode}
                                                        onChange={this.handleEditSettingsIdentificationCode}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Location Identification Code"
                                                    />
                                                    <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.editSaveLocationsValidation, this.state.editSettingsIdentificationCode, this.state.editedLocationIdentityCodeRequiredError)}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>GST</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnLocationSettingsIdentifyCode"
                                                        value={this.state.editSettingsGst}
                                                        onChange={this.handleEditSettingsGst}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter GST Number"
                                                    />
                                                     <Typography style={newStyles.validationText}>{this.LocationsValidationGSTLengthErrorRendered(this.state.editSaveLocationsValidation, this.state.editSettingsGst, this.state.editedLocationValidGstLengthError)}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Box style={newStyles.btnGridItem}>
                                                    <Button id="updateLocationSettingP2" onClick={this.handleUpdateSettingsLocation} style={newStyles.modalButton} variant="contained">Update Location</Button>
                                                    <Button onClick={this.handleEditSettingsLocationCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                                                </Box>
                                            </Grid>
                    </Grid>
                </Dialog>
                <Dialog maxWidth="xl" style={newStyles.dialog} open={this.state.openEditSettingCategoryModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Update Category</Typography> 
                    <Grid container >
                    
                                            <Grid item lg={6} >
                                                <Box style={newStyles.gridItem} marginRight={15}>
                                                    <Typography style={newStyles.modalLabel}>Category Name</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnEditLocationSettings"
                                                        value={this.state.editSettingsCategoryName}
                                                        onChange={this.handleEditSettingsCategoryName}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display',
                                                                    
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Category Name"
                                                    />
                                                </Box>
                                                <Typography style={newStyles.validationText}>{this.state.categorySettingUpdation && this.state.editSettingsCategoryName === "" && this.state.editSettingsCategoryRequiredField}</Typography>
                                            </Grid>
                    </Grid>
                    <Box display={"flex"} flexDirection={"row-reverse"} alignItems={"center"}> 
                        <Button id="updateSettingCategoryData" onClick={this.handleUpdateSettingsCategoryData} style={newStyles.modalUpdateCatButton} variant="contained">Update Category</Button>
                        <Button onClick={this.handleEditSettingsCategoryCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                    </Box>
                </Dialog>
                
                <Dialog maxWidth="xl" PaperProps={{style:{width:"70%"}}} style={newStyles.dialog} open={this.state.openEditSettingUnitMeasurementModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Update Unit</Typography> 
                    <Grid container>
                      <Grid item lg={4}>
                          <Box style={newStyles.gridItem}>
                              <Typography style={newStyles.modalLabel}>Unit</Typography>
                              <StyledTextField
                                  required
                                  testID="btnEditUnitMeasurement"
                                  value={this.state.editSettingsUnitMeasurement}
                                  onChange={this.handleEditSettingsUnitMeasurement}
                                  style={newStyles.textFieldBoxUnitMeasurement}
                                  inputProps={
                                      {
                                          style:
                                          {
                                              fontSize: 16,
                                              fontWeight: 400,
                                              fontFamily: 'Plus Jakarta Display'
                                          },
                                          //disabled: this.state.enableEditInformationSettings ? true : false,
                                      }}
                                  variant="outlined"
                                  placeholder="Enter Unit of Measurement"
                              />
                          </Box>
                      </Grid>
                    </Grid>
                    <Box display={"flex"} flexDirection={"row-reverse"} padding={"10px"}>
                        <Button id="updateUnitMeasurementData" onClick={this.handleUpdateSettingsUnitMeasurementData} style={newStyles.modalButton} variant="contained">Update Unit</Button>
                        <Button onClick={this.handleEditSettingsUnitMeasurementCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                    </Box>
                </Dialog>

                <Dialog maxWidth="md" style={newStyles.userAccessPermissionDialog} open={this.state.openUserPermissionsModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Add User Permissions</Typography>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                            <Box style={newStyles.gridItemforUserPermissions}>
                                <FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                        data-testid="userPermissionAll"
                                            label={<Typography style={this.userListPermissionStyleSelectAll()}>Select All</Typography>}
                                            control={<Checkbox
                                                name="selectAll"
                                                checked={this.state.selectAllPermissions}
                                                // indeterminate={!this.state.selectAllPermissions && this.state.selectedUserPermissions.length > 0}
                                                onChange={(e) => this.handleUsersSettingSelectAllCheckboxChange(e, USER_PERMISSIONS_ARRAY)}
                                                checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                icon={<img src={uncheckedUserPermissionIcon} style={newStyles.unCheckedBox} />}
                                            />}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid style={newStyles.userPermissionGrid} item xs={12} md={6}  lg={6} sm={12}  >
                        </Grid>

                        {USER_PERMISSIONS_ARRAY?.map((permission : any) => (
                            <Grid  sm={12} xs={12}  item lg={6} style={newStyles.userPermissionGrid} md={6}   key={permission?.name}>
                                <Box style={newStyles.gridItemforUserPermissions}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                            className="btnPermissionChange"
                                                label={<Typography style={this.userListPermissionStyle(permission?.label)}>{permission?.label}</Typography>}
                                                control={<Checkbox
                                                    icon={<img src={uncheckedUserPermissionIcon} style={newStyles.unCheckedBox} />}
                                                    onChange={this.handleUsersSettingCheckboxChange.bind(this,permission.name, USER_PERMISSIONS_ARRAY)}
                                                    checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                    checked={this.state.selectedUserPermissions.includes(permission?.name)}
                                                    name={permission?.name}
                                                />}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container style={{marginBottom:"30px",marginTop: "20px"}} >
                        <Grid item xs={12}  md={12} lg={6} sm={12} >
                        </Grid>
                        <Grid  md={12} sm={12} xs={12} item lg={6}>
                            <Box style={newStyles.gridItem}>
                                <Button id="saveUserAccess" style={newStyles.modalButton} onClick={this.handleSaveUserAccessPermissions} variant="contained">{this.handleCircularProgressBarr(this.state.isUserPermissionsCreating, "Add Permissions")}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Dialog>
                    </Container>
                </ThemeProvider>


                {
                    this.state.showUserSettings && <ThemeProvider theme={theme} >
                    <Box 
                        style={newStyles.innerContainer}>
                        <Box>
                         <ToastMessageDisplay message={this.state.settingsSearchFilterEmptyPopup} handleClose={this.handleSettingsSearchFilterEmptyPopupSnackbarClose} />
                         <ToastMessageDisplay message={this.state.settingsItemsSearchValueEmptyPopup} handleClose={this.handleSettingsSearchQuerryEmptyPopupSnackbarClose} />
                         <ToastMessageDisplay message={this.state.settingsNoRecordsFoundPopup} handleClose={this.handleSettingsNoRecordsFoundPopupSnackbarClose} />
                            {
                                this.handleMultiSearchCommonJsxRendered()
                            }
                        </Box>
                        {this.state.usersList?.length > 0 ? <TableContainer style={newStyles.tableContainer}>
                        <Table>
                            {this.state.usersList?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
                                .map((data: any, index: number) => { 
                                    return <TableDisplay
                                        data-testid="tableDisplay"
                                        className={"users_list_settings"}
                                        data={data}
                                        index={index}
                                        key={data?.id}
                                        selectedRow={this.state.userSettingSelectedRow} 
                                        checkedIcon={checkedRadioButton}
                                        uncheckedIcon={uncheckedRadioButton}
                                        radio={true}
                                        headerData={['', 'Requester', 'Name', 'Designation', 'Email', 'Created At', '']}
                                        displayData={[data?.attributes?.user_name, data?.attributes?.name, data?.attributes?.designation, data?.attributes?.email, data?.attributes?.created_at?.update_created_at]}
                                        icons={[
                                            {
                                                icon: 'User Permissions',
                                                dataTestId:"btnUserAccessPermissions",
                                                handleOnClick: this.handleEditedUserAccessPermissions,
                                                parameter: data,
                                                styling: newStyles.tableBodyRowsUnderline,
                                            },
                                            {
                                                icon: DeleteOutline,
                                                dataTestId:"btnDeleteUser",
                                                handleOnClick: this.handleDeleteUser,
                                                parameter: data?.id,
                                                styling: newStyles.iconsSpacing,
                                            },
                                        ]}

                                    />
                                })}
                        </Table>
                        </TableContainer> : 
                        <>
                            <Loading variant="rect" animation="wave" styling={newStyles.tableContainer} />
                            {this.state.isUsersSettingGotResponseOrNot && <Typography style={{color: 'red', textAlign: 'center'}}>No Record Found !</Typography>}
                        </>
                        }
                        <PaginationDisplay
                            data-test-id="btnPageChangeDisp6"
                            isNoRecordFound = {this.state.usersList?.length === 0}
                            totalPages={this.state.noOfPages}
                            currentPage={this.state.currentPage}
                            handlePageChange={this.handlePageChange}
                            styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
                        />
                    </Box>

                    <Dialog maxWidth="md" style={newStyles.userAccessPermissionDialog} open={this.state.openEditedUserPermissionsModal}>
                        <ToastMessageDisplay id="editedUserPermissionModalMessage" message={this.state.userPermissionMissingIdErrorMessage} handleClose={this.handleUserPermissionMissingIdErrorMessageSnackbarClose} />
                        <Typography style={newStyles.modalHeading} variant='h5'>Update User Permissions</Typography>
                        <Grid container>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                                <Box style={newStyles.gridItemforUserPermissions}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel 
                                                label={<Typography style={this.userListPermissionStyleSelectAll()}>Select All</Typography>}
                                                className="editUserPermission"
                                                control={<Checkbox
                                                    checked={this.state.editedSelectAllPermissions}
                                                    // indeterminate={!this.state.editedSelectAllPermissions && this.state.editedSelectedUserPermissions.length > 0}
                                                    onChange={(e)=>this.handleEditedUsersSettingSelectAllCheckboxUpdationChange(e, USER_PERMISSIONS_ARRAY)}
                                                    name="selectAll"
                                                    checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                    icon={<img src={uncheckedUserPermissionIcon} style={newStyles.unCheckedBox}/>}
                                                />}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                            </Grid>

                            {USER_PERMISSIONS_ARRAY?.map((permission : any) => (
                                <Grid key={permission?.name} item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                                    <Box style={newStyles.gridItemforUserPermissions}>
                                        <FormControl>
                                            <FormGroup>
                                                <FormControlLabel
                                                    className="btnEditPermissionChange"
                                                    label={<Typography style={this.userListPermissionStyle(permission?.label)}>{permission?.label}</Typography>}
                                                    control={<Checkbox
                                                        checked={this.state.editedSelectedUserPermissions.includes(permission?.name)}
                                                        onChange={this.handleEditedUsersSettingCheckboxUpdationChange.bind(this,permission?.name, USER_PERMISSIONS_ARRAY)}
                                                        checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                        icon={<img src={uncheckedUserPermissionIcon} style={newStyles.unCheckedBox}/>}
                                                        name={permission?.name}
                                                    />}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container style={{marginBottom:"30px",display:"flex",flexDirection:"row-reverse", marginTop: "20px"}}>
                            <Grid item lg={6} md={12} sm={12} xs={12} >
                                <Box style={newStyles.gridItem}>
                                    <Button data-test-id="btnCloseUserPermissionsModal" style={newStyles.modalCancelButton} onClick={this.handleEditedCloseUserAccessPermissionsModal} variant="contained">Cancel</Button>
                                    <Button id="editUserAccessPermiss" disabled={this.state.createUserPermissionsValidation} style={newStyles.modalButton} onClick={this.getEditedUserAccessPermissionUpdation.bind(this,this.state.editedCatchIdForUserPermissionsUpdation)} variant="contained">{this.handleCircularProgressBarr(this.state.isUserSettingPermissionsUpdating, "Update Permissions")}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Dialog>

                    </ThemeProvider>
                }

            </>
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#fff',
        },
    },
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: '20px 0px',
        },
        button: {
            textTransform: 'none',
        }
    },

});

const newStyles = {
    logoImg: {
        width: '129.63px',
        height: '40px',
        paddingLeft: '20px',
        paddingTop: '40px',
    },
    textFieldBox1: {
        backgroundColor: '#F2F2EC',
        width: '328px',
        height: '56px',
        borderRadius: '10px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        marginTop: '16px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '14',
        fontWeight: '400',
        boxSizing: 'border-box' as 'border-box',
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    headerContainer: {
        display: 'flex',
        displayDirection: 'column',
        flex: '1',
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        margin: 'auto',
        height: '120px',
        width: '100vw',
        boxSizing: 'border-box' as 'border-box',
    },
    mainContainer: {
        backgroundColor: 'rgba(242, 242, 236, 1)',
        width: '100%',
        flex: '1',
        alignItems: "flex-start",
        boxSizing: 'border-box' as 'border-box',
    },
    
    avatar: {
        color: 'white',
        backgroundColor: 'blue',
        flex: '1',
    },
    avatarDropdownMenu: {
        margin: 0,
        paddingLeft: '50px',
        flex: '1',
    },
    tabsContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#F8F8F5',
        margin: 'auto',
        height: '96px',
        width: '100vw',
        boxShadow: '#F0F0ED 0px 60px 60px -80px inset',
        boxSizing: 'border-box' as 'border-box',
    },
    divider: {
        width: '1px',
        height: '30px',
        marginLeft: '70px',
        marginTop: '28px',
    },
    avatarContainer: {
        display: 'flex',
        displayDirection: 'column',
        height: '40px',
        paddingRight: '20px',
        paddingTop: '40px',
        justifyContent: 'baseline',
    },
    dropDown: {
        minWidth: '328px',
        maxHeight: '56px',
        marginLeft: '20px',
        marginTop: '15px',
        backgroundColor: '#F2F2EC',
        fontSize: '16',
        margin: 'dense',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    dropDownItems: {
        lineSpacing: '19',
        letterSpacing: '-0.33',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    menuListItems: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        paddingTop: '30px',
        lineSpacing: '19',
        letterSpacing: '-0.33',
        marginRight: '30px',
        justifyContent: 'space-between'
    },
    menuListContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        height: '86px',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '35px',
        display: 'flex',
        boxSizing: 'border-box' as 'border-box',
    },
    tableContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginBottom: '20px',
        marginTop: '5px',
        paddingBottom: '50px',
        paddingLeft: '20px',
        paddingRight: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    tableBodyRows: {
        width: '180px',
        lineSpacing: '19',
        letterSpacing: '0',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    tableBodyRowsUnderline: {
        lineSpacing: '19',
        letterSpacing: '0',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
        textDecoration: 'underline',
    },
    icons: {
        fontSize: 'medium',
        paddingRight: '10px',
        cursor: 'pointer',
    },
    tableHeaderRows: {
        lineSpacing: '17',
        letterSpacing: '-0.29',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 500,
        fontSize: '17',
    },
    iconColumn: {
        whiteSpace: 'nowrap' as 'nowrap',
    },
    iconsSpacing: {
        fontSize: 'medium',
        paddingRight: '10px',
        cursor: 'pointer',
        marginLeft: '30px',
    },
    button: {
        position: 'relative' as 'relative',
        width: '328px',
        height: '56px',
        marginLeft: '81vw',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    createButton: {
        marginLeft: 'auto',
        position: 'relative' as 'relative',
        width: '289px',
        height: '56px',
        marginRight: '2%',
        marginBottom: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        boxSizing: 'border-box' as 'border-box',
        color: 'rgba(28, 28, 26, 1)',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    createButtonIcon: {
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        marginRight: '70px',
        boxSizing: 'border-box' as 'border-box',
    },
    createButton1: {
        marginLeft: '10px',
        position: 'relative' as 'relative',
        width: '289px',
        height: '56px',
        marginRight: '2%',
        marginBottom: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        boxSizing: 'border-box' as 'border-box',
        color: 'rgba(28, 28, 26, 1)',
        textAlignVertical: 'top',
        fontSize: '18',
        fontFamily: 'Plus Jakarta Display',
    },
    createLongTextButtonIcon: {
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        marginRight: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    modalButton: {
        position: 'relative' as 'relative',
        minWidth: '160px',
        height: '56px',
        marginInline: '5px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        paddingInline:"10px"
    },

    modalUpdateCatButton: {
        position: 'relative' as 'relative',
        width: '180px',
        height: '56px',
        margin: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '6px',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    fileAttachmentButtonIcon: {
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        fontWeight: 600,
        boxSizing: 'border-box' as 'border-box',
    },
    errorModalButton: {
        position: 'relative' as 'relative',
        width: '100px',
        height: '45px',
        margin: 'auto 0px',
        marginTop: '15px',
        marginBottom: '15px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    modalButtonRight: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        marginInline: '5px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        float: 'right' as 'right'
    },
    modalCancelButton: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        marginInline: '5px',
        opacity: '1',
        backgroundColor: '#FAEBE6',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    modalShowPaymentCancelButton: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        backgroundColor: '#FAEBE6',
        borderRadius: '10px',
        color: '#FD6533',
        opacity: '1',
        marginBottom : '30px',
        marginRight : '35px',
        fontSize: '18',
        boxSizing: 'border-box' as 'border-box',
        fontFamily: 'Plus Jakarta Display',
        padding: '0',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',

    },
    showLocationCancelBtn:{
        width: '160px',
        height: '56px',
        marginInline: '5px',
        opacity: '1',
        backgroundColor: '#FAEBE6',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        marginBottom: '20px',
        marginRight:"20px",
        color: '#FD6533',
        fontFamily: 'Plus Jakarta Display',
        textAlignVertical: 'top',
        fontSize: '18',
        textAlign: 'center' as 'center',
    },
    attachmentButton: {
        opacity: '1',
        width: '210px',
        height: '56px',
        justifyContent: 'center',
        padding: '0',
        borderRadius: '10px',
        textAlign: 'center' as 'center',
        includeFontPadding: false,
        backgroundColor: '#FAEBE6',
        color: '#FD6533',
        boxSizing: 'border-box' as 'border-box',
        fontSize: '18',
        fontFamily: 'Plus Jakarta Display',
        textAlignVertical: 'top',
    },
    buttonText: {
        width: '104',
        position: 'absolute',
        marginLeft: '16',
        height: '16',
        fontWeight: 'normal',
        opacity: '1',
        marginTop: '14',
        backgroundColor: 'transparent',
        includeFontPadding: false,
        color: 'rgba(28, 28, 26, 1)',
        fontStyle: 'normal',
        fontSize: '18',
        boxSizing: 'border-box' as 'border-box',
        fontFamily: 'Plus Jakarta Display',
        textAlignVertical: 'top',
        padding: '0',
        textAlign: 'center',
    },
    dialog: {
        width: '1360px',
        boxSizing: 'border-box' as 'border-box',
        margin: '0 auto',
    },
    locationUpdateDialogue: {
        width: 'auto',
        boxSizing: 'border-box' as 'border-box',
        margin: '0 auto',
    },
    userAccessPermissionDialog: {
        boxSizing: 'border-box' as 'border-box',
        margin: '0 auto',
    },
    errorDialog: {
        marginTop: '100px',
        marginBottom: '100px',
        padding: '100px',
        width: '1200px',
    },
    errorBox: {
        marginTop: '30px',
        fontFamily: 'Plus Jakarta Display',
        padding: '0 30px 0 30px',
        fontWeight: 400,
        fontSize: '16',

    },
    textFieldBox: {
        backgroundColor: '#FFFFFF',
        width: '360px',
        borderRadius: '10px',
        minHeight: '60px',
        position: 'relative' as 'relative',
        marginBottom: '10px',
        opacity: '1',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
        fontFamily: 'Plus Jakarta Display',
    },
    textFieldBoxUnitMeasurement: {
      backgroundColor: '#FFFFFF',
      width: '270px',
      minHeight: '60px',
      borderRadius: '10px',
      position: 'relative' as 'relative',
      marginBottom: '10px',
      opacity: '1',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
      boxSizing: 'border-box' as 'border-box',
  },
    textFieldBoxSmall: {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        minHeight: '60px',
        borderRadius: '10px',
        width: '182px',
        position: 'relative' as 'relative',
        fontFamily: 'Plus Jakarta Display',
        marginBottom: '10px',
        boxSizing: 'border-box' as 'border-box',
        opacity: '1',
        fontSize: '16',
        fontWeight: 400,
    },
    DateBox: {
        marginTop: '15px',
        backgroundColor: '#FFFFFF',
        paddingBottom: '10px',
        width: '400px',
        minHeight: '60px',
        borderRadius: '10px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    modalHeading: {
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        boxSizing: 'border-box' as 'border-box',
        padding: '20px',
        marginBottom: '30px',
        color: 'black',
    },
    modalHeadingArrow: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        fontWeight: 600,
        boxSizing: 'border-box' as 'border-box',
        color: 'black',
        marginBottom: '30px',
        paddingTop: '24px',
    },
    gridItem: {
        marginLeft: '20px',
        marginBottom: '15px'
    },
    btnGridItem : {
        paddingBottom: '20px',
        display: "flex",
        marginLeft: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    gridItemforUserPermissions: {
        paddingLeft: '20px',
        paddingBottom: '20px',
        marginBottom: '5%',
    },
    gridItemLabel: {
        marginBottom: '10px',
        fontFamily: 'Plus Jakarta Display',
    },
    selectBox: {
        width: '400px', 
        minHeight: '56px',
        marginBottom: '10px',
        borderRadius: '10px',
        border: '#B1B1AE 0.5px solid',
        boxSizing: 'border-box' as 'border-box',
    },
    roleSelectBox: {
        width: '360px', 
        marginBottom: '10px',
        borderRadius: '10px',
        boxSizing: 'border-box' as 'border-box',
        border: '#B1B1AE 0.5px solid',
        minHeight: '56px',
    },
    textFieldEndAdornment: {
        color: '#B1B1AE',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 300,
    },
    selectBoxLabel: {
        color: '#B1B1AE',
        width: '400px',
        minHeight: '56px',
        borderRadius: '10px',
        border: '#B1B1AE 0.5px solid',
        marginBottom: '10px',
        fontSize: '16px',
        boxSizing: 'border-box' as 'border-box',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 350
    },

    roleSelectBoxLabel: {
        width: '360px',
        fontFamily: 'Plus Jakarta Display',
        marginBottom: '10px',
        minHeight: '56px',
        borderRadius: '10px',
        fontWeight: 350,
        boxSizing: 'border-box' as 'border-box',
        border: '#B1B1AE 0.5px solid',
        fontSize: '16px',
        color: '#B1B1AE',
    },
    modalLabel: {
        position: 'relative' as 'relative',
        bottom: '10px',
        height: '15px',
        color: 'black',
        fontFamily: "Plus Jakarta Display",
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '15px',
    },
    validationText: {
        textAlign: 'right' as 'right',
        position: 'relative' as 'relative',
        width: '360px',
        opacity: '1',
        backgroundColor: 'transparent',
        padding: '0px',
        color: 'red',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '12',
        fontStyle: 'italic',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    termsHeadingBottom: {
        height: '22px',
        color: '#1c1c1a',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 500,
        fontFamily: "Plus Jakarta Display",
        letterSpacing: '-0.375px',
        marginTop: '5px',
    },
    termsHeadingTop: {
        height: '22px',
        color: '#1c1c1a',
        fontFamily: "Plus Jakarta Display",
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.375px',
    },
    termsBodyTop: {
        height: '17px',
        color: '#808080',
        fontFamily: "Plus Jakarta Display",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '-0.29166666px',
        marginTop: '15px',
        marginBottom: '20px',
    },
    termsBodyBottom: {
        height: '17px',
        color: '#808080',
        lineHeight: '17px',
        fontSize: '14px',
        fontFamily: "Plus Jakarta Display",
        fontWeight: 400,
        letterSpacing: '-0.29166666px',
        marginTop: '15px',
        marginBottom: '15px',
    },
    productText: {
        color: '#1c1c1a',
        height: '24px',
        marginLeft: '25px',
        marginTop: '30px',
        fontFamily: "Plus Jakarta Display",
        width: '82px',
        fontSize: '20px',
        fontWeight: 700,
        letterSpacing: '-0.41666666px',
        lineHeight: '24px',
    },
    settingsListContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        width: '96vw',
        borderRadius: '15px',
        margin: '32px auto 20px',
        boxSizing: 'border-box' as 'border-box',
    },
    outerContainer: {
        width: '96vw',
        backgroundColor: '#f2f2ec',
        position: 'relative' as 'relative',
        boxSizing: 'border-box' as 'border-box',
        paddingBottom: '15px',
        borderRadius: '15px',
    },
    innerContainer: {
        width: '96vw',
        margin: 'auto',
        borderRadius: '15px',
        backgroundColor: '#FFFFFF',
        paddingBottom: '15px',
        marginBottom: '20px',
    },
    settingsTextField: {
        width: '328px',
        backgroundColor: '#F2F2EC',
        minHeight: '56px',
        paddingBottom: '10px',
        borderRadius: '10px',
        marginLeft: '0px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        marginTop: '16px',
        fontSize: '14',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 300,
        boxSizing: 'border-box' as 'border-box',
    },
    backWithArrow: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        marginRight: 'auto',
        paddingLeft: '180px',
        cursor: 'pointer',
    },
    userPermissionText: {
        fontSize: '24px',
        marginLeft: '10px',
        lineSpacing: 29,
        letterSpacing: -0.5,
    },
    userPermissionsUnchecked: {
        fontSize: '24px',
        marginLeft: '12px',
        marginTop: '1.5px',
        lineSpacing: 29,
        letterSpacing: -0.5,
    },
    userPermissionTextSelectAll: {
        fontSize: '24px',
        marginLeft: '10px',
        lineSpacing: 29,
        letterSpacing: -0.5,
        fontWeight: 600,
    },
    userPermissionsUncheckedSelectAll: {
        fontSize: '24px',
        marginTop: '1.5px',
        lineSpacing: 29,
        marginLeft: '12px',
        letterSpacing: -0.5,
        fontWeight: 600,
    },
    userPermissionGrid: {
        height: '70px'
    },
    searchItemsListContainer: {
        position: "relative" as "relative",
        backgroundColor: "#FFFFFF",
        height: "60px",
        width: "96vw",
        borderRadius: "15px",
        margin: "auto",
        marginTop: "20px",
        marginBottom: "20px",
        display: "flex",
        alignItems: 'center', 
        justifyContent: 'end',
        paddingRight : '20px',
        boxSizing: "border-box" as "border-box",
    },
    searchBoxWrapper : {
        backgroundColor: '#e0e0e0',
        color: 'rgba(28, 28, 26, 1)',
        height: 20,
        padding: '10px',
        marginRight: '15px',
        lineHeight: '15px',
        borderRadius: 25,
    },
    searchPaperStyle : {height : "40px", display: 'flex', alignItems: "center"},
    unCheckedBox:{border:"solid 3px rgb(17, 1, 1)",borderRadius:"10px"},
    tabBtn:{
        width: '328px',
        height: '56px',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        backgroundColor: '#F2F2EC',
        justifyContent:"flex-start",
        margin:"12px"
    },
    iconAtEnd:{
        position:"absolute",
        right:"10px"
    }

}
// Customizable Area End
