import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React, { ChangeEvent } from "react";
import { setStorageData } from "../../../framework/src/Utilities";
let baseURL = require('../../../framework/src/config').baseURL


// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start 
  openModal: boolean;
  spendAnalysisList: any[],
  budgetListReports: any[],
  supplierPerformanceReport : any[],
  spendAnalysis: string,
  subTabValue: number;
  userLoginDetailsCatch : any,
  mainSpendAnalysisDepartmentCategoryData : any[],
  mainSpendAnalysisSuppliersCategoryData : any[],
  mainSpendAnalysisItemNumberData : any[],
  mainSpendAnalysisCategoriesData:any[],
  myReportsStatusNamesData: any[],
  myReportSelectedFromDate : any,
  myReportSelectedToDate : any,
  showSpendAnalysisDepartmentObj : any,
  isShowSpendAnalysisDepartmentActive : boolean,
  departmentFinalPdfDownloadOrNotMessage:string,
  departmentDatesFilteredResponseData : any[],
  isDepartmentDateSelected:boolean,
  isSupplierDateSelected: boolean,
  spendAnaysisDateValue:string,
  supplierDatesFilteredResponseData : any[],
  categoryFinalPdfDownloadOrNotMessage:string,
  itemsNumberFinalPdfDownloadOrNotMessage:string,
  isCategoryDateSelected:boolean,
  categoryDatesFilteredResponseData: any[],
  reportItemRandomId : number,
  isItemNumbersDateSelected : boolean,
  itemNumbersDatesFilteredResponseData : any[],
  departmentChartsData : any[],
  categoryChartsData : any[],
  itemsNumberChartsData : any[],
  supplierChartsData:any[],
  supplierFinalPdfDownloadOrNotMessage : string,
  myReportsMainFeaturesNamesData : any[],
  isBudgetCategoryDateSelected : boolean,
  mainBudgetCategoriesData : any[],
  budgetCategoryChartsData : any[],
  isBudgetDepartmentDateSelected : boolean,
  mainBudgetDepartmentData : any[],
  budgetDepartmentChartsData : any[],
  supplierPerformanceResData:any[],
  supplierPerformanceChartsResData:any[],
  supplierPerformanceReportDownloadorNotMessage:string,
  userNameProfile : string,
  isSupplierPerformanceDateSelected:boolean,
  supplierPerformanceFilteredData:any[],
  supplierPerformanceFilteredChartData:any[],
  totalPages:number,
  currentPage:number,
  itemsPerPage:number
  loggedUserNameCatchReport : string,
  loggedUserEmailCatchReport : string,






  // Customizable Area End
}
interface SS {
  id: any;
}

export default class MyReportsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  getUserModulePermissionsAPICallId: string = "";
  getMyReportsMainCategoryAPICallId: string = "";
  getSpendAnalysisSuppliersAPICallId: string = "";
  getShowSpendAnalysisDepartmentAPICallId: string = "";
  getPODatesForReportsSelectBoxAPICallId : string = "";
  getSpendAnalysisItemsNumberVsRupeeAPICallId: string = "";
  getReportsDepartmentRupeeFilteredDatesAfterUserSelectAPICallId : string = "";
  getReportsSupplierRupeeFilteredDatesAfterUserSelectAPICallId : string = "";
  getSpendAnalysisCategoryRupeeAPICallId: string = "";
  getReportsItemsNumberRupeeFilteredDatesAfterUserSelectAPICallId : string = "";
  getDepartmentChartsApiCallId : string = "";
  getReportsCategoryRupeeFilteredDatesAfterUserSelectAPICallId : string = "";
  getCategoryChartsApiCallId : string = "";
  getSupplierChartsApiCallId : string = "";
  getItemsNumberChartsApiCallId : string = "";
  getBudgetDepartmentAPICallId: string = "";
  getBudgetCategoryAPICallId: string = "";
  getBudgetDepartmentChartsApiCallId : string = "";
  getBudgetCategoryChartsApiCallId : string = "";
  getSupplierPerformanceApiCallId:string = "";
  getSupplierPerformanceChartsApiCallId:string = "";
  getReportsSupplierPerformanceFilteredDatesAfterUserSelectAPICallId:string = "";
  getPermissionsAPICallId:string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      openModal: false,
      spendAnalysisList: [],
      budgetListReports : [],
      supplierPerformanceReport: [],
      spendAnalysis: "Category vs Rupee Spend",
      subTabValue: 0,
      userLoginDetailsCatch : {},
      mainSpendAnalysisDepartmentCategoryData: [],
      mainSpendAnalysisSuppliersCategoryData: [],
      mainSpendAnalysisCategoriesData: [],
      mainSpendAnalysisItemNumberData: [],
      myReportsStatusNamesData:[],
      myReportSelectedFromDate : '',
      myReportSelectedToDate : '',
      showSpendAnalysisDepartmentObj: {},
      isShowSpendAnalysisDepartmentActive: false,
      departmentFinalPdfDownloadOrNotMessage: '',
      departmentDatesFilteredResponseData:[],
      isDepartmentDateSelected: false,
      isSupplierDateSelected: false,
      spendAnaysisDateValue: '',
      supplierDatesFilteredResponseData:[],
      categoryFinalPdfDownloadOrNotMessage: '',
      itemsNumberFinalPdfDownloadOrNotMessage: '',
      isCategoryDateSelected:false,
      categoryDatesFilteredResponseData: [],
      reportItemRandomId: 0,
      isItemNumbersDateSelected : false,
      itemNumbersDatesFilteredResponseData : [],
      departmentChartsData : [],
      categoryChartsData : [],
      itemsNumberChartsData :[],
      supplierChartsData:[],
      supplierFinalPdfDownloadOrNotMessage:'',
      isBudgetCategoryDateSelected : false,
      mainBudgetCategoriesData : [],
      budgetCategoryChartsData : [],
      isBudgetDepartmentDateSelected : false,
      mainBudgetDepartmentData : [],
      budgetDepartmentChartsData : [],


      myReportsMainFeaturesNamesData : [
        {
            id: 1,
            report_status : "Category vs Rupee Spend",
            report_type: 'spend analysis',
            handlingFunc: this.handleGetSpendAnalysisCategoryVsRupee
        },
        {
            id : 2,
            report_status : "Supplier vs Rupee Spend",
            report_type: 'spend analysis',
            handlingFunc: this.handleGetSpendAnalysisSuppliersVsRupee
        },
        {
            id : 3,
            report_status : "Item Number vs Rupee Spend",
            report_type: 'spend analysis',
            handlingFunc: this.handleGetSpendAnalysisItemNumbersVsRupee
        },
        {
            id : 4,
            report_status : "Department vs Rupee Spend",
            report_type: 'spend analysis',
            handlingFunc: this.handleGetMyReportDepartmentVsRupee
        },
        {
            id : 5,
            report_status : "Category Budget vs Category Spend",
            report_type: 'budget',
            handlingFunc: this.handleGetBudgetCategory
        },
        {
            id : 6,
            report_status : "Department Budget vs Actual Spend",
            report_type: 'budget',
            handlingFunc: this.handleGetBudgetDepartment
        },
      ],
      supplierPerformanceResData:[],
      supplierPerformanceChartsResData:[],
  supplierPerformanceReportDownloadorNotMessage:"",
  userNameProfile : "",
  isSupplierPerformanceDateSelected:false,
  supplierPerformanceFilteredData:[],
  supplierPerformanceFilteredChartData:[],
  totalPages:0,
  currentPage:1,
  itemsPerPage:5,
  loggedUserNameCatchReport : "",
  loggedUserEmailCatchReport : "",
  


    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  
    // Customizable Area Start
    if(!this.hasPermission("reports")){
      this.props.navigation.navigate("Dashboard");
    }
    this.getUserProfileDataFromLocalStorage2()
    this.handleGetMainUserModulePermissionsFuncCall()
    this.handleAllPoDatesForReportsSelectBox()
    this.handleGetSpendAnalysisCategoryVsRupee()
    this.handleGetBudgetCategory()
    this.handleCategoryCharts()
    this.handleSupplierPerformance()
    this.handleGetUserPermissionsAPI()
    
    

    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  

  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.handlegetUserModulePermissionsAPICallIdResponse(message)

    this.handleGetMyReportsMainCategoryAPICallIdResponse(message)

    this.handleGetSpendAnalysisSuppliersCategoryAPICallIdResponse(message)

    this.handleAllPoDatesForReportsSelectBoxAPICallIdResponse(message)

    this.handleSpendAnalysisItemsNumberAPICallIdResponse(message)

    this.handleReportsDepartmentRupeeFilteredDatesAfterUserSelectAPICallIdResponse(message)

    this.handleReportsSuppliersRupeeFilteredDatesAfterUserSelectAPICallIdResponse(message)

    this.handleGetSpendAnalysisCategoryAPICallIdResponse(message)

    this.handleReportsItemNumbersRupeeFilteredDatesAfterUserSelectAPICallIdResponse(message)

    this.handleReportsCategoryRupeeFilteredDatesAfterUserSelectAPICallIdResponse(message)

    this.handleDepartmentChartsAPICallIdResponse(message)

    this.handleCategoryChartsAPICallIdResponse(message)

    this.handleItemsNumberChartsAPICallIdResponse(message)

    this.handleSupplierChartsAPICallIdResponse(message)


    // budget reports

    this.handleGetBudgetDepartmentAPICallIdResponse(message);

    this.handleGetBudgetCategoryAPICallIdResponse(message);

    this.handleBudgetDepartmentChartsAPICallIdResponse(message);

    this.handleBudgetCategoryChartsAPICallIdResponse(message);

    // Supplier Performance
    this.handleGetSupplierPerformanceAPICallIdResponse(message)
    this.handleGetSupplierPerformanceChartsAPICallIdResponse(message)
    this.handleGetSupplierPerformanceFilteredDatesAPICallIdResponse(message)
    this.getUserPermissionsAPICallIdResponse(message)
    



    // Customizable Area End
  }

  // Customizable Area Start

  handleMyReportsSubTabValue = (selectedValue : number) => {
    if(selectedValue === 0){
      this.handleGetSpendAnalysisCategoryVsRupee()
      return this.setState({
        spendAnalysis: "Category vs Rupee Spend",
      });
    }
    else if(selectedValue === 1){
      this.handleGetBudgetCategory()
      return this.setState({
        spendAnalysis: "Category Budget vs Category Spend",
      });
    }
    else if(selectedValue === 2){
      this.handleSupplierPerformance()
      return this.setState({
        spendAnalysis: "Supplier Performance",
      });
    }
  }

  handleSubTabValueChange = (event: React.ChangeEvent<{}>, value: number): void => {
    this.setState({
      spendAnaysisDateValue : '',
      isDepartmentDateSelected : false,
      isSupplierDateSelected : false,
      isCategoryDateSelected:false,
      isItemNumbersDateSelected: false,
      subTabValue: value,
      isSupplierPerformanceDateSelected:false,
      currentPage:1,
      totalPages:0
    })
    this.handleMyReportsSubTabValue(value)
  }

  handleSelectFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      spendAnaysisDateValue : '',
      isDepartmentDateSelected : false,
      isSupplierDateSelected : false,
      isCategoryDateSelected:false,
      isItemNumbersDateSelected: false,
      spendAnalysis: event.target.value
    })
  }

  handleSelectSpendAnaysisDatesChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({spendAnaysisDateValue: event.target.value, currentPage:1})
  }

  changeTotalNumofPages = (data:Array<any>)=>{
    this.setState({
      totalPages:data.length % this.state.itemsPerPage === 0 ? Math.floor(data.length/this.state.itemsPerPage) : Math.floor(data.length/this.state.itemsPerPage)+1
    })
  }

  // receivers function started 

  handlegetUserModulePermissionsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      this.setState({
        userLoginDetailsCatch : apiResponse
      })
    } 
  }

  handlegetUserModulePermissionsAPICallIdResponse = (message: Message) => {
    if (this.getUserModulePermissionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handlegetUserModulePermissionsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetMyReportsMainCategoryAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data && apiResponse.data.length){
      this.setState({
        mainSpendAnalysisDepartmentCategoryData : apiResponse.data,
      })
      this.changeTotalNumofPages(apiResponse.data)
      this.handleDepartmentCharts()
    }
  }

  handleGetMyReportsMainCategoryAPICallIdResponse = (message: Message) => {
    if (this.getMyReportsMainCategoryAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse:any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetMyReportsMainCategoryAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetSpendAnalysisSuppliersCategoryAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data && apiResponse.data.suppliers){
      this.setState({
        mainSpendAnalysisSuppliersCategoryData : apiResponse.data.suppliers,
      })
      this.changeTotalNumofPages(apiResponse.data.suppliers)
    }
  }

  handleGetSpendAnalysisSuppliersCategoryAPICallIdResponse = (message: Message) => {
    if (this.getSpendAnalysisSuppliersAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetSpendAnalysisSuppliersCategoryAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handlAllPoDatesForReportsSelectBoxAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      const orderDates = apiResponse?.order_date?.filter((date:any) => date !== null && date !== undefined);
      this.setState({
        spendAnalysisList : orderDates
      })
    }
  }

  handleAllPoDatesForReportsSelectBoxAPICallIdResponse = (message: Message) => {
    if (this.getPODatesForReportsSelectBoxAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handlAllPoDatesForReportsSelectBoxAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleSpendAnalysisItemsNumberAPICallIdResponseAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data) {
      this.setState({
        mainSpendAnalysisItemNumberData : apiResponse.data,
      })
      const totalProductsLength = apiResponse.data.reduce((accumulator:number, currentPurchaseOrder:any) => {
        const productsArray = currentPurchaseOrder.attributes.products || [];
        return accumulator + productsArray.length;
      }, 0);
      this.setState({
        totalPages:totalProductsLength % this.state.itemsPerPage === 0 ? Math.floor(totalProductsLength/this.state.itemsPerPage) : Math.floor(totalProductsLength/this.state.itemsPerPage)+1
      })
      this.handleItemNumbersCharts()
    }
  }

  handleSpendAnalysisItemsNumberAPICallIdResponse = (message: Message) => {
    if (this.getSpendAnalysisItemsNumberVsRupeeAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleSpendAnalysisItemsNumberAPICallIdResponseAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetSpendAnalysisCategoryAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data){
      this.changeTotalNumofPages(apiResponse.data)
      this.setState({
        mainSpendAnalysisCategoriesData : apiResponse.data
      })
      this.handleCategoryCharts()
    }
  }

  handleGetSpendAnalysisCategoryAPICallIdResponse = (message: Message) => {
    if (this.getSpendAnalysisCategoryRupeeAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetSpendAnalysisCategoryAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleReportsDepartmentRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data && apiResponse.data.length){
      let departmentFilteredData = apiResponse?.data?.filter((data:any) => data?.attributes?.department !== null && data?.attributes?.department !== undefined);
      this.changeTotalNumofPages(departmentFilteredData)
      let cloneData = [...JSON.parse(JSON.stringify(departmentFilteredData))]
      cloneData = cloneData.map((val)=>{
        let {name , rupee_spend} = val.attributes.department
        val.attributes = {name , rupee_spend}
        return val
      })
      this.setState({
        departmentDatesFilteredResponseData : departmentFilteredData,
        departmentChartsData:cloneData,
      })
    } 
  }

  handleReportsDepartmentRupeeFilteredDatesAfterUserSelectAPICallIdResponse = (message: Message) => {
    if (this.getReportsDepartmentRupeeFilteredDatesAfterUserSelectAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleReportsDepartmentRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleReportsSuppliersRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse.errors){
      console.log('reports supplier filtered date error', apiResponse.errors)
    } else if(apiResponse && apiResponse.data && apiResponse.data.suppliers) {
      this.changeTotalNumofPages(apiResponse.data.suppliers)
      this.setState({
        supplierDatesFilteredResponseData : apiResponse?.data?.suppliers,
        mainSpendAnalysisSuppliersCategoryData:apiResponse.data.suppliers,
        })
    }else{
      this.setState({totalPages:0})
    }
  }

  handleReportsSuppliersRupeeFilteredDatesAfterUserSelectAPICallIdResponse = (message: Message) => {
    if (this.getReportsSupplierRupeeFilteredDatesAfterUserSelectAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleReportsSuppliersRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleReportsItemNumbersRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data && apiResponse.data.length) {
      this.changeTotalNumofPages(apiResponse.data)
      this.setState({
        itemNumbersDatesFilteredResponseData : apiResponse.data,
      })
      this.changeItemNumberTableDataToChartData(apiResponse.data)
    }
  }
  changeItemNumberTableDataToChartData = (data:Array<any>)=>{
    let filteredArray = data.filter(item=> item.attributes.products.length)
   let attributes = filteredArray.reduce((acc, item) => {
      if (item.attributes && item.attributes.products) {
        acc.push(...item.attributes.products.map((product:any) => ({
          ruppee_spend: item.attributes.department.rupee_spend,
          item_id: product.item_id
        })));
      }
      return acc;
    }, []);
    this.setState({
      itemsNumberChartsData: attributes
    })

  }

  handleReportsItemNumbersRupeeFilteredDatesAfterUserSelectAPICallIdResponse = (message: Message) => {
    if (this.getReportsItemsNumberRupeeFilteredDatesAfterUserSelectAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleReportsItemNumbersRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleReportsCategoryRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data){
      const categoryFilteredData = apiResponse?.data?.filter((data:any) => data?.attributes?.department !== null && data?.attributes?.department !== undefined);
      this.changeTotalNumofPages(categoryFilteredData)
      this.setState({
        currentPage: 1,
        categoryDatesFilteredResponseData : categoryFilteredData,
      })
      const newData = [...JSON.parse(JSON.stringify(categoryFilteredData))]
      this.changeChartsData(newData)
    }
  }
  changeChartsData = (data:Array<any>)=>{
    const chartData = data.map((val:any)=>{
      val.attributes = {name: val.attributes.department.name, ruppee_spend : val.attributes.department.rupee_spend}
      return val
    })
    this.setState({
      categoryChartsData:chartData
    })
  }

  handleReportsCategoryRupeeFilteredDatesAfterUserSelectAPICallIdResponse = (message: Message) => {
    if (this.getReportsCategoryRupeeFilteredDatesAfterUserSelectAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleReportsCategoryRupeeFilteredDatesAfterUserSelectAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleDepartmentChartsAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      this.setState({
        departmentChartsData : apiResponse?.data
      })
    }
  }

  handleDepartmentChartsAPICallIdResponse = (message: Message) => {
    if (this.getDepartmentChartsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleDepartmentChartsAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCategoryChartsAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      this.setState({
        categoryChartsData : apiResponse?.data
      })
    }
  }

  handleCategoryChartsAPICallIdResponse = (message: Message) => {
    if (this.getCategoryChartsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleCategoryChartsAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleItemsNumberChartsAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      this.setState({
        itemsNumberChartsData :apiResponse?.data?.attributes
      })
    }
  }

  handleItemsNumberChartsAPICallIdResponse = (message: Message) => {
    if (this.getItemsNumberChartsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleItemsNumberChartsAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleSupplierChartsAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      this.setState({
        supplierChartsData : apiResponse?.data
      })
    }
  }

  handleSupplierChartsAPICallIdResponse = (message: Message) => {
    if (this.getSupplierChartsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleSupplierChartsAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  // budget receiver

  handleGetBudgetDepartmentAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data){
      this.changeTotalNumofPages(apiResponse.data)
      this.setState({
        mainBudgetDepartmentData : apiResponse?.data
      })
      this.handleBudgetDepartmentCharts()
    }
  }

  handleGetBudgetDepartmentAPICallIdResponse = (message: Message) => {
    if (this.getBudgetDepartmentAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetBudgetDepartmentAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  } 

  handleGetBudgetCategoryAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse && apiResponse.data){
      this.changeTotalNumofPages(apiResponse.data)
      this.setState({
        mainBudgetCategoriesData : apiResponse?.data
      })
      this.handleBudgetCategoryCharts()
    }
  }

  handleGetBudgetCategoryAPICallIdResponse = (message: Message) => {
    if (this.getBudgetCategoryAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetBudgetCategoryAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleBudgetCategoryChartsAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      this.setState({
        budgetCategoryChartsData : apiResponse?.data
      })
    }
  }

  handleBudgetCategoryChartsAPICallIdResponse = (message: Message) => {
    if (this.getBudgetCategoryChartsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleBudgetCategoryChartsAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleBudgetDepartmentChartsAPICallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse){
      this.setState({
        budgetDepartmentChartsData : apiResponse?.data
      })
    }
  }

  handleBudgetDepartmentChartsAPICallIdResponse = (message: Message) => {
    if (this.getBudgetDepartmentChartsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleBudgetDepartmentChartsAPICallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  

  // receivers function ended


  // api calling started

  

  handleGetMyReportDepartmentVsRupee = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get Department vs rupee")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getMyReportsMainCategories = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getMyReportsMainCategoryAPICallId = getMyReportsMainCategories.messageId;
  
    getMyReportsMainCategories.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/department_rupee_spend_filter_data?report_status=department_vs_rupee_spend`
    );
  
    getMyReportsMainCategories.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getMyReportsMainCategories.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getMyReportsMainCategories.id, getMyReportsMainCategories);
  }


  handleGetSpendAnalysisSuppliersVsRupee = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get Spend Analysis Suppliers VS Rupee")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getSpendAnalysisSupplierCategories = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSpendAnalysisSuppliersAPICallId = getSpendAnalysisSupplierCategories.messageId;
  
    getSpendAnalysisSupplierCategories.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/rupee_spend_of_supplier`
    );
  
    getSpendAnalysisSupplierCategories.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getSpendAnalysisSupplierCategories.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getSpendAnalysisSupplierCategories.id, getSpendAnalysisSupplierCategories);
  }

  handleGetUserPermissionsAPI = () => {
    const token_localStorage = localStorage.getItem('authToken')
    let headers = { 'Content-Type': 'application/json',
                     'token': token_localStorage,
                  }
  
    const getPermissions = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.getPermissionsAPICallId = getPermissions.messageId;
  
    getPermissions.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/user_permision_details`
    );
  
    getPermissions.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getPermissions.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getPermissions.id, getPermissions);
  }

  handleGetSpendAnalysisItemNumbersVsRupee = () => {

    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get Spend Analysis Item Numbers vs Rupee")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getSpendAnalysisItemsNumberVsRupee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSpendAnalysisItemsNumberVsRupeeAPICallId = getSpendAnalysisItemsNumberVsRupee.messageId;
  



    getSpendAnalysisItemsNumberVsRupee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/item_number_rupee_spend_filter_data?report_status=item_number_vs_rupee_spend`
    );
  
    getSpendAnalysisItemsNumberVsRupee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getSpendAnalysisItemsNumberVsRupee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getSpendAnalysisItemsNumberVsRupee.id, getSpendAnalysisItemsNumberVsRupee);
  }

  handleGetSpendAnalysisCategoryVsRupee = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get Spend Analysis Category vs Rupee")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getSpendAnalysisCategoryVsRupee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSpendAnalysisCategoryRupeeAPICallId = getSpendAnalysisCategoryVsRupee.messageId;
    getSpendAnalysisCategoryVsRupee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/ruppee_spend_of_categories?report_status=category_vs_rupee_spend`
    );
  
    getSpendAnalysisCategoryVsRupee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getSpendAnalysisCategoryVsRupee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getSpendAnalysisCategoryVsRupee.id, getSpendAnalysisCategoryVsRupee);
  }


  handleGetMainUserModulePermissionsFuncCall = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getUserPermissionsMain = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserModulePermissionsAPICallId = getUserPermissionsMain.messageId;
  
    getUserPermissionsMain.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/view_account`
    );
  
    getUserPermissionsMain.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUserPermissionsMain.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getUserPermissionsMain.id, getUserPermissionsMain);
  }


  handleAllPoDatesForReportsSelectBox = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const pODatesForReportsSelectBox = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getPODatesForReportsSelectBoxAPICallId = pODatesForReportsSelectBox.messageId;
  
    pODatesForReportsSelectBox.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/fetch_all_purchase_orders_dates`
    );
  
    pODatesForReportsSelectBox.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    pODatesForReportsSelectBox.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(pODatesForReportsSelectBox.id, pODatesForReportsSelectBox);
  }


  handlePdfDownloadCommonFunc = (url: string, fileName: string, successMessage: string, errorMessage: string, stateKey: string) => {
  const token_localStorage = localStorage.getItem('authToken') as string;

  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      'token': token_localStorage,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      if (successMessage) {
        this.setState((prevState) => ({
          ...prevState,
          [stateKey]: successMessage,
        }));
      }

      link.parentNode!.removeChild(link);
    })
    .catch((error) => {
      if (error instanceof Error) {
        this.setState((prevState) => ({
          ...prevState,
          [stateKey]: errorMessage || error.message,
        }));
      }
    });
};

handleDepartmentsReportDownloadPdf = (departmentId: string) => {
  const fileName = `Report - Department vs Rupee Spend${new Date().toISOString().slice(0, 10)}.pdf`;
  const url = `${baseURL}/bx_block_my_forms/my_forms/generate_department_rupee_spend_filter_data_pdf?department_id=${departmentId}`;
  const successMessage = 'Department vs rupee spend file is downloaded';
  const stateName = 'departmentFinalPdfDownloadOrNotMessage'

  this.handlePdfDownloadCommonFunc(url, fileName, successMessage, '', stateName);
};

handleItemsNumberReportDownloadPdf = (itemNumsId: string) => {
  const fileName = `Report - Item Nums vs Rupee Spend${new Date().toISOString().slice(0, 10)}.pdf`;
  const url = `${baseURL}/bx_block_my_forms/my_forms/generate_item_number_rupee_spend_filter_data_pdf?purchase_order_id=${itemNumsId}`;
  const successMessage = 'Item Numbers vs rupee spend file is downloaded';
  const stateName = 'itemsNumberFinalPdfDownloadOrNotMessage'

  this.handlePdfDownloadCommonFunc(url, fileName, successMessage, '', stateName);
};


handleCategoryReportDownloadPdf = (categoryId:string) => {
  const fileName = `Report - Category vs Rupee Spend-${new Date().toISOString().slice(0, 10)}.pdf`;
  const url = `${baseURL}/bx_block_my_forms/my_forms/generate_category_rupee_spend_filter_data_pdf?category_setting_id=${categoryId}`;
  const successMessage = 'Category vs rupee spend file is downloaded';
  const stateName = 'categoryFinalPdfDownloadOrNotMessage'

  this.handlePdfDownloadCommonFunc(url, fileName, successMessage, '', stateName);
};

handleSupplierReportDownloadPdf = (categoryId:string) => {
  const fileName = `Report - Supplier vs Rupee Spend-${new Date().toISOString().slice(0, 10)}.pdf`;
  const url = `${baseURL}/bx_block_my_forms/my_forms/generate_supplier_rupee_spend_filter_data_pdf?supplier_id=${categoryId}`;
  const successMessage = 'Supplier vs rupee spend file is downloaded';
  const stateName = 'supplierFinalPdfDownloadOrNotMessage'

  this.handlePdfDownloadCommonFunc(url, fileName, successMessage, '', stateName);
};


  handleReportsDepartmentRupeeFilteredDatesAfterUserSelect = (startingDate : any, endDate: any) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    this.setState({
      isDepartmentDateSelected : true
    })
  
    const reportsDepartmentRupeeFilteredDatesAfterUserSelect = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getReportsDepartmentRupeeFilteredDatesAfterUserSelectAPICallId = reportsDepartmentRupeeFilteredDatesAfterUserSelect.messageId;
  
    reportsDepartmentRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders_between_dates?start_date=${startingDate}&end_date=${endDate}`
    );
  
    reportsDepartmentRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    reportsDepartmentRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(reportsDepartmentRupeeFilteredDatesAfterUserSelect.id, reportsDepartmentRupeeFilteredDatesAfterUserSelect);
  }

  handleReportsSuppliersRupeeFilteredDatesAfterUserSelect = (startingDate : any, endDate: any) => {
    this.setState({
      isSupplierDateSelected: true,
    })

    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const reportsSupplierRupeeFilteredDatesAfterUserSelect = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getReportsSupplierRupeeFilteredDatesAfterUserSelectAPICallId = reportsSupplierRupeeFilteredDatesAfterUserSelect.messageId;
  
    reportsSupplierRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/rupee_spend_of_supplier?start_date=${startingDate}&end_date=${endDate}`
    );
  
    reportsSupplierRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    reportsSupplierRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(reportsSupplierRupeeFilteredDatesAfterUserSelect.id, reportsSupplierRupeeFilteredDatesAfterUserSelect);
  }

  handleReportsItemNumbersRupeeFilteredDatesAfterUserSelect = (startingDate : any, endDate: any) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    this.setState({
      isItemNumbersDateSelected : true
    })
  
    const reportsItemNumbersRupeeFilteredDatesAfterUserSelect = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getReportsItemsNumberRupeeFilteredDatesAfterUserSelectAPICallId = reportsItemNumbersRupeeFilteredDatesAfterUserSelect.messageId;
  
    reportsItemNumbersRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/data_filter_by_date_item_number?start_date=${startingDate}&end_date=${endDate}`
    );
  
    reportsItemNumbersRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    reportsItemNumbersRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(reportsItemNumbersRupeeFilteredDatesAfterUserSelect.id, reportsItemNumbersRupeeFilteredDatesAfterUserSelect);
  }

  handleReportsCategoryRupeeFilteredDatesAfterUserSelect = (startingDate : any, endDate: any) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    this.setState({
      isCategoryDateSelected : true
    })
  
    const reportsCategoryRupeeFilteredDatesAfterUserSelect = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getReportsCategoryRupeeFilteredDatesAfterUserSelectAPICallId = reportsCategoryRupeeFilteredDatesAfterUserSelect.messageId;
  
    reportsCategoryRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/data_filter_by_date_category?start_date=${startingDate}&end_date=${endDate}`
    );
  
    reportsCategoryRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    reportsCategoryRupeeFilteredDatesAfterUserSelect.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(reportsCategoryRupeeFilteredDatesAfterUserSelect.id, reportsCategoryRupeeFilteredDatesAfterUserSelect);
  }

  handleDepartmentCharts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getDepartmentCharts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getDepartmentChartsApiCallId = getDepartmentCharts.messageId;
  
    getDepartmentCharts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/department_graph`
    );
  
    getDepartmentCharts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getDepartmentCharts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getDepartmentCharts.id, getDepartmentCharts);
  }

  handleCategoryCharts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getCategoryCharts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getCategoryChartsApiCallId = getCategoryCharts.messageId;
  
    getCategoryCharts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/category_graph`
    );
  
    getCategoryCharts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getCategoryCharts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getCategoryCharts.id, getCategoryCharts);
  }

  handleSupplierCharts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getSupplierCharts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSupplierChartsApiCallId = getSupplierCharts.messageId;
  
    getSupplierCharts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/department_graph`
    );
  
    getSupplierCharts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getSupplierCharts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getSupplierCharts.id, getSupplierCharts);
  }

  handleItemNumbersCharts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getItemsNumberCharts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getItemsNumberChartsApiCallId = getItemsNumberCharts.messageId;
  
    getItemsNumberCharts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/item_number_graph`
    );
  
    getItemsNumberCharts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getItemsNumberCharts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getItemsNumberCharts.id, getItemsNumberCharts);
  }



  // budget reports

  handleGetBudgetCategory = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get Budget Category")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetCategory = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetCategoryAPICallId = getBudgetCategory.messageId;
  
    getBudgetCategory.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/category_budget_data`
    );
  
    getBudgetCategory.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetCategory.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getBudgetCategory.id, getBudgetCategory);
  }

  handleGetBudgetDepartment = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get Budget Department vs rupee")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetDepartment = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetDepartmentAPICallId = getBudgetDepartment.messageId;
  
    getBudgetDepartment.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/department_budget_data`
    );
  
    getBudgetDepartment.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetDepartment.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getBudgetDepartment.id, getBudgetDepartment);
  }

  handleBudgetDepartmentCharts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetDepartmentCharts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetDepartmentChartsApiCallId = getBudgetDepartmentCharts.messageId;
  
    getBudgetDepartmentCharts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/department_budget_graph`
    );
  
    getBudgetDepartmentCharts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetDepartmentCharts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getBudgetDepartmentCharts.id, getBudgetDepartmentCharts);
  }

  handleBudgetCategoryCharts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetCategoryCharts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetCategoryChartsApiCallId = getBudgetCategoryCharts.messageId;
  
    getBudgetCategoryCharts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/category_budget_graph`
    );
  
    getBudgetCategoryCharts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetCategoryCharts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getBudgetCategoryCharts.id, getBudgetCategoryCharts);
  }
// Supplier Performance Api calls
handleSupplierPerformance = () => {
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = {
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const getSupplierPerformance = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSupplierPerformanceApiCallId = getSupplierPerformance.messageId;

  getSupplierPerformance.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_my_forms/my_forms/supplier_data`
  );

  getSupplierPerformance.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  getSupplierPerformance.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(getSupplierPerformance.id, getSupplierPerformance);
}

handleGetSupplierPerformanceApiCallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
  if(apiResponse && apiResponse.length){
    let chartData = apiResponse.map((val:any, index:number)=>{
      let grandTotal = val.timely + val.delayed
      let data = {id:index,type:"supplierperformance_graph",attributes:{supplier_name:val.supplier_name, supplier_percentage:val.timely/grandTotal *100}}
      return data;
    })
    this.changeTotalNumofPages(apiResponse)
    this.setState({
      supplierPerformanceResData : apiResponse,
      supplierPerformanceChartsResData:chartData
    })
     this.handleSupplierPerformanceCharts()
  }
}

handleGetSupplierPerformanceAPICallIdResponse = (message: Message) => {
  if (this.getSupplierPerformanceApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleGetSupplierPerformanceApiCallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
  }
}
handleSupplierPerformanceCharts = () => {
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = {
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const getSupplierPerformanceCharts = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSupplierPerformanceChartsApiCallId = getSupplierPerformanceCharts.messageId;

  getSupplierPerformanceCharts.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_my_forms/my_forms/supplier_data_graph`
  );

  getSupplierPerformanceCharts.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  getSupplierPerformanceCharts.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(getSupplierPerformanceCharts.id, getSupplierPerformanceCharts);
}

handleGetSupplierPerformanceChartsApiCallIdResponseSuccessAndErrorsNestedFunc = (apiResponse:any) => {
  if(apiResponse){
    this.setState({
      supplierPerformanceChartsResData:apiResponse?.data
    })
  }
}

handleGetSupplierPerformanceChartsAPICallIdResponse = (message: Message) => {
  if (this.getSupplierPerformanceChartsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleGetSupplierPerformanceChartsApiCallIdResponseSuccessAndErrorsNestedFunc(apiResponse)
  }
}

handleSupplierPerformanceReportDownload = (id:number)=>{
  const fileName = `Supplier Performance-${new Date().toISOString().slice(0, 10)}.pdf`;
  const url = `${baseURL}/bx_block_my_forms/my_forms/generate_supplier_performance_data_pdf?supplier_id=${id}`;
  const successMessage = 'Supplier Performance file is downloaded';
  const stateName = 'supplierPerformanceReportDownloadorNotMessage'

  this.handlePdfDownloadCommonFunc(url, fileName, successMessage, '', stateName);
}

handleSupplierPerformanceFilteredDatesAfterUserSelect = (startingDate : any, endDate: any) => {
  this.setState({isSupplierPerformanceDateSelected:true})
  const token_localStorage = localStorage.getItem('authToken') 
  let headers = {
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }  

  const reportsSupplierPerformanceFilteredDatesAfterUserSelect = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getReportsSupplierPerformanceFilteredDatesAfterUserSelectAPICallId = reportsSupplierPerformanceFilteredDatesAfterUserSelect.messageId;

  reportsSupplierPerformanceFilteredDatesAfterUserSelect.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_my_forms/my_forms/supplier_performance_filter_data?start_date=${startingDate}&end_date=${endDate}`
  );

  reportsSupplierPerformanceFilteredDatesAfterUserSelect.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  reportsSupplierPerformanceFilteredDatesAfterUserSelect.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(reportsSupplierPerformanceFilteredDatesAfterUserSelect.id, reportsSupplierPerformanceFilteredDatesAfterUserSelect);
}

handleSupplierPerformanceDatesFilteredResponce = (apiResponse:any)=>{
  let chartData = apiResponse.map((val:any, index:number)=>{
    let grandTotal = val.timely + val.delayed
    let data = {id:index,type:"supplierperformance_graph",attributes:{supplier_name:val.supplier_name, supplier_percentage:val.timely/grandTotal *100}}
    return data;
  })
  this.changeTotalNumofPages(apiResponse)
  this.setState({
    supplierPerformanceFilteredData : apiResponse,
    supplierPerformanceFilteredChartData:chartData
  })
}

handleGetSupplierPerformanceFilteredDatesAPICallIdResponse = (message: Message) => {
  if (this.getReportsSupplierPerformanceFilteredDatesAfterUserSelectAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleSupplierPerformanceDatesFilteredResponce(apiResponse)
  }
}
  // api calling ended



  // other functions started


  generateSpendAnalysisItemsRupeeRandomNumber = () => {
    const min = 10000;
    const max = 99999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  
    return randomNumber
  }

  handleCategoryFinalPdfDownloadOrNotMessageClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryFinalPdfDownloadOrNotMessage: '',
    })
  }
  handleSupplierFinalPdfDownloadOrNotMessageClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      supplierFinalPdfDownloadOrNotMessage: '',
    })
  }
  handleItemsNumberFinalPdfDownloadOrNotMessageClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      itemsNumberFinalPdfDownloadOrNotMessage: '',
    })
  }

  getUserProfileDataFromLocalStorage2 = () => {
    const userLoggedUserEmailStr2 : any = localStorage.getItem('userProfile_email')
    const userLoggedUserNameStr2 : any = localStorage.getItem('userProfile_user_Name')

    this.setState({
      loggedUserNameCatchReport : userLoggedUserNameStr2,
      loggedUserEmailCatchReport : userLoggedUserEmailStr2,
      userNameProfile: userLoggedUserNameStr2?.split(/\s+/)
        .map((word : string) => word.slice(0, 1))
        .join(""),
    })
  }


  handleDepartmentFinalPdfDownloadOrNotMessageClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      departmentFinalPdfDownloadOrNotMessage: '',
    })
  }
  // other functions ended

  hasPermission = (permission: string) => {
    const permissions = localStorage.getItem('user_permissions')
    if(permissions == "all permission allowed") {
      return true;
    }
    const permissionsArr = JSON.parse(permissions || "[]");
    if(permissionsArr && permissionsArr.includes(permission)) {
      return true;
    } 
     return false;
  }

  handleGetUserPermissionsResponse = async (response:any) => 
  {
    if(!response?.errors)
    { 
      await setStorageData('user_permissions',response.data.attributes.user_permission)
    }
  }
  
  getUserPermissionsAPICallIdResponse = (message: Message) => {
    if (this.getPermissionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    {
      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.handleGetUserPermissionsResponse(apiResponse)
    }
  }


  // Customizable Area End

}
