import React from "react";

// Customizable Area Start
import Pagination from '@material-ui/lab/Pagination'
import { withStyles } from '@material-ui/core/styles';


const StyledPagination = withStyles((theme) => ({
    root: {
        '& .Mui-selected': {
          backgroundColor: '#FFFF00',
          color:'black',
        },
    },
}))((props: any) => <Pagination {...props} />)


// Customizable Area End

export default class PaginationDisplay extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

        
        <StyledPagination
        disabled={this.props.isNoRecordFound}
            test-id={"pagination_display"}
            style={this.props.styling ? this.props.styling : newStyles.paginationStyle}
            count={this.props.isNoRecordFound ? 0 : this.props.totalPages}
            page={this.props.currentPage}
            onChange={this.props.handlePageChange}
        />
        
               
    );

  }
}

// Customizable Area Start
const newStyles = {
    paginationStyle:{
      marginLeft: '1%',
      display:"flex",
      justifyContent:"center",
    }

}
// Customizable Area End
