import React from "react";

// Customizable Area Start
import {  
  IconButton,
  Snackbar,
  Button
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { createTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import AddSharpIcon from '@material-ui/icons/AddSharp';



// Customizable Area End

export default class ButtonDisplay extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.props.buttonType=='FileButton' ? 
        <Button 
          data-test-id={""}
          style={this.props.styling} 
          startIcon={this.props.startIcon && <AddSharpIcon style={this.props.iconStyling}/>}
          variant={this.props.variant} 
          component="label">
          <input
              type="file"
              hidden
              accept={this.props.accept}
              onChange={this.props.onChangeHandle}
        />
        {this.props.placeholder}
        </Button>
          : 
        <Button
            data-test-id={""}
            disabled={this.props.disabled}
            variant={this.props.variant} 
            style={this.props.styling}
            //startIcon={<this.props.startIcon style={this.props.iconStyling}/>}
            startIcon={this.props.startIcon && <AddSharpIcon style={this.props.iconStyling}/>}
            onClick={this.props.onClickHandle}>
            {this.props.name}
        </Button>}
      </>

    // <Button
    //   disabled={this.props.disabled}
    //   variant={this.props.variant} 
    //   style={this.props.styling}
    //   onClick={this.props.onClickHandle}>
    //   {this.props.name}
    // </Button>
     
    );
  }
}

// Customizable Area Start
// Customizable Area End
