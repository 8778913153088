import React from "react";

// Customizable Area Start
import {  
  IconButton,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { createTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';


// Customizable Area End

export default class Loading extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

        
    <Skeleton 
        variant={this.props.variant} 
        animation={this.props.animation}
        style={this.props.styling}>
    </Skeleton>
        
               
    );

  }
}

// Customizable Area Start
const newStyles = {
    tableContainer: {
        //height: '300px',
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        //height: '443px',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginBottom: '50px',
        marginTop: '25px',
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingLeft: '20px',
        paddingRight: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    tableHeaderRows: {
        lineSpacing: '17',
        letterSpacing: '-0.29',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
        //width: '240px',
    },
}
// Customizable Area End
